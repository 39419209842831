import { select, call, put, take, cancel, fork } from "redux-saga/effects";
import { getToken } from "../selectors";
import { apiGet } from "./callApi";
import apiRoutes from "../../constants/apiRoutes";
import types from '../actionTypes';
import { getSpinAndGoInstancesFailure, getSpinAndGoInstancesSucsess } from "../actionCreators/spinAndGoInstances";

function* processGetSpinAndGoInstancesRequest({ configId }) {
  try {
   const token = yield select(getToken);
  
    const response = yield call(apiGet, apiRoutes.GET_SPIN_AND_GO_INSTANCES, { configId, token });
    if (response.data?.success) {
      yield put(getSpinAndGoInstancesSucsess(response.data?.result));
    }
  } catch (e) {
    yield put(getSpinAndGoInstancesFailure(e));
  }
}

export function* watchGetSpinAndGoInstancesRequest() {
  let forked = null;
  while (true) {
    const action = yield take(types.GET_SPIN_AND_GO_INSTANCES_REQUEST);
    if (forked) yield cancel(forked);
    forked = yield fork(processGetSpinAndGoInstancesRequest, action);
  }
}