import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import VeloxPopupTemplate from "../../common/VeloxPopupTemplate";
import { closeDialog } from "../../../store/actionCreators/dialogManager";

const VeloxUnregister = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onClose = useCallback(
    () => dispatch(closeDialog("veloxUnregister")),
    [dispatch]
  );

  const onConfirm = useCallback(
    () => {
      onClose();
    },
    [dispatch]
  );

  return (
    <VeloxPopupTemplate
      className="velox-unregister-dialog"
      title={t("veloxUnregisterDialogTitle")}
      onClose={onClose}
      onConfirm={onConfirm}
      confirmText={t("ok")}
    />
  );
};

export default VeloxUnregister;
