import moment from "moment";
import { useTranslation } from "react-i18next";
import CountdownTimer from "../../../CountdownTimer";
import { useFormatCurrency } from "../../../../hooks/useFormatCurrency";
import TournamentButtonState from "../../../TournamentButtonState";
import { useOnGoningEvents } from "../OnGoningEventsProvider";
import Button from "../../../../components/common/Button";

const DesktopOnGoingTournamentItem = ({ tournament }) => {
  const { t } = useTranslation();
  const formatCurrency = useFormatCurrency();
  const { activeTab, closeOnGoingEventsDialog } = useOnGoningEvents();
  const {
    tournamentGames,
    endDate,
    prizePool,
    tournamentName,
    startDate,
    tournamentId,
  } = tournament;
  const isStartsIn = moment(startDate).isAfter(moment());

  return (
    <div className={`on-going-events-tourament-item on-going-events-tourament-item--${activeTab}`}>
      <div className="item">
        <div className="item__countdown-label-wrapper">
          <span className="item__countdown-label">{t(isStartsIn ? "startsIn" : "endsIn")}</span>
          <div className="item__countdown-wrapper">
              <CountdownTimer
                variant="thirdType"
                countdownTimestampMs={isStartsIn ? startDate : endDate}
                simple
                skeleton
                onEndText={isStartsIn ? t('starting') : t('finished')}
              />
          </div>
        </div>
        <div className="item__card-wrapper">
          <div className="item__card-image-id-wrapper">
            <span className="item__card-id">ID:{tournamentId}</span>
            <img
              src={tournamentGames[0].thumbnail}
              alt="thumbnail"
            />
          </div>
          <div className="item__card-right-side">
            <div className="item__card-prize-info-pos-wrapper">
              <span className="item__card-prize">{formatCurrency(prizePool.value, prizePool.currency, 2, prizePool?.type)}</span>
             {activeTab !== 'upcoming' &&  <div className="item__card-info-pos-wrapper">
                {activeTab === 'play' && <span className="item__card-info"># {t('spinsLeft')} <b>{tournament?.spins}</b> </span>}
                {activeTab === 'rebuy' && <span className="item__card-info"># {t('rebuy')} <span>{tournament?.rebuysLeft ?? tournament?.rebuy.count}/</span><b>{tournament?.rebuy.count}</b> </span>}
                <span className="item__card-pos">{t('pos')} <span>{tournament?.playerPosition}</span> </span>
              </div>}
            </div>
            <div className="item__card-name-button-wrapper">
              <span className="item__card-name" dangerouslySetInnerHTML={{__html: tournamentName}}/>
              <div className="item__tournament-button-state-wrapper">
                {activeTab === 'upcoming' ? <Button disabled >{t('play')}</Button> : <TournamentButtonState rubutButtonText="REBUY" cb={closeOnGoingEventsDialog} showBuyin tournament={tournament} />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DesktopOnGoingTournamentItem;
