import { take, fork, call, put, select, cancel } from 'redux-saga/effects';
import types from '../actionTypes';
import { apiPost } from './callApi';
import apiRoutes from '../../constants/apiRoutes';
import { getToken, isSelectedJackpotTab } from '../selectors';
import { casinoTournamentRegisterFailure, casinoTournamentRegisterSuccess } from '../actionCreators/casinoTournamentRegister';
import { selectCasinoTournament, updateCasinoTournaments } from '../actionCreators/casinoTournament';
import { openDialog } from '../actionCreators/dialogManager';
import { getPlayerStateInTournamentsRequest } from '../actionCreators/playerStateInTournaments';
import { updateCasinoTournamentsJackpots } from '../actionCreators/jackpot';
import { openToaster } from '../actionCreators/toaster';

function* casinoTournamentRegistration({ tournamentData }) {
  try {
    const token = yield select(getToken);
    const isJackpot = yield select(isSelectedJackpotTab);
    const gameData = tournamentData.tournamentGames[0];
    const params = {
      token: token,
      slot: gameData["id"],
      providerId: gameData["providerId"],
      tournamentId: tournamentData["tournamentId"],
      paymentType: tournamentData.paymentType,
    };
    if (tournamentData.password) params.password = tournamentData.password;
    const response = yield call(apiPost, apiRoutes[isJackpot ? "SPIN_AND_GO_INSTANCE_REGISTER" : "CASINO_TOURNAMENT_REGISTER"], params);
    if (!response || !response.data) return false;
    const { tournament } = response.data.result;
    yield put(casinoTournamentRegisterSuccess(tournament, tournament.tournamentId));
    yield put(selectCasinoTournament(tournament));
    if (isJackpot) {
      if (tournament?.enrolled < 3) {
        yield put(openDialog({ dialogType: 'waitingRoom', tournament }));
      }
      yield put(updateCasinoTournamentsJackpots(tournament));
    } else {
      yield put(updateCasinoTournaments(tournament));
      yield put(openDialog({ dialogType: 'successfullRegistration' }));
    }

    yield put(getPlayerStateInTournamentsRequest([tournament?.tournamentId], true));
    window.parent.postMessage({ name: "SUCCESSFULL_REGISTRATION", data: { id: tournament.tournamentId}}, "*");
  } catch (e) {
    yield put(openToaster({view: 'tournamentRegisterFailure'}));
    yield put(casinoTournamentRegisterFailure(e, tournamentData));
  }
}

function* watchcasinoTournamentRegister() {
  let forked = null;
  while (true) {
    const action = yield take(types.CASINO_TOURNAMENT_REGISTER_REQUEST);
    if (forked) yield cancel(forked);
    yield fork(casinoTournamentRegistration, action);
  }
}

export default function* casinoTournamentRegistrationRequests() {
  yield call(watchcasinoTournamentRegister);
}
