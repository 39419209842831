import { take, fork, cancel, call, put, select } from "redux-saga/effects";
import { accountingApiGet } from "./callApi";
import apiRoutes from "../../constants/apiRoutes";
import { getToken } from "../selectors";
import {
  getAccountBalanceFailure,
  getAccountBalanceSuccess,
} from "../actionCreators/getAccountBalance";
import types from "../actionTypes";

function* processGetAccountBalanceRequestFromHost() {
  try {
    const token = yield select(getToken);
    const params = { token };

    const response = yield call(
      accountingApiGet,
      apiRoutes.getAccountBalance,
      params
    );
    if (response.data.success) {
      yield put(getAccountBalanceSuccess(response.data.result.wallets));
    } else {
      yield put(getAccountBalanceFailure());
    }
  } catch (e) {
    yield put(getAccountBalanceFailure(e));
  }
}

function* watchGetAccountBalanceRequestFromHost() {
  let forked = null;
  while (true) {
    yield take([
      types.GET_ACCOUNT_BALANCE_REQUEST_FROM_HOST
    ]);
    if (forked) yield cancel(forked);
    forked = yield fork(processGetAccountBalanceRequestFromHost);
  }
}

export default function* getAccountBalanceRequestsFromHost() {
  yield call(watchGetAccountBalanceRequestFromHost);
}