import OnGoingEventsLayout from "./OnGoingEventsLayout";
import PortalContainer from "../../common/PortalContainer";
import { OnGoningEventsProvider } from "./OnGoningEventsProvider";
import './OnGoingEvents.scss';

const OnGoingEvents = () => {
  return (
    <PortalContainer id="on-going-events">
      <OnGoningEventsProvider>
        <OnGoingEventsLayout />
      </OnGoningEventsProvider>
    </PortalContainer>
  );
};

export default OnGoingEvents;
