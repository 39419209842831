import { classNames } from '../../../helpers/pureFunctions';
import Modal from '../../common/Modal';
import HowToPlayPage from '../../HowToPlayPage';
import './HowToPlay.scss'

const HowToPlay = ({ isMobile, isPortrait, isLandscape }) => {

    const howToPlayModalContainerClasses = classNames('how-to-play-modal-container', {
        'how-to-play-modal-container--portrait': isMobile && isPortrait,
        'how-to-play-modal-container--landscape': isMobile && isLandscape,
    });

    return (
        <Modal className="how-to-play-modal">
            <Modal.Header />
            <Modal.Body>
                <div className={howToPlayModalContainerClasses}>
                    <HowToPlayPage />
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default HowToPlay;
