import { t } from "i18next";
import GOSPoint from "../components/GOSPoint";

export const getTournamentBuyinInfo = (data, formatCurrency) => {
  const { currency, buyin, fee, methodsAllowed, costGOS, feeGOS } = data?.buyIn || {};
  const allowPaymentMain = methodsAllowed?.realMoney;
  const allowPaymentGOSPoint = methodsAllowed?.GOS;

  const allowRebuyMain = data?.rebuy?.methodsAllowed?.realMoney;

  const allowRebuyGOSPoint = data?.rebuy?.methodsAllowed?.GOS;

  const allowByTicket = methodsAllowed?.tourTicket;
  const allowRebuyTicket = data?.rebuy?.methodsAllowed?.tourTicket;

  const buyinMain = buyin + fee;

  const buyinGOSPoint = costGOS + feeGOS;

  const rebuyMain = data?.rebuy?.cost + data?.rebuy?.fee;
  const rebuyGOSPoint = data?.rebuy?.costGOS + data?.rebuy?.feeGOS;

  const formatForMainBuyin = formatCurrency(buyinMain, currency);
  const formatForMainRebuy = formatCurrency(rebuyMain, data?.rebuy?.currency);
  
  const formatForGOSPointBuyin = (amount) => (
    <div style={{ display: 'flex', alignItems: 'center' }} className="GOSPoint">
      <GOSPoint />
      <span style={{ marginLeft: 2 }}>{new Intl.NumberFormat().format(amount)}</span>
    </div>
  );
  const GOSPointBuyin = formatForGOSPointBuyin(buyinGOSPoint);
  const GOSPointRebuy = formatForGOSPointBuyin(rebuyGOSPoint);

  const formatForGOSPointBuyinWithoutIcon = (amount) => (
    <div style={{ display: 'flex', alignItems: 'center' }} className="GOSPoint">
      <GOSPoint />
      <div style={{ marginLeft: 2 }}>{new Intl.NumberFormat().format(amount)}</div>
    </div>
  );
  const GOSPointBuyinWithoutIcon = formatForGOSPointBuyinWithoutIcon(buyinGOSPoint);
  const GOSPointRebuyinWithoutIcon = formatForGOSPointBuyinWithoutIcon(rebuyGOSPoint);

  let buyinValue;
  let rebuyValue;
  let buyinValueWithoutIcon;
  let rebuyValueWithoutIcon;

  if (!allowPaymentGOSPoint && allowPaymentMain) {
    buyinValue = formatForMainBuyin;
    buyinValueWithoutIcon = formatForMainBuyin;
  }

  if (!allowRebuyGOSPoint && allowRebuyMain) {
    rebuyValue = formatForMainRebuy;
    rebuyValueWithoutIcon = formatForMainRebuy;
  }
  
  if (!allowPaymentMain && allowPaymentGOSPoint) {
    buyinValue = GOSPointBuyin
    buyinValueWithoutIcon = GOSPointBuyinWithoutIcon
  }
  if (!allowRebuyMain && allowRebuyGOSPoint) {
    rebuyValue = GOSPointRebuy;
    rebuyValueWithoutIcon = GOSPointRebuyinWithoutIcon;
  }

  if (allowPaymentMain && allowPaymentGOSPoint) {
    buyinValue = (
      <div className="fl align-center">
        {formatForMainBuyin} / {GOSPointBuyin}
      </div>
    );
    buyinValueWithoutIcon = (
      <div className="fl align-center">
        {formatForMainBuyin} / {GOSPointBuyinWithoutIcon}
      </div>
    );
  }
  if (allowRebuyMain && allowRebuyGOSPoint) {
    rebuyValue = (
      <div className="fl align-center">
        <span>{formatForMainRebuy}</span><span style={{ marginLeft: 2 }}>/</span><span style={{ marginLeft: 2 }}>{GOSPointRebuy}</span>
      </div>
    );
    rebuyValueWithoutIcon = (
      <div className="fl align-center">
        {formatForMainRebuy} / {GOSPointRebuyinWithoutIcon}
      </div>
    );
  }
  if (allowByTicket && !allowPaymentGOSPoint && !allowPaymentMain) {
    buyinValue = (
      <div className="fl align-center">
        <i className="icon-ticket" /> <span style={{ marginLeft: '4px' }}>{t("ticket")}</span>
      </div>
    )
    buyinValueWithoutIcon = (
      <div className="fl align-center">
        <i className="icon-ticket" /> <span style={{ marginLeft: '4px' }}>{t("ticket")}</span>
      </div>
    )
  }
  if (allowRebuyTicket && !allowRebuyMain && !allowRebuyGOSPoint) {
    rebuyValue = (
      <div className="fl align-center">
        <i className="icon-ticket" /> <span style={{ marginLeft: '4px' }}>{t("ticket")}</span>
      </div>
    )
    rebuyValueWithoutIcon = (
      <div className="fl align-center">
        <i className="icon-ticket" /> <span style={{ marginLeft: '4px' }}>{t("ticket")}</span>
      </div>
    )
  }
  if (buyin === 0 && !allowByTicket) {
    buyinValue = <span className="buyin-free">{t('free')}</span>
    buyinValueWithoutIcon = <span className="buyin-free">{t('free')}</span>
  }

  if (data?.rebuy?.cost === 0 && !allowRebuyTicket) {
    rebuyValue = <span className="buyin-free">{t('free')}</span>
    rebuyValueWithoutIcon = <span className="buyin-free">{t('free')}</span>
  }

  return {
    allowPaymentMain,
    allowPaymentGOSPoint,
    buyinValue,
    formatForMainBuyin,
    GOSPointBuyin,
    formatForGOSPointBuyin,
    GOSPointBuyinWithoutIcon,
    GOSPointRebuyinWithoutIcon,
    formatForGOSPointBuyinWithoutIcon,
    buyinValueWithoutIcon,
    rebuyValueWithoutIcon,
    allowByTicket,
    rebuyValue,
    allowRebuyMain,
    allowRebuyGOSPoint,
    formatForMainRebuy,
    GOSPointRebuy
  };
};
