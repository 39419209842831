import { memo, useMemo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Virtuoso } from 'react-virtuoso';
import {
  casinoTournamentPlayersRequest,
  casinoTournamentPlayersReset,
} from "../../store/actionCreators/casinoTournamentPlayers";
import {
  getCasinoTournamentPlayers,
  getPlayerPositionFromAPI,
  getPlayerResult,
  getTournamentPlayers,
  getUserId,
  getUsersAvatars,
  isCasinoTournamentPlayersPending,
  isSelectedJackpotTab,
} from "../../store/selectors";
import Skeleton from "../Skeleton";
import { useFormatCurrency } from "../../hooks/useFormatCurrency";
import { classNames, isBetweenOrEqual } from "../../helpers/pureFunctions";
import { isMobile, useMobileOrientation } from "react-device-detect";
import GOSPoint from "../GOSPoint";
import "./LeaderboardWidget.scss";

const LeaderboardWidget = ({ tournament, isGame }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isLandscape } = useMobileOrientation();
  const playersFromAPI = useSelector(getCasinoTournamentPlayers)(
    tournament?.tournamentId
  );
  const playersFromSocket = useSelector(getTournamentPlayers)?.(
    tournament?.tournamentId
  );
  const isJackpot = useSelector(isSelectedJackpotTab);
  const self = useSelector(getPlayerPositionFromAPI)(tournament.tournamentId);
  const players = isGame && isJackpot ? playersFromSocket : playersFromAPI;
  const avatars = useSelector(getUsersAvatars);
  const userId = useSelector(getUserId);
  const isPlayersPending = useSelector(isCasinoTournamentPlayersPending);
  const formatCurrency = useFormatCurrency();
  const [hasAnimation, setAnimation] = useState(false);
  const [offset, setOffset] = useState(0);
  const pageSize = 20;
  const playerResult = useSelector(getPlayerResult);

  const currentStructure = useMemo(() => {
    const { enrolled, tournamentAward } = tournament || {};
    const { structure } = tournamentAward || {};
    const defaultStructure = structure?.[0];
    return enrolled === 0 || structure?.length === 1
      ? defaultStructure
      : structure.find(({ playersCount }) =>
          isBetweenOrEqual(enrolled, playersCount.min, playersCount.max)
        ) || structure[structure.length - 1];
  }, [tournament]);

  useEffect(() => {
    let timer;
    if (hasAnimation) {
      timer = setTimeout(() => {
        setAnimation(false);
      }, 400);
    }
    return () => clearTimeout(timer);
  }, [hasAnimation]);

  useEffect(() => {
    if (tournament?.tournamentId) {
      dispatch(casinoTournamentPlayersRequest({ id: tournament.tournamentId, offset, limit: pageSize }));
    }
    return () => {
      dispatch(casinoTournamentPlayersReset());
    };
  }, [dispatch, tournament?.tournamentId]);

  const loadMorePlayers = () => {
    if (players?.length >= tournament?.enrolled) {
      return;
    }
    if (!isPlayersPending) {
      setOffset((prevOffset) => {
        dispatch(casinoTournamentPlayersRequest({ id: tournament.tournamentId, offset: prevOffset + pageSize, limit: pageSize }));
        return prevOffset + pageSize;
      });
    }
  };

  const computeAwardArray = () => {
    return players?.reduce((result, _, index) => {
      const award = currentStructure?.awards?.[index]?.award;
      const { placesFrom, placesTo } = currentStructure?.awards?.[index] || {};

      if (award && placesFrom !== undefined && placesTo !== undefined) {
        const numberOfAwards = placesTo - placesFrom + 1;
        result.push(
          ...Array(numberOfAwards).fill({
            currency: award.currency,
            value: award.value,
            type: award?.type,
            url: award?.url,
            name: award?.name,
          })
        );
      }

      return result;
    }, []);
  };

  const loopIconClassnames = classNames('leaderboard-widget__icon-loop', 'icon-loop', {
    'leaderboard-widget__icon-loop--spinner': hasAnimation || isPlayersPending,
  });

  const getPlayers = () => {
    if (isGame && !isJackpot) {
      dispatch(casinoTournamentPlayersRequest({ id: tournament.tournamentId, offset: 0, limit: players?.length || pageSize }));
      setAnimation(true);
    }
  };

  return (
    <div className={`leaderboard-widget ${isMobile && isLandscape ? 'leaderboard-widget--landscape': ''} ${isGame && !isJackpot ? 'leaderboard-widget--clickable' : ''}`}>
      <div className="leaderboard-widget__header" onClick={getPlayers}>
        <span className="leaderboard-widget__text">{t("leaderboard")}</span>
        {isGame && !isJackpot && <i className={loopIconClassnames} />}
        <span className="leaderboard-widget__user-count-wrapper">
          <i className="icon-user"></i>
          <span>{tournament?.enrolled}</span>
        </span>
      </div>
      <div className="leaderboard-widget__body">
        <div className="leaderboard-widget__table">
          <div className="leaderboard-widget__table-header">
            <span className="leaderboard-widget__table-header-row position">
              {t("leaderboardPosition")}
            </span>
            <span className="leaderboard-widget__table-header-row username">
              {t("username")}
            </span>
            <span className="leaderboard-widget__table-header-row score">
              {t("score")}
            </span>
            {(!isMobile || (isMobile && !isLandscape)) && (
              <span className="leaderboard-widget__table-header-row prize">
                {t("prize")}
              </span>
            )}
          </div>
          <div className="leaderboard-widget__table-body">
              <Virtuoso
                data={players}
                endReached={loadMorePlayers}
                itemContent={(index, player) => (
                  <div
                    className={`leaderboard-widget__table-body-row-wrapper ${
                      player?.accountId === self?.accountId ? 'leaderboard-widget__table-body-row-wrapper--self' : ''
                    }`}
                  >
                    <span className="leaderboard-widget__table-body-row position">{`${index + 1}.`}</span>
                    <div className="leaderboard-widget__table-body-row username">
                      {avatars?.[player?.accountId]?.imagePath ? (
                        <img
                          src={avatars[player?.accountId].imagePath}
                          alt="avatar"
                        />
                      ) : (
                        <div className="leaderboard-widget__default-avatar">
                          <i className="icon-avatar" />
                        </div>
                      )}
                      <span>{player?.nickname || player?.name}</span>
                    </div>
                    <span className="leaderboard-widget__table-body-row score">
                      {player?.points || player?.score}
                    </span>
                    {(!isMobile || (isMobile && !isLandscape)) && (
                      <span className="leaderboard-widget__table-body-row prize">
                        {computeAwardArray()?.[index] &&
                        computeAwardArray()?.[index]?.currency === "GOS" ? (
                          <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }} className="GOSPoint">
                            <GOSPoint width={12} />
                            <span style={{ marginLeft: 4 }}>{computeAwardArray()?.[index]?.value}</span>
                          </div>
                        ) : (
                          computeAwardArray()?.[index]?.type === "externalPrize"
                            ? computeAwardArray()?.[index]?.url
                              ? <a target="_blank" href={computeAwardArray()?.[index]?.url} >{computeAwardArray()?.[index]?.name}</a>
                              : computeAwardArray()?.[index]?.name
                            : formatCurrency(
                                computeAwardArray()?.[index]?.value,
                                computeAwardArray()?.[index]?.currency,
                                2,
                                computeAwardArray()?.[index]?.type,
                              )
                        )}
                      </span>
                    )}
                  </div>
                )}
              />
          </div>
          {tournament?.playerPosition && (
            <div className="leaderboard-widget__table-body-row-wrapper leaderboard-widget__table-body-row-wrapper--self">
              <span className="leaderboard-widget__table-body-row position">{`${playerResult ? playerResult?.playerPosition : tournament?.playerPosition}.`}</span>
              <div className="leaderboard-widget__table-body-row username">
                {avatars?.[userId]?.imagePath ? (
                  <img
                    src={avatars[userId].imagePath}
                    alt="avatar"
                  />
                ) : (
                  <div className="leaderboard-widget__default-avatar">
                    <i className="icon-avatar" />
                  </div>
                )}
                <span>{t('you')}</span>
              </div>
              <span className="leaderboard-widget__table-body-row score">
                {playerResult ? playerResult?.playerPoints : tournament?.playerPoints}
              </span>
              {(!isMobile || (isMobile && !isLandscape)) && (
                <span className="leaderboard-widget__table-body-row prize">
                  {computeAwardArray()?.[tournament?.playerPosition - 1] &&
                  computeAwardArray()?.[tournament?.playerPosition - 1].currency === "GOS" ? (
                    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }} className="GOSPoint">
                      <GOSPoint width={12} />
                      <span style={{ marginLeft: 4 }}>{computeAwardArray()?.[tournament?.playerPosition - 1]?.value}</span>
                    </div>
                  ) : (
                    computeAwardArray()?.[tournament?.playerPosition - 1]?.type === "externalPrize"
                      ? computeAwardArray()?.[tournament?.playerPosition - 1]?.url
                        ? <a target="_blank" href={computeAwardArray()?.[tournament?.playerPosition - 1]?.url} >{computeAwardArray()?.[tournament?.playerPosition - 1]?.name}</a>
                        : computeAwardArray()?.[tournament?.playerPosition - 1]?.name
                      : formatCurrency(
                          computeAwardArray()?.[tournament?.playerPosition - 1]?.value,
                          computeAwardArray()?.[tournament?.playerPosition - 1]?.currency,
                          2,
                          computeAwardArray()?.[tournament?.playerPosition - 1]?.type,
                        )
                  )}
                </span>
              )}
            </div>
          )}
                                      {isPlayersPending && <div className="leaderboard-widget__skeletons-wrapper">
                <div className="leaderboard-widget__skeleton-wrapper position">
                  <Skeleton height={15} width="100%" />
                </div>
                <div className="leaderboard-widget__skeleton-wrapper username">
                  <Skeleton height={15} width="100%" />
                </div>
                <div className="leaderboard-widget__skeleton-wrapper score">
                  <Skeleton height={15} width="100%" />
                </div>
                {(!isMobile || (isMobile && !isLandscape)) && (
                  <div className="leaderboard-widget__skeleton-wrapper prize">
                    <Skeleton height={15} width="100%" />
                  </div>
                )}
              </div>}
        </div>
      </div>
    </div>
  );
};

export default memo(LeaderboardWidget);
