import React from "react";
import { useTranslation } from "react-i18next";
import { classNames } from "../../../helpers/pureFunctions";
import "./PopUpItemInfo.scss";

const PopUpItemInfo = ({ title, titleInfo, value, descriptionText, variant = "default" }) => {
  const { t } = useTranslation();

  const popUpItemInfoContainerClasses = classNames(
    "pop-up-item-info-container",
    {
      [variant]: variant,
    }
  );

  return (
    <div className={popUpItemInfoContainerClasses}>
      <div className="pop-up-item-info-container-input">
        <div className="pop-up-item-info-container-input__label">
          <span className="pop-up-item-info-container-input__label--title">
            {t(title)}:
          </span>
          {!!titleInfo && (
            <span className="pop-up-item-info-container-input__label--title-info">
              {titleInfo}
            </span>
          )}
        </div>
        <div className="pop-up-item-info-container-input__value">{value}</div>
      </div>
      {!!descriptionText && (
        <div className="pop-up-item-info-container__description-text">
          {t(descriptionText)}
        </div>
      )}
    </div>
  );
};

export default PopUpItemInfo;
