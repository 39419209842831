import types from "../actionTypes";

export const getSpinAndGoInstancesRequest = (configId) => ({
  type: types.GET_SPIN_AND_GO_INSTANCES_REQUEST,
  configId,
});

  
export const getSpinAndGoInstancesFailure = (e) => ({
  type: types.GET_SPIN_AND_GO_INSTANCES_FAILURE,
	exception: e,
});

  
export const getSpinAndGoInstancesSucsess = (payload) => ({
  type: types.GET_SPIN_AND_GO_INSTANCES_SUCSESS,
  payload,
});

  
export const getSpinAndGoInstancesReset = () => ({
  type: types.GET_SPIN_AND_GO_INSTANCES_RESET,
});
