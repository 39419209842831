import { call, cancel, fork, put, select, take } from "redux-saga/effects";
import apiRoutes from "../../constants/apiRoutes";
import { successMessages, successReadMessage } from "../actionCreators/messages";
import types from "../actionTypes";
import { getSkinId, getToken, getUserId } from "../selectors";
import { callMsgApi, callMsgApiGet } from "./callApi";
import { openDialog } from "../actionCreators/dialogManager";

function* markMessageAsRead({ messageId }) {
  try {
    const token = yield select(getToken);
    const userId = yield select(getUserId);
    const params = {
      status: 'read',
      accountId: userId,
      messageId,
      token
    };
    yield call(callMsgApi, apiRoutes.SUBSCRIPTION, params);
    yield put(successReadMessage(messageId));
  } catch (e) {
    console.log("Failed to change the message status.");
  }
}

export function* watchReadMessage() {
  let forked = null;
  while (true) {
    const action = yield take(types.READ_MESSAGE_REQUEST);
    if (forked) yield cancel(forked);
    forked = yield fork(markMessageAsRead, action);
  }
}

function* getPublicMessages() {
  try {
    const userId = yield select(getUserId);
    const skinId = yield select(getSkinId);
    const token = yield select(getToken);
    const params = {
      accountId: userId,
      skinId: skinId,
      token,
    };
    const response = yield call(callMsgApiGet, 'api/message', params);
    const tourFinishedMessages = response.data?.result?.messages?.[userId].filter(item => item?.templateId === "tourFinished" && item?.status === "unread");
    yield put(successMessages(response.data.result.messages));
    if (tourFinishedMessages?.length) {
      for (let i = 0; i < tourFinishedMessages.length; i++) {
        const element = tourFinishedMessages[i];
        const dialogType = `tournamentEnd_${i + 1}`;
        yield put(openDialog({ dialogType, payload: { ...element?.data, messageId: element?._id } }));
      }
    }
  } catch (e) {
    console.log(e);
  }
}
  
export function* watchPublicMessages() {
  while (true) {
    const action = yield take(types.PUBLIC_MESSAGES_REQUEST);
    yield fork(getPublicMessages, action.value);
  }
}