import { useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next';

export const useLanguageChange = (language) => {
  const { i18n } = useTranslation();
  const onChange = useCallback(value => {
    i18n.changeLanguage(value);
  }, [i18n]);

  useEffect(() => {
    onChange(language);
  }
  , [language, onChange]);
};
