import React from 'react';
import { useMobileOrientation } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import TournamentGameDetailsInfoWidget from '../TournamentGameWidget/TournamentGameDetailsInfoWidget';

import { classNames } from '../../../../helpers/pureFunctions';

import { casinoTournamentPlayersRequest } from '../../../../store/actionCreators/casinoTournamentPlayers';
import { casinoTournamentRegisterReset } from '../../../../store/actionCreators/casinoTournamentRegister';
import { resetTournamentPlayers } from '../../../../store/actionCreators/tournamentCasinoPlayers';
import { resetTournamentGameUrl } from '../../../../store/actionCreators/tournamentGameUrl';
import { getCasinoTournament, getPlayerResult } from '../../../../store/selectors';

import './TournamentGameMobileHeader.scss'
import { resetToggleJackpotGameFromHost } from '../../../../store/actionCreators/toggleJackpotGameFromHost';

const TournamentGameMobileHeader = ({ onClick, gameWidgetState }) => {
  const { isLandscape } = useMobileOrientation();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const tournament = useSelector(getCasinoTournament);
  const playerResult = useSelector(getPlayerResult);


  const { totalSpins, actualTotalSpins } = tournament;

  const onGameClose = () => {
    dispatch(resetTournamentGameUrl());
    dispatch(resetToggleJackpotGameFromHost(false));
    dispatch(casinoTournamentRegisterReset());
    dispatch(resetTournamentPlayers());
    dispatch(casinoTournamentPlayersRequest({ id: tournament.tournamentId, offset: 0, limit: 20 }));
  }

  const tournamentGameMobileHeaderWrapperClasses = classNames(
    'tournament-game-mobile-header-wrapper',
    {
      'landscape': isLandscape
    }
  )

  return (
    <div className={tournamentGameMobileHeaderWrapperClasses}>
      {isLandscape && (
        <div className='tournament-game-mobile-header-wrapper__widget-toggler-section' onClick={onClick}>
          <span className="tournament-game-widget-toggle-button__img">
            <img src='/images/tournamentGameWidget/tournament.svg' alt='' />
          </span>
          <span className="tournament-game-widget-toggle-button__text">
            {gameWidgetState ? t('close') : t('open')}
          </span>
          <span className={`tournament-game-widget-toggle-button__icon ${gameWidgetState ? "opened" : "closed"}`}>
            <i className="icon-arrow-right" /><i className="icon-arrow-right" />
          </span>
        </div>
      )}
      <div className='tournament-game-mobile-header-wrapper__user-info-section'>
        {isLandscape && (
          <div className='tournament-game-mobile-header-wrapper__user-info-section--item'>
            <span className='tournament-game-mobile-header__tournament-img'>
              <img src='/images/tournamentGameWidget/tournament.svg' alt='' />
            </span>
          </div>
        )}
        {!isLandscape && (
          <div className='tournament-game-mobile-header-wrapper__user-info-section--item'>
            <div className='tournament-game-widget-toggle-button-portrait' onClick={onClick}>
              <span className="tournament-game-widget-toggle-button-portrait__img">
                <img src='/images/tournamentGameWidget/tournament.svg' alt='' />
              </span>
              <span className={`tournament-game-widget-toggle-button-portrait__icon ${gameWidgetState ? "opened" : "closed"}`}>
                <i className="icon-arrow-right" /><i className="icon-arrow-right" />
              </span>
            </div>
          </div>
        )}
        <div className='tournament-game-mobile-header-wrapper__user-info-section--item'>
          <TournamentGameDetailsInfoWidget title="spins" info={playerResult ? playerResult?.spinsLeft : tournament?.spins} hasProgressBar progressTarget={actualTotalSpins || totalSpins} progressStatus={playerResult ? playerResult?.spinsLeft : tournament?.spins} variant={'header'} />
        </div>
        <div className='tournament-game-mobile-header-wrapper__user-info-section--item'>
          <TournamentGameDetailsInfoWidget title="score" info={playerResult ? playerResult?.playerPoints : tournament?.playerPoints} variant={'header'} />
        </div>
        <div className='tournament-game-mobile-header-wrapper__user-info-section--item'>
          <TournamentGameDetailsInfoWidget title="position" info={playerResult ? playerResult?.playerPosition : tournament?.playerPosition} variant={'header'} />
        </div>
      </div>
      <div className='tournament-game-mobile-header-wrapper__close-game-section'>
        <button className='close-tournament-game-button' onClick={isLandscape ? onGameClose : gameWidgetState ? onClick : onGameClose}>
          <i className={`icon-${isLandscape ? 'home' : gameWidgetState ? 'cross' : 'home'}`} />
        </button>
      </div>
    </div>
  )
}

export default TournamentGameMobileHeader;