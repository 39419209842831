import {
  take,
  fork,
  cancel,
  call,
  put,
  select,
  delay,
  all,
} from "redux-saga/effects";
import { apiGetLobbyURL } from "./callApi";
import apiRoutes from "../../constants/apiRoutes";
import types from "../actionTypes";
import {
  casinoTournamentsFailure,
  casinoTournamentsSuccess,
  selectCasinoTournament,
} from "../actionCreators/casinoTournament";
import {
  getAllSharedToursSeparately,
  getCasinoTournament,
  getCasinoTournaments,
  getSelectedTournament,
  getSkinId,
  getToken,
  getTournamentGameUrl,
  isSelectedJackpotTab,
} from "../selectors";
import tournamentConfig from "../../configs/tournamentConfig";
import { getPlayerStateInTournamentsRequest } from "../actionCreators/playerStateInTournaments";
import { setTournament } from "../actionCreators/setTournament";

function* processCasinoTournamentsRequest({
  checkResumePopup,
  openRegisterPopup,
  setFirstTournamentAsSelected,
  payload,
}) {
  try {
    const token = yield select(getToken);
    const skinId = yield select(getSkinId);
    const selectedTournament = yield select(getCasinoTournament);
    const selectedListTournament = yield select(getSelectedTournament);
    const isJackpot = yield select(isSelectedJackpotTab);
    const allSharedToursSeparately = yield select(getAllSharedToursSeparately);

    const prevTournaments = yield select(getCasinoTournaments);

    const params = { skinId: skinId, tournamentType: "regular" };
    if (token) params.token = token;
    if (allSharedToursSeparately && allSharedToursSeparately === "true")
      params.getAllSharedToursSeparately = true;
    const response = yield call(
      apiGetLobbyURL,
      apiRoutes.GET_CASINO_TOURNAMENTS,
      params
    );
    const newSelectedTournament = response.data.result?.find(
      (item) => item?.tournamentId === selectedTournament?.tournamentId
    );
    const newListSelectedTournament = response.data.result?.find(
      (item) => item?.tournamentId === selectedListTournament?.tournamentId
    );
    if (response.data) {
      const tournamentsWithoutSpecialView = response.data.result
        ?.filter(
          (item) =>
            !item?.specialView ||
            (item?.specialView && item.tournamentState === "announce")
        )
        .map((item) => ({ ...item, specialView: false }));
      const onGoingSpecialTournaments = response.data.result?.filter(
        (item) =>
          item?.specialView &&
          item.tournamentState !== "finished" &&
          item.tournamentState !== "announce"
      );
      const finsihedSpecialTournaments = response.data.result?.filter(
        (item) => item?.specialView && item.tournamentState === "finished"
      );

      const tournaments = [
        ...onGoingSpecialTournaments,
        ...tournamentsWithoutSpecialView,
        ...finsihedSpecialTournaments,
      ];

      if (newSelectedTournament) {
        yield put(selectCasinoTournament(newSelectedTournament));
      }
      if (!newListSelectedTournament && newListSelectedTournament) {
        yield put(setTournament(newListSelectedTournament));
      }
      yield put(casinoTournamentsSuccess(tournaments));
      if (
        !isJackpot &&
        (!prevTournaments?.length || setFirstTournamentAsSelected)
      ) {
        yield put(setTournament(tournaments[0]));
      }
      if (token) {
        yield put(
          getPlayerStateInTournamentsRequest(
            [...new Set(tournaments.map((item) => item?.tournamentId))],
            false,
            checkResumePopup,
            openRegisterPopup
          )
        );
      }
    } else {
      yield put(casinoTournamentsFailure());
    }
  } catch (e) {
    yield put(casinoTournamentsFailure(e));
  }
}

function* watchCasinoTournamentsRequest() {
  let forked = null;

  while (true) {
    const action = yield take([
      types.GET_CASINO_TOURNAMENTS_REQUEST,
      types.SET_SELECTED_TAB,
    ]);
    const isJackpot = yield select(isSelectedJackpotTab);

    while (true) {
      yield delay(tournamentConfig.updateInterval);

      if (forked) {
        yield cancel(forked);
      }
      forked = yield fork(processCasinoTournamentsRequest, action);

      const newIsJackpot = yield select(isSelectedJackpotTab);
      const tournamentGameUrl = yield select(getTournamentGameUrl);

      if (tournamentGameUrl) {
        yield cancel(forked);
        break; // Stop polling if tournamentGameUrl is true
      }

      if (newIsJackpot) {
        yield cancel(forked);
        break;
      }
    }

    if (isJackpot || (action.type === types.SET_SELECTED_TAB && action?.init)) {
      if (forked) {
        yield cancel(forked);
        forked = null;
      }
      continue; // Skip the rest of the loop and wait for the next action
    }

    forked = yield fork(processCasinoTournamentsRequest, action);
  }
}

function* watchCasinoTournamentsRequestWithoutDelay() {
  let forked;
  while (true) {
    const action = yield take([
      types.GET_CASINO_TOURNAMENTS_REQUEST_WITHOUT_DELAY,
      types.LOGIN_SET_QUERY_PARAMS,
      types.LOGOUT_SET_QUERY_PARAMS,
    ]);
    if (forked) yield cancel(forked);
    forked = yield fork(processCasinoTournamentsRequest, action);
  }
}

function* watchCasinoTournamentsUpdateWithoutDelay() {
  let forked;
  while (true) {
    const action = yield take(types.CASINO_TOURNAMENTS_UPDATE_WITHOUT_DELAY);
    if (forked) yield cancel(forked);
    forked = yield fork(processCasinoTournamentsRequest, action);
  }
}

export default function* casinoTournamentsRequests() {
  yield all([
    call(watchCasinoTournamentsRequest),
    call(watchCasinoTournamentsRequestWithoutDelay),
    call(watchCasinoTournamentsUpdateWithoutDelay),
  ]);
}
