import { memo } from "react";
import { useTranslation } from "react-i18next";
import Version from "../common/Version.js/Version";
import "./Footer.scss";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <div className="footer">
      <span className="footer__copyright">
        {t("footerText", { year: new Date().getFullYear() })}
      </span>
      <div className="footer__version-wrapper">
        <Version />
      </div>
    </div>
  );
};

export default memo(Footer);
