import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import { closeDialog } from '../../../store/actionCreators/dialogManager';
import { useFormatCurrency } from '../../../hooks/useFormatCurrency';
import PortalContainer from '../../common/PortalContainer';
import Wheel from '../../Wheel';
import { setJackpotPrizeGenerationBool } from '../../../store/actionCreators/jackpot';
import './PrizePoolGenerator.scss';

const PrizePoolGeneratorDialog = ({ tournament }) => {
  const { t } = useTranslation();
  const [showGif, setShowGif] = useState(false);
  const [boxText, setBoxText] = useState(tournament?.buyIn?.buyin)
  const formatCurrency = useFormatCurrency();
  const dispatch = useDispatch();

  useEffect(() => {
    return () => dispatch(setJackpotPrizeGenerationBool(false));
  }, []);

  const segmentsObj = {
    2: [
      'x1.5',
      'x1000',
      'x10',
      'x3',
      'x100',
      'x1.5',
      'x1000',
      'x10',
      'x3',
      'x100',
    ],
    3: [
      'x2',
      'x1000',
      'x15',
      'x5',
      'x100',
      'x2',
      'x1000',
      'x15',
      'x5',
      'x100',
    ],
  };

  const onFinished = () => {
    setBoxText(tournament?.prizePool.value);
    setShowGif(true);
    dispatch(setJackpotPrizeGenerationBool(true));
    setTimeout(() => dispatch(closeDialog('prizePoolGenerator')), 6000);
  };
const segments = segmentsObj?.[tournament?.maxPlayersCount] || segmentsObj?.[2];
const segmentIndex = segments.map(item => Number(item.slice(1) * tournament?.buyIn?.buyin)).findIndex(item => item === tournament?.prizePool.value);

  return (
    <PortalContainer
      selector=".tournament-game__iframe-wrapper"
      id="prize-pool-generator-dialog"
      className={isMobile ? 'mobile': ''}
    >
      <div className="prize-pool-generator-dialog">
        <div className="prize-pool-generator-dialog__wheel-wrapper">
          <Wheel
            segments={segments}
            winningSegment={segments[segmentIndex >= 0 ? segmentIndex : 0]}
            onFinished={onFinished}
            isOnlyOnce={true}
            size={290}
            upDuration={500}
            downDuration={1000}
          />
        </div>
        <div className="prize-pool-generator-dialog__arrow" />
        <div className="prize-pool-generator-dialog__prize-box">
        {showGif && <div className="prize-pool-generator-dialog__congrats-animation" />}
          <span>{formatCurrency(boxText,tournament?.prizePool.currency)}</span>
        </div>
        <span>{t('generatingPrizePool')}</span>
      </div>
    </PortalContainer>
  );
};

export default PrizePoolGeneratorDialog;
