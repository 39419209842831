import { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUsername } from "../../store/selectors";
import { openDialog } from "../../store/actionCreators/dialogManager";
import Avatar from "../Avatar";
import "./AvatarWidget.scss";

const AvatarWidget = () => {
  const dispatch = useDispatch();
  const username = useSelector(getUsername);

  const openAvatarChangeDialog = () => dispatch(openDialog({ dialogType: 'userSettings' }));

  return <div className="avatar-widget" onClick={openAvatarChangeDialog}>
    <span className="avatar-widget__username">{username}</span>
    <div className="avatar-widget__avatar-wrapper">
      <Avatar size={35} />
    </div>
  </div>;
};

export default memo(AvatarWidget);
