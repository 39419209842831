import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getTournamentBuyinInfo } from "../../../helpers/getTournamentBuyinInfo";
import { useFormatCurrency } from "../../../hooks/useFormatCurrency";
import { casinoTournamentRegisterReset } from "../../../store/actionCreators/casinoTournamentRegister";
import { getRegisterErrorData, getSpinAndGORegisterErrorData, getWallet, isSelectedJackpotTab } from "../../../store/selectors";
import PopUpDialogTemplate from "../../common/PopUpDialogTemplate";
import PopUpItemInfo from "../../common/PopUpItemInfo";
import { createSpinAndGoRegisterReset } from "../../../store/actionCreators/spinAndGoRegister";
import "./RegistrationErrorLowBalance.scss";


const RegistrationErrorLowBalance = () => {
  const formatCurrency = useFormatCurrency();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const regularFailData = useSelector(getRegisterErrorData);
  const spinAndGoFailData = useSelector(getSpinAndGORegisterErrorData);
  const isJackpot = useSelector(isSelectedJackpotTab);
  const failData = isJackpot ? spinAndGoFailData : regularFailData;
  const { formatForMainBuyin, GOSPointBuyinWithoutIcon, formatForGOSPointBuyinWithoutIcon } = getTournamentBuyinInfo(failData, formatCurrency);
  const { paymentType, buyIn: { currency } } = failData;
  const GOSWallet = useSelector(getWallet)("GOS");
  const mainWallet = useSelector(getWallet)(currency);

  const onClose = useCallback(() => {
    dispatch(isJackpot ? createSpinAndGoRegisterReset() : casinoTournamentRegisterReset());
  }, [dispatch, isJackpot]);

  return (
    <PopUpDialogTemplate
      className="tournament-register-error-low-balance-pop-up"
      dialogName="tournamentRegisterErrorLowBalancePopUp"
      onClose={onClose}
      onConfirm={onClose}
      confirmText={t("ok")}
    >
      <div className="tournament-register-error-low-balance">
        {<PopUpItemInfo
          title={'price'}
          value={paymentType === 'GOS' ? GOSPointBuyinWithoutIcon : formatForMainBuyin}
        />}
        {<PopUpItemInfo
          title={'yourBalance'}
          value={paymentType === 'GOS' ? formatForGOSPointBuyinWithoutIcon(GOSWallet.balance) : formatCurrency(mainWallet.balance, mainWallet.currency)}
          variant={'danger'}
          descriptionText={'replenishYourBalance'}
        />}
      </div>
    </PopUpDialogTemplate>
  );
};

export default RegistrationErrorLowBalance;
