import { useSelector } from "react-redux";
import { locales } from "../constants/locales";
import { getLocalExchangeRates, getSelectedCurrency, getShowFormattedAmountWithDot } from "../store/selectors";
import GOSPoint from "../components/GOSPoint";

export const useFormatCurrency = () => {
  const selectedCurrency = useSelector(getSelectedCurrency);
  const localExchangeRates = useSelector(getLocalExchangeRates);
  const showFormattedAmountWithDot = useSelector(getShowFormattedAmountWithDot);
  const isShowFormattedAmountWithDot = showFormattedAmountWithDot === 'true';

  const formatAmount = (amount, currency, fixedDecimalCount, type) => {
    if (String(amount) === '0' || amount < 0) return'0';
    if (!amount) return;
    if (!currency) {
      if (type === "tourTicket") {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
            className="tour-ticket-prize"
          >
            <i
              style={{
                marginRight: "4px",
                fontSize: "10px",
                transform: "translateY(0.5px)",
              }}
              className="icon-ticket"
            />
            <span>{String(amount)}</span>
          </div>
        );
      }
      return String(amount);
    }
    if (currency === "GOS") {
      return (
        <div style={{ display: 'flex', alignItems: 'center' }} className="GOSPoint">
          <GOSPoint />
          <i style={{ marginLeft: 2 }}>{String(new Intl.NumberFormat().format(amount))}</i>
        </div>
      );
    }

    const currencyToChange = localExchangeRates?.find((l) => l.currencyCode === currency);
    const exchangeRate = currencyToChange?.exchangeRate || 1;
    const convertedAmount = selectedCurrency?.label !== "!convert" ? +(amount / exchangeRate) : amount;

    let formattedAmount = "";
    let suffix = "";

    if ((convertedAmount >= 1000000) && !isShowFormattedAmountWithDot) {
      formattedAmount = (Math.floor(convertedAmount / 1000000 * 100) / 100).toFixed(2);
      suffix = " M";
    } else if ((convertedAmount >= 100000) && !isShowFormattedAmountWithDot) {
      formattedAmount = (Math.floor(convertedAmount / 1000 * 100) / 100).toFixed(2);
      suffix = " K";
    } else {
      formattedAmount = convertedAmount.toFixed(isShowFormattedAmountWithDot ? 0 : fixedDecimalCount ?? 2);
    }

    const selectedLocale = selectedCurrency?.label !== "!convert" ? locales[selectedCurrency?.label] : locales[currency] || "tr-TR";
    const currencyLabel = selectedCurrency?.label !== "!convert" ? selectedCurrency?.label || currency : currency;
    const minimumFractionDigits = isShowFormattedAmountWithDot ? 0 :fixedDecimalCount ?? 2;

    const formatter = new Intl.NumberFormat(selectedLocale, {
      style: "currency",
      currency: currencyLabel,
      minimumFractionDigits,
    });

    const formattedNumber = isShowFormattedAmountWithDot ? formatter.format(formattedAmount).replace(/,/g, '.') : formatter.format(formattedAmount);

    return `${formattedNumber}${suffix}`;
  };

  return formatAmount;
};
