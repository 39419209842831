import types from "../actionTypes";

export const getLocalExchangeRatesRequest = () => ({
  type: types.GET_LOCAL_EXCHANGE_RATES_REQUEST,
});

export const getLocalExchangeRatesFailure = (e) => ({
  type: types.GET_LOCAL_EXCHANGE_RATES_FAILURE,
  exception: e,
});

export const getLocalExchangeRatesSucsess = (payload) => ({
  type: types.GET_LOCAL_EXCHANGE_RATES_SUCSESS,
  payload,
});

export const getLocalExchangeRatesReset = () => ({
  type: types.GET_LOCAL_EXCHANGE_RATES_RESET,
});
