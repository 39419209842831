import { memo } from "react";
import { useSelector } from "react-redux";
import { useTournamentButtonState } from "../../hooks/useTournamentButtonState";
import { getCurrentPlayerStateInTournaments, isSelectedJackpotTab } from "../../store/selectors";
import "./TournamentInfoState.scss";

const TournamentInfoState = ({ tournament, cb, disabled }) => {
  const {
    regStarted,
    regFinished,
    canUnregister,
  } = useTournamentButtonState(tournament);

  const playerStateInTournaments = useSelector(getCurrentPlayerStateInTournaments)(tournament?.tournamentId);
  const isJackpot = useSelector(isSelectedJackpotTab);
  const {
    canRegister,
    canResume,
    canRebuy,
  } = playerStateInTournaments || {};

  const register =  (canRegister && regStarted && !regFinished || canUnregister);
  const resume = canResume || canRebuy;
  const announced = !regStarted;
  const outOfSpin = !register && !resume && !announced;

  return (
    <>
    {!isJackpot && <div onClick={cb && !disabled ? cb : (() => {})} className={`tournament-info-state ${announced || outOfSpin || disabled ? 'tournament-info-state--black' : ''}`}>
      <i className={`icon-${register || announced ? 'play' : 'rounded-play'}`}></i>
    </div>}
    {isJackpot && <div onClick={cb && !disabled ? cb : (() => {})} className={`tournament-info-state ${tournament?.tournamentState === 'finished' || disabled ? 'tournament-info-state--black' : ''}`}>
      <i className={`icon-${tournament?.tournamentState === 'registration' ? 'play' : 'rounded-play'}`}></i>
    </div>}
    </>
  );
};

export default memo(TournamentInfoState);
