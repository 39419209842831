import { take, fork, cancel, call, put, select } from "redux-saga/effects";
import { accountingApiGet } from "./callApi";
import apiRoutes from "../../constants/apiRoutes";
import { getToken } from "../selectors";
import {
  getAccountBalanceFailure,
  getAccountBalanceSuccess,
} from "../actionCreators/getAccountBalance";
import types from "../actionTypes";

function* processGetAccountBalanceRequest(options) {
  try {
    const token = yield select(getToken);
    const params = { token };

    const response = yield call(
      accountingApiGet,
      apiRoutes.getAccountBalance,
      params
    );
    console.log(options);
    if (window && window.parent && !options?.rejectPostMessageToParent) {
      window.parent.postMessage({name: "balanceUpdate"}, "*");
    }
    if (response.data.success) {
      yield put(getAccountBalanceSuccess(response.data.result.wallets));
    } else {
      yield put(getAccountBalanceFailure());
    }
  } catch (e) {
    yield put(getAccountBalanceFailure(e));
  }
}

function* watchGetAccountBalanceRequest() {
  let forked = null;
  while (true) {
    const action = yield take([
      types.GET_ACCOUNT_BALANCE_REQUEST,
      types.CASINO_TOURNAMENT_REGISTER_SUCCESS,
      types.CASINO_TOURNAMENT_UNREGISTER_SUCCESS,
      types.CASINO_TOURNAMENT_REBUY_SUCCESS,
    ]);
    if (forked) yield cancel(forked);
    forked = yield fork(processGetAccountBalanceRequest, action);
  }
}

export default function* getAccountBalanceRequests() {
  yield call(watchGetAccountBalanceRequest);
}
