import { take, fork, cancel, call, put, select } from 'redux-saga/effects';
import { apiGetLobbyURL } from './callApi';
import apiRoutes from '../../constants/apiRoutes';
import types from '../actionTypes';
import { getPlayerStateInTournamentsFailure, getPlayerStateInTournamentsSucsess, getPlayerStateInTournamentsUpdate } from '../actionCreators/playerStateInTournaments';
import { getCasinoTournaments, getToken, getUsername } from '../selectors';
import { selectCasinoTournament } from '../actionCreators/casinoTournament';
import { openDialog } from '../actionCreators/dialogManager';

function* processGetPlayerStateInTournamentsRequest({ tournamentIds, isUpdate, checkResumePopup, type, openRegisterPopup }) {
  try {
    const token = yield select(getToken);
    const username = yield select(getUsername);
    const casinoTournaments = yield select(getCasinoTournaments);
    const params = {
      token,
    };
    if (type ===  types.TOURNAMENT_GAME_URL_RESET && casinoTournaments) {
      params.tournamentIds = [... new Set(casinoTournaments.map(item => item?.tournamentId))]
    } else {
      params.tournamentIds = tournamentIds || [];
    }

    const response = yield call(apiGetLobbyURL, apiRoutes.GET_PLAYER_STATE_IN_TOURNAMENTS, params);
    
    if (response.data?.success && response.data?.result?.length) {
      if (isUpdate) {
        yield put(getPlayerStateInTournamentsUpdate(response.data.result[0]))
      } else {
        yield put(getPlayerStateInTournamentsSucsess(response.data.result));
      }
      if (checkResumePopup) {
        const resumeTournamentId = response.data.result.find(item => item?.canResume)?.tournamentId;
        if (resumeTournamentId) {
          const tournamentToSendAsPostMessage = casinoTournaments?.find(item => item?.tournamentId === resumeTournamentId);
          if (tournamentToSendAsPostMessage) {
            window.parent.postMessage({ name: "resumeTournament", tournament: { ...tournamentToSendAsPostMessage, username}}, "*");
          }
        }
      }
      if (openRegisterPopup?.tournamentId) {
        const registerCandidate = response.data.result?.find(item => String(item?.tournamentId) === String(openRegisterPopup?.tournamentId));
        if (registerCandidate?.canRegister) {
          const tournament = casinoTournaments?.find(tour => String(tour?.tournamentId) === String(registerCandidate?.tournamentId));
          if (tournament) {
           yield put(selectCasinoTournament(tournament));
           yield put(openDialog({ tournament, dialogType: 'tournamentRegister' }));
          }
        }
      }
    }
  } catch (e) {
    yield put(getPlayerStateInTournamentsFailure(e));
  }
}

export function* watchGetPlayerStateInTournamentsRequest() {
  let forked = null;
  while (true) {
    const action = yield take([types.GET_PLAYER_STATE_IN_TOURNAMENTS_REQUEST, types.TOURNAMENT_GAME_URL_RESET]);
    if (forked) yield cancel(forked);
    forked = yield fork(processGetPlayerStateInTournamentsRequest, action);
  }
}