import { isMobile } from "react-device-detect";
import MobileOnGoingTournamentItem from "./MobileOnGoingTournamentItem";
import DesktopOnGoingTournamentItem from "./DesktopOnGoingTournamentItem";

const OnGoingTournamentItem = (props) => {
  const Component = isMobile
    ? MobileOnGoingTournamentItem
    : DesktopOnGoingTournamentItem;
  return <Component {...props} />;
};

export default OnGoingTournamentItem;
