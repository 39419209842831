import { isMobile, useMobileOrientation } from "react-device-detect";
import { classNames } from "../../../helpers/pureFunctions";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { openDialog } from "../../../store/actionCreators/dialogManager";
import PortalContainer from "../PortalContainer";
import "./PopUpDialogTemplate.scss";
import config from "../../../configs/config";

const PopUpDialogTemplate = ({
  children,
  className,
  onClose,
  onConfirm,
  onCloseBtn,
  confirmText,
  closeText,
  dialogName,
  textParams,
  howToPlay,
  dynamicTitle,
  titleIcon,
  withoutDescription,
  confirmBtnDanger,
  confirmBtnGray,
  titleImage,
  pending,
  setHTML,
  titleParams,
  selector,
  id,
  customTextReplacingHowToPlay,
  boldSubtitle,
}) => {
  const { t } = useTranslation();
  const { isLandscape } = useMobileOrientation();
  const dispatch = useDispatch();

  const popUpClasses = classNames("pop-up", {
    [className]: !!className,
    mobile: isMobile,
    landscape: isMobile && isLandscape,
  });

  const popUpButtonsWrapperClasses = classNames("pop-up__buttons-wrapper", {
    both: !!onConfirm && !!onCloseBtn,
    reverse: confirmBtnDanger,
  });

  const popUpConfirmButtonClassses = classNames("pop-up__confirm-button", {
    danger: confirmBtnDanger,
    gray: confirmBtnGray,
  });

  const openTournamentInfo = () => {
    onClose();
    config?.externalLinkHowToPlay
    ? window.open(config?.externalLinkHowToPlay, '_blank')
    : dispatch(openDialog({ dialogType: "howToPlay" }));
  };

  const closeTop = () => {
    if (!!onClose) {
      onClose();
      return;
    }
    if (!!onConfirm) onConfirm();
    return;
  };

  return (
    <PortalContainer selector={selector || ""} id={id || "modal-root"}>
      <div className="pop-up-wrapper">
        <div className={popUpClasses}>
          <div className="pop-up__overlay" />
          <div className="pop-up__close-wrapper">
            <i className="pop-up__close icon-cross" onClick={closeTop} />
          </div>
          {!!titleIcon && (
            <div className="pop-up__title-icon">
              <i className={`title-icon icon-${titleIcon}`} />
            </div>
          )}
          <span className="pop-up__title">
            {!setHTML && (
              <span>
                {!!dynamicTitle
                  ? dynamicTitle
                  : t(`${dialogName}.title`, titleParams ?? {})}
              </span>
            )}
            {setHTML && (
              <span
                dangerouslySetInnerHTML={{
                  __html: !!dynamicTitle
                    ? dynamicTitle
                    : t(`${dialogName}.title`),
                }}
              />
            )}
            {!!titleImage && (
              <span className="pop-up__title-image">{titleImage}</span>
            )}
          </span>
          {!withoutDescription && (
            <p
              className="pop-up__text"
              dangerouslySetInnerHTML={{
                __html: t(`${dialogName}.text`, textParams ?? {}),
              }}
              style={{ fontWeight: boldSubtitle ? 700 : 500 }}
            />
          )}
          {howToPlay && (
            <span onClick={openTournamentInfo} className="pop-up__how-to-play">
              {t("howToPlayDialog")}
            </span>
          )}
          {!howToPlay && customTextReplacingHowToPlay && (
            <span className="pop-up__custom-text">
              {t(`${dialogName}.customText`, textParams ?? {})}
            </span>
          )}
          <div className="pop-up__body">
            {!!children && children}
            {(onConfirm || onCloseBtn) && (
              <div className={popUpButtonsWrapperClasses}>
                {onConfirm && (
                  <button
                    disabled={pending}
                    onClick={onConfirm}
                    className={popUpConfirmButtonClassses}
                  >
                    {confirmText || t("confirm")}
                  </button>
                )}
                {onCloseBtn && (
                  <button onClick={onClose} className="pop-up__confirm-close">
                    {closeText || t("close")}
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </PortalContainer>
  );
};

export default PopUpDialogTemplate;
