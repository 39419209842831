import { isMobile } from "react-device-detect";
import MobileNavigation from "./MobileNavigation";
import DesktopNavigation from "./DesktopNavigation";

const Navigation = (props) => {
  const Component = isMobile ? MobileNavigation : DesktopNavigation;

  return <Component {...props} />;
};

export default Navigation;
