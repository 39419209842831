import { useState, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import ReactDOM from 'react-dom';

const PortalContainer = ({ children, id, selector, className, returnChildren }) => {
  const [container, setContainer] = useState(null);

  useEffect(() => {
    if (returnChildren) return;
    const portalContainer = document.createElement('div');
    portalContainer.id = id || '';
    if (className) {
      portalContainer.classList.add(className);
    }
    if (isMobile) {
      portalContainer.classList.add('mobile');
    }
    setContainer(portalContainer);

  
    const parentElement = selector ? document.querySelector(selector) : document.body;
    if (parentElement) {
      parentElement.appendChild(portalContainer);
    }
  
    return () => {
      if (parentElement) {
        parentElement.removeChild(portalContainer);
      }
    };
  }, [returnChildren]);

  return returnChildren ? children : container && ReactDOM.createPortal(children, container);
};

export default PortalContainer;