import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getSelectedTab } from "../../store/selectors";
import { myTournamentsReset } from "../../store/actionCreators/myTournaments";
import { setTab } from "../../store/actionCreators/tab";
import config from "../../configs/config";
import "./Tabs.scss";
import { classNames } from "../../helpers/pureFunctions";

const Tabs = ({ externalTabSetter, externalTab, cb }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectedTab = useSelector(getSelectedTab);

  const handleTabView = (view) => {
    if (externalTabSetter && externalTab) {
      externalTabSetter(view);
    } else {
      dispatch(setTab(view));
      dispatch(myTournamentsReset());
    }
    if (cb) {
      cb();
    }
  };

  return (
    <div className="tabs">
      <div
        onClick={() => handleTabView("regular")}
        className={`tabs__tab ${
         (( externalTabSetter && externalTab) ? externalTab === "regular" : selectedTab === "regular") ? "tabs__tab--selected" : ""
        }`}
      >
        <div className="tabs__tab-inner">
          <i className="tabs__tab-icon icon-casino-tournament" />
          <span className="tabs__tab-text">{t("regular")}</span>
        </div>
      </div>
      <div
        onClick={
          config?.enableJackpot ? () => handleTabView("jackpot") : () => {}
        }
        className={`tabs__tab ${
          ((externalTabSetter && externalTab) ? externalTab === "jackpot" : selectedTab === "jackpot") ? "tabs__tab--selected" : ""
        } ${!config?.enableJackpot ? "tabs__tab--disabled" : ""}`}
      >
        <span className={classNames('tabs__tab-soon', {'tabs__tab-soon--new': config?.enableJackpot})}>{t(config?.enableJackpot ? 'new' : "comingSoon")}</span>
        <div className="tabs__tab-inner">
          <i className="tabs__tab-icon icon-spin" />
          <span className="tabs__tab-text">{t("crazyPrize")}</span>
        </div>
      </div>
    </div>
  );
};

export default Tabs;
