import { useMemo } from "react";
import { useLocation } from "react-router-dom";

export const useQuery = () => {
  const { search } = useLocation();
  return useMemo(() => {
    return Array.from(new URLSearchParams(search).entries()).reduce((acc, [key, value]) => {
      acc[key] = value;
      return acc;
    }, {})
  }, [search]);
};
