import { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMobileOrientation, isMobile } from 'react-device-detect';
import {
  getDialogs,
  getRebuyErrorMessage,
  getRegisterErrorMessage,
  getSpinAndGORegisterErrorMessage,
  getTournamentGameUrl,
} from "../../store/selectors";
import BadBet from "./BadBet";
import RegistrationErrorLowBalance from "./RegistrationErrorLowBalance";
import SuccessfullRegistration from "./SuccessFullRegistration";
import TournamentFinished from "./TournamentFinished";
import TournamentGame from "./TournamentGame";
import TournamentInformation from "./TournamentInformation";
import TournamentRegister from "./TournamentRegister";
import TournamentUnregister from "./TournamentUnregister";
import HowToPlay from "./HowToPlay";
import TournamentRebuy from "./TournamentRebuy";
import SuccessTournamentRebuy from "./SuccessTournamentRebuy";
import RebuyErrorLowBalance from "./RebuyErrorLowBalance";
import TournamentsHistory from "./TournamentsHistory";
import CurrencyExchange from "./CurrencyExchange";
import UserSettingsDialog from "./UserSettingsDialog";
import WaitingRoom from "./WaitingRoom";
import PrizePoolGeneratorDialog from "./PrizePoolGeneratorDialog";
import JackpotTournamentFinished from "./JackpotTournamentFinished";
import TournamentEnd from "./TournamentEnd";
import LeaderboardPopup from "./Leaderboard";
import MobileBurgerMenu from "./MobileBurgerMenu";
import TermsAndConditions from "./TermsAndConditions";
import { setJackpotPrizeGenerationBool } from "../../store/actionCreators/jackpot";
import OnGoingEvents from "./OnGoingEvents";
import GOSPopupsFromCMS from "./GOSPopupsFromCMS";
import VeloxRegistration from "./VeloxRegistration";
import VeloxSuccessfulRegistartion from "./VeloxSuccessfulRegistartion";
import VeloxForceCloseGame from "./VeloxForceCloseGame";
import VeloxUnregister from "./VeloxUnregister";
import VeloxUnregisterConfirmation from "./VeloxUnregisterConfirmation";
import HowGetFreeTickets from "./HowGetFreeTickets";

const Dialogs = () => {
  const dispatch = useDispatch();
  const dialogs = useSelector(getDialogs);
  const registerErrorMessage = useSelector(getRegisterErrorMessage);
  const spinAndGoRegisterErrorMessage = useSelector(getSpinAndGORegisterErrorMessage);
  const rebuyErrorMessage = useSelector(getRebuyErrorMessage)
  const tournamentGameUrl = useSelector(getTournamentGameUrl);
  const { isPortrait, isLandscape } = useMobileOrientation();
  const deviceInfo = { isMobile, isLandscape, isPortrait }
  useEffect(() => {
    if (!!tournamentGameUrl) {
      window.parent.postMessage({ name: "gameStateChanged", fullScreen: true }, '*');
    } else {
      dispatch(setJackpotPrizeGenerationBool(false));
      window.parent.postMessage({ name: "gameStateChanged", fullScreen: false}, "*");
    }
  }, [tournamentGameUrl])

  useEffect(() => {
    if (dialogs && Object.values(dialogs).filter(item => Boolean(item))?.length) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
  }, [dialogs])

  return (
    <>
      {dialogs?.tournamentRegister && (
        <TournamentRegister {...deviceInfo} {...dialogs.tournamentRegister} />
      )}
      {dialogs?.successfullRegistration && <SuccessfullRegistration {...deviceInfo} />}
      {dialogs?.successTournamentRebuy && <SuccessTournamentRebuy {...deviceInfo} />}
      {dialogs?.badBet && <BadBet {...deviceInfo} {...dialogs.badBet} />}
      {dialogs?.tournamentInformation && <TournamentInformation {...deviceInfo} />}
      {dialogs?.tournamentUnregister && <TournamentUnregister {...deviceInfo} {...dialogs.tournamentUnregister}/>}
      {dialogs?.tournamentFinished && (
        <TournamentFinished {...deviceInfo} {...dialogs.tournamentFinished} />
      )}
      {[registerErrorMessage, spinAndGoRegisterErrorMessage].includes('low_balance') && (
        <RegistrationErrorLowBalance {...deviceInfo} />
      )}
      {rebuyErrorMessage === "low_balance" && (
        <RebuyErrorLowBalance {...deviceInfo} />
      )}
      {tournamentGameUrl && <TournamentGame url={tournamentGameUrl} />}
      {dialogs?.howToPlay && <HowToPlay {...deviceInfo} />}
      {dialogs?.tournamentsHistory && <TournamentsHistory {...deviceInfo} />}
      {dialogs?.tournamentRebuy && (
        <TournamentRebuy {...deviceInfo} {...dialogs.tournamentRebuy} />
      )}
      {dialogs?.currencyExchange && (
        <CurrencyExchange {...deviceInfo} {...dialogs.currencyExchange} />
      )}
      {dialogs?.userSettings && (
        <UserSettingsDialog {...deviceInfo} />
      )}
      {dialogs?.waitingRoom && (
        <WaitingRoom {...deviceInfo} {...dialogs.waitingRoom} />
      )}
      {dialogs?.prizePoolGenerator && (
        <PrizePoolGeneratorDialog {...deviceInfo} {...dialogs.prizePoolGenerator} />
      )}
      {dialogs?.jackpotTournamentFinished && (
        <JackpotTournamentFinished {...deviceInfo} {...dialogs.jackpotTournamentFinished} />
      )}
      {Object.keys(dialogs)
        .filter(dialogKey => dialogKey.startsWith('tournamentEnd') && dialogs?.[dialogKey])
        .map(dialogKey => (
          <TournamentEnd dialogKey={dialogKey} {...deviceInfo} {...dialogs?.[dialogKey]} key={dialogKey} />
      ))}
      {dialogs?.leaderboard && (
        <LeaderboardPopup {...deviceInfo} {...dialogs.leaderboard} />
      )}
      {dialogs?.burgerMenu && (
        <MobileBurgerMenu {...deviceInfo} {...dialogs.burgerMenu} />
      )}
      {dialogs?.termsAndConditions && (
        <TermsAndConditions {...deviceInfo} {...dialogs.termsAndConditions} />
      )}
      {dialogs?.onGoingEvents && (
        <OnGoingEvents {...deviceInfo} {...dialogs.onGoingEvents} />
      )}
      {Object.keys(dialogs)
        .filter(dialogKey => dialogKey.startsWith('gosPopupFromCMS') && dialogs?.[dialogKey])
        .map(dialogKey => (
          <GOSPopupsFromCMS {...deviceInfo} {...dialogs?.[dialogKey]} key={dialogKey} />
        ))}
      {dialogs?.veloxRegistration && (
        <VeloxRegistration {...dialogs.veloxRegistration.payload} />
      )}
      {dialogs?.veloxSuccessfulRegistration && (
        <VeloxSuccessfulRegistartion {...dialogs.veloxSuccessfulRegistration.payload} />
      )}
      {dialogs?.veloxForceCloseGame && (
        <VeloxForceCloseGame {...dialogs.veloxForceCloseGame.payload} />
      )}
      {dialogs?.veloxUnregister && (
        <VeloxUnregister />
      )}
      {dialogs?.veloxUnregisterConfirmation && (
        <VeloxUnregisterConfirmation {...dialogs.veloxUnregisterConfirmation.payload} />
      )}
      {dialogs?.howGetFreeTickets && (
        <HowGetFreeTickets />
      )}
    </>
  );
};

export default memo(Dialogs);
