import { useTranslation } from "react-i18next";
import { useFormatCurrency } from "../../../../hooks/useFormatCurrency";
import GOSPoint from "../../../GOSPoint";
import "./LeaderboardTopLeadCard.scss";

const LeaderboardTopLeadCard = ({ item }) => {
  const { t } = useTranslation();
  const formatCurrency = useFormatCurrency();
  return (
    <div className="leaderboard-top-lead-card">
      <div className="leaderboard-top-lead-card__avatar-username-wrapper">
        {item.image ? (
          <img
            className="leaderboard-top-lead-card__avatar"
            src={item.image}
            alt="avatar"
          />
        ) : (
          <div className="leaderboard-top-lead-card__default-avatar">
            <i className="icon-avatar" />
          </div>
        )}
        <div className="leaderboard-top-lead-card__username-wrapper">
          <div className="leaderboard-top-lead-card__position-wrapper">
            <span className="leaderboard-top-lead-card__position">
              #{item?.position}
            </span>
            <div
              className={`leaderboard-top-lead-card__position-icon leaderboard-top-lead-card__position-icon--${item?.position}`}
            />
          </div>
          <span className="leaderboard-top-lead-card__username">
            {item?.unMaskedName}
          </span>
        </div>
      </div>
      <div className="leaderboard-top-lead-card__info-wrapper">
        <div className="leaderboard-top-lead-card__info-item">
          <span className="leaderboard-top-lead-card__info-label">
            {t("points")}:
          </span>
          <span className="leaderboard-top-lead-card__info-value">
            {item?.points}
          </span>
        </div>
        <div className="leaderboard-top-lead-card__info-item">
          <span className="leaderboard-top-lead-card__info-label">
            {t("prize")}:
          </span>
          <span className="leaderboard-top-lead-card__info-value">
            {item?.currency === "GOS" ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
                className="GOSPoint"
              >
                <GOSPoint width={12} />
                <span style={{ marginLeft: 4 }}>{item?.value}</span>
              </div>
            ) : (
              item?.type === "externalPrize" ? item?.url ? <a target="_blank" href={item?.url} >{item?.name}</a> : item?.name : formatCurrency(item?.value, item?.currency, 2, item?.type)
            )}
          </span>
        </div>
      </div>
    </div>
  );
};

export default LeaderboardTopLeadCard;
