import { useTranslation } from "react-i18next";
import { getCasinoTournament } from "../../../store/selectors";
import ToasterTemplate from "../common/ToasterTemplate";
import './TournamentRebuyFailure.scss';

const TournamentRebuyFailure = ({ onClose }) => {
  const { t } = useTranslation();

  return (
    <ToasterTemplate onClose={onClose}>
      <ToasterTemplate.Right toasterName="tournamentRebuyFailure">
      </ToasterTemplate.Right>
    </ToasterTemplate>
  );
};

export default TournamentRebuyFailure;
