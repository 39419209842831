import { memo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocalStorage } from "../../../hooks/useLocalStorage";
import { closeDialog } from "../../../store/actionCreators/dialogManager";
import { getWallets } from "../../../store/selectors";
import CurrencySwitcher from "../../CurrencySwitcher";
import PortalContainer from "../../common/PortalContainer";
import { SIGNES } from "../../../constants/signes";
import "./CurrencyExchange.scss";
import Button from "../../common/Button";
import { isMobile } from "react-device-detect";
import { useResetState } from "../../../hooks/useResetState";

const CurrencyExchange = () => {
  const dispatch = useDispatch();
  useResetState(onClose);
  const { t } = useTranslation();
  const [selectedCurrencyStorage, setSelectedCurrencyStorage] = useLocalStorage(
    "selectedCurrency",
    null
  );
  const wallets = useSelector(getWallets);

  function onClose() {
    dispatch(closeDialog("currencyExchange"));
  }

  return (
    <PortalContainer id="currency-exchange">
      <div className="currency-exchange">
        <div className="currency-exchange__close">
          <i className="icon-cross" onClick={onClose} />
        </div>
        <div className="currency-exchange__icon">
          <img src="/images/tournamentsHistory/currency-exchange.svg" alt="" />
        </div>
        <span className="currency-exchange__title">{t('currencyExchangeTitle')}</span>
        <span className="currency-exchange__description">{t('currencyExchangeDescription', { currency: SIGNES?.[wallets?.[0]?.currency] })}</span>
        <div className="currency-exchange__currency-switcher-wrapper">
          <CurrencySwitcher
            setSelectedCurrencyStorage={setSelectedCurrencyStorage}
            selectedCurrencyStorage={selectedCurrencyStorage}
          />
        </div>
        <div className="currency-exchange__button-wrapper">
          <Button onClick={onClose} variant="primary">{t('save')}</Button>
        </div>
      </div>
    </PortalContainer>
  );
};

export default memo(CurrencyExchange);
