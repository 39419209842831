import { useEffect, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { getGosPlayerLastWinRequest } from "../../store/actionCreators/gosPlayerLastWin";
import {
  getGosPlayerLastWinData,
  isGosPlayerLastWinPending,
} from "../../store/selectors";
import { useFormatCurrency } from "../../hooks/useFormatCurrency";
import Skeleton from "../Skeleton";
import HorizontalScroll from "../HorizontalScroll";
import GOSPoint from "../GOSPoint";
import "./PlayerLastWinsWindget.scss";

const PlayerLastWinsWindget = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const formatCurrency = useFormatCurrency();
  const pending = useSelector(isGosPlayerLastWinPending);
  const gosPlayerLastWins = useSelector(getGosPlayerLastWinData);

  useEffect(() => {
    dispatch(getGosPlayerLastWinRequest());
  }, []);

  return (
    <div className="player-last-wins-windget">
      <div className="player-last-wins-windget__list">
        {pending && (
          <Skeleton count={2} space={8} width={138} height={70} radius={6} />
        )}
        {!pending && gosPlayerLastWins?.length > 0 && (
          <HorizontalScroll
            icon="giftcard"
            title="latestWin"
            gap={8}
            itemWidth={138}
          >
            {gosPlayerLastWins.map(({ tournamentName, tournamentId, winDate, win }) => (
              <div
                key={`${tournamentId}_${winDate}`}
                className="player-last-wins-windget__item"
              >
                <div className="player-last-wins-windget__item-prize-wrapper">
                  {(win?.type === "fixedPrize" || win?.type === "percentage") &&
                    (win?.currency !== "GOS" ? (
                      <span className="player-last-wins-windget__amount">{`+${formatCurrency(win?.amount, win?.currency)}`}</span>
                    ) : (
                      <div className="player-last-wins-windget__item-prize-gos-wrapper">
                        <GOSPoint height={16} />
                        <span className="player-last-wins-windget__amount">+{win?.amount}</span>
                      </div>
                    ))}
                    {(win?.type === "externalPrize" || win?.type === "tourTicket") && (
                      <div className="player-last-wins-windget__item-prize-tour-ticket-external-prize-wrapper">
                        <i className={`icon-${win?.type === "externalPrize" ? 'giftcard' : 'ticket'}`} />
                        <span>{t(win?.type === "externalPrize" ? 'externalPrize' : 'ticket')}</span>
                      </div>
                    )}
                </div>
                <span className="player-last-wins-windget__item-tournament-name">
                  {tournamentName}
                </span>
                <span className="player-last-wins-windget__item-prize-date">
                  {moment(winDate).format('MMMM DD, YYYY')}
                </span>
              </div>
            ))}
          </HorizontalScroll>
        )}
      </div>
    </div>
  );
};

export default memo(PlayerLastWinsWindget);
