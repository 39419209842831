import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import StatisticItem from "../StatisticItem";

const MobileHeaderStatistics = ({ data }) => {
  return (
    <>
      {data?.length > 0 && (
        <div className="header-statistics-mobile">
          <Swiper
            slidesPerView="auto"
            spaceBetween={12}
            autoplay={{
              delay: 3000,
            }}
            modules={[Autoplay]}
            speed={1500}
            slidesPerGroup={1}
            loop
          >
            {data.map((item, index) => (
              <SwiperSlide key={index}>
                <StatisticItem item={item} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      )}
    </>
  );
};

export default MobileHeaderStatistics;
