import React from 'react';
import { classNames } from '../../../helpers/pureFunctions';
import './Button.scss';

function Button({ children, onClick, disabled, variant, size }) {
    
    const buttonClassNames = classNames({
        button: true,
        [`button--${variant}`]: variant,
        [`button--${size}`]: size,
    });

    return (
        <button disabled={!!disabled} onClick={onClick} className={buttonClassNames}>{children}</button>
    )
}

export default Button