import { isMobile } from 'react-device-detect';
import MobileJackpotGameHeader from './MobileJackpotGameHeader';
import DesktopJackpotGameHeader from './DesktopJackpotGameHeader';

const JackpotGameHeader = (props) => {
  const Component = isMobile
    ? MobileJackpotGameHeader
    : DesktopJackpotGameHeader;
  return <Component {...props} />;
};

export default JackpotGameHeader;
