import { useTranslation } from "react-i18next";
import { useOnGoningEvents } from "./OnGoningEventsProvider";
import { classNames } from "../../../helpers/pureFunctions";

const OnGoingEventsTabs = () => {
  const { t } = useTranslation();
  const { tabs, activeTab, handleSetActiveTab, tournaments } = useOnGoningEvents();
  return (
    <div className="on-going-events-tabs">
      {tabs.map((tab) => (
        <div
          onClick={() => handleSetActiveTab(tab)}
          key={tab}
          className={classNames("on-going-events-tabs__item", {
            "on-going-events-tabs__item--active": activeTab === tab,
            "on-going-events-tabs__item--disable": !tournaments[tab].length,
          })}
        >
          <span className="on-going-events-tabs__text">{t(tab)}</span>
        </div>
      ))}
    </div>
  );
};

export default OnGoingEventsTabs;
