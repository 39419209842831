import types from "../actionTypes";

export const setJackpotJoinedPlayer = (payload) => ({
  type: types.SET_JACKPOT_JOINED_PLAYERS,
  payload,
});

export const resetJackpotJoinedPlayer = () => ({
  type: types.RESET_JACKPOT_JOINED_PLAYERS,
});
