import types from '../actionTypes';

export const tournamentRebuyRequest = (tournamentData) => ({
  type: types.CASINO_TOURNAMENT_REBUY_REQUEST,
  tournamentData,
});

export const tournamentRebuyFailure = (e, tournamentData) => ({
  type: types.CASINO_TOURNAMENT_REBUY_FAILURE,
  exception: e,
  failMessage: e.response?.data?.message?.key,
  failData: tournamentData,
});

export const tournamentRebuySuccess = (tournament, id) => ({
  type: types.CASINO_TOURNAMENT_REBUY_SUCCESS,
  payload: tournament,
  id,
});

export const casinoTournamentRebuyReset = () => ({
  type: types.CASINO_TOURNAMENT_REBUY_RESET,
});
