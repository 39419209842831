import { memo, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getAvatarMenuRequest } from "../../store/actionCreators/avatars";
import { getAvatarMenu, isAvatarMenuPending } from "../../store/selectors";
import Skeleton from "../Skeleton";
import { isMobile } from "react-device-detect";
import "./AvatarSection.scss";

const AvatarSection = ({ selectedAvatar, setSelectedAvatar, cb }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const avatarMenu = useSelector(getAvatarMenu);
  const pending = useSelector(isAvatarMenuPending);
  const avatarMenuRef = useRef(null);
  const [itemWidth, setItemWidth] = useState(60);

  useEffect(() => {
    dispatch(getAvatarMenuRequest());
  }, []);

  useEffect(() => {
    if (avatarMenuRef.current) {
      setItemWidth(avatarMenuRef.current.children[0].offsetWidth);
    }
  }, [avatarMenuRef]);

  const scrollAvatarMenu = (distance) => {
    const avatarMenu = avatarMenuRef.current;
    if (avatarMenu) {
      const scrollPos = avatarMenu.scrollLeft + distance;
      avatarMenu.scrollTo({
        left: scrollPos,
        behavior: "smooth"
      });
    }
  };

  const handleScrollLeft = () => {
    scrollAvatarMenu(-itemWidth * 7);
  };

  const handleScrollRight = () => {
    scrollAvatarMenu(itemWidth * 7)
  };

  const handleSetAvatar = (path, e) => {
    if (path !== selectedAvatar) {
      setSelectedAvatar(path);
      if (cb) cb?.(e, path);
    }
  };

  return (
    <div className="avatar-section">
      <div className="avatar-section__text-button-wrapper">
        <span className="avatar-section__text">{t('chooseYourAvatar')}</span>
        {!isMobile && (
          <div className="avatar-section__buttons-wrapper">
            <div className="avatar-section__button avatar-section__button--left" onClick={handleScrollLeft}>
              <i className="icon-arrow-right"></i>
            </div>
            <div className="avatar-section__button" onClick={handleScrollRight}>
              <i className="icon-arrow-right"></i>
            </div>
          </div>
        )}
      </div>
      {pending && (
        <div className="avatar-section__skeleton-wrapper">
          <Skeleton count={7} space={12} height={isMobile ? 40 : 60} width={isMobile ? 40 : 60} rounded />
        </div>
      )}
      {avatarMenu?.length > 0 && (
        <div ref={avatarMenuRef} className="avatar-section__avatar-menu">
          {avatarMenu.map(item => (
            <div key={item} className={`avatar-section__avatar-item ${selectedAvatar === item ? 'avatar-section__avatar-item--selected' : ''}`} onClick={handleSetAvatar.bind(null, item)}>
              <div className="avatar-section__avatar-item-checked">
                <i className="icon-check" />
              </div>
              <img src={item} alt="avatar" />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default memo(AvatarSection);
