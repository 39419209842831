import types from '../actionTypes';

export const tournamentLastBetRequest = (id) => ({
    type: types.TOURNAMENT_LAST_BET_REQUEST,
    tournamentId: id
});

export const tournamentLastBetSuccess = (data) => ({
    type: types.TOURNAMENT_LAST_BET_SUCCESS,
    payload: data,
});

export const tournamentLastBetFailure = (e) => ({
    type: types.TOURNAMENT_LAST_BET_FAILURE,
    exception: e,
});