import React from 'react';
import { useFullScreen } from 'react-browser-hooks';
import { isMobile, isIOS } from 'react-device-detect';

import "./FullScreen.scss";

// Preventint zoom on tap/zoom in iOS
//
if (isIOS) {
  const prevent = e => e.preventDefault();
  document.body.addEventListener('gesturechange', prevent);
  document.body.addEventListener('gesturestart', prevent);
  document.body.addEventListener('gestureend', prevent);
}

const ForceFullScreen = () => {
  const androidFSDetector = useFullScreen();

  const goFullScreen = () => {
    if (document.documentElement.requestFullScreen) {
      window.document.documentElement.requestFullScreen();
    } else if (document.documentElement.mozRequestFullScreen) {
      window.document.documentElement.mozRequestFullScreen();
    } else if (document.documentElement.webkitRequestFullScreen) {
      window.document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
    }
  }

  return (
    <React.Fragment>
      {isMobile && !isIOS && !androidFSDetector.fullScreen &&
        <div className={`fullScreenForcer android`} onClick={goFullScreen}></div>
      }
    </React.Fragment>
  )
};

export default ForceFullScreen;
