import TournamentLastBet from "./TournamentLastBet";
import TournamentRebuyFailure from "./TournamentRebuyFailure";
import TournamentRegisterFailure from "./TournamentRegisterFailure";

const Views = {
  tournamentLastBet: TournamentLastBet,
  tournamentRebuyFailure: TournamentRebuyFailure,
  tournamentRegisterFailure: TournamentRegisterFailure,
};

export default Views;