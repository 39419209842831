import { useEffect } from "react";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getTournamentBuyinInfo } from "../../../../helpers/getTournamentBuyinInfo";
import { classNames } from "../../../../helpers/pureFunctions";
import { validatePassword } from "../../../../helpers/validators";
import { useFormatCurrency } from "../../../../hooks/useFormatCurrency";
import {
  casinoTournamentRegisterErrorMessageReset,
  casinoTournamentRegisterRequest,
} from "../../../../store/actionCreators/casinoTournamentRegister";
import { closeDialog } from "../../../../store/actionCreators/dialogManager";
import {
  getRegisteredCasinoTournament,
  getRegisterErrorMessage,
  getWallet,
  isRegisterPending,
} from "../../../../store/selectors";
import Input from "../../../common/Input";
import Modal from "../../../common/Modal";
import "./TournamentRegister_v1.scss";

const TournamentRegister_v1 = ({
  tournament,
  isMobile,
  isPortrait,
  isLandscape,
}) => {
  const formatCurrency = useFormatCurrency();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    tournamentName,
    numberOfSpins,
    buyIn: { currency },
  } = tournament;
  const [tournamentGame] = tournament.tournamentGames;

  const [radioOption, setRadioOption] = useState("userBalance");
  const [password, setPassword] = useState({
    value: "",
    errorMessage: "",
  });
  const GOSWallet = useSelector(getWallet)("GOS");
  const mainWallet = useSelector(getWallet)(currency);
  const registerErrorMessage = useSelector(getRegisterErrorMessage);
  const registeredCasinoTournament = useSelector(getRegisteredCasinoTournament);
  const {
    buyinValue,
    allowPaymentMain,
    allowPaymentGOSPoint,
    formatForMainBuyin,
    GOSPointBuyin,
    formatForGOSPointBuyin,
  } = getTournamentBuyinInfo(tournament, formatCurrency);


  useEffect(() => {
    if (registerErrorMessage && !password.errorMessage) {
      setPassword((prev) => ({ ...prev, errorMessage: registerErrorMessage }));
    }
    return () => dispatch(casinoTournamentRegisterErrorMessageReset());
  }, [registerErrorMessage, password.errorMessage]);

  useEffect(() => {
    if (
      tournament?.isPasswordProtected &&
      registeredCasinoTournament?.tournamentId === tournament?.tournamentId
    ) {
      onClose();
    }
  }, [registeredCasinoTournament, tournament]);

  const onClose = useCallback(
    () => dispatch(closeDialog("tournamentRegister")),
    [dispatch]
  );

  const onTournamentRegisterRequest = useCallback(
    (protectedPassword) => {
      const params = {
        ...tournament,
        paymentType: allowPaymentGOSPoint
        ? allowPaymentMain
          ? radioOption === "vipPoints" ? 'GOS' : 'realMoney'
          : true
        : 'realMoney',
      };
      if (protectedPassword) params.password = protectedPassword;
      dispatch(casinoTournamentRegisterRequest(params));
    },
    [dispatch, radioOption]
  );

  const onHandlePasswordProtectedSubmit = useCallback(() => {
    const errorMessage = validatePassword(password.value);
    if (!errorMessage) {
      setPassword((prev) => ({ ...prev, errorMessage: "" }));
      onTournamentRegisterRequest(password.value);
    } else {
      setPassword((prev) => ({ ...prev, errorMessage }));
    }
  }, [password]);

  const onSubmit = useCallback(() => {
    if (tournament.isPasswordProtected) {
      onHandlePasswordProtectedSubmit();
    } else {
      onTournamentRegisterRequest();
      onClose();
    }
  }, [
    tournament,
    onHandlePasswordProtectedSubmit,
    onTournamentRegisterRequest,
  ]);

  const onChangeHandler = (e) => {
    setRadioOption(e.target.value);
  };

  const tournamentRegisterClassNames = classNames("tournament-register", {
    "tournament-register--portrait": isMobile && isPortrait,
    "tournament-register--landscape": isMobile && isLandscape,
  });

  if (!mainWallet) return null;

  return (
    <Modal>
      <Modal.Header onClose={onClose} />
      <Modal.Body>
        <div className={tournamentRegisterClassNames}>
          <span className="tournament-register__title">
            {t("tournamentRegistration")}
          </span>
          <div
            style={{ backgroundImage: `url(${tournamentGame.thumbnail})` }}
            className="tournament-register__image"
          />
          <span className="tournament-register__tournament-name" dangerouslySetInnerHTML={{__html: tournamentName}} />
          <span className="tournament-register__tournament-description">
            {t("spinLimitScheduledTournament", { spinCount: numberOfSpins })}
          </span>
          <div className="tournament-register__tournament-price-wrapper">
            <span className="tournament-register__tournament-price-label">{`${t(
              "buyIn"
            )}:`}</span>
            <span className="tournament-register__tournament-price-value">
              {buyinValue}
            </span>
          </div>
          <div className="tournament-register__wallets">
            {allowPaymentMain && (
              <div className="tournament-register__wallets-row">
                <span className="tournament-register__wallets-label">{`${t(
                  "mainBalance"
                )}:`}</span>
                <span className="tournament-register__wallets-value">
                  {formatCurrency(mainWallet.balance, mainWallet.currency)}
                </span>
              </div>
            )}
            {allowPaymentGOSPoint && (
              <div className="tournament-register__wallets-row">
                <span className="tournament-register__wallets-label">{`${t(
                  "bonusCoins"
                )}:`}</span>
                <span className="tournament-register__wallets-value">
                  {formatForGOSPointBuyin(GOSWallet.balance)}
                </span>
              </div>
            )}
          </div>
          {allowPaymentMain && allowPaymentGOSPoint && (
            <div className="tournament-register__options">
              <div className="tournament-register__options-content">
                <div className="tournament-register__option">
                  <div className="tournament-register__option-radio-wrapper">
                    <input
                      onChange={onChangeHandler}
                      checked={radioOption === "userBalance"}
                      value="userBalance"
                      type="radio"
                      name="balanceOption"
                      id="userBalance"
                    />
                    <label htmlFor="userBalance">{`${t(
                      "userBalance"
                    )}:`}</label>
                  </div>
                  <span className="tournament-register__option-balance">
                    {formatForMainBuyin}
                  </span>
                </div>
                <div className="tournament-register__option">
                  <div className="tournament-register__option-radio-wrapper">
                    <input
                      onChange={onChangeHandler}
                      checked={radioOption === "vipPoints"}
                      value="vipPoints"
                      type="radio"
                      name="balanceOption"
                      id="vipPoints"
                    />
                    <label htmlFor="vipPoints">{`${t(
                      "vipPoints"
                    )}:`}</label>
                  </div>
                  <span className="tournament-register__option-balance">
                    {GOSPointBuyin}
                  </span>
                </div>
              </div>
            </div>
          )}
          {tournament?.isPasswordProtected && (
            <div className="tournament-register__password-protected">
              <span className="tournament-register__password-protected-label">
                {t("enterPrivatePassword")}
              </span>
              <Input
                type="password"
                className={`tournament-register__password-protected-input ${
                  password.errorMessage
                    ? "tournament-register__password-protected-input--error"
                    : ""
                }`}
                style={{ marginTop: "8px" }}
                value={password.value}
                callbackFn={(e) =>
                  setPassword((prev) => ({
                    ...prev,
                    value: e.target.value,
                    errorMessage: "",
                  }))
                }
                showPasswordToggle
              />
              {password.errorMessage && (
                <span className="tournament-register__password-protected-error">
                  {t(password.errorMessage)}
                </span>
              )}
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer
        submitText="register"
        closeText="cancel"
        onSubmit={onSubmit}
        onClose={onClose}
      />
    </Modal>
  );
};

export default TournamentRegister_v1;
