import React from "react";
import { useMemo } from "react";
import { useState } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  classNames,
  isBetweenOrEqual,
} from "../../../../../helpers/pureFunctions";
import {
  transformTournamentGameData,
} from "../../../../../helpers/transform";
import { useFormatCurrency } from "../../../../../hooks/useFormatCurrency";
import {
  getCasinoTournament,
} from "../../../../../store/selectors";
import TournamentGameInfoBoard from "../TournamentGameInfoBoard";
import "./TournamentGameInfoWidget.scss";
import LeaderboardWidget from "../../../../LeaderboardWidget";

const INFO_TABS = ["ranking", "prizePool"];

const TournamentGameInfoWidget = () => {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState("ranking");
  const formatCurrency = useFormatCurrency();

  const tournament = useSelector(getCasinoTournament);

  const currentStructure =
    tournament?.enrolled === 0 ||
    tournament?.tournamentAward?.structure.length === 1
      ? tournament?.tournamentAward?.structure[0]
      : tournament?.tournamentAward?.structure.find((s) =>
          isBetweenOrEqual(
            tournament?.enrolled,
            s?.playersCount.min,
            s?.playersCount.max
          )
        ) ||
        tournament?.tournamentAward?.structure[
          tournament?.tournamentAward?.structure.length - 1
        ];
  const transformTournamentData = useMemo(
    () => transformTournamentGameData(currentStructure?.awards, formatCurrency),
    [tournament]
  );

  const selectTabHandler = (tabName) => {
    setSelectedTab(tabName);
  };

  const tournamentGameInfoWidgetClasses = classNames(
    "tournament-game-info-widget-wrapper",
    {
      mobile: isMobile,
    }
  );

  return (
    <div className={tournamentGameInfoWidgetClasses}>
      <div className="tournament-game-info-widget-wrapper__tabs">
        {INFO_TABS.map((item) => (
          <div
            key={item}
            className={`tournament-game-info-widget-wrapper__tab ${
              selectedTab === item ? "selected" : ""
            }`}
            onClick={() => selectTabHandler(item)}
          >
            <span className="tournament-game-info-widget-wrapper__tab--name">
              {t(item)}
            </span>
          </div>
        ))}
      </div>
      <div className="tournament-game-info-widget-wrapper__body">
        {selectedTab === "prizePool" ? (
          <TournamentGameInfoBoard
            title="prizePool"
            data={transformTournamentData}
            totalCountInfo={formatCurrency(
              tournament?.prizePool?.value,
              tournament?.prizePool?.currency,
              2,
              tournament?.prizePool?.type,
            )}
          />
        ) : (
          <LeaderboardWidget isGame tournament={tournament} />
        )}
      </div>
    </div>
  );
};

export default TournamentGameInfoWidget;
