import { memo } from "react";
import { useDispatch } from "react-redux";
import { closeDialog } from "../../../store/actionCreators/dialogManager";
import LeaderboardInfo from "./LeaderboardInfo";
import PortalContainer from "../../common/PortalContainer";
import LeaderboardTableLayout from "./LeaderboardTableLayout";
import { useResetState } from "../../../hooks/useResetState";
import './Leaderboard.scss';

const LeaderboardPopup = ({ isMobile, data }) => {
  const dispatch = useDispatch();
  useResetState(handleClose);

  function handleClose(){
    dispatch(closeDialog('leaderboard'));
  };

  return (
    <PortalContainer
      className={isMobile ? "mobile" : ""}
      id="leaderboard-popup"
    >
      <div className="leaderboard-popup">
        <div className="leaderboard-popup__inner">
          <div onClick={handleClose} className="leaderboard-popup__close">
            <i className="icon-cross" />
          </div>
          <div className="leaderboard-popup__leaderboardInfo-wrapper">
            <LeaderboardInfo tournament={data} />
          </div>
          <div className="leaderboard-popup__leaderboard-table-layout-wrapper">
            <LeaderboardTableLayout tournament={data} />
          </div>
        </div>
      </div>
    </PortalContainer>
  );
};

export default memo(LeaderboardPopup);
