import React from 'react';
import { isMobile } from 'react-device-detect';
import TournamentsHistoryTableItemDesktop from './Desktop';
import TournamentsHistoryTableItemMobile from './Mobile';
import './TournamentsHistoryTableItem.scss';

const TournamentsHistoryTableItem = ({ data, opened, cb }) => {

  const Component = isMobile ? TournamentsHistoryTableItemMobile : TournamentsHistoryTableItemDesktop;
  return <Component data={data} opened={opened} cb={cb} />;
}

export default TournamentsHistoryTableItem