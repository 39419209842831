import { useCallback, useMemo } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  openDialog,
  closeDialog,
} from "../../../store/actionCreators/dialogManager";
import { requestTournamentGameUrl } from "../../../store/actionCreators/tournamentGameUrl";
import { getCasinoTournament } from "../../../store/selectors";
import Loader from "../../common/Loader";
import { useTranslation } from "react-i18next";
import "./SuccessFullRegistration.scss";
import PopUpDialogTemplate from "../../common/PopUpDialogTemplate";
import { classNames } from "../../../helpers/pureFunctions";

const SuccessfullRegistration = ({ isMobile, isPortrait, isLandscape }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const tournament = useSelector(getCasinoTournament);

  const onClose = useCallback(
    () => dispatch(closeDialog("successfullRegistration")),
    [dispatch]
  );

  const onSubmit = useCallback(() => {
    dispatch(requestTournamentGameUrl(tournament));
    onClose();
  }, [dispatch, onClose, tournament]);

  const canPlay = useMemo(
    () => tournament && !moment().isBefore(tournament.startDate),
    [tournament]
  );

  const successfullRegistrationClassNames = classNames('successfull-registration', {
    'successfull-registration--portrait': isMobile && isPortrait,
    'successfull-registration--landscape': isMobile && isLandscape,
    'without-button': !canPlay
  });

  if (!tournament)
    return (
      <div>
        <Loader size="small" />
      </div>
    );

  return (
    <PopUpDialogTemplate
      className="successfull-registration-pop-up"
      dialogName="successfullRegistrationPopUp"
      withoutDescription
      onClose={onClose}
      dynamicTitle={`${tournament?.tournamentName} (#${tournament?.tournamentId})`}
      onConfirm={canPlay && onSubmit}
      confirmText={t("startTournament")}
      setHTML
    >
      <div className={successfullRegistrationClassNames}>
        <div className="successfull-registration__success-icon">
          <span className="successfull-registration__success-icon-wrapper">
            <i className="icon-check success-icon" />
          </span>
        </div>
        <div className="successfull-registration__description">
          <span className="successfull-registration__description--text">{t('successfullyRegisteredForTheTournament')}</span>
        </div>
      </div>
    </PopUpDialogTemplate>
  );
};

export default SuccessfullRegistration;
