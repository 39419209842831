import { take, fork, call, put, select, cancel } from 'redux-saga/effects';
import types from '../actionTypes';
import { apiPost } from './callApi';
import apiRoutes from '../../constants/apiRoutes';
import { getToken } from '../selectors';
import { tournamentLastBetFailure } from '../actionCreators/lastBetRequest';

function* lastBetRequest({ tournamentId }) {
  try {
    const token = yield select(getToken);
    const headers = {};
    headers['x-access-token'] = token;
    const params = {
      tournamentId
    };
    const response = yield call(apiPost, apiRoutes.TOURNAMENT_LAST_BET, params, headers);
    if (!response || !response.data) return false;
  } catch (e) {
    yield put(tournamentLastBetFailure(e));
  }
}

export default function* watchTournamentLastBetRequest() {
  let forked = null;
  while (true) {
    const action = yield take(types.TOURNAMENT_LAST_BET_REQUEST);
    if (forked) yield cancel(forked);
    yield fork(lastBetRequest, action);
  }
};
