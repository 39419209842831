import types from "../actionTypes";

export const getAccountBalanceRequest = (rejectPostMessageToParent) => ({
  type: types.GET_ACCOUNT_BALANCE_REQUEST,
  rejectPostMessageToParent,
});

export const getAccountBalanceRequestFromHost = () => ({
  type: types.GET_ACCOUNT_BALANCE_REQUEST_FROM_HOST,
});

export const getAccountBalanceSuccess = (wallets) => ({
  type: types.GET_ACCOUNT_BALANCE_SUCCESS,
  wallets,
});

export const getAccountBalanceFailure = (e) => ({
  type: types.GET_ACCOUNT_BALANCE_FAILURE,
  exception: e,
});
