import { useSelector } from "react-redux";
import { getCasinoTournament } from "../../../../../store/selectors";
import PlayerInfo from "../../PlayerInfo";
import HowToPlayPageLink from "../HowToPlayPageLink";
import TournamentGameInfoWidget from "../TournamentGameInfoWidget";
import TournamentGameTimerWidget from "../TournamentGameTimerWidget";
import "./DesktopTournamentGameWidget.scss";

const DesktopTournamentGameWidget = () => {
  const tournament = useSelector(getCasinoTournament);

  const { endDate } = tournament;
  
  return (
    <div className="tournament-game__widget">
      <div className="tournament-game__widget__info-link-wrapper">
        <HowToPlayPageLink />
      </div>
      <div className="tournament-game__widget__countdown-timer-wrapper">
        <TournamentGameTimerWidget countdownTimestamp={endDate} />
      </div>
      <div className="tournament-game__widget__player-info-wrapper">
        <PlayerInfo />
      </div>
      <div className="tournament-game__widget__players-list-wrapper">
        <TournamentGameInfoWidget />
      </div>
    </div>
  );
};

export default DesktopTournamentGameWidget;
