import { call, cancel, fork, put, take } from "redux-saga/effects";
import apiRoutes from "../../constants/apiRoutes";
import types from '../actionTypes';
import { apiGetFileStorage } from "./callApi";
import { getGosPopupsFromCMSFailure, getGosPopupsFromCMSSucsess } from "../actionCreators/gosPopupsFromCMS";
import settings from "../../configs/settings";

function filterByDeviceType(obj, deviceType) {
  const filteredArray = [];

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const device = obj[key][deviceType];

      if (device) {
        filteredArray.push(device);
      }
    }
  }

  return filteredArray;
}

function* processGetGosPopupsFromCMSRequest() {
  try {
    const response = yield call(
      apiGetFileStorage,
      apiRoutes.GET_CMS_POPUPS,
      {}
    );
    const outputObject = response.data?.data.reduce((accumulator, item) => {
      const id = item?.id;
      const deviceType = item?.attributes?.Device?.type;
      const imageUrl = item?.attributes?.Images?.data?.[0]?.attributes?.url;
      if (!accumulator[id]) {
        accumulator[id] = {};
      }
      if (!accumulator[id][deviceType]) {
        accumulator[id][deviceType] = {};
      }
  
      accumulator[id][deviceType].id = id;
      accumulator[id][deviceType].imageURL = `${settings.FILE_STORAGE_ADDRESS}${imageUrl}`;
  
      return accumulator;
  }, {});
  
    yield put(getGosPopupsFromCMSSucsess({
      mobile: filterByDeviceType(outputObject, 'mobile'),
      desktop: filterByDeviceType(outputObject, 'desktop')
    }));
  } catch (e) {
    yield put(getGosPopupsFromCMSFailure(e));
  }
}

export function* watchGetGosPopupsFromCMSRequest() {
  let forked = null;
  while (true) {
    yield take(types.GET_GOS_POPUPS_FROM_CMS_REQUEST);
    if (forked) yield cancel(forked);
    forked = yield fork(processGetGosPopupsFromCMSRequest);
  }
}
