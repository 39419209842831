import { useCallback, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { isMobile } from "react-device-detect";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import {
  getDesktopBanners,
  getMobileBanners,
  isCMSResponsePending,
} from "../../store/selectors";
import { classNames } from "../../helpers/pureFunctions";
import settings from "../../configs/settings";
import Skeleton from "../Skeleton";
import "./Banner.scss";

const Banner = () => {
  const banners = useSelector(isMobile ? getMobileBanners : getDesktopBanners);
  const pending = useSelector(isCMSResponsePending);

  const sliderRef = useRef(null);
  const timer = useRef();

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current?.swiper?.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current?.swiper?.slideNext();
  }, []);

  useEffect(() => {
    timer.current = setInterval(handleNext, 3000);
    return () => clearInterval(timer.current);
  }, [timer.current]);

  return pending || banners?.length <= 0 ? (
    <Skeleton
      style={{ marginBottom: 12 }}
      radius={8}
      width="100%"
      height={177}
    />
  ) : (
    <div
      className={classNames("banner", {
        "banner--scale": isMobile && banners?.length > 3,
      })}
    >
      <Swiper
        slidesPerView={isMobile ? (banners?.length > 3 ? 1.2 : 1) : 1}
        centeredSlides
        spaceBetween={isMobile ? (banners?.length > 3 ? 24 : 8) : 8}
        speed={800}
        loop
        pagination={isMobile ? { clickable: true } : false}
        modules={[Pagination]}
        ref={sliderRef}
      >
        {banners?.map((banner, index) => (
          <SwiperSlide key={index}>
            <img
              src={`${settings?.FILE_STORAGE_ADDRESS}${banner}`}
              alt="banner"
              className="banner__image"
            />
          </SwiperSlide>
        ))}
      </Swiper>
      {banners?.length > 1 && (
        <>
          <button
            className="banner__button banner__button--prev"
            onClick={handlePrev}
          >
            <i className="icon-arrow-right" />
          </button>
          <button
            className="banner__button banner__button--next"
            onClick={handleNext}
          >
            <i className="icon-arrow-right" />
          </button>
        </>
      )}
    </div>
  );
};

export default Banner;
