import { take, fork, call, put, select, cancel } from 'redux-saga/effects';
import types from '../actionTypes';
import { apiPost } from './callApi';
import apiRoutes from '../../constants/apiRoutes';
import { getToken } from '../selectors';
import { selectCasinoTournament, updateCasinoTournaments } from '../actionCreators/casinoTournament';
import { tournamentRebuyFailure, tournamentRebuySuccess } from '../actionCreators/casinoTournamentRebuy';
import { openDialog } from '../actionCreators/dialogManager';
import { openToaster } from '../actionCreators/toaster';
import { getPlayerStateInTournamentsRequest } from '../actionCreators/playerStateInTournaments';

function* casinoTournamentRebuy({ tournamentData }) {
  try {
    const token = yield select(getToken);
    const gameData = tournamentData.tournamentGames[0];
    const params = {
      token: token,
      slot: gameData["id"],
      providerId: gameData["providerId"],
      tournamentId: tournamentData["tournamentId"],
      paymentType: tournamentData.paymentType,
    };
    const response = yield call(apiPost, apiRoutes.CASINO_TOURNAMENT_REBUY, params);

    if (!response || !response.data) return false;
    const { tournament } = response.data.result;

    yield put(tournamentRebuySuccess(tournament, tournament.tournamentId));
    yield put(selectCasinoTournament(tournament));
    yield put(openDialog({ dialogType: 'successTournamentRebuy' }));
    yield put(updateCasinoTournaments(tournament));
    yield put(getPlayerStateInTournamentsRequest([tournament?.tournamentId], true));
  } catch (e) {
    if (e.response?.data?.message?.key !== 'low_balance' && e?.message != 'Network Error') {
      yield put(openToaster({view: 'tournamentRebuyFailure'}));
    }
    yield put(tournamentRebuyFailure(e, tournamentData));
  }
}

export default function* watchTournamentCasinoRebuy() {
  let forked = null;
  while (true) {
    const action = yield take(types.CASINO_TOURNAMENT_REBUY_REQUEST);
    if (forked) yield cancel(forked);
    yield fork(casinoTournamentRebuy, action);
  }
};
