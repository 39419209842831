import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const ToasterTemplate = ({ onClose, children, timerDelay }) => {
  useEffect(() => {
    let timer;
    if (timerDelay) {
      timer = setTimeout(onClose, timerDelay);
    }
    return () => clearTimeout(timer);
  }, [timerDelay]);

  return (
    <div className="toaster-template">
      <div className="toaster-template__close-wrapper" onClick={onClose}>
        <i className="toaster-template__close icon-cross" />
      </div>
      <div className="toaster-template__inner">{children}</div>
      {+timerDelay > 0 && (
        <div className="toaster-template__timer">
          <span
            style={{
              animationName: "progress",
              animationDuration: `${timerDelay / 1000}s`,
              animationFillMode: "forwards",
              animationTimingFunction: "linear",
            }}
          />
        </div>
      )}
    </div>
  );
};

ToasterTemplate.Left = ({ children }) => {
  return <div className="toaster-template__left-side-wrapper">{children}</div>;
};
ToasterTemplate.Right = ({
  children,
  titleParams,
  toasterName,
  textParams,
  timerDelay,
  hasDescriptionText
}) => {
  const { t } = useTranslation();
  return (
    <div
      className="toaster-template__info-wrapper"
      style={{ padding: `12px 8px ${+timerDelay > 0 ? 13 : 8}px` }}
    >
      <div className="toaster-template__info-title-wrapper">
        <span
          className="toaster-template__info-title"
          dangerouslySetInnerHTML={{
            __html: t(`${toasterName}.title`, titleParams ?? {}),
          }}
        />
      </div>
      {hasDescriptionText && (
        <span
          className="toaster-template__info-text"
          dangerouslySetInnerHTML={{
          __html: t(`${toasterName}.text`, textParams ?? {}),
        }}
      />
      )}
      {children}
    </div>
  );
};

export default ToasterTemplate;
