import { useEffect, useState, memo } from "react";
import { useTranslation } from "react-i18next";
import { classNames } from "../../helpers/pureFunctions";
import "./Tooltip.scss";

const Tooltip = ({ children, text, position }) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const handleTooltipClick = () => {
    setShow((prev) => !prev);
  }

  useEffect(() => {
    let timer;
    if (show) {
      timer = setTimeout(() => {
        setShow(false);
      }, 4000)
    }
    return () => clearTimeout(timer);
  }, [show]);

  return (
    <div className="tooltip">
      {show && (
        <span className={classNames('tooltip__overlay', { [`tooltip__overlay--${position}`]: !!position })}>
          {t(text)}
        </span>
      )}
      <div onClick={handleTooltipClick} className="tooltip__inner">
        {children}
      </div>
    </div>
  );
};

export default memo(Tooltip);
