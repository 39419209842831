import types from "../actionTypes";

export const myTournamentsToggle = (payload) => ({
  type: types.MY_TOURNAMENTS_TOGGLE,
  payload
});

export const myTournamentsSET = ({ type, value }) => ({
  type: types.MY_TOURNAMENTS_SET,
  payload: {
    type,
    value,
  },
});

export const myTournamentsReset = () => ({
  type: types.MY_TOURNAMENTS_RESET,
});
