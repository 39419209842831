import { useRef, memo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useOutsideClick } from "../../../hooks/useOutsideClick";
import { useMobileMenu } from "../../../hooks/useMobileMenu";
import PortalContainer from "../../common/PortalContainer";
import BalanceWidget from "../../BalanceWidget";
import PlayerLastWinsWindget from "../../PlayerLastWinsWindget";
import ThemeSwitcher from "../../ThemeSwitcher";
import config from "../../../configs/config";
import { naviagtion } from "../../Navigation/navigationConfig";
import { classNames } from "../../../helpers/pureFunctions";
import { setTab } from "../../../store/actionCreators/tab";
import { myTournamentsReset } from "../../../store/actionCreators/myTournaments";
import { setCategory } from "../../../store/actionCreators/category";
import "./MobileBurgerMenu.scss";

const MobileBurgerMenu = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const node = useRef();

  const { menu, isClosed, handleAnimationEnd, closeBurgerMenu } =
    useMobileMenu();
  useOutsideClick(node, undefined, closeBurgerMenu);

  const handleTabView = (view) => {
    dispatch(setTab(view));
    dispatch(myTournamentsReset());
    dispatch(setCategory(view));
    closeBurgerMenu();
  };

  const goToLobby = () => {
    dispatch(setCategory(''));
    closeBurgerMenu();
  };

  return (
    <PortalContainer id="mobile-burger-menu">
      <div
        className={`mobile-burger-menu ${
          isClosed ? "mobile-burger-menu--hide" : ""
        }`}
        ref={node}
        onAnimationEnd={handleAnimationEnd}
      >
        <div className="mobile-burger-menu__close-balance-widegt-wrapper">
          <div className="mobile-burger-menu__close">
            <i onClick={closeBurgerMenu} className="icon-cross" />
          </div>
          <div className="mobile-burger-menu__balance-widegt-wrapper">
            <BalanceWidget tooltipPosition="bottom" />
          </div>
        </div>
        <div className="mobile-burger-menu__player-last-wins-widget-wrapper">
          <PlayerLastWinsWindget />
        </div>
        <div className="mobile-burger-menu__navigation-wrapper">
          <div onClick={goToLobby} className="mobile-burger-menu__navigation-title-wrapper">
            <span className="mobile-burger-menu__navigation-title">
              {t('gosLobby')}
            </span>
            <i className="mobile-burger-menu__navigation-icon icon-arrow-right" />
          </div>
          <div className="mobile-burger-menu__navigation-list">
            {naviagtion.map((nav, index) => (
              <div
                onClick={handleTabView.bind(null, nav.value)}
                key={index}
                className={classNames("mobile-burger-menu__navigation-item", {
                  "mobile-burger-menu__navigation-item--disabled":nav?.disabled,
                  "mobile-burger-menu__navigation-item--new":nav?.new,
                })}
              >
                <div className="mobile-burger-menu__navigation-image-wrapper">
                  <img
                    src={nav.image}
                    alt={t(nav.label)}
                    className="mobile-burger-menu__navigation-image"
                  />
                </div>
                <span className="mobile-burger-menu__navigation-text">
                  {t(nav.label)}
                </span>
                {(nav?.disabled || nav?.new)&& (
                  <div className={`mobile-burger-menu__navigation-label-wrapper mobile-burger-menu__navigation-label-wrapper--${nav?.disabled ? 'disabled' : 'new'}`}>
                    <span className="mobile-burger-menu__navigation-label">
                      {t(nav?.disabled ? "comingSoon": "new")}
                    </span>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
        <div className="mobile-burger-menu__list">
          <div className="mobile-burger-menu__list-inner">
            {menu
              .filter((item) => item?.permission)
              .map((item, index) => (
                <div
                  key={index}
                  className="mobile-burger-menu__item"
                  onClick={item?.action}
                >
                  <div className="mobile-burger-menu__item-icon">
                    {item?.icon_text && <span>{item.icon_text}</span>}
                    {item?.icon && <i className={`icon-${item.icon}`} />}
                  </div>
                  <span
                    className="mobile-burger-menu__item-text"
                    style={{
                      textTransform: item?.isCapitalized
                        ? "capitalize"
                        : "none",
                    }}
                  >
                    {t(item?.text)}
                  </span>
                  <i className="icon-arrow-right" />
                </div>
              ))}
            {config?.hasSkinSwitcher && (
              <div className="mobile-burger-menu__theme-switcher-wrapper">
                <ThemeSwitcher />
              </div>
            )}
          </div>
        </div>
      </div>
    </PortalContainer>
  );
};

export default memo(MobileBurgerMenu);
