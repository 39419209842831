import { useSelector } from "react-redux";
import { isMobile } from "react-device-detect";
import MobileLobby from "./MobileLobby";
import DesktopLobby from "./DesktopLobby";
import HeaderStatistics from "../HeaderStatistics";
import { getShowHeaderStatistics } from "../../store/selectors";
import "./Lobby.scss";
import Header from "../Header";

const Lobby = (props) => {
  const Component = isMobile ? MobileLobby : DesktopLobby;
  const showHeaderStatistics = useSelector(getShowHeaderStatistics);

  return (
    <>
      {!isMobile && (
        <div className="lobby__header-wrapper">
          <Header />
        </div>
      )}
      {showHeaderStatistics === 'true' && (
        <div className="lobby__header-statistics-wrapper">
          <HeaderStatistics />
        </div>
      )}
      <Component
        {...props}
      />
    </>
  );
};

export default Lobby;
