import { useRef, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./HorizontalScroll.scss";

const HorizontalScroll = ({ children, title, gap, itemWidth, icon }) => {
  const { t } = useTranslation();
  const scrollContainerRef = useRef(null);
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const container = scrollContainerRef.current;
    if (!container) return;
    const handleScrollEvent = () => {
      setScrollPosition(container.scrollLeft);
    };

    container.addEventListener("scroll", handleScrollEvent);

    return () => {
      container.removeEventListener("scroll", handleScrollEvent);
    };
  }, []);
  

  const handleScroll = (scrollOffset) => {
    const container = scrollContainerRef.current;
    if (container) {
      const containerWidth = container.offsetWidth;
      const scrollWidth = container.scrollWidth;
      const maxScroll = scrollWidth - containerWidth;

      let newPosition = scrollPosition + scrollOffset;
      newPosition = Math.max(0, Math.min(newPosition, maxScroll));

      container.scrollTo({
        left: newPosition,
        behavior: "smooth",
      });

      setScrollPosition(newPosition);
    }
  };

  return (
    <>
      {children?.length > 1 ? (
        <div className="horizontal-scroll">
          <div className="horizontal-scroll__info-buttons">
            {icon && <div className="horizontal-scroll__icon"><i className={`icon-${icon}`} /></div>}
            {title && (
              <span className="horizontal-scroll__info">{`${t(title)} (${
                children?.length
              })`}</span>
            )}
            <div className="horizontal-scroll__buttons">
              <div onClick={() => handleScroll(-itemWidth || -320)} className="horizontal-scroll__button horizontal-scroll__button--prev">
                <i className="icon-arrow-right" />
              </div>
              <div onClick={() => handleScroll(itemWidth || 320)} className="horizontal-scroll__button">
                <i className="icon-arrow-right" />
              </div>
            </div>
          </div>
          <div
            className="horizontal-scroll__items"
            ref={scrollContainerRef}
            style={{
              gap: gap || 12,
            }}
          >
            {children.map((item, index) => (
              <div
                key={index}
                className="horizontal-scroll__item"
                style={{
                  minWidth: itemWidth || 320,
                }}
              >
                {item}
              </div>
            ))}
          </div>
        </div>
      ) : (
        children[0]
      )}
    </>
  );
};

export default HorizontalScroll;
