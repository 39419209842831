import { useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { closeDialog } from "../../../store/actionCreators/dialogManager";
import PortalContainer from "../../common/PortalContainer";
import Checkbox from "../../common/Checkbox";
import "./GOSPopupsFromCMS.scss";

const GOSPopupsFromCMS = ({ dialogType, imageURL}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [show, setShow] = useState(() => localStorage.getItem('dontShowAgainPopups') === 'true');

  const handleClick = useCallback(() => {
    const isChecked = localStorage.getItem(`dontShowAgain--${dialogType}`);
    isChecked === 'true'? localStorage.setItem(`dontShowAgain--${dialogType}`, 'false') : localStorage.setItem(`dontShowAgain--${dialogType}`, 'true');
    setShow(prev => !prev);
  }, []);

  const onClose = () => {
    dispatch(closeDialog(dialogType));
  }

  return (
    <PortalContainer id={dialogType}>
      <div
        className="gos-popup-from-cms"
        style={{
          backgroundImage: `url(${imageURL})`,
        }}
      >
                <div className="gos-popup-from-cms__close">
          <i className="icon-cross" onClick={onClose} />
        </div>
        <div onClick={handleClick} className="gos-popup-from-cms__checkbox-wrapper">
          <Checkbox checked={show} />
          <span>{t("dontShowAgain")}</span>
        </div>
      </div>
    </PortalContainer>
  );
};

export default GOSPopupsFromCMS;
