import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getUsersAvatars, isCasinoTournamentPlayersPending, isUsersAvatarsPending } from "../../../../store/selectors";
import Skeleton from "../../../Skeleton";
import "./JackpotGameLeaderboardTable.scss";

const JackpotGameLeaderboardTable = ({ tournament, self, isGame, players }) => {
  const { t } = useTranslation();

  const avatars = useSelector(getUsersAvatars);
  const isPlayersPending = useSelector(isCasinoTournamentPlayersPending);
  const isAvatarPending = useSelector(isUsersAvatarsPending);

  return (
    <div className="jackpot-game-leader-board-table">
      <div className="jackpot-game-leader-board-table__header">
        <span className="jackpot-game-leader-board-table__header-row position">
          {t("leaderboardPosition")}
        </span>
        <span className="jackpot-game-leader-board-table__header-row username" />
        <span className="jackpot-game-leader-board-table__header-row spins">
          {t("spins")}
        </span>
        <span className="jackpot-game-leader-board-table__header-row points">
          {t("points")}
        </span>
      </div>
      <div className="jackpot-game-leader-board-table__body">
        {isPlayersPending || isAvatarPending ? (
          <div className="jackpot-game-leader-board-table__skeletons-wrapper">
            <div className="jackpot-game-leader-board-table__skeleton-wrapper position">
              <Skeleton height={15} width="100%" />
            </div>
            <div className="jackpot-game-leader-board-table__skeleton-wrapper username">
              <Skeleton height={15} width="100%" />
            </div>
            <div className="jackpot-game-leader-board-table__skeleton-wrapper score">
              <Skeleton height={15} width="100%" />
            </div>
            <div className="jackpot-game-leader-board-table__skeleton-wrapper prize">
              <Skeleton height={15} width="100%" />
            </div>
          </div>
        ) : (
          players?.map((player, index) => (
            <div
              key={player.accountId}
              className={`jackpot-game-leader-board-table__body-row-wrapper ${player?.accountId === self?.accountId ? 'jackpot-game-leader-board-table__body-row-wrapper--self' : ''}`}
            >
              <span className={`jackpot-game-leader-board-table__body-row ${index === 0 ? 'jackpot-game-leader-board-table__body-row--leader' : '' } position`}>{index !== 0 ? `${
                index + 1
              }.` : ''}</span>
              <div className="jackpot-game-leader-board-table__body-row username">
                {avatars?.[player?.accountId]?.imagePath ? (
                  <img src={avatars[player.accountId].imagePath} alt="avatar" />
                ) : (
                  <div className="jackpot-game-leader-board-table__default-avatar">
                    <i className="icon-avatar" />
                  </div>
                )}
                <span>{player.nickname || player.name}</span>
              </div>
              <span className="jackpot-game-leader-board-table__body-row spins">
                {`${player?.spins}/${tournament?.totalSpins ?? tournament?.actualTotalSpins}`}
              </span>
              <span className="jackpot-game-leader-board-table__body-row points">
                  {player?.score ?? player?.points}
              </span>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default JackpotGameLeaderboardTable;
