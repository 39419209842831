import './Loader.scss';
import { useTranslation } from 'react-i18next';
import { classNames } from '../../../helpers/pureFunctions';

const Loader = ({ size = 'large' }) => {

    const { t } = useTranslation();
    const loaderClassNames = classNames('spinner', {
        [`spinner-${size}`]: size,
    })

    return (
        <div className='loader'>
            <span className={loaderClassNames}></span>
        </div>
    )
}

export default Loader