import moment from 'moment';

export const getRemainingTimeUntilMsTimestamp = (timestampMs) => {
    const timestampDay = moment(timestampMs);
    const now = moment();
    if(timestampDay.isBefore(now)) {
        return {
            seconds: '00',
            minutes: '00',
            hours: '00',
            days: '00'
        }
    }
    return {
        seconds : getRemainingSeconds(now, timestampDay),
        minutes : getRemainingMinutes(now, timestampDay),
        hours : getRemainingHours(now, timestampDay),
        days : getRemainingDays(now, timestampDay)
    } ;
};

const getRemainingSeconds = (now, timestampDay) => {
    const seconds = timestampDay.diff(now, 'seconds') % 60;
    return padWithZeros(seconds, 2);
};

const getRemainingMinutes = (now, timestampDay) => {
    const minutes = timestampDay.diff(now, 'minutes') % 60;
    return padWithZeros(minutes, 2);
};

const getRemainingHours = (now, timestampDay) => {
    const hours = timestampDay.diff(now, 'hours') % 24;
    return padWithZeros(hours, 2);
};

const getRemainingDays = (now, timestampDay) => {
    const days = timestampDay.diff(now, 'days');
    return padWithZeros(days, 2);
};

const padWithZeros = (number, minLength) => {
    const numberString = number.toString();
    if(numberString.length >= minLength) return numberString;
    return "0".repeat(minLength - numberString.length) +  numberString;
};
