const languageConfig =  {
  lng: ["en", "ru", "tr", "pt", "fr", "it"],
  fallbackLng: "en",
  fallbackNS: "common",
  lowerCaseLng: true,
  ns: "common",
  backend: {
    loadPath: "/locales/{{lng}}/{{ns}}.json",
  },
  keySeparator: ".",
};

export default languageConfig;