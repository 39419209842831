import { useTranslation } from "react-i18next";
import moment from "moment";
import "./DesktopLeaderboardInfo.scss";

const DesktopLeaderboardInfo = ({ tournament }) => {
  const { t } = useTranslation();
  const {
    tournamentGames: [tournamentGame],
    startDate,
    endDate,
    tournamentName,
    tournamentId,
  } = tournament;

  const notes = tournament?.extraInfo?.externalPrize ?? "";

  return (
    <div className="leaderboard-info">
      <img
        className="leaderboard-info__image"
        src={tournamentGame?.thumbnail}
        alt="game image"
      />
      <div className="leaderboard-info__name-date-notes-wrapper">
        <div className="leaderboard-info__name-date-wrapper">
          <span className="leaderboard-info__name">
            {tournamentName} {`(#${tournamentId})`}
          </span>
          <span className="leaderboard-info__date">
            {moment(startDate).format("DD/MM/YYYY HH:mm")} -{" "}
            {moment(endDate).format("DD/MM/YYYY HH:mm")}
          </span>
        </div>
        <div className="leaderboard-info__notes-wrapper">
          <span className="leaderboard-info__notes-label">
            {t("prizePool")}:
          </span>
          <span className="leaderboard-info__notes-value">{notes}</span>
        </div>
      </div>
    </div>
  );
};

export default DesktopLeaderboardInfo;
