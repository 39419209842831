import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import StatisticItem from "../StatisticItem";

const DesktopHeaderStatistics = ({ data }) => {
  return (
    <>
      {data?.length > 0 && (
        <div className="header-statistics">
          <Swiper
            slidesPerView={4}
            spaceBetween={24}
            autoplay={{
              delay: 5000,
            }}
            modules={[Autoplay]}
            speed={1500}
            slidesPerGroup={3}
            loop
          >
            {data.map((item, index) => (
              <SwiperSlide key={index}>
                <StatisticItem item={item} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      )}
    </>
  );
};

export default DesktopHeaderStatistics;
