import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { closeDialog } from '../../../store/actionCreators/dialogManager';
import PopUpDialogTemplate from '../../common/PopUpDialogTemplate';
import Loader from '../../common/Loader';
import { jacpotsRequest } from '../../../store/actionCreators/jackpot';
import { useResetState } from '../../../hooks/useResetState';
import { getCasinoTournamentPlayers, getJackpotJoinedPlayers, getUsername } from '../../../store/selectors';
import './WaitingRoom.scss';
import { useEffect } from 'react';
import { casinoTournamentPlayersRequest } from '../../../store/actionCreators/casinoTournamentPlayers';
import { resetJackpotJoinedPlayer } from '../../../store/actionCreators/jackpotJoinedPlayers';

const WaitingRoom = ({ tournament }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  useResetState(onClose);
  const { tournamentName, tournamentId } = tournament;
  const selfUsernamer = useSelector(getUsername);
  const jackpotJoinedPlayers = useSelector(getJackpotJoinedPlayers);
  const players = useSelector(getCasinoTournamentPlayers)(tournamentId);

  const filteredPlayerNames = players?.filter(player => !player?.self)?.map(player => player?.unMaskedName) || [];

  const opponents = [
    ...filteredPlayerNames,
    ...(jackpotJoinedPlayers ? jackpotJoinedPlayers.filter(item => item !== selfUsernamer && !filteredPlayerNames.includes(item)) : [])
  ];


  useEffect(() => {
    dispatch(casinoTournamentPlayersRequest({ id: tournamentId, offset: 0, limit: 20 }));
    return () => dispatch(resetJackpotJoinedPlayer());
  }, []);

  function onClose(){
    dispatch(closeDialog('waitingRoom'))
    dispatch(jacpotsRequest());
  }

  return (
    <PopUpDialogTemplate
      className="waiting-room-pop-up"
      dialogName="waitingRoomDialog"
      titleParams={{ tournamentName, tournamentId }}
      onClose={onClose}
    >
      <div className="waiting-room">
        <div className="waiting-room__joined-players-wrapper">
          <span>{opponents?.length ? `${opponents?.join(' ,')} ${t('joined')}...` : t('waitToJoinPlayer')}</span>
        </div>
        <div className="waiting-room__loading-wrapper">
          <Loader />
        </div>
      </div>
    </PopUpDialogTemplate>
  );
};

export default WaitingRoom;
