import { take, fork, cancel, call, put, select } from 'redux-saga/effects';
import { apiGetLobbyURL } from './callApi';
import apiRoutes from '../../constants/apiRoutes';
import types from '../actionTypes';
import { getSkinId, getToken } from '../selectors';
import { casinoTournamentsHistoryFailure, casinoTournamentsHistorySuccess } from '../actionCreators/casinoTournamentHistory';
import { maskString } from '../../helpers/pureFunctions';
import config from '../../configs/config';

function groupByTournamentType(data) {
  return data.reduce((acc, item) => {
    const { tournamentType } = item;
    const key = tournamentType === 'spinAndGo' ? 'jackpot' : tournamentType;
    acc[key] = acc[key] || [];
    acc[key].push(item);
    return acc;
  }, {});
}

function* casinoTournamentsHistoryRequest({page, limit}) {
  try {
    const token = yield select(getToken);
    const params = { page, limit };
    if (token) params.token = token;

    const response = yield call(apiGetLobbyURL, apiRoutes.GET_CASINO_TOURNAMENTS_HISTORY, params);
    if (response.data) {
      const transformedData = groupByTournamentType(response.data.casinoTournamentHistory.map(item => ({
        ...item,
        position1: {...item.position1, username: config?.maskNickname ? maskString(item.position1?.nickname || item.position1?.username) : item.position1?.nickname || item.position1?.username},
        position2: {...item.position2, username: config?.maskNickname ? maskString(item.position1?.nickname || item.position2?.username) : item.position1?.nickname || item.position2?.username},
        position3: {...item.position3, username: config?.maskNickname ? maskString(item.position1?.nickname || item.position3?.username) : item.position1?.nickname ||  item.position3?.username},
        requesterPosition: {...item.requesterPosition, username: config?.maskNickname ? maskString(item.requesterPosition?.nickname || item.requesterPosition?.username) : item.requesterPosition?.nickname || item.requesterPosition?.username},
      })));
      const historyData = {
        ...response.data,
        casinoTournamentHistory: transformedData,
        totalCount: {
          regular: transformedData?.['regular']?.length,
          jackpot: transformedData?.['jackpot']?.length,
        }
      }
      yield put(casinoTournamentsHistorySuccess(historyData));
    } else {
      yield put(casinoTournamentsHistoryFailure());
    }
  } catch (e) {
    yield put(casinoTournamentsHistoryFailure(e));
  }
}

export default function* watchCasinoTournamentsHistoryRequest() {
  let forked = null;
  while (true) {
    const action = yield take(types.GET_CASINO_TOURNAMENTS_HISTORY_REQUEST);
    if (forked) yield cancel(forked);
    yield fork(casinoTournamentsHistoryRequest, action);
  }
};
