import { useEffect, useMemo, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCasinoTournamentPlayers,
  getUsersAvatars,
  isCasinoTournamentPlayersPending,
  isUsersAvatarsPending,
} from "../../../../store/selectors";
import {
  casinoTournamentPlayersRequest,
} from "../../../../store/actionCreators/casinoTournamentPlayers";
import LeaderboardTable from "../LeaderboardTable";
import LeaderboardTopLeads from "../LeaderboardTopLeads";
import { isBetweenOrEqual } from "../../../../helpers/pureFunctions";
import "./LeaderboardTableLayout.scss";
import EmptyList from "../../../EmptyList";

const LeaderboardTableLayout = ({ tournament }) => {
  const dispatch = useDispatch();
  const players = useSelector(getCasinoTournamentPlayers)(
    tournament?.tournamentId
  );
  const isPlayersPending = useSelector(isCasinoTournamentPlayersPending);
  const avatars = useSelector(getUsersAvatars);
  const isAvatarPending = useSelector(isUsersAvatarsPending);

  const currentStructure = useMemo(() => {
    const { enrolled, tournamentAward } = tournament || {};
    const { structure } = tournamentAward || {};
    const defaultStructure = structure?.[0];
    return enrolled === 0 || structure?.length === 1
      ? defaultStructure
      : structure.find(({ playersCount }) =>
          isBetweenOrEqual(enrolled, playersCount.min, playersCount.max)
        ) || structure[structure.length - 1];
  }, [tournament]);

  const computeAwardArray = () => {
    return players?.reduce((result, _, index) => {
      const award = currentStructure?.awards?.[index]?.award;
      const { placesFrom, placesTo } = currentStructure?.awards?.[index] || {};

      if (award && placesFrom !== undefined && placesTo !== undefined) {
        const numberOfAwards = placesTo - placesFrom + 1;
        result.push(
          ...Array(numberOfAwards).fill({
            currency: award.currency,
            value: award.value,
            type: award?.type,
            url: award?.url,
            name: award?.name,
          })
        );
      }

      return result;
    }, []);
  };

  useEffect(() => {
    let timerId;
  
    const fetchData = () => {
      if (tournament?.tournamentId) {
        dispatch(casinoTournamentPlayersRequest({ id: tournament.tournamentId }));
      }
    };
  
    if (tournament?.tournamentId) {
      fetchData();
      timerId = setInterval(fetchData, 30000);
    }
  
    return () => {
      clearInterval(timerId);
    };
  }, [tournament]);

  return (
    <div className="leaderboard-table-layout">
      {isPlayersPending || players?.length ? (
        <>
          <div className="leaderboard-table-layout__leaderboard-top-leads-wrapper">
            <LeaderboardTopLeads
              computeAwardArray={computeAwardArray}
              players={players}
              isPlayersPending={isPlayersPending}
              avatars={avatars}
            />
          </div>
          <div className="leaderboard-table-layout__leaderboard-table-wrapper">
            <LeaderboardTable
              computeAwardArray={computeAwardArray}
              players={players}
              isPlayersPending={isPlayersPending}
              avatars={avatars}
              isAvatarPending={isAvatarPending}
            />
          </div>
        </>
      ) : (
        <div className="leaderboard-table-layout__empty-list-wrapper">
          <EmptyList textKey="thereIsNoDataToShow" />
        </div>
      )}
    </div>
  );
};

export default memo(LeaderboardTableLayout);
