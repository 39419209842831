import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { classNames } from "../../helpers/pureFunctions";
import Loader from "../common/Loader";
import Skeleton from "../Skeleton";
import { useCountdownTimer } from "../../hooks/useCountdownTimer";
import "./CountdownTimer.scss";

const CountdownTimer = ({
  countdownTimestampMs,
  countdownDurationMs,
  variant,
  size,
  simple,
  skeleton,
  skeletonHeight,
  onEndText,
  onTimerEnd,
}) => {
  const { t } = useTranslation();
  const { remainingTime, isTimerEnd } = useCountdownTimer(countdownTimestampMs, countdownDurationMs, onTimerEnd);

  const buttonClassNames = classNames({
    "countdown-timer": true,
    "countdown-timer--mobile": isMobile,
    [`countdown-timer--${variant}`]: variant,
    [`countdown-timer--${size}`]: size,
  });

  if (!remainingTime)
    return skeleton ? (
      <Skeleton count={4} space={6} height={skeletonHeight || "17px"} />
    ) : (
      <div>
        <Loader size="small" />
      </div>
    );
  const { days, hours, minutes, seconds } = remainingTime;
  const REMAING_TIMES = [
    { label: "Day", value: days },
    { label: "HH", value: hours },
    { label: "MM", value: minutes },
    { label: "SS", value: seconds },
  ];

  return (
    <div className={buttonClassNames}>
      {onEndText && isTimerEnd && days === '00' && hours === '00' && minutes === '00' && seconds === "00" ? onEndText : simple
        ? `${days} : ${hours} : ${minutes} : ${seconds}`
        : REMAING_TIMES.map(({ label, value }) => (
            <div key={label} className="countdown-timer__col">
              <div className="countdown-timer__remaining-time-wrapper">
                <span className="countdown-timer__remaining-time">{value}</span>
              </div>
              <span className="countdown-timer__label">{t(label)}</span>
            </div>
          ))}
    </div>
  );
};

export default CountdownTimer;
