import { useDispatch } from "react-redux";
import PortalContainer from "../../common/PortalContainer";
import { useTranslation } from "react-i18next";
import { closeDialog } from "../../../store/actionCreators/dialogManager";
import config from "../../../configs/config";
import "./HowGetFreeTickets.scss";

const HowGetFreeTickets = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const closeTop = () => {
    dispatch(closeDialog("howGetFreeTickets"));
  };

  const handleHowgetFreeTickets = () => {
    window.open(config?.externalLinkHowGetTickets, "_blank");
  };

  return (
    <PortalContainer id="modal-root">
      <div className="how-get-free-tickets">
        <div className="how-get-free-tickets__inner">
          <div className="how-get-free-tickets__close-wrapper">
            <i className="how-get-free-tickets__close icon-cross" onClick={closeTop} />
          </div>
          <span className="how-get-free-tickets__title">{t('howGetFreeTickets.title')}</span>
          <div className="how-get-free-tickets__image" />
          <span className="how-get-free-tickets__description">{t('howGetFreeTickets.description')}</span>
          <div className="how-get-free-tickets__button-wrapper">
            <button onClick={handleHowgetFreeTickets} className="how-get-free-tickets__button button button--primary">{t('howGetFreeTickets.button')}</button>
          </div>
        </div>
      </div>
    </PortalContainer>
  );
};

export default HowGetFreeTickets;
