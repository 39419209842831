import { useTranslation } from 'react-i18next';
import './EmptyList.scss';

const EmptyList = ({ textKey }) => {
  const { t } = useTranslation();
  return (
    <div className="empty-list">
      <div className="empty-list__image" />
      <span className='empty-list__text'>{t(textKey)}</span>
    </div>
  )
}

export default EmptyList