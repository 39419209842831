import { useEffect } from 'react';
import { useMobileOrientation, isMobile } from 'react-device-detect';
import { classNames } from '../../../helpers/pureFunctions';
import Button from '../../common/Button';
import config from "../../../configs/config";
import { t } from 'i18next';
import PortalContainer from '../PortalContainer';

const Modal = ({ fullScreen = false, className, children }) => {
  const { isPortrait, isLandscape } = useMobileOrientation();
  useEffect(() => {
    require(`./${config.skin}/Modal.scss`);
  }, []);

  const modalClassNames = classNames("modal", {
    "modal--portrait": isMobile && isPortrait,
    "modal--landscape": isMobile && isLandscape,
    fullScreen: fullScreen,
  });

  return (
    <PortalContainer id="modal-root">
      <>
        <div className="modal__overlay" />
        <div className={modalClassNames}>
          <div className={classNames('modal__content', {[className]: !!className})}>{children}</div>
        </div>
      </>
    </PortalContainer>
  );
};

Modal.Header = ({  children }) => {
  return (
    <div
      className='modal__header'>
      {children}
    </div>
  );
};
Modal.Body = ({ children, className }) => {
  return <div className={`modal__body ${className || ''}`}>{children}</div>;
};
Modal.Footer = ({ onClose, onSubmit, submitText, closeText, vereticalButtons, submitError, closeError }) => {
  return (
    <div className='modal__footer'>
      <div className={`modal__buttons ${vereticalButtons ? 'modal__buttons--vertical' : ''}`}>
        {onClose && (
          <Button variant={closeError ? 'error' : 'tertiary'} onClick={onClose}>
            {t(closeText) || t('close')}
          </Button>
        )}
        {onSubmit && (
          <Button variant={submitError ? 'error' : 'primary'} onClick={onSubmit}>
            {t(submitText) || t('submit')}
          </Button>
        )}
      </div>
    </div>
  );
};

export default Modal;
