import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { closeDialog } from "../../../store/actionCreators/dialogManager";
import VeloxPopupTemplate from "../../common/VeloxPopupTemplate"
import { createSpinAndGoRegisterErrorMessageReset, createSpinAndGoRegisterRequest } from "../../../store/actionCreators/spinAndGoRegister";
import { getWallets } from "../../../store/selectors";
import { useFormatCurrency } from "../../../hooks/useFormatCurrency";
import "./VeloxRegistration.scss"

const VeloxRegistration = (props) => {
  const { buyIn } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const wallets = useSelector(getWallets);
  const formatCurrency = useFormatCurrency();

  const onClose = useCallback(
    () => dispatch(closeDialog("veloxRegistration")),
    [dispatch]
  );

  const onConfirm = useCallback(
    () => {
      dispatch(createSpinAndGoRegisterRequest(props));
      dispatch(onClose())
    },
    [dispatch]
  );

  useEffect(() => {
    return () => {
      dispatch(createSpinAndGoRegisterErrorMessageReset());
    }
  }, []);

  return (
    <VeloxPopupTemplate
      className="velox-registration-dialog"
      title={t('registration')}
      onClose={onClose}
      onConfirm={onConfirm}
      confirmText={t('registerNow')}
    >
      <div className="velox-registration">
        {+wallets?.[0]?.balance >= 0 &&
          (
            <div
              className="velox-registration-dialog__wallet-item"
            >
              <div className="velox-registration-dialog__label-value-wrapper">
                <div className="velox-registration-dialog__label">
                  <label htmlFor="userBalance">
                    <span className="velox-registration-dialog__label-title">{`${t(
                      "mainBalance"
                    )}: `}</span>
                    <span className="velox-registration-dialog__label-balance">
                      {formatCurrency(wallets?.[0]?.balance, wallets?.[0]?.currency)}
                    </span>
                  </label>

                </div>
                <div className="velox-registration-dialog__value">
                  {formatCurrency(buyIn?.buyin, buyIn?.currency)}
                </div>
              </div>
            </div>
          )
        }
      </div>
    </VeloxPopupTemplate>
  )
}

export default VeloxRegistration;

