import { useDispatch } from 'react-redux';
import JackpotGameLeaderBoard from '../../JackpotGameLeaderBoard';
import { jacpotsRequest } from '../../../../../store/actionCreators/jackpot';
import { getSpinAndGoConfigsRequest } from '../../../../../store/actionCreators/spinAndGoConfigs';
import './MobileJackpotGameHeader.scss';

const MobileJackpotGameHeader = ({ onClose, tournament }) => {
  const dispatch = useDispatch();
  const onCloseGame = () => {
    onClose();
    dispatch(jacpotsRequest());
    dispatch(getSpinAndGoConfigsRequest());
  };

  return <div className='mobile-jackpot-game-header'>
    <div className="mobile-jackpot-game-header__jackpot-game-leaderboard-wrapper">
      <JackpotGameLeaderBoard tournament={tournament} />
    </div>
    <div onClick={onCloseGame} className="mobile-jackpot-game-header__icon-wrapper">
      <i className='icon-home' />
    </div>
  </div>;
};

export default MobileJackpotGameHeader;
