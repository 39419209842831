import types from '../actionTypes';

export const getPlayerStateInTournamentsRequest = (tournamentIds, isUpdate, checkResumePopup, openRegisterPopup) => ({
  type: types.GET_PLAYER_STATE_IN_TOURNAMENTS_REQUEST,
  tournamentIds,
  isUpdate,
  checkResumePopup,
  openRegisterPopup,
});

  
export const getPlayerStateInTournamentsFailure = (e) => ({
  type: types.GET_PLAYER_STATE_IN_TOURNAMENTS_FAILURE,
	exception: e,
});

  
export const getPlayerStateInTournamentsSucsess = (payload) => ({
  type: types.GET_PLAYER_STATE_IN_TOURNAMENTS_SUCSESS,
  payload,
});

export const getPlayerStateInTournamentsUpdate = (payload) => ({
  type: types.GET_PLAYER_STATE_IN_TOURNAMENTS_UPDATE,
  payload,
});
  
export const getPlayerStateInTournamentsReset = () => ({
  type: types.GET_PLAYER_STATE_IN_TOURNAMENTS_RESET,
});
