import { isMobile } from 'react-device-detect';
import { useSelector } from 'react-redux';
import { classNames } from '../../../../helpers/pureFunctions';
import { getCasinoTournament, getPlayerResult } from '../../../../store/selectors';
import TournamentGameDetailsInfoWidget from '../TournamentGameWidget/TournamentGameDetailsInfoWidget';
import './PlayerInfo.scss';

const PlayerInfo = () => {

  const tournament = useSelector(getCasinoTournament);
  const playerResult = useSelector(getPlayerResult);

  const { totalSpins, actualTotalSpins } = tournament;

  const playerInformationWrapperClasses = classNames('player-information-wrapper', {
    mobile: isMobile
  })

  return (
    <div className={playerInformationWrapperClasses}>
      <div className='player-information-wrapper__row'>
        <div className='player-information-wrapper__player-position-col'>
          <TournamentGameDetailsInfoWidget title="position" info={playerResult ? playerResult?.playerPosition : tournament?.playerPosition} variant={'widget'} />
        </div>
        <div className='player-information-wrapper__player-spins-col'>
          <TournamentGameDetailsInfoWidget title="spins" info={playerResult ? playerResult?.spinsLeft : tournament?.spins} hasProgressBar progressTarget={actualTotalSpins || totalSpins} progressStatus={playerResult ? playerResult?.spinsLeft : tournament?.spins} variant={'widget'} />
        </div>
      </div>
      <div className='player-information-wrapper__row'>
        <div className='player-information-wrapper__player-score-col'>
          <TournamentGameDetailsInfoWidget title="score" info={playerResult ? playerResult?.playerPoints : tournament?.playerPoints} variant={'widget'} />
        </div>
      </div>
    </div>
  );
};

export default PlayerInfo;
