import types from '../actionTypes';

export const updateTournamentPlayers = (id, players, player, userIds) => ({
  type: types.TOURNAMENT_PLAYER_UPDATE,
  id,
  players,
  player,
  userIds,
});

export const resetTournamentPlayers = () => ({
  type: types.TOURNAMENT_PLAYER_RESET,
});
