import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { closeDialog } from "../../../store/actionCreators/dialogManager";
import VeloxPopupTemplate from "../../common/VeloxPopupTemplate";
import './VeloxSuccessfulRegistartion.scss';

const VeloxSuccessfulRegistartion = ({ name }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onClose = useCallback(
    () => dispatch(closeDialog("veloxSuccessfulRegistration")),
    [dispatch]
  );

  return (
    <VeloxPopupTemplate
      className="velox-successful-registration-dialog"
      title={name}
      onClose={onClose}
      onConfirm={onClose}
      confirmText={t('ok')}
    >
      <div className="velox-successful-registration">
        <div className="velox-successful-registration-dialog__top">
          <div className="velox-successful-registration-dialog__icon">
            <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="13" cy="13" r="12" fill="#285816" stroke="#33741B" stroke-width="2" />
              <path d="M7 13.7143L10.9394 18L20 9" stroke="white" stroke-width="3" stroke-linecap="round" />
            </svg>
          </div>
          <span className="velox-successful-registration-dialog__title">
            {t('veloxSuccessfulRegistrationDialogTitle')}
          </span>
        </div>
        <div className="velox-successful-registration-dialog__bottom">
          <span className="velox-successful-registration-dialog__description">
            {t('veloxSuccessfulRegistrationDialogDescription')}
          </span>
        </div>
      </div>
    </VeloxPopupTemplate>
  )
}

export default VeloxSuccessfulRegistartion