import { take, fork, call, put, select } from 'redux-saga/effects';
import { apiGetLobbyURL } from './callApi';
import apiRoutes from '../../constants/apiRoutes';
import types from '../actionTypes';
import { getSkinId, getToken, getUserId } from '../selectors';
import { casinoTournamentPlayersFailure, casinoTournamentPlayersSuccess } from '../actionCreators/casinoTournamentPlayers';
import config from '../../configs/config';
import { maskString } from '../../helpers/pureFunctions';
import { openDialog } from '../actionCreators/dialogManager';

function* processCasinoTournamentPlayersRequest({ id, dialogType, payload, offset, limit }) {
  try {
    const token = yield select(getToken);
    const skinId = yield select(getSkinId);
    const userId = yield select(getUserId);
    const params = { skinId, tournamentId: +id, offset, limit };
    if (token) params.token = token;

    const response = yield call(apiGetLobbyURL, apiRoutes.GET_CASINO_TOURNAMENT_PLAYERS, params);
    if (response.data.success) {
      yield put(casinoTournamentPlayersSuccess({
        casinoTournamentPlayers: response.data.result.map(player => ({
          ...player,
          self: Number(player.accountId) === Number(userId),
          nickname: config?.maskNickname ? maskString(player?.nickname) : player?.nickname,
          unMaskedName: player?.nickname,
          username: config?.maskNickname ? maskString(player?.username) : player?.username
        })),
        id,
        userIds: response.data.result.map(player => player.accountId),
        offset,
      }));
      if (dialogType) {
        const dialogParams = {
          dialogType,
        };
        if (payload) {
          dialogParams.payload = payload;
        }
        yield put(openDialog(dialogParams));
      }
    } else {
      yield put(casinoTournamentPlayersFailure());
    }
  } catch (e) {
    yield put(casinoTournamentPlayersFailure(e));
  }
}

function* watchCasinoTournamentPlayersRequest() {
  let forked = null;
  while (true) {
    const action = yield take([
      types.GET_CASINO_TOURNAMENT_PLAYERS_REQUEST,
      types.CASINO_TOURNAMENT_UNREGISTER_SUCCESS,
    ]);
    forked = yield fork(processCasinoTournamentPlayersRequest, action);
  }
};

export default function* casinoTournamentPlayersRequests() {
  yield call(watchCasinoTournamentPlayersRequest);
};
