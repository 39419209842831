import { take, fork, call, cancel, put } from 'redux-saga/effects';
import apiRoutes from "../../constants/apiRoutes";
import types from '../actionTypes';
import { apiGetFileStorage } from './callApi';
import { getCMSResponseFailure, getCMSResponseSucsess } from '../actionCreators/CMSResponse';

const LANGUAGES = {
  en: 'en',
  es: 'es',
  it: 'it',
  'pt-BR': 'pt',
  'fr-FR': 'fr',
  'ar-001': 'ar',
};

function* processGetCMSResponseRequest() {
  try {
    const response = yield call(apiGetFileStorage, apiRoutes.GET_CMS_RESPONSE, {});
    if (response?.data) {
      const result = {
        banners: {
          desktopBannerURL: response.data?.data?.attributes?.desktopMainLobbyBanner?.data?.map(u => u?.attributes?.url),
          mobileBannerURL: response.data?.data?.attributes?.mobileMainLobbyBanner?.data?.map(u => u?.attributes?.url)
        },
        [LANGUAGES?.[response.data?.data?.attributes?.locale]] : {
          termsAndConditions: response.data?.data?.attributes?.termsAndConditions,
          howToPlay: {
            desktopHowToPlay: response.data?.data?.attributes?.desktopHowToPlay,
            mobileHowToPlay:  response.data?.data?.attributes?.mobileHowToPlay,
            desktopHowToPlayJackpot:  response.data?.data?.attributes?.desktopHowToPlaySpinAndGo,
            mobileHowToPlayJackpot:  response.data?.data?.attributes?.mobileHowToPlaySpinAndGo,
          }
        },
        ...response.data?.data?.attributes?.localizations?.data?.reduce((acc, item) => {
          acc[LANGUAGES[item?.attributes.locale]] = {
            termsAndConditions: item?.attributes?.termsAndConditions,
            howToPlay: {
              desktopHowToPlay: item?.attributes?.desktopHowToPlay,
              mobileHowToPlay:  item?.attributes?.mobileHowToPlay,
              desktopHowToPlayJackpot:  item?.attributes?.desktopHowToPlaySpinAndGo,
              mobileHowToPlayJackpot:  item?.attributes?.mobileHowToPlaySpinAndGo,
            }
          }
          return acc;
        }, {}),
      }
      yield put(getCMSResponseSucsess(result));
    }
  } catch (e) {
    yield put(getCMSResponseFailure(e));
  }
}

export function* watchGetCMSResponseRequest() {
  let forked = null;
  while (true) {
    yield take(types.GET_CMS_RESPONSE_REQUEST);
    if (forked) yield cancel(forked);
    forked = yield fork(processGetCMSResponseRequest);
  }
}
