import { useEffect } from 'react';

const useSocketCallBack = (name, socket, fn) => {
  useEffect(() => {
    if(socket) socket.on(name, fn);
    return  () => {
      if(socket) socket.off(name, fn);
    }
  },[socket, fn, name]);
}

export default useSocketCallBack;
