import { useDispatch, useSelector } from "react-redux";
import Views from "./Views";
import { isMobile } from "react-device-detect";
import { classNames } from "../../helpers/pureFunctions";
import { getOpenedToasters } from "../../store/selectors";
import { closeToaster } from "../../store/actionCreators/toaster";
import PortalContainer from "../common/PortalContainer";
import './Toaster.scss';

const renderDialog = (toaster, dispatch, toasterClassNames) => {
  if (!toaster.view) return null;
  const onClose = () => dispatch(closeToaster({id: toaster.id}));
  const View = Views[toaster.view];
  return <PortalContainer id="toaster-root">
    <>
      {!!View && (
        <div
          key={toaster.id}
          className={`${toasterClassNames} ${toaster.view}`}
        >
          <View key={toaster.id} onClose={onClose} {...toaster.params} />
        </div>
      )}
    </>,
  </PortalContainer>
};

const MainToaster = () => {
  const dispatch = useDispatch();
  const toasters = useSelector(getOpenedToasters);
  const toasterClassNames = classNames("toaster", {
    mobile: isMobile,
  });
  return (
    <>
      {toasters?.length > 0 &&
        toasters.map((toaster) =>
          renderDialog(toaster, dispatch, toasterClassNames)
        )}
    </>
  );
};

export default MainToaster;
