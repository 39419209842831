import { take, fork, put, call, select, cancel, delay } from 'redux-saga/effects';
import { apiGetLobbyURL } from './callApi';
import types from '../actionTypes';
import { getSkinId, getUserId, getToken, getTournamentGameUrl, getJackpots, getLanguage, getCasinoTournaments } from '../selectors';
import { failTournamentGameUrl, successTournamentGameUrl } from '../actionCreators/tournamentGameUrl';
import apiRoutes from '../../constants/apiRoutes';
import settings from '../../configs/settings';
import { openDialog } from '../actionCreators/dialogManager';
import { casinoTournamentsRequest, selectCasinoTournament } from '../actionCreators/casinoTournament';

function* getTournamentGameUrlSagas({ game, rebuy, tournamentId, isRegular }) {
  try {
    const tournamentGameUrl =  yield select(getTournamentGameUrl);
    const jackpots =  yield select(getJackpots);
    const tournaments =  yield select(getCasinoTournaments);
    const token = yield select(getToken);
    const skinId = yield select(getSkinId);
    const userId = yield select(getUserId);
    const lang = yield select(getLanguage);
    const tours = isRegular ? tournaments : jackpots;
    const tournament = game ? game : tournamentId ? tours?.find(item => item?.tournamentId === tournamentId) : undefined;
    if (tournament) {
      const tournamentGame = tournament.tournamentGames[0];
      const params = {
        token,
        skinId,
        lang: lang || 'en',
        device: 'desktop',
        userId: userId || null,
        tournamentId: tournament.tournamentId,
        id: tournamentGame["id"],
        providerName: tournamentGame["providerName"],
        integrator: tournamentGame["integrator"],
      };
      if (tournamentGame.integrator === 'silver') {
        params.lobbyUrl = settings.SILVER_HOME_URL;
      } else if (
        ['Endorphina'].includes(tournamentGame.providerName) ||
        (tournamentGame.integrator === 'xpress' && tournamentGame.provider === 'EVOLUTION')
      ) {
        params.lobbyUrl = window.location.origin.toString();
      }
  
      const response = yield call(apiGetLobbyURL, apiRoutes.GET_CASINO_URL_FOR_TOURNAMENT, params);
  
      const needToReload = tournamentGameUrl === response.data.result.gameUrl;
  
      if(needToReload && rebuy) {
        yield put(successTournamentGameUrl(null, response.data.result?.width));
        yield delay(0);
      }
      yield put(successTournamentGameUrl({ url: response.data.result.gameUrl, tournamentId: tournament?.tournamentId}, response.data.result?.width, needToReload));
      if (tournamentId && !isRegular) {
        yield put(openDialog({ dialogType: 'prizePoolGenerator', tournament }));
        yield put(selectCasinoTournament(tournament));
      }
    }

  } catch (e) {
    yield put(failTournamentGameUrl(e));
  }
}

export function* watchRequestTournamentGameUrl() {
  let forked = null;
  while (true) {
    const action = yield take(types.TOURNAMENT_GAME_URL_REQUEST);
    if (forked) yield cancel(forked);
    yield fork(getTournamentGameUrlSagas, action);
  }
};

function* getTournamentGameUrlResetSagas() {
  try {
    yield put(casinoTournamentsRequest());
  } catch (e) {
    console.log(e);
  }
}

export function* watchRequestTournamentGameUrlReset() {
  let forked = null;
  while (true) {
    const action = yield take(types.TOURNAMENT_GAME_URL_RESET);
    if (forked) yield cancel(forked);
    yield fork(getTournamentGameUrlResetSagas, action);
  }
};
