import React from 'react';
import { useTranslation } from 'react-i18next';
import { classNames } from '../../../helpers/pureFunctions';
import './TournamentItemDetailInfo.scss';

const TournamentItemDetailInfo = ({title, info, first, last}) => {
  const { t } = useTranslation();

  const tournamentItemDetailInfoWrapperClasses = classNames('tournament-item-detail-info-wrapper', {
    'first': first && !last,
    'last': !first && last,
    'default': !first && !last,
  })
  return (
    <div className={tournamentItemDetailInfoWrapperClasses}>
      <span className="tournament-item-detail-info-wrapper__title">{t(title)}</span>
      <span className="tournament-item-detail-info-wrapper__info">{info}</span>
    </div>
  )
}

export default TournamentItemDetailInfo