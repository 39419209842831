import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormatCurrency } from '../../../../../hooks/useFormatCurrency';
import ProgressBar from '../../../../ProgressBar';
import Skeleton from '../../../../Skeleton';
import { useSelector } from 'react-redux';
import { getDialogs, isJackpotPrizeGenerated } from '../../../../../store/selectors';
import './MobileJackpotGameLeaderBoard.scss';

const circlePositions = ['-46px', '0px', '46px'];
const opponentPositions = ['0px', '-157px'];

const MobileJackpotGameLeaderBoard = ({ tournament, players, self, leader }) => {
  const { t } = useTranslation();
  const formatCurrency = useFormatCurrency();
  const [circlePositionIndex, setCirclePositionIndex] = useState(0);
  const [currentCirclePosition, setCurrentCirclePosition] = useState(circlePositions[0]);

  const [opponentPositionIndex, setOpponentPositionIndex] = useState(0);
  const [currentOpponentPosition, setCurrentOpponentPosition] = useState(opponentPositions[0]);

  const dialogs = useSelector(getDialogs);
  const pending = useSelector(isJackpotPrizeGenerated);

  const opponents = players?.filter(player => !player?.self);

  useEffect(() => {
    const intervalCircle = setInterval(() => {
      setCirclePositionIndex((prevIndex) => (prevIndex + 1) % circlePositions.length);
    }, 4000);

    return () => {
      clearInterval(intervalCircle);
    };
  }, []);
  useEffect(() => {
    let intervalOpponent;
    if (opponents?.length > 1) {
      intervalOpponent = setInterval(() => {
        setOpponentPositionIndex((prevIndex) => (prevIndex + 1) % opponentPositions.length);
      }, 3000);
    }
    return () => {
      clearInterval(intervalOpponent);
    };
  }, [opponents?.length]);

  useEffect(() => {
    setCurrentCirclePosition(circlePositions[circlePositionIndex]);
  }, [circlePositionIndex]);

  useEffect(() => {
    setCurrentOpponentPosition(opponentPositions[opponentPositionIndex]);
  }, [opponentPositionIndex]);

  return (
    <div className="mobile-jackpot-game-leaderBoard">
      {players?.length && self && (
        <div className="mobile-jackpot-game-leaderBoard__player-opponent-wrapper">
          <div  className="mobile-jackpot-game-leaderBoard__vs">
            <div style={{ 
            transform: `translateX(${currentCirclePosition})`,
           }} className='mobile-jackpot-game-leaderBoard__vs-inner'>
              <div className="mobile-jackpot-game-leaderBoard__vs-item-wrapper">
                <ProgressBar circle endsAfterMS={tournament?.endsAfterMS} duration={tournament?.duration} />
              </div>
              <div className="mobile-jackpot-game-leaderBoard__vs-item-wrapper">
                {!dialogs?.prizePoolGenerator || pending
                  ? <span>{formatCurrency(tournament?.prizePool?.value, tournament?.prizePool?.currency)}</span>
                  : <Skeleton height={46} width={46} rounded />
                }                
              </div>
              <div className="mobile-jackpot-game-leaderBoard__vs-item-wrapper">
                <span>VS</span>
              </div>
            </div>
          </div>
          <div className="mobile-jackpot-game-leaderBoard__player-opponent mobile-jackpot-game-leaderBoard__player-opponent--self">
            <div className="mobile-jackpot-game-leaderBoard__player-opponent-username-spins-wrapper">
              <span className='mobile-jackpot-game-leaderBoard__player-opponent-username'>{t('you')}</span>
              <span className='mobile-jackpot-game-leaderBoard__player-opponent-spins'>{`${self?.spins}/${tournament?.totalSpins ?? tournament?.actualTotalSpins}`}</span>
            </div>
            <div className="mobile-jackpot-game-leaderBoard__player-opponent-points-leader-wrapper">
              <div className="mobile-jackpot-game-leaderBoard__player-opponent-points-wrapper">
                <span>{self?.score || self?.points || 0}</span>
              </div>
              {leader && leader?.accountId === self?.accountId && <div className='mobile-jackpot-game-leaderBoard__player-opponent__leader' />}
            </div>
          </div>
          <div className="mobile-jackpot-game-leaderBoard__player-opponents">
            <div className="mobile-jackpot-game-leaderBoard__player-opponents-inner" style={{ 
            transform: `translateX(${currentOpponentPosition})`,
           }}>
              {players?.filter(player => !player?.self)?.map(player => (
                <div key={player.id} className="mobile-jackpot-game-leaderBoard__player-opponent">
                  <div className="mobile-jackpot-game-leaderBoard__player-opponent-username-spins-wrapper">
                    <span className='mobile-jackpot-game-leaderBoard__player-opponent-spins'>{`${player?.spins}/${tournament?.totalSpins ?? tournament?.actualTotalSpins}`}</span>
                    <span className='mobile-jackpot-game-leaderBoard__player-opponent-username'>{player?.unMaskedName}</span>
                  </div>
                  <div className="mobile-jackpot-game-leaderBoard__player-opponent-points-leader-wrapper">
                    {leader && leader?.accountId === player?.accountId && <div className='mobile-jackpot-game-leaderBoard__player-opponent__leader' />}
                    <div className="mobile-jackpot-game-leaderBoard__player-opponent-points-wrapper">
                      <span>{player?.score || player?.points || 0}</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MobileJackpotGameLeaderBoard;
