import { useState, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import PortalContainer from "../../common/PortalContainer";
import {
  closeDialog,
  openDialog,
} from "../../../store/actionCreators/dialogManager";
import {
  getCurrentAvatar,
  getUserId,
  getUsername,
} from "../../../store/selectors";
import AvatarSection from "../../AvatarSection";
import Button from "../../common/Button";
import { setAvatarRequest } from "../../../store/actionCreators/avatars";
import Avatar from "../../Avatar";
import { useResetState } from "../../../hooks/useResetState";
import config from "../../../configs/config";
import "./UserSettingsDialog.scss";
import ThemeSwitcher from "../../ThemeSwitcher";

const UserSettingsDialog = ({ isMobile }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  useResetState(closeUserSettings);
  const username = useSelector(getUsername);
  const [selectedAvatar, setSelectedAvatar] = useState(null);
  const userId = useSelector(getUserId);
  const avatar = useSelector(getCurrentAvatar)(+userId);

  function closeUserSettings() {
    dispatch(closeDialog("userSettings"));
  }

  const openHowToPlayDialog = () => {
    closeUserSettings();
    config?.externalLinkHowToPlay
      ? window.open(config?.externalLinkHowToPlay, "_blank")
      : dispatch(openDialog({ dialogType: "howToPlay" }));
  };

  const openHistory = () => {
    closeUserSettings();
    dispatch(openDialog({ dialogType: "tournamentsHistory" }));
  };

  const openTermsAndConditions = () => {
    closeUserSettings();
    config?.externalLinkTermsAndConditions
      ? window.open(config?.externalLinkTermsAndConditions, "_blank")
      : dispatch(openDialog({ dialogType: "termsAndConditions" }));
  };

  const handleSave = useCallback(
    (_, payload) => {
      dispatch(setAvatarRequest(payload || selectedAvatar));
    },
    [selectedAvatar]
  );

  useEffect(() => {
    if (avatar?.imagePath && !selectedAvatar) {
      setSelectedAvatar(avatar.imagePath);
    }
  }, [avatar?.imagePath, selectedAvatar]);

  return (
    <PortalContainer id="user-settings-dialog">
      <div className="user-settings-dialog">
        <div
          onClick={closeUserSettings}
          className="user-settings-dialog__close"
        >
          <i className="icon-cross" />
        </div>
        <div className="user-settings-dialog__avatar-username-wrapper">
          <div className="user-settings-dialog__avatar-wrapper">
            <Avatar size={isMobile ? 50 : 70} />
          </div>
          <div className="user-settings-dialog__username-wrapper">
            <span className="user-settings-dialog__welcome-back">
              {t("welcomeBack")}
            </span>
            <span className="user-settings-dialog__username">{username}</span>
            <span
              onClick={openHowToPlayDialog}
              className="user-settings-dialog__how-play-text"
            >
              {t("howToPlayDialog")}
            </span>
          </div>
        </div>
        <div className="user-settings-dialog__scroll-wrapper">
          <div className="user-settings-dialog__avatar-section-wrapper">
            <AvatarSection
              cb={isMobile && handleSave}
              selectedAvatar={selectedAvatar}
              setSelectedAvatar={setSelectedAvatar}
            />
          </div>
          {!isMobile && (
            <>
              <div className="user-settings-dialog__history-wrapper">
                <i className="user-settings-dialog__history-icon icon-history" />
                <span className="user-settings-dialog__history-text">
                  {t("history")}
                </span>
                <span className="user-settings-dialog__history-description">
                  {t("historyDescription")}
                </span>
                <span
                  onClick={openHistory}
                  className="user-settings-dialog__history-link"
                >
                  {t("viewHistory")}
                </span>
              </div>
              <div className="user-settings-dialog__terms-and-conditions-wrapper">
                <i className="user-settings-dialog__terms-and-conditions-icon icon-terms" />
                <span className="user-settings-dialog__terms-and-conditions-text">
                  {t("terms&Conditions")}
                </span>
                <span className="user-settings-dialog__terms-and-conditions-description">
                  {t("terms&ConditionsDescription")}
                </span>
                <span
                  onClick={openTermsAndConditions}
                  className="user-settings-dialog__terms-and-conditions-link"
                >
                  {t("viewTerms&Conditions")}
                </span>
              </div>
            </>
          )}
        </div>
        {!isMobile && (
          <div className="user-settings-dialog__button-theme-switcher-wrapper">
            {config?.hasSkinSwitcher && (
              <div className="user-settings-dialog__theme-switcher-wrapper">
                <ThemeSwitcher openToTopOfList />
              </div>
            )}
            <Button onClick={handleSave} variant="primary">
              {t("save")}
            </Button>
          </div>
        )}
      </div>
    </PortalContainer>
  );
};

export default UserSettingsDialog;
