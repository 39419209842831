import { useRef, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import io from 'socket.io-client';
import { socketUsersToInner } from '../helpers/transform';
import { getToken, getUserId, getUsername } from '../store/selectors';
import { updateTournamentPlayers } from '../store/actionCreators/tournamentCasinoPlayers';
import useSocketCallBack from './useSocketCallBack';
import settings from '../configs/settings'; 
import { addToaster } from '../store/actionCreators/ui';
import config from '../configs/config';
import { setJackpotJoinedPlayer } from '../store/actionCreators/jackpotJoinedPlayers';
import { jacpotsRequest } from '../store/actionCreators/jackpot';
import { casinoTournamentPlayersRequest } from '../store/actionCreators/casinoTournamentPlayers';
import { getAccountBalanceRequest } from '../store/actionCreators/getAccountBalance';
import { updatePlayerAction } from '../store/actionCreators/updatePlayer';
import { updateTournamentAction } from '../store/actionCreators/updateTournament';
import { getRandomElement } from '../helpers/pureFunctions';

const socketUrl = getRandomElement(settings.SOCKET_URL);

const useTournamentSocket = (tourId, onBadBet, onServerMessage) => {
  const dispatch = useDispatch();
  const socket = useRef(null);
  const token = useSelector(getToken);
  const id = useSelector(getUserId);
  const username = useSelector(getUsername);

  const onGetPlayerList = useCallback(({ tournamentId, players }) => {
    console.log('players spcket', tournamentId, players);
    if (!players) return;
    dispatch(updateTournamentPlayers(tournamentId, players.map(socketUsersToInner(id)), undefined, players.map(player => player?.accountId)));
  }, [dispatch, id]);
  
  const onNewPlayerJoined = useCallback((newPlayer) => {
    if (!newPlayer?.username) return;
    dispatch(setJackpotJoinedPlayer(newPlayer.username))
  }, []);

  const onSpinAndGoStarted = (message) => {
    if (!message?.tournamentId) return;
    dispatch(jacpotsRequest(message?.tournamentId, message?.isLastPlayer));
  };

  const socketLogger = (name,body) => {
    console.log('%cIN', 'color: #0c3;', name, body);
  };

  const onUpdatePlayer = useCallback(([player]) => {
    dispatch(updatePlayerAction(player));
  }, []);

  const onUpdateTournament = useCallback((payload) => {
    dispatch(updateTournamentAction(payload));
  }, []);

  const onDisconnect = (e) => {
    if(config.showSocketErrorToaster) {
      dispatch(addToaster({msgText: `GoS Socket Disconnected \n  ${socketUrl}`}));
    }
	  console.error(`GoS Socket onDisconnect \n  ${socketUrl}`);
  }

  const onReconnectAttempt = () => {
    if(config.showNetworkErrorToaster) {
      dispatch(addToaster({msgText: `Reconnecting to \n  ${socketUrl}`}));
    }
	  console.error(`GoS Socket onReconnectAttempt \n  ${socketUrl}`);
  } 

  const onEngineUpgrade = (p) => {
	  console.log(`GoS Socket onEngineUpgrade \n  ${(p)}`);
  } 

  const onTournamentFinished = (tourFinishInfo) => {
    dispatch(casinoTournamentPlayersRequest({
      id: tourFinishInfo?.tournamentId,
      offset: 0,
      limit: 20,
      dialogType: `tournamentEnd_${tourFinishInfo?.tournamentId}`,
      payload: tourFinishInfo
    }));
    dispatch(getAccountBalanceRequest());
  };

  useEffect(() => {
    if (!socketUrl || !id || !username || !token) return;
    socket.current = io(socketUrl, {
      query: { id, username, token },
    });
	  socket.current.io.engine.on("upgrade", onEngineUpgrade)

    socket.current.on('updateTournament', onUpdateTournament);
    socket.current.on('tournamentFinished', onTournamentFinished);
    socket.current.on('newPlayerJoined', onNewPlayerJoined);
    socket.current.on('spinAndGoStarted', onSpinAndGoStarted);
    socket.current.on('getPlayersList', onGetPlayerList);
    socket.current.on('playerResult', onUpdatePlayer);
    socket.current.on('serverMessage', m => onServerMessage(m));
    socket.current.onAny(socketLogger);
    return () => {
      if (socket.current) {
        console.log('Gos Socket disconnected in useEffect');
        socket.current.disconnect(true);
      } 
      socket.current = null;
    }
  }, [socketUrl, id, username, token, onGetPlayerList, onUpdatePlayer, onUpdateTournament]);
  const badBetFn = useCallback(d => onBadBet(d.tournamentId), [onBadBet]);
  useSocketCallBack('badBetWin', socket.current, badBetFn);
  
  const onReconnect = useCallback(() => {
	  console.error(`GoS Socket onReconnect \n  ${socketUrl}`);
    if (tourId && socket.current) {
      socket.current.emit('joinToGame', { tourId });
    }
  }, [tourId]);

  useSocketCallBack('reconnect', socket.current, onReconnect);
  useSocketCallBack('connect', socket.current, onReconnect);
  useSocketCallBack('disconnect', socket.current, onDisconnect);
  useSocketCallBack('reconnect_attempt', socket.current, onReconnectAttempt);

  useEffect(() => {
    if (tourId && socket.current) {
      socket.current.emit('joinToGame', { tourId });
    }
  }, [tourId]);

  return useCallback((type, params) => {
    if (socket.current) {
      console.log('%cOUT', 'color: #36f;', type, params);
      socket.current.emit(type, params);
    } 
  }, []);
}

export default useTournamentSocket;
