import types from "../actionTypes";

export const requestMessages = () => ({
  type: types.GET_MESSAGES_REQUEST
});

export const successMessages = (arrMessages) => ({
  type: types.GET_MESSAGES_SUCCESS,
  arrMessages
});

export const requestReadMessage = (messageId) => ({
  type: types.READ_MESSAGE_REQUEST,
  messageId,
});

export const successReadMessage = (messageId) => ({
  type: types.READ_MESSAGE_SUCCESS,
  messageId,
});

export const requestPublicMessages = () => ({
  type: types.PUBLIC_MESSAGES_REQUEST
});
