import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { getRemainingTimeUntilMsTimestamp } from '../../../../../helpers/countdownTimer';
import { classNames } from '../../../../../helpers/pureFunctions';
import Loader from '../../../../common/Loader';
import './TournamentGameTimerWidget.scss'

const TournamentGameTimerWidget = ({ countdownTimestamp }) => {

    const { t } = useTranslation();
    const [remainingTime, setRemainingTime] = useState(null);

    useEffect(() => {
        const intervalId = setInterval(() => {
            updateRemainingTime(countdownTimestamp);
        }, 1000);
        return () => clearInterval(intervalId);
    }, [countdownTimestamp]);

    const updateRemainingTime = (countdown) => {
        setRemainingTime(getRemainingTimeUntilMsTimestamp(countdown));
    };

    if (!remainingTime) return <div><Loader size='small' /></div>;
    const { days, hours, minutes, seconds } = remainingTime;
    const REMAING_TIMES = [
        { label: "DAY", value: days },
        { label: "HH", value: hours },
        { label: "MM", value: minutes },
        { label: "SS", value: seconds },
    ];

    const tournamentGameTimerWidgetClasses = classNames('tournament-game-timer-widget-wrapper', {
        mobile: isMobile
    })

    return (
        <div className={tournamentGameTimerWidgetClasses}>
            <div className='tournament-game-timer-widget-wrapper__header'>
                <span className='tournament-game-timer-widget-wrapper__header--title'>{t('endsIn')}</span>
            </div>
            <div className='tournament-game-timer-widget-wrapper__body'>
                {REMAING_TIMES.map(({ label, value }, index) => (
                    <div key={label} className="tournament-game-timer-widget-wrapper__body-item">
                        <div className="tournament-game-timer-widget-wrapper__body-item--time">
                            <span className="countdown-widget-timer__time">{value}</span>
                            <span className="countdown-widget-timer__label">{t(label)}</span>
                        </div>
                        {!(index === REMAING_TIMES.length - 1) && <span className='countdown-widget-timer__dots'>:</span>}
                    </div>
                ))}
            </div>
        </div>
    )
}

export default TournamentGameTimerWidget 