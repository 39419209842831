import React from 'react';
import { isMobile } from 'react-device-detect';
import TournamentsHistoryTableItemResultsDesktop from './Desktop';
import TournamentsHistoryTableItemResultsMobile from './Mobile';
import './TournamentsHistoryTableItemResults.scss';

const TournamentsHistoryTableItemResults = ({ data }) => {

  const Component = isMobile ? TournamentsHistoryTableItemResultsMobile : TournamentsHistoryTableItemResultsDesktop;
  return <Component data={data} />;
}

export default TournamentsHistoryTableItemResults