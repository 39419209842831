import types from "./actionTypes";
import { fail, getInitial, reset, wait } from "../helpers/redux";
import config from "../configs/config";
import { filterObjectById } from "../helpers/pureFunctions";

const dialogManagerInitial = {
  dialogs: {},
};

export const dialogManager = (state = dialogManagerInitial, action) => {
  switch (action.type) {
    case types.OPEN_DIALOG:
      return {
        ...state,
        dialogs: {
          ...state.dialogs,
          [action.payload.dialogType]: action.payload,
        },
      };
    case types.CLOSE_DIALOG:
      return {
        ...state,
        dialogs: {
          ...state.dialogs,
          [action.payload]: undefined,
        },
      };
    default:
      return state;
  }
};

export const queryParams = (state = {}, action) => {
  switch (action.type) {
    case types.SET_QUERY_PARAMS:
      return {
        ...state,
        ...action.payload,
      };
    case types.LOGOUT_SET_QUERY_PARAMS:
      return {
        ...state,
        token: null,
        username: null,
        id: null,
      };
    case types.LOGIN_SET_QUERY_PARAMS:
      return {
        ...state,
        ...action.payload,
      };
    case types.RESET_QUERY_PARAMS:
      return {};
    default:
      return state;
  }
};

export const tournamentPlayers = (state = {}, action) => {
  switch (action.type) {
    case types.TOURNAMENT_PLAYER_UPDATE:
      const { id, players, player } = action;
      const playerIdx = state[id]?.playersList?.findIndex(p => p?.accountId === player?.accountId);
      return {
        ...state,
        [id]: {
          prevList: state[id]?.playersList || [],
          playersList: player && playerIdx > -1
            ? [
              ...state[id]?.playersList.slice(0, playerIdx),
              { ...state[id]?.playersList[playerIdx], place: player.playerPosition, spins: player.spinsLeft, score: player.playerPoints },
              ...state[id]?.playersList.slice(playerIdx + 1),
            ].sort((a, b) => a.playerPosition - b.playerPosition)
            : players,
        },
      };
    case types.TOURNAMENT_PLAYER_RESET:
      return {};
    default:
      return state;
  }
};

const casinoTournamentInitial = getInitial({
  casinoTournaments: null,
});

export const casinoTournament = (state = casinoTournamentInitial, action) => {
  switch (action.type) {
    case types.GET_CASINO_TOURNAMENTS_REQUEST:
    case types.GET_CASINO_TOURNAMENTS_REQUEST_WITHOUT_DELAY:
      return { ...wait(state) };
    case types.GET_CASINO_TOURNAMENTS_FAILURE:
      return fail(state);
    case types.GET_CASINO_TOURNAMENTS_SUCCESS:
      return {
        ...reset(state),
        casinoTournaments: action.payload,
      };
    case types.UPDATE_CASINO_TOURNAMENTS:
      const idx = state.casinoTournaments.findIndex(
        (item) => item.tournamentId === action.payload.tournamentId
      );
      if (idx !== -1) {
        return {
          ...state,
          casinoTournaments: [
            ...state.casinoTournaments.slice(0, idx),
            action.payload,
            ...state.casinoTournaments.slice(idx + 1),
          ],
        };
      } else {
        return state;
      }
    case types.GET_CASINO_TOURNAMENTS_RESET:
      return casinoTournamentInitial;
    case types.GET_CASINO_TOURNAMENT_PLAYERS_SUCCESS:
      const { id, casinoTournamentPlayers } = action;
      const playerPositionCurrent = casinoTournamentPlayers.findIndex(player => player.self) + 1;
      if(playerPositionCurrent) {
        return {...state, casinoTournaments: [...state.casinoTournaments.map(item => {
          if(item.tournamentId === id) {
            return {...item, playerPosition: playerPositionCurrent}
          } else {
            return item
          }
        })]}
      } else {
        return state
      }
    default:
      return state;
  }
};

const casinoTournamentRegisterInitial = getInitial({
  failMessage: null,
  failData: null,
  casinoTournament: null,
});

export const casinoTournamentRegister = (
  state = casinoTournamentRegisterInitial,
  action
) => {
  switch (action.type) {
    case types.CASINO_TOURNAMENT_REGISTER_REQUEST:
      return {
        ...wait(state),
        failMessage: null,
        casinoTournament: null,
        failData: null,
      };
    case types.CASINO_TOURNAMENT_REGISTER_FAILURE:
      return {
        ...fail(state),
        failMessage: action.failMessage,
        failData: action.failData,
      };
    case types.CASINO_TOURNAMENT_REGISTER_SUCCESS:
      return { ...reset(state), casinoTournament: action.payload };
    case types.CASINO_TOURNAMENT_REGISTER_ERROR_MESSAGE_RESET:
      return { ...reset(state), failMessage: null };
    case types.CASINO_TOURNAMENT_REGISTER_RESET:
      return casinoTournamentRegisterInitial;
    default:
      return state;
  }
};

const casinoTournamentRebuyInitial = getInitial({
  failMessage: null,
  failData: null,
  casinoTournament: null,
});

export const casinoTournamentRebuy = (state = casinoTournamentRebuyInitial, action) => {
  switch(action.type) {
    case types.CASINO_TOURNAMENT_REBUY_REQUEST:
      return wait(state);
    case types.CASINO_TOURNAMENT_REBUY_SUCCESS:
      return reset(state);
    case types.CASINO_TOURNAMENT_REBUY_FAILURE:
      return {
        ...fail(state),
        failMessage: action.failMessage,
        failData: action.failData,
      };
    case types.CASINO_TOURNAMENT_REBUY_RESET:
      return casinoTournamentRebuyInitial;
    default:
      return state
  }
}

const selectedCasinoTournamentInitial = {
  selectedTournament: null
};

export const selectedCasinoTournament = (
  state = selectedCasinoTournamentInitial,
  action
) => {
  switch (action.type) {
    case types.SELECT_CASINO_TOURNAMENT:
      return {
        ...reset(state),
        selectedTournament: action.payload
      }
    case types.RESET_CASINO_TOURNAMENT:
      return selectedCasinoTournamentInitial;
    default:
      return state;
  }
}

const casinoTournamentUnregisterInitial = getInitial({});

export const casinoTournamentUnregister = (
  state = casinoTournamentUnregisterInitial,
  action
) => {
  switch (action.type) {
    case types.CASINO_TOURNAMENT_UNREGISTER_REQUEST:
      return wait(state);
    case types.CASINO_TOURNAMENT_UNREGISTER_FAILURE:
      return fail(state);
    case types.CASINO_TOURNAMENT_UNREGISTER_SUCCESS:
      return reset(state);
    case types.CASINO_TOURNAMENT_UNREGISTER_RESET:
      return casinoTournamentUnregisterInitial;
    default:
      return state;
  }
};

const tournamentGameUrlInitial = getInitial({ url: null });

export const tournamentGameUrl = (state = tournamentGameUrlInitial, action) => {
  switch (action.type) {
    case types.TOURNAMENT_GAME_URL_REQUEST:
      return { ...wait(state), url: action.rebuy ? state.url : null };
    case types.TOURNAMENT_GAME_URL_SUCCESS:
      const { url, size, needToReload, tournamentId } = action.value;
      const windowSize =
        size === undefined || String(size) === "1"
          ? "fullScreen"
          : "noFullScreen";
      return {
        ...reset(state),
        url,
        size: windowSize,
        needToReload,
        tournamentId,
      };
    case types.TOURNAMENT_GAME_URL_FAILURE:
      return fail(state);
    case types.TOURNAMENT_GAME_URL_RESET:
      return tournamentGameUrlInitial;
    default:
      return state;
  }
};

const casinoTournamentPlayersInitial = getInitial({});

export const casinoTournamentPlayers = (
  state = casinoTournamentPlayersInitial,
  action
) => {
  switch (action.type) {
    case types.GET_CASINO_TOURNAMENT_PLAYERS_REQUEST:
      return wait(state);
    case types.GET_CASINO_TOURNAMENT_PLAYERS_FAILURE:
      return fail(state);
    case types.GET_CASINO_TOURNAMENT_PLAYERS_SUCCESS:
      const { id, casinoTournamentPlayers, offset } = action;
      return {
        ...reset(state),
        [id]: {
          ...state[id],
          players: state[id]?.players ?  !offset ? casinoTournamentPlayers : [...state[id].players, ...casinoTournamentPlayers] : casinoTournamentPlayers,
        },
      };
    case types.GET_CASINO_TOURNAMENT_PLAYERS_RESET:
      return casinoTournamentPlayersInitial;
    default:
      return state;
  }
};

const getAccountBalanceInital = getInitial({
  wallets: [],
});

export const getAccountBalance = (state = getAccountBalanceInital, action) => {
  switch (action.type) {
    case types.GET_ACCOUNT_BALANCE_REQUEST:
      return { ...wait(state), wallets: [] };
    case types.GET_ACCOUNT_BALANCE_REQUEST_FROM_HOST:
      return { ...wait(state), wallets: [] };
    case types.GET_ACCOUNT_BALANCE_SUCCESS:
      return { ...reset(state), wallets: action.wallets };
    case types.GET_ACCOUNT_BALANCE_FAILURE:
      return fail(state);
    default:
      return state;
  }
};

export const openedToasters = (state = [], action) => {
  switch (action.type) {
    case types.OPEN_TOASTER:
      if(!!state.find(msg => msg.id === action.id)) return state;
      return [...state, { view: action.view, id: action.id, params: action.params }];
    case types.CLOSE_TOASTER:
      return state.filter(dlg => action.view ? dlg.view !== action.view : dlg.id !== action.id);
    case types.CLOSE_ALL_TOASTERS:
      return action.view ? state.filter((t) => t.view !== action.view) : [];
    default:
      return state;
  }
};

export const gameOpenCloseState = (state = false, action) => {
  switch (action.type) {
    case types.KEEP_GAME_OPEN:
      return true
    case types.RESET_GAME_OPEN_CLOSE_STATE:
      return false;
    default:
      return state;
  }
}

export const actualLanguage = (state = null, action) => {
  switch (action.type) {
    case types.SET_ACTUAL_LANGUAGE:
      return action.payload;
    case types.RESET_ACTUAL_LANGUAGE:
      return null;
    default:
      return state;
  }
};


const casinoTournamentsHistoryInitial = getInitial({
  casinoTournamentsHistory: null,
});

export const casinoTournamentsHistory = (state = casinoTournamentsHistoryInitial, action) => {
  switch (action.type) {
    case types.GET_CASINO_TOURNAMENTS_HISTORY_REQUEST:
    case types.GET_CASINO_TOURNAMENTS_HISTORY_FAILURE:
      return fail(state);
    case types.GET_CASINO_TOURNAMENTS_HISTORY_SUCCESS:
      return {
        ...reset(state),
        casinoTournamentsHistory: action.payload,
      };
    case types.GET_CASINO_TOURNAMENTS_HISTORY_RESET:
      return casinoTournamentsHistoryInitial;
    default:
      return state;
  }
};

const toastsInitial = [];
export const toasts = (state = toastsInitial, action) => {

  switch (action.type) {
    case 'TOURNAMENT_REGISTER_FAILURE': {
      if (action?.message) {
        return [...state, {
          msgTranslation: action.message,
          appearance: 'error',
          autoDismiss: true
        }];
      } else {
        return state;
      }
    }
    case 'ADD_TOAST': {
      if (action?.data?.msgText) {
        return [...state, {
          msgTranslation: action.data.msgText,
          appearance: 'error',
          autoDismiss: true
        }];
      } else {
        return state;
      }
    }
    default:
      if (action.type && action.type.includes('_FAILURE') && config?.showNetworkErrorToaster) {
        let msg = '';
        if(action?.exception?.message === 'Network Error') {
          msg = action.exception.message + ' \n ' + action.exception?.config?.url;
        } else {
          msg = action.exception ? action.exception.message : 'no exception message';
        }
        return [
          ...state,
          { msgTranslation: msg,
            appearance: 'error', 
            autoDismiss: true },
        ];
      }
      return state;
  }
};

const currencyInitial = {
  isAutoConvertToPlayerCurrencyEnabled: config?.mulicurrency?.autoConvertToPlayerCurrency,
  selectedCurrency: null,
};
export const currency = (state = currencyInitial, action) => {
  switch (action.type) {
    case types.ENABLE_AUTO_CONVER_TO_PLAYER_CURRENCY:
      return { ...state, isAutoConvertToPlayerCurrencyEnabled: action.payload };
    case types.CURRENCY_CHANGE:
      return { ...state, selectedCurrency: action.payload, isAutoConvertToPlayerCurrencyEnabled: true };
    default:
      return state;
  }
};

const localExchangeRatesInitial = getInitial({
  data: null,
});

export const localExchangeRates = (state = localExchangeRatesInitial, action) => {
  switch (action.type) {
    case types.GET_LOCAL_EXCHANGE_RATES_REQUEST:
      return { ...wait(state), data: null };
    case types.GET_LOCAL_EXCHANGE_RATES_FAILURE:
      return fail(state);
    case types.GET_LOCAL_EXCHANGE_RATES_SUCSESS:
      return { ...reset(state), data: action.payload };
    case types.GET_LOCAL_EXCHANGE_RATES_RESET:
      return localExchangeRatesInitial;
    default:
      return state;
  }
};

export const messages = (state = [], action) => {
  switch (action.type) {
    case types.GET_MESSAGES_SUCCESS:
      return action.arrMessages
    case types.READ_MESSAGE_SUCCESS:
      const newState = [...state];
      const message = newState.find(msg => msg._id === action.messageId);
      if (message) {
        message.status = 'read';
      }
      return newState;
    default:
      return state;
  }
};

const avatarMenuInitial = getInitial({
  avatarMenu: null,
});

export const avatarMenu = (state = avatarMenuInitial, action) => {
  switch (action.type) {
    case types.GET_AVATAR_MENU_REQUEST:
      return { ...wait(state), avatarMenu: null };
    case types.GET_AVATAR_MENU_FAILURE:
      return fail(state);
    case types.GET_AVATAR_MENU_SUCSESS:
      return { ...reset(state), avatarMenu: action.payload };
    default:
      return state;
  }
};

const setAvatarInitial = getInitial({});

export const setAvatar = (state = setAvatarInitial, action) => {
  switch (action.type) {
    case types.SET_AVATAR_REQUEST:
      return wait(state);
    case types.SET_AVATAR_FAILURE:
      return fail(state);
    case types.SET_AVATAR_SUCSESS:
      return reset(state);
    default:
      return state;
  }
};

const usersAvatarInitial = getInitial({
  usersAvatar: null,
});

export const usersAvatar = (state = usersAvatarInitial, action) => {
  switch (action.type) {
    case types.GET_USERS_AVATAR_REQUEST:
      return wait(state);
    case types.GET_USERS_AVATAR_FAILURE:
      return fail(state);
    case types.GET_USERS_AVATAR_SUCSESS:
      return { ...reset(state), usersAvatar: { ...state.usersAvatar, ...action.payload } };
    default:
      return state;
  }
};

const myTournamentsInitial = {
 regular: {
  toggle: false,
 },
 jackpot: {
  toggle: false,
 }
};

export const myTournaments = (state = myTournamentsInitial, action) => {
  switch (action.type) {
    case types.MY_TOURNAMENTS_TOGGLE:
      return {
        ...state,
        [action.payload]: {
          toggle: !state[action.payload].toggle,
        }
      }
    case types.MY_TOURNAMENTS_SET:
      return {
        ...state,
        [action.payload.type]: {
          toggle: action.payload.value,
        }
      }
    case types.MY_TOURNAMENTS_RESET:
      return myTournamentsInitial;
    default:
      return state;
  }
};


const jackpotsInitial = getInitial({
  jackpots: null,
});

export const jackpots = (state = jackpotsInitial, action) => {
  switch (action.type) {
    case types.GET_CASINO_TOURNAMENTS_JACKPOTS_REQUEST:
      return { ...wait(state) };
    case types.GET_CASINO_TOURNAMENTS_JACKPOTS_FAILURE:
      return fail(state);
    case types.GET_CASINO_TOURNAMENTS_JACKPOTS_SUCCESS:
      return {
        ...reset(state),
        jackpots: action.payload,
      };
    case types.UPDATE_CASINO_TOURNAMENTS_JACKPOTS:
      const idx = state.jackpots.findIndex(
        (item) => item.tournamentId === action.payload.tournamentId
      );
      if (idx !== -1) {
        return {
          ...state,
          jackpots: [
            ...state.jackpots.slice(0, idx),
            action.payload,
            ...state.jackpots.slice(idx + 1),
          ],
        };
      } else {
        return state;
      }
    case types.UPDATE_CASINO_TOURNAMENTS_JACKPOTS_FROM_SOCKET_SUCCESS: {
      const idx = state.jackpots.findIndex(
        (item) => item.tournamentId === action.payload.tournamentId
      );
      if (idx !== -1) {
        return {
          ...state,
          jackpots: [
            ...state.jackpots.slice(0, idx),
            {
              ...state.jackpots[idx],
              ...action.payload,
            },
            ...state.jackpots.slice(idx + 1),
          ],
        };
      } else {
        return state;
      }
    }
    default:
      return state;
  }
};

const tabInitial = 'regular';

export const tab = (state = tabInitial, action) => {
  switch (action.type) {
    case types.SET_SELECTED_TAB:
      document.body.classList.remove(state);
      document.body.classList.add(action.payload);
      return action.payload
    default:
      return state;
  }
};

const jackpotJoinedPlayersInitial = [];

export const jackpotJoinedPlayers = (state = jackpotJoinedPlayersInitial, action) => {
  switch (action.type) {
    case types.SET_JACKPOT_JOINED_PLAYERS:
      return [...state, action.payload];
    case types.RESET_JACKPOT_JOINED_PLAYERS:
      return jackpotJoinedPlayersInitial;
    default:
      return state;
  }
};

const playerStateInTournamentsInitial = getInitial({
  data: null,
});

export const playerStateInTournaments = (state = playerStateInTournamentsInitial, action) => {
  switch (action.type) {
    case types.GET_PLAYER_STATE_IN_TOURNAMENTS_REQUEST:
      return wait(state);
    case types.GET_PLAYER_STATE_IN_TOURNAMENTS_FAILURE:
      return fail(state);
    case types.GET_PLAYER_STATE_IN_TOURNAMENTS_SUCSESS:
      return { ...reset(state), data: action.payload};
    case types.GET_PLAYER_STATE_IN_TOURNAMENTS_UPDATE: {
      if (state.data?.length && action?.payload) {
        const index = state.data.findIndex(item => item.tournamentId === action.payload.tournamentId);
        if (index !== -1) {
          return {
            ...reset(state),
            data: [
              ...state.data.slice(0, index),
              action.payload,
              ...state.data.slice(index + 1),
            ]
          }
        }
        return state;
      }
      return state;
    }
    case types.GET_PLAYER_STATE_IN_TOURNAMENTS_RESET:
      return playerStateInTournamentsInitial;
    default:
      return state;
  }
};

const selectedTournamentInitial = null;

export const selectedTournament = (state = selectedTournamentInitial, action) => {
  switch (action.type) {
    case types.SET_TOURNAMENT:
      return action.payload;
    default:
      return state;
  }
};

const CMSResponseInitial = getInitial({
  data: null,
});

export const CMSResponse = (state = CMSResponseInitial, action) => {
  switch (action.type) {
    case types.GET_CMS_RESPONSE_REQUEST:
      return { ...wait(state), data: null};
    case types.GET_CMS_RESPONSE_FAILURE:
      return fail(state);
    case types.GET_CMS_RESPONSE_SUCSESS:
      return { ...reset(state), data: action.payload};
    default:
      return state;
  }
};


const toggleJackpotGameFromHostInitial = {
  toggle: false,
  id: null,
};

export const toggleJackpotGameFromHost = (state = toggleJackpotGameFromHostInitial, action) => {
  switch (action.type) {
    case types.SET_TOGGLE_JACKPOT_GAME_FROM_HOST:
      return action.payload
    case types.RESET_TOGGLE_JACKPOT_GAME_FROM_HOST:
      return toggleJackpotGameFromHostInitial;
    default:
      return state;
  }
};

const gosPlayerLastWinInitial = getInitial({
  data: null,
});

export const gosPlayerLastWin = (state = gosPlayerLastWinInitial, action) => {
  switch (action.type) {
    case types.GET_GOS_PLAYER_LAST_WIN_REQUEST:
      return { ...wait(state), data: null };
    case types.GET_GOS_PLAYER_LAST_WIN_FAILURE:
      return fail(state);
    case types.GET_GOS_PLAYER_LAST_WIN_SUCSESS:
      return { ...reset(state), data: action.payload };
    case types.GET_GOS_PLAYER_LAST_WIN_RESET:
      return gosPlayerLastWinInitial;
    default:
      return state;
  }
};


export const jackpotPrizeGenerationBool = (state = false, action) => {
  switch (action.type) {
    case types.JACKPOT_PRIZE_GENERATED_TOGGLE:
      return action.payload;
    default:
      return state;
  }
};

const initialState = {
  isOngoingTournamentsModalOpen: false,
};

export const onGoingTournamentsModal = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_ONGOING_TOURNAMENTS_MODAL:
      return {
        ...state,
        isOngoingTournamentsModalOpen: action.payload,
      };
    default:
      return state;
  }
};

const gosPopupsFromCMSInitial = getInitial({
  data: null,
});

export const gosPopupsFromCMS = (state = gosPopupsFromCMSInitial, action) => {
  switch (action.type) {
    case types.GET_GOS_POPUPS_FROM_CMS_REQUEST:
      return { ...wait(state), data: null }
    case types.GET_GOS_POPUPS_FROM_CMS_FAILURE:
      return fail(state);
    case types.GET_GOS_POPUPS_FROM_CMS_SUCSESS:
      return { ...reset(state), data: action.payload }
    case types.GET_GOS_POPUPS_FROM_CMS_RESET:
      return gosPopupsFromCMSInitial;
    default:
      return state;
  }
};

export const playerResult = (state = {data: null}, action) => {
  switch (action.type) {
    case types.UPDATE_PLAYER_RESULT:
      return { ...state, data: action.payload };
    case types.RESET_PLAYER_RESULT:
      return {data: null};
    default:
      return state;
  }
};

const categoryInitial = config?.disableLobbyOnMobile ? 'regular' : '';

export const category = (state = categoryInitial, action) => {
  switch (action.type) {
    case types.SET_CATEGORY:
      return action.payload
    default:
      return state;
  }
};

const spinAndGoConfigsInitial = getInitial({
  data: null,
});

export const spinAndGoConfigs = (state = spinAndGoConfigsInitial, action) => {
  switch (action.type) {
    case types.GET_SPIN_AND_GO_CONFIGS_REQUEST:
      return wait(state);
    case types.GET_SPIN_AND_GO_CONFIGS_FAILURE:
      return fail(state);
    case types.GET_SPIN_AND_GO_CONFIGS_SUCSESS:
      return {
        ...reset(state),
        data: action.payload
      }
    case types.GET_SPIN_AND_GO_CONFIGS_RESET:
      return spinAndGoConfigsInitial;
    default:
      return state;
  }
};


const spinAndGoRegisterInitial = getInitial({
  failMessage: null,
  failData: null,
});

export const spinAndGoRegister = (state = spinAndGoRegisterInitial, action) => {
  switch (action.type) {
    case types.CREATE_SPIN_AND_GO_REGISTER_REQUEST:
      return {
        ...wait(state),
        failMessage: null,
        failData: null,
      };
    case types.CREATE_SPIN_AND_GO_REGISTER_FAILURE:
      return {
        ...fail(state),
        failMessage: action.failMessage,
        failData: action.failData,
      };
    case types.CREATE_SPIN_AND_GO_REGISTER_SUCSESS:
      return reset(state);
    case types.CREATE_SPIN_AND_GO_REGISTER_ERROR_MESSAGE_RESET:
      return { ...reset(state), failMessage: null };
    case types.CREATE_SPIN_AND_GO_REGISTER_RESET:
      return spinAndGoRegisterInitial;
    default:
      return state;
  }
};

const spinAndGoUnregInitial = getInitial({});

export const spinAndGoUnreg = (state = spinAndGoUnregInitial, action) => {
  switch (action.type) {
    case types.CREATE_SPIN_AND_GO_UNREG_REQUEST:
      return wait(state);
    case types.CREATE_SPIN_AND_GO_UNREG_FAILURE:
      return fail(state);
    case types.CREATE_SPIN_AND_GO_UNREG_SUCSESS:
      return reset(state);
    case types.CREATE_SPIN_AND_GO_UNREG_RESET:
      return spinAndGoUnregInitial;
    default:
      return state;
  }
};

export const spinAndGoGameOpenCloseState= (state = false, action) => {
  switch (action.type) {
    case types.OPEN_SPIN_AND_GO_GAME:
      return true;
    case types.CLOSE_SPIN_AND_GO_GAME:
      return false;
    default:
      return state;
  }
};

const spinAndGoInstancesInitial = getInitial({
  data: null,
});

export const spinAndGoInstances = (state = spinAndGoInstancesInitial, action) => {
  switch (action.type) {
    case types.GET_SPIN_AND_GO_INSTANCES_REQUEST:
      return { ...wait(state), data: null };
    case types.GET_SPIN_AND_GO_INSTANCES_FAILURE:
      return fail(state);
    case types.GET_SPIN_AND_GO_INSTANCES_SUCSESS:
      return { ...reset(state), data: action.payload };
    case types.GET_SPIN_AND_GO_INSTANCES_RESET:
      return spinAndGoInstancesInitial;
    default:
      return state;
  }
};

const spinAndGoWinnersInitial = getInitial({
  data: null,
});

export const spinAndGoWinners = (state = spinAndGoWinnersInitial, action) => {
  switch (action.type) {
    case types.GET_SPIN_AND_GO_WINNERS_REQUEST:
      return wait(state);
    case types.GET_SPIN_AND_GO_WINNERS_FAILURE:
      return fail(state);
    case types.GET_SPIN_AND_GO_WINNERS_SUCSESS:
      return { ...reset(state), data: action.payload }
    case types.GET_SPIN_AND_GO_WINNERS_RESET:
      return spinAndGoWinnersInitial;
    default:
      return state;
  }
};


const cashGameUrlInitial = getInitial({ url: null });

export const cashGameUrl = (state = cashGameUrlInitial, action) => {
  switch (action.type) {
    case types.CASH_GAMES_URL_REQUEST:
      return { ...wait(state), url: null };
    case types.CASH_GAMES_URL_SUCCESS:
      const { url, size, cashGameId } = action.value;
      const windowSize =
        size === undefined || String(size) === "1"
          ? "fullScreen"
          : "noFullScreen";
      return {
        ...reset(state),
        url,
        size: windowSize,
        cashGameId,
      };
    case types.CASH_GAMES_URL_FAILURE:
      return fail(state);
    case types.CASH_GAMES_URL_RESET:
      return cashGameUrlInitial;
    default:
      return state;
  }
};
