module.exports = {
  disableTournamentCasinoPortrait: false,
  skin: "betpassion", // SKINS ====> ['gamedaddy', 'betpassion'],
  maskNickname: true,
  showLastBetToaster: false,
  hasYoutubeLinkOnHowToPlayPage: false,
  tournamentsHistoryPageItemsLimit: 1000,
  hasTextOnTournamentHistoryWidgetBanner: true, // used for show text on tournament history widget banner on logout case
  mulicurrency: {
    autoConvertToPlayerCurrency: false,
    enableCurrencySwitcherMenu: false,
  },
  showNetworkErrorToaster: false,
  showSocketErrorToaster: false,
  withOutForceFullScreen: false,
  enableJackpot: false,
  skinStyle: "dark",
  hasSkinSwitcher: true,
  disableLobbyOnMobile: true,
  externalLinkHowToPlay:
    "https://www.betpassion.it/promozioni/casino/Tornei-di-slot/game-of-slots/come-funziona",
  externalLinkTermsAndConditions:
    "https://www.betpassion.it/promozioni/casino/Tornei-di-slot/game-of-slots/termini-e-condizioni",
  externalLinkHowGetTickets:
    "https://www.betpassion.it/game-of-slots/summer-party",
  naviagtion: {
    jackpot: {
      show: true,
      disabled: true,
    },
    cashGames: {
      show: true,
      disabled: true,
    },
    WOS: {
      show: true,
      disabled: true,
    },
  },
};
