import { memo } from 'react';
import { Route, Switch } from 'react-router';
import Lobby from '../Lobby';
import HowToPlayPage from '../HowToPlayPage'

const Router = () => {
  return (
    <>
      <Switch>
        <Route path="/howToPlay">
          <HowToPlayPage />
        </Route>
        <Route path="/" >
          <Lobby />
        </Route>
      </Switch>
    </>
  )
}

export default memo(Router); 