const apiRoutes = {
  GET_CASINO_TOURNAMENTS: "/casinoTournament/getCasinoTournamentInstances",
  CASINO_TOURNAMENT_REGISTER: "/casinoTournament/casinoTournamentRegister",
  CASINO_TOURNAMENT_REBUY: "/casinoTournament/rebuyTournament",
  CASINO_TOURNAMENT_UNREGISTER: "/casinoTournament/casinoTournamentUnregister",
  GET_CASINO_URL_FOR_TOURNAMENT: "/casinoTournament/getCasinoUrlForTournament",
  GET_CASINO_TOURNAMENT_PLAYERS: "/casinoTournament/getCasinoTournamentPlayers",
  TOURNAMENT_LAST_BET: "/casinoTournament/requestTourFinish",
  GET_CASINO_TOURNAMENTS_HISTORY: "/casinoTournament/getCasinoTournamentHistory",
  getAccountBalance: '/api_v2/getBalance',
  GET_LOCAL_EXCHANGE_RATES: "/api_v2/getLocalExchangeRates",
  SUBSCRIPTION: "api/subscribtion",
  GET_AVATAR_MENU: '/getAvatarMenu?singleFile=true',
  GET_AVATARS: '/getAvatars',
  SET_AVATAR: '/setAvatar',
  GET_PLAYER_STATE_IN_TOURNAMENTS: '/casinoTournament/getPlayerStateInTournaments',
  GET_CMS_RESPONSE: '/api/gos-widgets-content?populate=*',
  GET_CMS_POPUPS: '/api/gos-popups?populate=*',
  GET_GOS_PLAYER_LAST_WIN: "/api/getGoSPlayerLastWin",
  SPIN_AND_GO_INSTANCE_REGISTER: "/casinoTournament/spinAndGoInstanceRegister",
  GET_SPIN_AND_GO_CONFIGS: "/casinoTournament/getSpinAndGoConfigs",
  SPIN_AND_GO_REGISTER: "/casinoTournament/spinAndGoRegister",
  SPIN_AND_GO_UNREG: "/casinoTournament/spinAndGoUnreg",
  GET_SPIN_AND_GO_INSTANCES: "/casinoTournament/getSpinAndGoInstances",
  GET_SPIN_AND_GO_WINNERS: "/casinoTournament/getSpinAndGoWinners",
  GET_CASINO_URL_FOR_CASH_GAME: "/casinoTournament/getCasinoUrlForTournament",
  GET_GAME_URL: "/lobby/gameUrl",
};

export default apiRoutes;
