import {
  select,
  call,
  put,
  take,
  cancel,
  fork,
  delay,
} from "redux-saga/effects";
import {
  getToken,
  getTournamentGameUrl,
  isSelectedJackpotTab,
} from "../selectors";
import { apiGetLobbyURL } from "./callApi";
import apiRoutes from "../../constants/apiRoutes";
import types from "../actionTypes";
import {
  getSpinAndGoWinnersFailure,
  getSpinAndGoWinnersSucsess,
} from "../actionCreators/spinAndGoWinners";
import tournamentConfig from "../../configs/tournamentConfig";

function* processGetSpinAndGoWinnersRequest() {
  try {
    const token = yield select(getToken);
    const response = yield call(
      apiGetLobbyURL,
      apiRoutes.GET_SPIN_AND_GO_WINNERS,
      { token }
    );
    if (response.data?.success) {
      yield put(getSpinAndGoWinnersSucsess(response.data?.result?.slice(0, 4)));
    }
  } catch (e) {
    yield put(getSpinAndGoWinnersFailure(e));
  }
}

function* pollSpinAndGoWinnersData() {
  while (true) {
    yield call(processGetSpinAndGoWinnersRequest);
    yield delay(tournamentConfig?.spinAndGoUpdateInterval);
  }
}

export function* watchGetSpinAndGoWinnersRequest() {
  let pollingTask = null;

  while (true) {
    yield take([
      types.GET_SPIN_AND_GO_WINNERS_REQUEST,
      types.SET_SELECTED_TAB,
      ,
      types.TOURNAMENT_GAME_URL_SUCCESS,
      ,
      types.TOURNAMENT_GAME_URL_FAILURE,
      types.TOURNAMENT_GAME_URL_RESET,
    ]);
    const isJackpot = yield select(isSelectedJackpotTab);
    const tournamentGameUrl = yield select(getTournamentGameUrl);

    if (pollingTask && (!isJackpot || tournamentGameUrl)) {
      yield cancel(pollingTask);
      pollingTask = null;
    }

    if (isJackpot && !tournamentGameUrl && !pollingTask) {
      pollingTask = yield fork(pollSpinAndGoWinnersData);
    }
  }
}
