import OnGoingTournamentItem from "./OnGoingTournamentItem";
import { useOnGoningEvents } from "./OnGoningEventsProvider";

const OnGoingEventsTouramentsList = () => {
  const { currentTournamentList } = useOnGoningEvents();
  return (
    <div className="on-going-events-touraments-list">
      {currentTournamentList.map((tournament) => (
        <div
          key={tournament.tournamentId}
          className="on-going-events-touraments-list__on-going-events-tourament-item-wrapper"
        >
          <OnGoingTournamentItem tournament={tournament} />
        </div>
      ))}
    </div>
  );
};

export default OnGoingEventsTouramentsList;
