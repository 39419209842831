import { isMobile } from "react-device-detect";
import DesktopLeaderboardInfo from "./DesktopLeaderboardInfo";
import MobileLeaderboardInfo from "./MobileLeaderboardInfo";

const LeaderboardInfo = (props) => {
  const Component = isMobile ? MobileLeaderboardInfo : DesktopLeaderboardInfo;
  return <Component {...props} />;
};

export default LeaderboardInfo;
