import { useTranslation } from 'react-i18next';
import './DesktopJackpotGameLeaderBoard.scss';
import Skeleton from '../../../../Skeleton';
import JackpotGameLeaderboardTable from '../../JackpotGameLeaderboardTable';

const DesktopJackpotGameLeaderBoard = ({ tournament, players, self }) => {
  const { t } = useTranslation();

  return (
    <div className="jackpot-game-leaderBoard">
      {!players?.length && <Skeleton width="100%" radius={10} height={96}  />}
      {players?.length && <><span className="jackpot-game-leaderBoard__text">{t('leaderboard')}</span>
      {self && (
        <div className="jackpot-game-leaderBoard__players-list">
          <div className='jackpot-game-leaderBoard__jackpot-game-leader-board-table-wrapper'>
            <JackpotGameLeaderboardTable players={players} self={self} tournament={tournament} isGame />
          </div>
        </div>
      )}</>}
    </div>
  );
};

export default DesktopJackpotGameLeaderBoard;
