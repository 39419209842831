import { useState, useEffect } from "react";
import "./ProgressBar.scss";

const ProgressBar = ({ duration, circle, endsAfterMS }) => {
  const [progress, setProgress] = useState(0);
  const [countdown, setCountdown] = useState("");

  useEffect(() => {
    const durationMilliseconds = duration * 60 * 1000;
    const totalMilliseconds = Math.min(
      endsAfterMS,
      durationMilliseconds
    );

    const initialProgress =
      ((durationMilliseconds - totalMilliseconds) / durationMilliseconds) * 100;
    setProgress(initialProgress);

    let start = null;

    const step = (timestamp) => {
      if (!start) start = timestamp;
      const elapsed = timestamp - start;
      const newProgress =
        initialProgress +
        (elapsed / totalMilliseconds) * (100 - initialProgress);
      setProgress(Math.min(newProgress, 100));

      const remainingTime = Math.max(totalMilliseconds - elapsed, 0);
      const minutes = Math.floor(remainingTime / (1000 * 60));
      const seconds = Math.floor((remainingTime / 1000) % 60);
      setCountdown(
        `${minutes.toString().padStart(2, "0")}:${seconds
          .toString()
          .padStart(2, "0")}`
      );

      if (elapsed < totalMilliseconds && newProgress < 100) {
        window.requestAnimationFrame(step);
      }
    };

    window.requestAnimationFrame(step);

    return () => {
      setProgress(0);
    };
  }, [endsAfterMS, duration]);

  return (
    <div className={`progress-bar ${circle ? "progress-bar--circle" : ""}`}>
      {circle ? (
        <>
          <div className="progress-bar__middle-circle">{countdown}</div>
          <div
            style={{
              background: `conic-gradient(#DD3A41 ${Math.round(
                progress
              )}%, #CDB2B3 ${Math.round(progress)}%)`,
            }}
            className="progress-bar__spinner"
          />
        </>
      ) : (
        <div
          className="progress-bar__fill"
          style={{
            width: `${progress}%`,
          }}
        />
      )}
    </div>
  );
};

export default ProgressBar;
