import { useCallback, useEffect } from "react";

export const useResetState = (cb) => {
  const handleFrameMessages = useCallback(
    (e) => {
      if (e.data.type === "resetState") cb?.();
    },
    [cb]
  );

  useEffect(() => {
    window.addEventListener("message", (e) => handleFrameMessages(e));
    return () => {
      window.removeEventListener("message", (e) => handleFrameMessages(e));
    };
  }, []);
};
