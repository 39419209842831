import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormatCurrency } from '../../../../hooks/useFormatCurrency';
import './TournamentsHistoryTableItemResultsMobile.scss';

const TOURNAMENT_WINNERS_RESULTS = [
  {key: "position1", award: "gold", position: 1},
  {key: "position2", award: "silver", position: 2},
  {key: "position3", award: "bronze", position: 3},
];

const TournamentsHistoryTableItemResultsMobile = ({ data }) => {
  const { t } = useTranslation();
  const formatCurrency = useFormatCurrency();

  const { requesterPosition } = data;
  return (
    <div className='history-table-item-results-mobile'>
      <div className='history-table-item-results-mobile__player-results'>
        <div className='tournament-results-wrapper-mobile player'>
          <div className='tournament-results-wrapper-mobile__info-items name'>
            <div className='tournament-results-wrapper-mobile__info-items--title'>#{requesterPosition?.position}</div>
            <div className='tournament-results-wrapper-mobile__info-items--value'>{requesterPosition?.nickname || requesterPosition?.username}</div>
          </div>
          <div className='tournament-results-wrapper-mobile__info-items points'>
            <div className='tournament-results-wrapper-mobile__info-items--title'>{t('points')}</div>
            <div className='tournament-results-wrapper-mobile__info-items--value'>{requesterPosition?.points}</div>
          </div>
          <div className='tournament-results-wrapper-mobile__info-items win'>
            <div className='tournament-results-wrapper-mobile__info-items--title'>{t('win')}</div>
            <div className='tournament-results-wrapper-mobile__info-items--value'>
            {requesterPosition?.win?.type === 'externalPrize' ? requesterPosition?.win?.prizeName : requesterPosition?.win?.amount >= 0 ? formatCurrency(requesterPosition.win.amount, requesterPosition.win.currency) : 0}
            </div>
          </div>
        </div>
      </div>
      <div className='history-table-item-results-mobile__tournament-results'>
        {TOURNAMENT_WINNERS_RESULTS.map((el) => (
          <div key={el.key} className='tournament-results-wrapper-mobile'>
            <div className='tournament-results-wrapper-mobile__info-items name'>
              <div className='tournament-results-wrapper-mobile__info-items--title'>
                #{el?.position}
                <div className={`award-position ${el?.award}`}></div>
              </div>
              <div className='tournament-results-wrapper-mobile__info-items--value'>{data[el.key]?.nickname || data[el.key]?.username}</div>
            </div>
            <div className='tournament-results-wrapper-mobile__info-items points'>
              <div className='tournament-results-wrapper-mobile__info-items--title'>{t('points')}</div>
              <div className='tournament-results-wrapper-mobile__info-items--value'>{data[el.key]?.points}</div>
            </div>
            <div className='tournament-results-wrapper-mobile__info-items win'>
              <div className='tournament-results-wrapper-mobile__info-items--title'>{t('win')}</div>
              <div className='tournament-results-wrapper-mobile__info-items--value'>
                {data[el.key]?.win?.type === 'externalPrize' ? data[el.key]?.win?.prizeName : data[el.key]?.win?.amount >= 0 ? formatCurrency(data[el.key].win.amount, data[el.key].win.currency) : 0}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default TournamentsHistoryTableItemResultsMobile