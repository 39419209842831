import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { classNames } from "../../helpers/pureFunctions";
import { closeDialog } from "../../store/actionCreators/dialogManager";
import { useResetState } from "../../hooks/useResetState";
import { useState } from "react";
import Views from "./Views";
import Tabs from "../Tabs";
import "./HowToPlayPage.scss";

const HowToPlayPage = () => {
  const dispatch = useDispatch();
  useResetState(backToTournaments);
  const [tab, setTab] = useState('regular');

  const pageContainerClasses = classNames("how-to-play-page-container", {
    mobile: isMobile,
  });

  function backToTournaments() {
    dispatch(closeDialog("howToPlay"));
  }

  const View = Views[tab];

  return (
    <div className={pageContainerClasses}>
      
      <div className="pop-up__close-wrapper"  onClick={backToTournaments}>
          <i className="pop-up__close icon-cross" />
        </div>
      <div className="how-to-play__tabs-wrapper">
        <Tabs externalTabSetter={setTab} externalTab={tab} />
      </div>
      <div className="how-to-play-section__views">
        <View tab={tab} />
      </div>
    </div>
  );
};

export default HowToPlayPage;
