import types from '../actionTypes';

export const setToggleJackpotGameFromHost = (payload) => ({
  type: types.SET_TOGGLE_JACKPOT_GAME_FROM_HOST,
  payload,
});
export const resetToggleJackpotGameFromHost = (payload) => ({
  type: types.RESET_TOGGLE_JACKPOT_GAME_FROM_HOST,
  payload,
});
