import { take, fork, call, put, cancel, select } from 'redux-saga/effects';
import types from '../actionTypes';
import apiRoutes from '../../constants/apiRoutes';
import { apiGet } from './callApi';
import { getSpinAndGoConfigsFailure, getSpinAndGoConfigsSucsess } from '../actionCreators/spinAndGoConfigs';
import { getSkinId, getToken } from '../selectors';

function* processGetSpinAndGoConfigsRequest() {
  try {
    const token = yield select(getToken);
    const skinId = yield select(getSkinId);
    const params = { token };
    if (skinId) {
      params.skinId = Number(skinId);
    }
    const response = yield call(apiGet, apiRoutes.GET_SPIN_AND_GO_CONFIGS, params);
    if (response.data?.success) {
      yield put(getSpinAndGoConfigsSucsess(response.data?.result));
    }
  } catch (e) {
    yield put(getSpinAndGoConfigsFailure(e));
  }
}

export function* watchGetSpinAndGoConfigsRequest() {
  let forked = null;
  while (true) {
    yield take(types.GET_SPIN_AND_GO_CONFIGS_REQUEST);
    if (forked) yield cancel(forked);
    forked = yield fork(processGetSpinAndGoConfigsRequest);
  }
}