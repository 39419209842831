const actionTypes = {
  OPEN_DIALOG: "OPEN_DIALOG",
  CLOSE_DIALOG: "CLOSE_DIALOG",
  SET_QUERY_PARAMS: "SET_QUERY_PARAMS",
  LOGIN_SET_QUERY_PARAMS: "LOGIN_SET_QUERY_PARAMS",
  LOGOUT_SET_QUERY_PARAMS: "LOGOUT_SET_QUERY_PARAMS",
  RESET_QUERY_PARAMS: "RESET_QUERY_PARAMS",
  TOURNAMENT_PLAYER_UPDATE: "TOURNAMENT_PLAYER_UPDATE",
  TOURNAMENT_PLAYER_RESET: "TOURNAMENT_PLAYER_RESET",
  GET_CASINO_TOURNAMENTS_REQUEST: "GET_CASINO_TOURNAMENTS_REQUEST",
  GET_CASINO_TOURNAMENTS_REQUEST_WITHOUT_DELAY: "GET_CASINO_TOURNAMENTS_REQUEST_WITHOUT_DELAY",
  GET_CASINO_TOURNAMENTS_SUCCESS: "GET_CASINO_TOURNAMENTS_SUCCESS",
  GET_CASINO_TOURNAMENTS_FAILURE: "GET_CASINO_TOURNAMENTS_FAILURE",
  GET_CASINO_TOURNAMENTS_RESET: "GET_CASINO_TOURNAMENTS_RESET",
  SELECT_CASINO_TOURNAMENT: "SELECT_CASINO_TOURNAMENT",
  RESET_CASINO_TOURNAMENT: "RESET_CASINO_TOURNAMENT",
  UPDATE_CASINO_TOURNAMENTS: "UPDATE_CASINO_TOURNAMENTS",
  UPDATE_CASINO_TOURNAMENTS_JACKPOTS_FROM_SOCKET_REQUEST: "UPDATE_CASINO_TOURNAMENTS_JACKPOTS_FROM_SOCKET_REQUEST",
  UPDATE_CASINO_TOURNAMENTS_JACKPOTS_FROM_SOCKET_SUCCESS: "UPDATE_CASINO_TOURNAMENTS_JACKPOTS_FROM_SOCKET_SUCCESS",
  CASINO_TOURNAMENT_REGISTER_REQUEST: "CASINO_TOURNAMENT_REGISTER_REQUEST",
  CASINO_TOURNAMENT_REGISTER_SUCCESS: "CASINO_TOURNAMENT_REGISTER_SUCCESS",
  CASINO_TOURNAMENT_REGISTER_FAILURE: "CASINO_TOURNAMENT_REGISTER_FAILURE",
  CASINO_TOURNAMENT_REGISTER_RESET: "CASINO_TOURNAMENT_REGISTER_RESET",
  CASINO_TOURNAMENT_REBUY_REQUEST: "CASINO_TOURNAMENT_REBUY_REQUEST",
  CASINO_TOURNAMENT_REBUY_FAILURE: "CASINO_TOURNAMENT_REBUY_FAILURE",
  CASINO_TOURNAMENT_REBUY_SUCCESS: "CASINO_TOURNAMENT_REBUY_SUCCESS",
  CASINO_TOURNAMENT_REBUY_RESET: "CASINO_TOURNAMENT_REBUY_RESET",
  CASINO_TOURNAMENT_REGISTER_ERROR_MESSAGE_RESET: "CASINO_TOURNAMENT_REGISTER_RESET",
  CASINO_TOURNAMENT_UNREGISTER_REQUEST: "CASINO_TOURNAMENT_UNREGISTER_REQUEST",
  CASINO_TOURNAMENT_UNREGISTER_SUCCESS: "CASINO_TOURNAMENT_UNREGISTER_SUCCESS",
  CASINO_TOURNAMENT_UNREGISTER_FAILURE: "CASINO_TOURNAMENT_UNREGISTER_FAILURE",
  CASINO_TOURNAMENT_UNREGISTER_RESET: "CASINO_TOURNAMENT_UNREGISTER_RESET",
  TOURNAMENT_GAME_URL_REQUEST: "TOURNAMENT_GAME_URL_REQUEST",
  TOURNAMENT_GAME_URL_SUCCESS: "TOURNAMENT_GAME_URL_SUCCESS",
  TOURNAMENT_GAME_URL_FAILURE: "TOURNAMENT_GAME_URL_FAILURE",
  TOURNAMENT_GAME_URL_RESET: "TOURNAMENT_GAME_URL_RESET",
  GET_CASINO_TOURNAMENT_PLAYERS_REQUEST: "GET_CASINO_TOURNAMENT_PLAYERS_REQUEST",
  GET_CASINO_TOURNAMENT_PLAYERS_SUCCESS: "GET_CASINO_TOURNAMENT_PLAYERS_SUCCESS",
  GET_CASINO_TOURNAMENT_PLAYERS_FAILURE: "GET_CASINO_TOURNAMENT_PLAYERS_FAILURE",
  GET_CASINO_TOURNAMENT_PLAYERS_RESET: "GET_CASINO_TOURNAMENT_PLAYERS_RESET",
  GET_ACCOUNT_BALANCE_REQUEST: "GET_ACCOUNT_BALANCE_REQUEST",
  GET_ACCOUNT_BALANCE_SUCCESS: "GET_ACCOUNT_BALANCE_SUCCESS",
  GET_ACCOUNT_BALANCE_FAILURE: "GET_ACCOUNT_BALANCE_FAILURE",
  GET_ACCOUNT_BALANCE_REQUEST_FROM_HOST: "GET_ACCOUNT_BALANCE_REQUEST_FROM_HOST",
  TOURNAMENT_LAST_BET_REQUEST: "TOURNAMENT_LAST_BET_REQUEST",
  TOURNAMENT_LAST_BET_SUCCESS: "TOURNAMENT_LAST_BET_SUCCESS",
  TOURNAMENT_LAST_BET_FAILURE: "TOURNAMENT_LAST_BET_FAILURE",
  OPEN_TOASTER: "OPEN_TOASTER",
  CLOSE_TOASTER: "CLOSE_TOASTER",
  CLOSE_ALL_TOASTERS: "CLOSE_ALL_TOASTERS",
  KEEP_GAME_OPEN: "KEEP_GAME_OPEN",
  RESET_GAME_OPEN_CLOSE_STATE: "RESET_GAME_OPEN_CLOSE_STATE",
  SET_ACTUAL_LANGUAGE: "SET_ACTUAL_LANGUAGE",
  RESET_ACTUAL_LANGUAGE: "RESET_ACTUAL_LANGUAGE",
  GET_CASINO_TOURNAMENTS_HISTORY_REQUEST: "GET_CASINO_TOURNAMENTS_HISTORY_REQUEST",
  GET_CASINO_TOURNAMENTS_HISTORY_SUCCESS: "GET_CASINO_TOURNAMENTS_HISTORY_SUCCESS",
  GET_CASINO_TOURNAMENTS_HISTORY_FAILURE: "GET_CASINO_TOURNAMENTS_HISTORY_FAILURE",
  GET_CASINO_TOURNAMENTS_HISTORY_RESET: "GET_CASINO_TOURNAMENTS_HISTORY_RESET",
  CASINO_TOURNAMENTS_UPDATE_WITHOUT_DELAY: "CASINO_TOURNAMENTS_UPDATE_WITHOUT_DELAY",
  ADD_TOAST: "ADD_TOAST",
  CURRENCY_CHANGE: "CURRENCY_CHANGE",
  ENABLE_AUTO_CONVER_TO_PLAYER_CURRENCY: 'ENABLE_AUTO_CONVER_TO_PLAYER_CURRENCY',
  GET_LOCAL_EXCHANGE_RATES_REQUEST: 'GET_LOCAL_EXCHANGE_RATES_REQUEST',
  GET_LOCAL_EXCHANGE_RATES_FAILURE: 'GET_LOCAL_EXCHANGE_RATES_FAILURE',
  GET_LOCAL_EXCHANGE_RATES_SUCSESS: 'GET_LOCAL_EXCHANGE_RATES_SUCSESS',
  GET_LOCAL_EXCHANGE_RATES_RESET: 'GET_LOCAL_EXCHANGE_RATES_RESET',
  GET_MESSAGES_REQUEST: 'GET_MESSAGES_REQUEST',
  GET_MESSAGES_SUCCESS: 'GET_MESSAGES_SUCCESS',
  READ_MESSAGE_REQUEST: 'READ_MESSAGE_REQUEST',
  READ_MESSAGE_SUCCESS: 'READ_MESSAGE_SUCCESS',
  PUBLIC_MESSAGES_REQUEST: 'PUBLIC_MESSAGES_REQUEST',
  GET_AVATAR_MENU_REQUEST: 'GET_AVATAR_MENU_REQUEST',
  GET_AVATAR_MENU_FAILURE: 'GET_AVATAR_MENU_FAILURE',
  GET_AVATAR_MENU_SUCSESS: 'GET_AVATAR_MENU_SUCSESS',
  SET_AVATAR_REQUEST: 'SET_AVATAR_REQUEST',
  SET_AVATAR_FAILURE: 'SET_AVATAR_FAILURE',
  SET_AVATAR_SUCSESS: 'SET_AVATAR_SUCSESS',
  GET_USERS_AVATAR_REQUEST: 'GET_USERS_AVATAR_REQUEST',
  GET_USERS_AVATAR_FAILURE: 'GET_USERS_AVATAR_FAILURE',
  GET_USERS_AVATAR_SUCSESS: 'GET_USERS_AVATAR_SUCSESS',
  MY_TOURNAMENTS_TOGGLE: 'MY_TOURNAMENTS_TOGGLE',
  MY_TOURNAMENTS_SET: 'MY_TOURNAMENTS_SET',
  MY_TOURNAMENTS_RESET: 'MY_TOURNAMENTS_RESET',
  GET_CASINO_TOURNAMENTS_JACKPOTS_REQUEST: "GET_CASINO_TOURNAMENTS_JACKPOTS_REQUEST",
  GET_CASINO_TOURNAMENTS_JACKPOTS_WITH_DELAY_REQUEST: "GET_CASINO_TOURNAMENTS_JACKPOTS_WITH_DELAY_REQUEST",
  GET_CASINO_TOURNAMENTS_JACKPOTS_SUCCESS: "GET_CASINO_TOURNAMENTS_JACKPOTS_SUCCESS",
  GET_CASINO_TOURNAMENTS_JACKPOTS_FAILURE: "GET_CASINO_TOURNAMENTS_JACKPOTS_FAILURE",
  UPDATE_CASINO_TOURNAMENTS_JACKPOTS: "UPDATE_CASINO_TOURNAMENTS_JACKPOTS",
  SET_SELECTED_TAB: 'SET_SELECTED_TAB',
  SET_JACKPOT_JOINED_PLAYERS: 'SET_JACKPOT_JOINED_PLAYERS',
  RESET_JACKPOT_JOINED_PLAYERS: 'RESET_JACKPOT_JOINED_PLAYERS',
  GET_PLAYER_STATE_IN_TOURNAMENTS_REQUEST: 'GET_PLAYER_STATE_IN_TOURNAMENTS_REQUEST',
  GET_PLAYER_STATE_IN_TOURNAMENTS_FAILURE: 'GET_PLAYER_STATE_IN_TOURNAMENTS_FAILURE',
  GET_PLAYER_STATE_IN_TOURNAMENTS_SUCSESS: 'GET_PLAYER_STATE_IN_TOURNAMENTS_SUCSESS',
  GET_PLAYER_STATE_IN_TOURNAMENTS_UPDATE: 'GET_PLAYER_STATE_IN_TOURNAMENTS_UPDATE',
  GET_PLAYER_STATE_IN_TOURNAMENTS_RESET: 'GET_PLAYER_STATE_IN_TOURNAMENTS_RESET',
  SET_TOURNAMENT: "SET_TOURNAMENT",
  GET_CMS_RESPONSE_REQUEST: 'GET_CMS_RESPONSE_REQUEST',
  GET_CMS_RESPONSE_FAILURE: 'GET_CMS_RESPONSE_FAILURE',
  GET_CMS_RESPONSE_SUCSESS: 'GET_CMS_RESPONSE_SUCSESS',
  SET_TOGGLE_JACKPOT_GAME_FROM_HOST: "SET_TOGGLE_JACKPOT_GAME_FROM_HOST",
  RESET_TOGGLE_JACKPOT_GAME_FROM_HOST: "RESET_TOGGLE_JACKPOT_GAME_FROM_HOST",
  GET_GOS_PLAYER_LAST_WIN_REQUEST: 'GET_GOS_PLAYER_LAST_WIN_REQUEST',
  GET_GOS_PLAYER_LAST_WIN_FAILURE: 'GET_GOS_PLAYER_LAST_WIN_FAILURE',
  GET_GOS_PLAYER_LAST_WIN_SUCSESS: 'GET_GOS_PLAYER_LAST_WIN_SUCSESS',
  GET_GOS_PLAYER_LAST_WIN_RESET: 'GET_GOS_PLAYER_LAST_WIN_RESET',
  JACKPOT_PRIZE_GENERATED_TOGGLE: 'JACKPOT_PRIZE_GENERATED_TOGGLE',
  SET_ONGOING_TOURNAMENTS_MODAL: 'SET_ONGOING_TOURNAMENTS_MODAL',
  GET_GOS_POPUPS_FROM_CMS_REQUEST: 'GET_GOS_POPUPS_FROM_CMS_REQUEST',
  GET_GOS_POPUPS_FROM_CMS_FAILURE: 'GET_GOS_POPUPS_FROM_CMS_FAILURE',
  GET_GOS_POPUPS_FROM_CMS_SUCSESS: 'GET_GOS_POPUPS_FROM_CMS_SUCSESS',
  GET_GOS_POPUPS_FROM_CMS_RESET: 'GET_GOS_POPUPS_FROM_CMS_RESET',
  UPDATE_PLAYER_RESULT: 'UPDATE_PLAYER_RESULT',
  RESET_PLAYER_RESULT: 'RESET_PLAYER_RESULT',
  SET_CATEGORY: 'SET_CATEGORY',
  GET_SPIN_AND_GO_CONFIGS_REQUEST: 'GET_SPIN_AND_GO_CONFIGS_REQUEST',
  GET_SPIN_AND_GO_CONFIGS_FAILURE: 'GET_SPIN_AND_GO_CONFIGS_FAILURE',
  GET_SPIN_AND_GO_CONFIGS_SUCSESS: 'GET_SPIN_AND_GO_CONFIGS_SUCSESS',
  GET_SPIN_AND_GO_CONFIGS_RESET: 'GET_SPIN_AND_GO_CONFIGS_RESET',
  CREATE_SPIN_AND_GO_REGISTER_REQUEST: 'CREATE_SPIN_AND_GO_REGISTER_REQUEST',
  CREATE_SPIN_AND_GO_REGISTER_FAILURE: 'CREATE_SPIN_AND_GO_REGISTER_FAILURE',
  CREATE_SPIN_AND_GO_REGISTER_SUCSESS: 'CREATE_SPIN_AND_GO_REGISTER_SUCSESS',
  CREATE_SPIN_AND_GO_REGISTER_ERROR_MESSAGE_RESET: 'CREATE_SPIN_AND_GO_REGISTER_ERROR_MESSAGE_RESET',
  CREATE_SPIN_AND_GO_REGISTER_RESET: 'CREATE_SPIN_AND_GO_REGISTER_RESET',
  CREATE_SPIN_AND_GO_UNREG_REQUEST: 'CREATE_SPIN_AND_GO_UNREG_REQUEST',
  CREATE_SPIN_AND_GO_UNREG_FAILURE: 'CREATE_SPIN_AND_GO_UNREG_FAILURE',
  CREATE_SPIN_AND_GO_UNREG_SUCSESS: 'CREATE_SPIN_AND_GO_UNREG_SUCSESS',
  CREATE_SPIN_AND_GO_UNREG_RESET: 'CREATE_SPIN_AND_GO_UNREG_RESET',
  OPEN_SPIN_AND_GO_GAME: "OPEN_SPIN_AND_GO_GAME",
  CLOSE_SPIN_AND_GO_GAME: "CLOSE_SPIN_AND_GO_GAME",
  UPDATE_PLAYER: "UPDATE_PLAYER",
  UPDATE_TOURNAMENT: "UPDATE_TOURNAMENT",
  GET_SPIN_AND_GO_INSTANCES_REQUEST: 'GET_SPIN_AND_GO_INSTANCES_REQUEST',
  GET_SPIN_AND_GO_INSTANCES_FAILURE: 'GET_SPIN_AND_GO_INSTANCES_FAILURE',
  GET_SPIN_AND_GO_INSTANCES_SUCSESS: 'GET_SPIN_AND_GO_INSTANCES_SUCSESS',
  GET_SPIN_AND_GO_INSTANCES_RESET: 'GET_SPIN_AND_GO_INSTANCES_RESET',
  GET_SPIN_AND_GO_WINNERS_REQUEST: 'GET_SPIN_AND_GO_WINNERS_REQUEST',
  GET_SPIN_AND_GO_WINNERS_FAILURE: 'GET_SPIN_AND_GO_WINNERS_FAILURE',
  GET_SPIN_AND_GO_WINNERS_SUCSESS: 'GET_SPIN_AND_GO_WINNERS_SUCSESS',
  GET_SPIN_AND_GO_WINNERS_RESET: 'GET_SPIN_AND_GO_WINNERS_RESET',

  CASH_GAMES_URL_REQUEST: "CASH_GAMES_URL_REQUEST",
  CASH_GAMES_URL_SUCCESS: "CASH_GAMES_URL_SUCCESS",
  CASH_GAMES_URL_FAILURE: "CASH_GAMES_URL_FAILURE",
  CASH_GAMES_URL_RESET: "CASH_GAMES_URL_RESET",
};

export default actionTypes;
