import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  getSelectedCurrency,
  getWallets,
} from "../../store/selectors";
import {
  enalbleAutoConvertToPlayerCurrency,
  selectCurrency,
} from "../../store/actionCreators/currency";
import "./CurrencySwitcher.scss";
import { SIGNES } from "../../constants/signes";

const CurrencySwitcher = ({ setSelectedCurrencyStorage }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const selectedCurrency = useSelector(getSelectedCurrency);
  const wallets = useSelector(getWallets);
  

  const onChangeHandler = (_, item) => {
    dispatch(enalbleAutoConvertToPlayerCurrency(item.label !== "!convert"));
    dispatch(selectCurrency(item));
    setSelectedCurrencyStorage(item);
  };

  return (
    <div className="currency-switcher">
      <div className="currency-switcher__list">
        {wallets?.length > 0 && (
          <div className={`currency-switcher__item ${wallets[0].currency === selectedCurrency?.label ? 'currency-switcher__item--active' : ''}`}>
            <input
              id={wallets[0].currency}
              className="currency-switcher__item-value"
              type="radio"
              name="currency"
              checked={wallets[0].currency === selectedCurrency?.label}
              onChange={(e) =>
                onChangeHandler(e, { label: wallets[0].currency })
              }
            />
            <label
              htmlFor={wallets[0].currency}
              className="currency-switcher__item-label-wrapper"
            >
              <span className="currency-switcher__item-label-label">{SIGNES?.[wallets[0].currency]}</span>
              <span className="currency-switcher__item-label-value">{t('displayMyCurrency')}</span>
            </label>
          </div>
        )}
        <div className={`currency-switcher__item ${"!convert" === selectedCurrency?.label ? 'currency-switcher__item--active' : ''}`}>
          <input
            className="currency-switcher__item-value"
            id="!convert"
            type="radio"
            name="currency"
            checked={"!convert" === selectedCurrency?.label}
            onChange={(e) => onChangeHandler(e, { label: "!convert" })}
          />
          <label htmlFor="!convert" className="currency-switcher__item-label-wrapper">
            <span className="currency-switcher__item-label-label">{t('displayInOriginalWay')}</span>
            <span className="currency-switcher__item-label-value">{t('multipleCurrenciesSupported')}</span>
          </label>
        </div>
      </div>
    </div>
  );
};

export default CurrencySwitcher;
