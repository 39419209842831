import types from '../actionTypes';

export const getSpinAndGoConfigsRequest = () => ({
  type: types.GET_SPIN_AND_GO_CONFIGS_REQUEST,
});

  
export const getSpinAndGoConfigsFailure = (e) => ({
  type: types.GET_SPIN_AND_GO_CONFIGS_FAILURE,
	exception: e,
});

  
export const getSpinAndGoConfigsSucsess = (payload) => ({
  type: types.GET_SPIN_AND_GO_CONFIGS_SUCSESS,
  payload,
});

  
export const getSpinAndGoConfigsReset = () => ({
  type: types.GET_SPIN_AND_GO_CONFIGS_RESET,
});
