import { memo } from "react";
import TournamentEndJackpot from "./Jackpot";
import TournamentEndRegular from "./Regular";

const TournamentEnd = (props) => {
  const Component = props?.payload?.tournamentType === "spinAndGo" ? TournamentEndJackpot : TournamentEndRegular;
  return <Component {...props} />;
};

export default memo(TournamentEnd);
