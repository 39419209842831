import { useCallback } from 'react';
import './Checkbox.scss';

const Checkbox = ({
    className,
    checkboxClassName,
    onChange,
    checked,
}) => {
    const classes = ['checkbox'];
    if (className) classes.push(className);
    if (checked) classes.push('checkbox-checked');

    const checkboxClasses = ['checkbox-field'];
    if (checkboxClassName) checkboxClasses.push(checkboxClassName);
    if (checked) checkboxClasses.push('icon-check');

    const onClick = useCallback(() => {
        if (onChange) onChange(!checked);
    }, [checked, onChange]);

    return (
        <div className={classes.join(' ')} onClick={onClick}>
            <div className={checkboxClasses.join(' ')} />
        </div>
    );
}

export default Checkbox;