import { takeEvery, put, select } from 'redux-saga/effects';
import types from '../actionTypes';
import { jacpotsRequest, updateCasinoTournamentsJackpotFromSocketRequest } from '../actionCreators/jackpot';
import { isSelectedJackpotTab } from '../selectors';


function* handleUpdateTournament({ payload }) {
  try {
    const isJackpot = yield select(isSelectedJackpotTab);
    if (isJackpot) {
      if (!payload?.notes?.includes('Auto spawn')){
        if (payload?.playerSeatsLeft !== 0) {
          yield put(updateCasinoTournamentsJackpotFromSocketRequest(payload));
        }
      } else {
        yield put(jacpotsRequest());
      }
    }

  } catch (error) {
    console.log(error);
  }
}

export function* watchUpdateTournament() {
  yield takeEvery(types.UPDATE_TOURNAMENT, handleUpdateTournament);
}
