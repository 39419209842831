import { call, take, cancel, fork, all } from "redux-saga/effects";
import types from '../actionTypes';

// GAME OPEN

function* processSpinAndGoOpenRequest() {
  try {
    console.log('skselaaaa');
  } catch (e) {
    console.log(e);
  }
}

function* watchSpinAndGoOpenRequest() {
  let forked = null;
  while (true) {
    yield take(types.OPEN_SPIN_AND_GO_GAME);
    if (forked) yield cancel(forked);
    forked = yield fork(processSpinAndGoOpenRequest);
  }
}

// GAME CLOSE

function* processSpinAndGoCloseRequest() {
  try {
    console.log('verjacellaaa');
  } catch (e) {
    console.log(e);
  }
}

function* watchSpinAndGoCloseRequest() {
  let forked = null;
  while (true) {
    yield take(types.CLOSE_SPIN_AND_GO_GAME);
    if (forked) yield cancel(forked);
    forked = yield fork(processSpinAndGoCloseRequest);
  }
}

export function* watchSpinAndGoOpenCloseStateRequest() {
  yield all([
    call(watchSpinAndGoOpenRequest),
    call(watchSpinAndGoCloseRequest),
  ]);
};
