import { useState, useEffect } from "react";
import { getRemainingTimeUntilMsTimestamp } from "../helpers/countdownTimer";

export const useCountdownTimer = (countdownTimestampMs, countdownDurationMs, onTimerEnd) => {
  const [remainingTime, setRemainingTime] = useState(null);
  const [isTimerEnd, setTimerEnd] = useState(false);
  let intervalId = null;

  useEffect(() => {
    let targetTimestampMs;

    if (countdownTimestampMs) {
      targetTimestampMs = countdownTimestampMs;
    } else if (countdownDurationMs) {
      targetTimestampMs = Date.now() + countdownDurationMs;
    }

    intervalId = setInterval(() => {
      updateRemainingTime(targetTimestampMs);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [countdownTimestampMs, countdownDurationMs]);

  const updateRemainingTime = (targetTimestampMs) => {
    const newRemainingTime = getRemainingTimeUntilMsTimestamp(targetTimestampMs);
    setRemainingTime(newRemainingTime);
    if (
      newRemainingTime.days === "00" &&
      newRemainingTime.hours === "00" &&
      newRemainingTime.minutes === "00" &&
      newRemainingTime.seconds === "00"
    ) {
      clearInterval(intervalId);
      setTimerEnd(true);
      if (onTimerEnd) {
        onTimerEnd();
      }
    }
  };

  return { remainingTime, isTimerEnd };;
};
