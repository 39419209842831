import tournament from "./images/tournament.png";
import jackpot from "./images/jackpot.png";
import wos from "./images/wos.png";
import cashTournaments from "./images/cash_tournaments.png";
import config from "../../configs/config";

export const naviagtion = [
  {
    value: "regular",
    label: "tournament",
    image: tournament,
  }
];

if (config?.naviagtion?.jackpot?.show) {
  naviagtion.push({
    value: "jackpot",
    label: "crazyPrize",
    new: true,
    image: jackpot,
    disabled: !!config?.naviagtion?.jackpot?.disabled,
  });
}

if (config?.naviagtion?.cashGames?.show) {
  naviagtion.push(  {
    value: "cashTournaments",
    label: "cashGames",
    new: true,
    image: cashTournaments,
    disabled: !!config?.naviagtion?.cashGames?.disabled,
  });
}

if (config?.naviagtion?.WOS?.show) {
  naviagtion.push(  {
    value: "wos",
    label: "wallOfSlots",
    image: wos,
    disabled: !!config?.naviagtion?.WOS?.disabled,
  });
}