import { take, fork, call, put, select, cancel } from 'redux-saga/effects';
import types from '../actionTypes';
import { apiPost } from './callApi';
import apiRoutes from '../../constants/apiRoutes';
import { getToken } from '../selectors';
import { updateCasinoTournaments } from '../actionCreators/casinoTournament';
import { casinoTournamentUnregisterSuccess, casinoTournamentUnregisterFailure } from '../actionCreators/casinoTournamentUnregister';
import { getPlayerStateInTournamentsRequest } from '../actionCreators/playerStateInTournaments';

function* casinoTournamentUnregistration({ tournamentData }) {
  try {
    const token = yield select(getToken);
    const params = {
      token,
      tournamentId: tournamentData.tournamentId,
    };
    const response = yield call(apiPost, apiRoutes.CASINO_TOURNAMENT_UNREGISTER, params);
    if (!response || !response.data) return false;
    const { tournament } = response.data.result;

    yield put(casinoTournamentUnregisterSuccess(tournament.tournamentId));
    yield put(updateCasinoTournaments(tournament));
    yield put(getPlayerStateInTournamentsRequest([tournament?.tournamentId], true));
    window.parent.postMessage({ name: "SUCCESSFULL_UNREGISTRATION", data: { id: tournament.tournamentId}}, "*");
  } catch (e) {
    yield put(casinoTournamentUnregisterFailure(e));
  }
}

export default function* watchTournamentCasinoUnregister() {
  let forked = null;
  while (true) {
    const action = yield take(types.CASINO_TOURNAMENT_UNREGISTER_REQUEST);
    if (forked) yield cancel(forked);
    yield fork(casinoTournamentUnregistration, action);
  }
};
