import TournamentRegister_v1 from "./TournamentRegister_v1";
import TournamentRegister_v2 from "./TournamentRegister_v2";
import config from "../../../configs/config";

const TournamentRegister = (props) => {
  // const Component = config?.popUpVersion_2
  //   ? TournamentRegister_v2
  //   : TournamentRegister_v1;
  return <TournamentRegister_v2 {...props} />;
};

export default TournamentRegister;
