import config from "../configs/config";
import { maskString } from "./pureFunctions";

export const socketUsersToInner = (userId) => (u, i) => {
  const nickname = u.account?.nickname || u.nickname || u.username;
  return {
    id: i,
    place: i + 1,
    name: config?.maskNickname ? maskString(nickname) :  nickname,
    unMaskedName: nickname,
    spins: u.spins,
    score: u.points,
    accountId: u.accountId,
    change: 0,
    self: Number(u.accountId) === Number(userId),
  }
};

export const transformTournamentDataForGameInfoBoard = (data, formatCurrency, t) => {
  if (!data) {
    return null
  } else {
    const newData = {
      firstColumnTitle: 'participants',
      secondColumnTitle: 'type',
      thirdColumnTitle: 'prize',
      dataInfo: data.map((item, index) => (
        { id: index + 1,
          place: item.placesFrom === item.placesTo ? item.placesFrom : `${item.placesFrom}-${item.placesTo}`,
          rowName: 'place',
          rowValue: item?.award?.type === 'percentage' ? `${item?.award?.percentage?.toFixed(2)}%` : item?.award?.type === 'tourTicket' ? t('tourTicket') : undefined,
          thirdColumnValue: item?.award?.type === 'externalPrize' ? item?.award?.url ? { url: item?.award?.url, name: item?.award?.name } : item?.award?.name : formatCurrency(item?.award?.value, item?.award?.currency, 2),
          thirdColumnIcon: item?.award?.currency === 'GOS' ?  'GOS' : null,
          self: null
        }
      ))
    }
    return newData
  }
}

export const transformTournamentGameData = (data, formatCurrency) => {
  if (!data) {
    return null
  } else {
    const newData = {
      firstColumnTitle: 'participants',
      secondColumnTitle: 'prize',
      dataInfo: data.map((item, index) => (
        { id: index + 1,
          place: item.placesFrom === item.placesTo ? item.placesFrom : `${item.placesFrom}-${item.placesTo}`,
          rowName: 'place',
          rowValue: item?.award?.type === 'externalPrize' ? item?.award?.url ? { url: item?.award?.url, name: item?.award?.name } : item?.award?.name : formatCurrency(item?.award?.value, item?.award?.currency, 2),
          secondColumnIcon: item?.award?.currency === 'GOS' ?  'GOS' : null,
          self: null
        }
      ))
    }
    return newData;
  }
}

export const transformPlayersDataForGameInfoBoard = (data) => {
  if (!data) {
    return null;
  } else {
    const newData = {
      firstColumnTitle: 'username',
      secondColumnTitle: 'score',
      dataInfo: data.map(user => ({ id: user.id, place: user?.place, rowName: user?.nickname || user.name, rowValue: user?.score, self: user?.self }))
    }
    return newData
  }
}

export const transformPlayersDataForTournamentItemInfo = (data) => {
  if (!data) {
    return null;
  } else {
    const newData = {
      firstColumnTitle: 'username',
      secondColumnTitle: 'score',
      dataInfo: data.map((user, index) => ({ id: user.accountId, place: index + 1, rowName: user?.nickname || user.username , rowValue: user?.points, self: user?.self }))
    }
    return newData
  }
}