import types from '../actionTypes';

export const createSpinAndGoUnregRequest = (configId) => ({
  type: types.CREATE_SPIN_AND_GO_UNREG_REQUEST,
  configId,
});

export const createSpinAndGoUnregFailure = (e) => ({
  type: types.CREATE_SPIN_AND_GO_UNREG_FAILURE,
	exception: e,
});

export const createSpinAndGoUnregSucsess = () => ({
  type: types.CREATE_SPIN_AND_GO_UNREG_SUCSESS,
});

  
export const createSpinAndGoUnregReset = () => ({
  type: types.CREATE_SPIN_AND_GO_UNREG_RESET,
});
