import { useTranslation } from "react-i18next";
import gitVersion from "../../../_git_commit";
import './Version.scss';

const gitInfo = gitVersion.logMessage
  ? gitVersion.logMessage
  : "version is not defined for windows dev";
const Version = ({}) => {
  const { t } = useTranslation();
  return (
    <span className="version">
      {t(`version`)}: {gitInfo}
    </span>
  );
};

export default Version;
