import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useFormatCurrency } from "../../../hooks/useFormatCurrency";
import { closeDialog } from "../../../store/actionCreators/dialogManager";
import { getCasinoTournament } from "../../../store/selectors";
import PopUpDialogTemplate from "../../common/PopUpDialogTemplate";
import PopUpItemInfo from "../../common/PopUpItemInfo";
import "./BadBet.scss";

const BadBet = ({ isMobile, isPortrait, isLandscape }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const formatCurrency = useFormatCurrency();

  const tournament = useSelector(getCasinoTournament);

  const onClose = useCallback(
    () => dispatch(closeDialog("badBet")),
    [dispatch]
  );

  return (
    <PopUpDialogTemplate
      className="bad-bet-pop-up"
      dialogName="badBetDialog"
      titleIcon="alert-triangle"
      withoutDescription
      onClose={onClose}
    >
      <div className="bad-bet">
        <div className="bad-bet__description"><span>{`${t("makeSureThatBetAmountIsCorrect")}!`}</span></div>
        <div className="bad-bet__info-row">
          <PopUpItemInfo title='betValue' value={formatCurrency(tournament.minBet, tournament.prizePool.currency)} variant='warning' />
        </div>
        <div className="bad-bet__confirm-row">
          <button className="bad-bet-confirm-button" onClick={onClose}>{t('ok')}</button>
        </div>
      </div>
    </PopUpDialogTemplate>
  );
};

export default BadBet;
