import { useTranslation } from "react-i18next";
import { naviagtion } from "../navigationConfig";
import { useDispatch, useSelector } from "react-redux";
import { getSelectedTab } from "../../../store/selectors";
import { setTab } from "../../../store/actionCreators/tab";
import { myTournamentsReset } from "../../../store/actionCreators/myTournaments";
import { classNames } from "../../../helpers/pureFunctions";
import { setCategory } from "../../../store/actionCreators/category";
import "./MobileNavigation.scss";

const MobileNavigation = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectedTab = useSelector(getSelectedTab);

  const handleTabView = (view) => {
    dispatch(setTab(view));
    dispatch(myTournamentsReset());
    dispatch(setCategory(view));
  };

  return (
    <div className="mobile-navigation">
      {naviagtion.map((nav, index) => (
        <div
          onClick={handleTabView.bind(null, nav.value)}
          key={index}
          className={classNames("mobile-navigation__item", {
            "mobile-navigation__item--active": nav.value === selectedTab,
            "mobile-navigation__item--disabled": nav?.disabled,
            "mobile-navigation__item--new": nav?.new,
          })}
        >
          <img
            src={nav.image}
            alt={t(nav.label)}
            className="mobile-navigation__image"
          />
          <span className="mobile-navigation__text">{t(nav.label)}</span>
          {(nav?.disabled || nav?.new) && (
            <div className={`mobile-navigation__label-wrapper mobile-navigation__label-wrapper--${nav?.disabled ? 'disabled' : 'new'}`}>
              <span className="mobile-navigation__label">
                {t(nav?.disabled ? "comingSoon" : "new")}
              </span>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default MobileNavigation;
