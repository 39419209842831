import { useEffect } from 'react';

export const useGoogleAnalytics = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://www.googletagmanager.com/gtag/js?id=G-F5QT2DNJMF';

    document.head.appendChild(script);

    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }

    gtag('js', new Date());
    gtag('config', 'G-F5QT2DNJMF');

    return () => {
      document.head.removeChild(script);
    };
  }, []);
};
