import { useTranslation } from "react-i18next";
import OnGoingEventsTabs from "./OnGoingEventsTabs";
import { useOnGoningEvents } from "./OnGoningEventsProvider";
import OnGoingEventsTouramentsList from "./OnGoingEventsTouramentsList";
import { classNames } from "../../../helpers/pureFunctions";
import { isMobile } from "react-device-detect";

const OnGoingEventsLayout = () => {
  const { t } = useTranslation();
  const { activeTab, closeLayout, isOpenedLayout, handleAnimationEnd, closeOnGoingEventsDialog } = useOnGoningEvents();
  return (
    <div onAnimationEnd={handleAnimationEnd} className={classNames('on-going-events-layout', { 'on-going-events-layout--hide': !isOpenedLayout })}>
      <div className="on-going-events-layout__header">
        <div className="on-going-events-layout__close-wrapper">
          <i
            className="on-going-events-layout__close icon-cross"
            onClick={isMobile ? closeLayout : closeOnGoingEventsDialog}
          />
        </div>
        <span className="on-going-events-layout__title">
          {t("ongoingTournaments")}
        </span>
        <div className="on-going-events-layout__on-going-events-tabs-wrapper">
          <OnGoingEventsTabs />
        </div>
        <span className="on-going-events-layout__description">{t(`onGoingEventsDescription.${activeTab}`)}</span>
      </div>
      <div className="on-going-events-layout__body">
        <OnGoingEventsTouramentsList />
      </div>
    </div>
  );
};

export default OnGoingEventsLayout;
