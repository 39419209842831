import { memo, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useTournamentButtonState } from "../../hooks/useTournamentButtonState";
import {
  getCurrentPlayerStateInTournaments,
  getToken,
  isPlayerStateInTournamentsPending,
  isSelectedJackpotTab,
} from "../../store/selectors";
import { openDialog } from "../../store/actionCreators/dialogManager";
import { requestTournamentGameUrl } from "../../store/actionCreators/tournamentGameUrl";
import { selectCasinoTournament } from "../../store/actionCreators/casinoTournament";
import Button from "../common/Button";
import IsMobileContext from "../../contexts/IsMobileContext";
import { getTournamentBuyinInfo } from "../../helpers/getTournamentBuyinInfo";
import { useFormatCurrency } from "../../hooks/useFormatCurrency";
import Skeleton from "../Skeleton";
import "./TournamentButtonState.scss";
import TournamentInfoState from "../TournamentInfoState";

const TournamentButtonState = ({
  tournament,
  showBuyin,
  cb,
  rubutButtonText,
  iconInsteadButton,
}) => {
  const formatCurrency = useFormatCurrency();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const token = useSelector(getToken);
  const playerStateInTournaments = useSelector(
    getCurrentPlayerStateInTournaments
  )(tournament?.tournamentId);
  const pending = useSelector(isPlayerStateInTournamentsPending);
  const isJackpot = useSelector(isSelectedJackpotTab);
  const { isMobile } = useContext(IsMobileContext);
  const { tournamentState, enrolled, maxPlayersCount, buyIn } = tournament;
  const {
    started,
    isActive,
    regStarted,
    regFinished,
    regStartsToday,
    regStartsIn,
    regStartsOn,
    canUnregister,
  } = useTournamentButtonState(tournament);
  const { formatForMainBuyin, buyinValue, rebuyValue } = getTournamentBuyinInfo(
    tournament,
    formatCurrency
  );

  const {
    canRegister,
    isRegistered,
    canResume,
    canRebuy,
    spinsLeft,
    registrationTypes,
  } = playerStateInTournaments || {};

  const onlyByTicket =
    registrationTypes?.tourTicket &&
    !registrationTypes?.realMoney &&
    !registrationTypes?.GOS;
  const allowByTicket = registrationTypes?.tourTicket;
  const allowPaymentMain = registrationTypes?.realMoney;
  const isPlayerNotRegisteredOrFinished =
    !isRegistered || (spinsLeft === 0 && !canRebuy);
  const isRegistrationNotStartedOrFinished =
    !regStarted || (regStarted && !canRegister) || regFinished;
  const isNotQualifiedForRegistration = !(
    isRegistered &&
    isActive &&
    started &&
    spinsLeft === 0 &&
    !canRebuy
  );

  const onRegister = () => {
    dispatch(openDialog({ tournament, dialogType: "tournamentRegister" }));
    if (cb) cb();
  };

  const onRebuy = () => {
    dispatch(openDialog({ tournament, dialogType: "tournamentRebuy" }));
    if (cb) cb();
  };

  const onResume = () => {
    if (isJackpot && enrolled !== tournament?.maxPlayersCount) {
      dispatch(openDialog({ dialogType: "waitingRoom", tournament }));
      return;
    }
    dispatch(requestTournamentGameUrl(tournament));
    dispatch(selectCasinoTournament(tournament));
    if (cb) cb();
  };

  const onUnregister = () => {
    dispatch(openDialog({ tournament, dialogType: "tournamentUnregister" }));
    if (cb) cb();
  };

  const onLogOutRegister = () => {
    if (window && window.parent) {
      window.parent.postMessage({ name: "openLoginForm" }, "*");
    }
  };

  const handleTicketInfo = () => dispatch(openDialog({ dialogType: "howGetFreeTickets" }));

  const variant = "primary";
  const size = isMobile ? "sm" : "";

  return (
    <div className="tournament-button-state">
      {token ? <>
            {canRegister && !onlyByTicket && (
              <>
                {iconInsteadButton ? (
                  <TournamentInfoState
                    cb={onRegister}
                    tournament={tournament}
                  />
                ) : (
                  <Button
                    onClick={onRegister}
                    size={size}
                    disabled={false}
                    variant={tournament?.specialView ? "special" : variant}
                  >
                    <span>
                      {tournament?.isPasswordProtected ? (
                        t("registrationByPassword")
                      ) : allowByTicket && allowPaymentMain ? (
                        t("buyTicketFor", { amount: formatForMainBuyin })
                      ) : buyIn?.buyin + buyIn?.fee === 0 ? (
                        t("playFree")
                      ) : showBuyin ? (
                        <span className="registerFor">
                          <span>{t("registerFor")}</span>
                          <span>{buyinValue}</span>
                        </span>
                      ) : (
                        t("registration")
                      )}
                    </span>
                    {tournament?.isPasswordProtected && (
                      <i style={{ marginLeft: "6px" }} className="icon-lock" />
                    )}
                  </Button>
                )}
              </>
            )}
            {canRegister && onlyByTicket && (
              <>
                {iconInsteadButton ? (
                  <TournamentInfoState tournament={tournament} />
                ) : (
                  <Button onClick={handleTicketInfo} size={size} variant={variant}>
                    <span>{t("registerByTicket")}</span>
                    <i style={{ marginLeft: "6px" }} className="icon-ticket" />
                  </Button>
                )}
              </>
            )}
            {((isPlayerNotRegisteredOrFinished &&
              isRegistrationNotStartedOrFinished &&
              isNotQualifiedForRegistration &&
              !canRegister) ||
              tournamentState === "finished") && (
              <>
                {iconInsteadButton ? (
                  <TournamentInfoState tournament={tournament} />
                ) : (
                  <Button size={size} disabled variant={variant}>
                    {regFinished
                      ? tournamentState === "finished"
                        ? t("finished")
                        : t("registrationClosed")
                      : regStartsToday
                      ? `${t("regOpensIn")} ${regStartsIn}`
                      : `${t("regOpensOn")} ${regStartsOn}`}
                  </Button>
                )}
              </>
            )}
            {!isRegistered &&
              regStarted &&
              !regFinished &&
              enrolled >= maxPlayersCount && (
                <>
                  {iconInsteadButton ? (
                    <TournamentInfoState tournament={tournament} />
                  ) : (
                    <Button size={size} disabled variant={variant}>
                      {t("registrationClosed")}
                    </Button>
                  )}
                </>
              )}
            {((isJackpot &&
              isRegistered &&
              tournamentState !== "finished" &&
              !(isRegistered && spinsLeft === 0 && !canRebuy)) ||
              canResume) && (
              <>
                {iconInsteadButton ? (
                  <TournamentInfoState cb={onResume} tournament={tournament} />
                ) : (
                  <Button size={size} variant={variant} onClick={onResume}>
                    {t("resume")}
                  </Button>
                )}
              </>
            )}
            {!!canRebuy && (
              <>
                {iconInsteadButton ? (
                  <TournamentInfoState cb={onRebuy} tournament={tournament} />
                ) : (
                  <Button size={size} variant={variant} onClick={onRebuy}>
                    {showBuyin ? (
                      <span className="registerFor">
                        <span>{rubutButtonText || t("rebuyFor")}</span>
                        <span>{rebuyValue}</span>
                      </span>
                    ) : (
                      rubutButtonText || t("rebuy")
                    )}
                  </Button>
                )}
              </>
            )}
            {isRegistered && isActive && spinsLeft === 0 && !canRebuy && (
              <>
                {iconInsteadButton ? (
                  <TournamentInfoState tournament={tournament} />
                ) : (
                  <Button size={size} disabled variant={variant}>
                    {t("outOfSpins")}
                  </Button>
                )}
              </>
            )}
            {canUnregister && !isJackpot && (
              <>
                {iconInsteadButton ? (
                  <TournamentInfoState
                    cb={onUnregister}
                    tournament={tournament}
                    disabled={tournament?.unregistrationDisabled}
                  />
                ) : (
                  <Button
                    disabled={tournament?.unregistrationDisabled}
                    size={size}
                    variant={variant}
                    onClick={onUnregister}
                  >
                    {tournament?.unregistrationDisabled
                      ? t("registered")
                      : t("unregister")}
                    {tournament?.unregistrationDisabled && (
                      <i
                        style={{ fontSize: 8, marginLeft: "6px" }}
                        className="icon-check"
                      />
                    )}
                  </Button>
                )}
              </>
            )}
          </> : (
        <>
          {((regStarted && !regFinished) || (isJackpot && tournament?.enrolled < tournament?.maxPlayersCount)) && (
            <>
              {iconInsteadButton ? (
                <TournamentInfoState
                  cb={onLogOutRegister}
                  tournament={tournament}
                />
              ) : (
                <Button
                  size={size}
                  variant={variant}
                  onClick={onLogOutRegister}
                >
                  {showBuyin ? (
                    <span className="registerFor">
                      <span>{t("registerFor")}</span>
                      <span>{buyinValue}</span>
                    </span>
                  ) : (
                    t("registration")
                  )}
                </Button>
              )}
            </>
          )}
          {(!(regStarted && !regFinished) && !isJackpot) && (
            <>
              {iconInsteadButton ? (
                <TournamentInfoState tournament={tournament} />
              ) : (
                <Button size={size} disabled variant={variant}>
                  {t(tournamentState)}
                </Button>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default memo(TournamentButtonState);
