import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { keepGameOpen } from "../../../store/actionCreators/gameOpenCloseState";
import { tournamentLastBetRequest } from "../../../store/actionCreators/lastBetRequest";
import { getCasinoTournament } from "../../../store/selectors";
import Button from "../../common/Button";
import ToasterTemplate from "../common/ToasterTemplate";
import './TournamentLastBet.scss';

const TournamentLastBet = ({ onClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const tournament = useSelector(getCasinoTournament);

  const clickHandler = useCallback(() => {
    dispatch(tournamentLastBetRequest(tournament.tournamentId));
    dispatch(keepGameOpen());
  }, [dispatch]);

  return (
    <ToasterTemplate onClose={onClose}>
      <ToasterTemplate.Right toasterName="tournamentLastBetToaster">
        <div className="tournament-last-bet__button-wrapper">
          <Button
            variant="primary"
            onClick={clickHandler}>
              {t('showSummary')}
            </Button>
        </div>
      </ToasterTemplate.Right>
    </ToasterTemplate>
  );
};

export default TournamentLastBet;
