import { createPortal } from 'react-dom';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isMobile, useMobileOrientation } from 'react-device-detect';

import TournamentGameMobileHeader from './TournamentGameMobileHeader';
import TournamentGameHeader from './TournamentGameHeader';
import TournamentGameWidget from './TournamentGameWidget';
import ForceFullScreen from '../../FullScreen';

import {
  requestTournamentGameUrl,
} from '../../../store/actionCreators/tournamentGameUrl';
import {
  getCasinoTournament,
  getSelectedTab,
  isSelectedJackpotTab,
} from '../../../store/selectors';
import { resetGameOpenCloseState } from '../../../store/actionCreators/gameOpenCloseState';

import { classNames } from '../../../helpers/pureFunctions';

import config from '../../../configs/config';

import './TournamentGame.scss';

import imageGif from './images/rotate.png';

import NoSleep from 'nosleep.js';
import JackpotGameSideBar from '../../Lobby/Jackpot/JackpotGameSidebar';
import JackpotGameHeader from '../../Lobby/Jackpot/JackpotGameHeader';
import { closeDialog, openDialog } from '../../../store/actionCreators/dialogManager';
import { resetPlayerResult } from '../../../store/actionCreators/playerResult';
import { exitFullScreenMode } from '../../../helpers/fullScreen';

const noSleepObj = new NoSleep();

const TournamentGame = ({ url }) => {
  const dispatch = useDispatch();

  const { isLandscape } = useMobileOrientation();

  const [gameWidget, setGameWidget] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const isJackpotTab = useSelector(isSelectedJackpotTab);
  const selectedTab = useSelector(getSelectedTab);

  const tournament = useSelector(getCasinoTournament);

  
  const handleExpandWidget = () => {
    if (showDetails) {
      setShowDetails(false);
    } else setGameWidget((gameWidget) => !gameWidget);
  };

  const onClose = useCallback(() => {
    dispatch(openDialog({ dialogType: "veloxForceCloseGame", tournamentId: tournament.tournamentId }));
  }, [tournament]);

  const handleFrameMessages = (event) => {
    const casinoCloseMessages = [
      'close',
      'closeGame',
      'notifyCloseContainer',
      'GAME_MODE:LOBBY',
    ];
    if (casinoCloseMessages.includes(event.data)) {
      ['closeGame'].includes(event.data)
        ? dispatch(requestTournamentGameUrl(tournament))
        : onClose();
    }
    if (event.data && event.data.name === 'gameStatus') {
      switch (event.data.status) {
        case 'closed':
          onClose();
          break;
        default:
          break;
      }
    }
  };

  const enableNoSleep = () => {
    noSleepObj.enable();
    document.removeEventListener('touchstart', enableNoSleep, false);
  };

  useEffect(() => {
    document.addEventListener('touchstart', enableNoSleep, false);
  }, []);

  useEffect(() => {
    if (isJackpotTab) {
      dispatch(closeDialog("waitingRoom"));
    }
  }, [isJackpotTab]);

  useEffect(() => {
    document.body.classList.add('game-open');
    window.addEventListener('message', (event) => handleFrameMessages(event));
    return () => {
      dispatch(resetGameOpenCloseState());
      dispatch(resetPlayerResult());
      exitFullScreenMode();
      document.body.classList.remove('game-open');
      window.removeEventListener('message', (event) =>
        handleFrameMessages(event)
      );
    };
  }, []);

  const gameClassName = classNames('tournament-game', {
    'tournament-game--mobile': isMobile,
    [selectedTab]: selectedTab,
  });

  const tournamentGameWidgetClasses = classNames(
    'tournament-game__widget-wrapper',
    {
      'show-sidebar': gameWidget,
      mobile: isMobile,
    }
  );

  const tournamentGameIframeWidgetWrapperClasses = classNames(
    'tournament-game__iframe-widget-wrapper',
    {
      'open-sidebar': gameWidget,
      landscape: isLandscape && isMobile,
      portrait: !isLandscape && isMobile,
    }
  );

  return createPortal(
    <>
      <div className="tournament-game__overlay" />
      {isMobile && !config.withOutForceFullScreen ? <ForceFullScreen /> : null}
      {!isLandscape && isMobile && config.disableTournamentCasinoPortrait && (
        <div className="tournament__game__rotate">
          <div className="tournament__game__rotate--img-wrapper">
            <img className="tournament__game__rotate--img" src={imageGif} />
          </div>
        </div>
      )}
      <div className={gameClassName}>
        <div className="tournament-game__content">
          {!isJackpotTab ? (
            <>
              {!isMobile && (
                <div className="tournament-game__header-wrapper">
                  <TournamentGameHeader />
                </div>
              )}
              <div className={tournamentGameIframeWidgetWrapperClasses}>
                {isMobile ? (
                  <iframe
                    allow="screen-wake-lock"
                    className="tournament-game__iframe"
                    title="Tournament Game"
                    src={url}
                    frameBorder="0"
                    width="100%"
                    height="100%"
                    allowFullScreen={!isMobile ? 'allow' : undefined}
                  ></iframe>
                ) : (
                  <iframe
                    allow="screen-wake-lock"
                    className="tournament-game__iframe"
                    title="Tournament Game"
                    src={url}
                    frameBorder="0"
                  ></iframe>
                )}
                {isMobile && (
                  <div className="tournament-game__mobile-header-wrapper">
                    <TournamentGameMobileHeader
                      onClick={handleExpandWidget}
                      gameWidgetState={gameWidget}
                    />
                  </div>
                )}
                <div className={tournamentGameWidgetClasses}>
                  <TournamentGameWidget setShowDetails={setShowDetails} />
                </div>
              </div>
            </>
          ) : (
            <div className="tournament-game__jackpot-game-header-jackpot-game-sidebar-game-wrapper">
              <div className="tournament-game__jackpot-game-header-game-wrapper">
                <div className="tournament-game__jackpot-game-header-wrapper">
                  <JackpotGameHeader tournament={tournament} onClose={onClose} />
                </div>
                <div className="tournament-game__iframe-wrapper">
                  {isMobile ? (
                    <iframe
                      allow="screen-wake-lock"
                      className="tournament-game__iframe"
                      title="Tournament Game"
                      src={url}
                      frameBorder="0"
                      width="100%"
                      height="100%"
                      allowFullScreen={!isMobile ? 'allow' : undefined}
                    ></iframe>
                  ) : (
                    <iframe
                      allow="screen-wake-lock"
                      className="tournament-game__iframe"
                      title="Tournament Game"
                      src={url}
                      frameBorder="0"
                    ></iframe>
                  )}
                </div>
              </div>
              {!isMobile && (
                <div className="tournament-game__jackpot-game-sidebar-wrapper">
                  <JackpotGameSideBar tournament={tournament} />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>,
    document.getElementById('root')
  );
};

export default TournamentGame;
