import { useTranslation } from "react-i18next";
import moment from "moment";
import "./MobileLeaderboardInfo.scss";

const MobileLeaderboardInfo = ({ tournament }) => {
  const { t } = useTranslation();
  const {
    tournamentGames: [tournamentGame],
    startDate,
    endDate,
    tournamentName,
    tournamentId,
  } = tournament;
  const notes = tournament?.extraInfo?.externalPrize ?? "";
  return (
    <div className="mobile-leaderboard-info">
      <div className="mobile-leaderboard-info__image-name-date-wrapper">
        <img
          className="mobile-leaderboard-info__image"
          src={tournamentGame?.thumbnail}
          alt="game image"
        />
        <div className="mobile-leaderboard-info__name-date-wrapper">
          <span className="mobile-leaderboard-info__name">
            {tournamentName} {`(#${tournamentId})`}
          </span>
          <span className="mobile-leaderboard-info__date">
            {moment(startDate).format("DD/MM/YYYY HH:mm")} -{" "}
            {moment(endDate).format("DD/MM/YYYY HH:mm")}
          </span>
        </div>
      </div>
      <div className="mobile-leaderboard-info__notes-wrapper">
        <span className="mobile-leaderboard-info__notes-label">
          {t("prizePool")}:
        </span>
        <span className="mobile-leaderboard-info__notes-value">{notes}</span>
      </div>
    </div>
  );
};

export default MobileLeaderboardInfo;
