import types from '../actionTypes';

export const openToaster = ({ view, id, params }) => ({
  type: types.OPEN_TOASTER,
  view,
  id,
  params
})

export const closeToaster = ({ id, view }) => ({
  type: types.CLOSE_TOASTER,
  id,
  view,
});
export const closeAllToasters = (view) => ({
  type: types.CLOSE_ALL_TOASTERS,
  view,
});
