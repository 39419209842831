import { useTranslation } from "react-i18next";

const StatisticItem = ({ item }) => {
  const { t } = useTranslation();
  return (
    <div className={`statistic-item statistic-item--${item.icon}`}>
      <div className="statistic-item__icon-wrapper">
        <div className="statistic-item__icon" />
      </div>
      <div className="statistic-item__label-value-wrapper">
        <span className="statistic-item__value">{item.value}</span>
        <span className="statistic-item__label">{t(item.label)}</span>
      </div>
    </div>
  );
};

export default StatisticItem;
