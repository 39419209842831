import moment from 'moment'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useFormatCurrency } from '../../../../hooks/useFormatCurrency'
import InfoTab from '../../../common/InfoTab'
import TournamentsHistoryTableItemResults from '../../TournamentsHistoryTableItemResults'
import './TournamentsHistoryTableItemMobile.scss'

const renderDescriptionItem = (title, value) => {
  return (
    <div className='tournament-info__description-section-item'>
      <span className='tournament-info__description-section-item--title'>{title}</span>
      <span className='tournament-info__description-section-item--value'>{value}</span>
    </div>
  )
}

const TournamentsHistoryTableItemMobile = ({data, opened, cb}) => {
  const { t } = useTranslation();
  const formatCurrency = useFormatCurrency();

  const {
    tournamentId,
    tournamentName,
    prizePool,
    totalPlayerCount,
    tournamentEndDate
  } = data

  return (
    <div className='history-table-item-mobile'>
      <div className='history-table-item-mobile-container'>
        <div className={`history-table-item-mobile-container__header ${!opened ? 'show' : 'hide'}`}>
          <div className='history-table-item-mobile-container__header-info'>
            <div className='history-table-item-mobile-container__header-info--image-section'>
              <img src={data?.tournamentGames[0]?.thumbnail} alt="" />
            </div>
            <div className='history-table-item-mobile-container__header-info--info-section'>
              <div className='tournament-info__name-section' dangerouslySetInnerHTML={{__html: `${tournamentName} (#${tournamentId})`}} />
              <div className='tournament-info__description-section'>
                {renderDescriptionItem(t('prizePool'), formatCurrency(prizePool?.value, prizePool?.currency, 2, prizePool?.type))}
                {renderDescriptionItem(t('players'), totalPlayerCount)}
                {renderDescriptionItem(t('date'), moment(tournamentEndDate).format("DD/MM/YYYY hh:mm"))}
              </div>
            </div>
          </div>
          <div className='history-table-item-mobile-container__header-btn-section' onClick={()=> cb(tournamentId)}>
            <button className='toggle-btn'>
              <i className='icon-tournaments icon-casino-tournament'/>
              <span className='toggle-btn__text'>{t(opened ? 'hideTheResults' : 'seeTheResults')}</span>
              <i className={`icon-arrow icon-arrow-right ${opened ? 'up' : 'down'}`}/>
            </button>
          </div>
          <div className="history-table-item-mobile-container__header--info-tab">
            <InfoTab text={'finished'} background="violet" size="sm" />
          </div>
        </div>
        <div className={`history-table-item-mobile-container__body ${opened ? 'show' : 'hide'}`}>
          <div className='history-table-item-mobile-container__body--item'>
            <TournamentsHistoryTableItemResults data={data} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default TournamentsHistoryTableItemMobile