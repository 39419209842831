import { lazy, Suspense, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  getMobileBanners,
  getSelectedCategory,
  getSelectedTab,
  getToken,
} from "../../../store/selectors";
import Loader from "../../common/Loader";
import Banner from "../../Banner/index,";
import BalanceWidget from "../../BalanceWidget";
import AvatarWidget from "../../AvatarWidget";
import { openDialog } from "../../../store/actionCreators/dialogManager";
import Navigation from "../../Navigation";
import config from "../../../configs/config";
import { setCategory } from "../../../store/actionCreators/category";

const MobileLobby = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const token = useSelector(getToken);
  const selectedTab = useSelector(getSelectedTab);
  const selectedCategory = useSelector(getSelectedCategory);
  const banners = useSelector(getMobileBanners);

  const openBurgerMenu = () =>
    dispatch(openDialog({ dialogType: "burgerMenu" }));

  const openHowToPlayDialog = useCallback(() => {
    config?.externalLinkHowToPlay
      ? window.open(config?.externalLinkHowToPlay, "_blank")
      : dispatch(openDialog({ dialogType: "howToPlay" }));
  }, []);

  const LazyView = lazy(() => {
    return import(
      `../${selectedTab.charAt(0).toUpperCase() + selectedTab.slice(1)}`
    );
  });

  return (
    <div className="lobby">
      {token && (
        <div className="lobby__buger-balance-avatar-wrapper">
          <div className="lobby__burger" onClick={openBurgerMenu}>
            <i className="icon-burger" />
          </div>
          <div className="lobby__balance-wdget-wrapper">
            <BalanceWidget tooltipPosition="bottom" />
          </div>
          <div className="lobby__avatar-wdget-wrapper">
            <AvatarWidget />
          </div>
        </div>
      )}
      {!config?.disableLobbyOnMobile && !selectedCategory && (
        <>
          <div
            style={{
              height: banners?.length > 3 ? 158 : 174,
              marginTop: banners?.length > 3 ? 24 : 16,
              marginBottom: banners?.length > 3 ? 40 : 32,
            }}
            className="lobby__banner-wrapper"
          >
            <Banner />
          </div>
          <div className="lobby__mobile-navigation-wrapper">
            <Navigation />
          </div>
          <div className="lobby__mobile-info-panels">
            <div onClick={openHowToPlayDialog} className="lobby__mobile-info-panel">
              <i className="lobby__mobile-info-panel-icon icon-how-to-play" />
              <span className="lobby__mobile-info-panel-text">{t('howToPlay')}</span>
            </div>
          </div>
        </>
      )}
      {(config?.disableLobbyOnMobile || selectedCategory) && (
        <>
          {!config?.disableLobbyOnMobile && <div onClick={() => dispatch(setCategory(''))} className="mobile-lobby-tools__back-to-lobby-wrapper">
            <i className="mobile-lobby-tools__back-to-lobby-icon icon-arrow-right" />
            <span className="mobile-lobby-tools__back-to-lobby-text">
              {t("backToLobby")}
            </span>
          </div>}
          <Suspense fallback={<Loader />}>
            <div className="mobile-lobby-tools__lobby-view-wrapper">
              <LazyView />
            </div>
          </Suspense>
        </>
      )}
    </div>
  );
};

export default MobileLobby;
