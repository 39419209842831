import { isMobile, useMobileOrientation } from "react-device-detect";
import { classNames } from "../../../helpers/pureFunctions";
import PortalContainer from "../PortalContainer";
import "./VeloxPopupTemplate.scss";

const VeloxPopupTemplate = ({
  className,
  onClose,
  onConfirm,
  title,
  children,
  pending,
  confirmText,
  selector,
  id,
  onCancel,
  cancelText,
}) => {
  const { isLandscape } = useMobileOrientation();

  const classes = classNames("velox-popup-template", {
    [className]: !!className,
    mobile: isMobile,
    landscape: isMobile && isLandscape,
    title,
  });

  const closeTop = () => {
    if (!!onClose) {
      onClose();
      return;
    }
    if (!!onConfirm) onConfirm();
    return;
  };

  return (
    <PortalContainer selector={selector || ""} id={id || "modal-root"}>
      <div className="velox-popup-template-wrapper">
        <div className="velox-popup-template__overlay" />
        <div className={classes}>
          <div className="velox-popup-template__header">
            <div className="velox-popup-template__header-img" />
            {title && (
              <span className="velox-popup-template__header-title">{title}</span>
            )}
            <div className="velox-popup-template__close-wrapper">
              <i
                className="velox-popup-template__close icon-cross"
                onClick={closeTop}
              />
            </div>
          </div>
          <div className="velox-popup-template__body">{children}</div>
          {(onConfirm || onCancel) && (
            <div className="velox-popup-template__footer">
              {onConfirm && (
                <button
                  disabled={pending}
                  onClick={onConfirm}
                  className="velox-popup-template__button velox-popup-template__button--confirm"
                >
                  {confirmText}
                </button>
              )}
              {onCancel && (
                <button
                  onClick={onCancel}
                  className="velox-popup-template__button velox-popup-template__button--cancel"
                >
                  {cancelText}
                </button>
              )}
            </div>
          )}
        </div>
      </div>
    </PortalContainer>
  );
};

export default VeloxPopupTemplate;
