import types from '../actionTypes';

export const selectCurrency = (payload) => ({
  type: types.CURRENCY_CHANGE,
  payload,
});

export const enalbleAutoConvertToPlayerCurrency = (payload) => ({
  type: types.ENABLE_AUTO_CONVER_TO_PLAYER_CURRENCY,
  payload
});
