import { useEffect, useCallback } from 'react';

export const useOutsideClick = (ref, useStateCB, cb) => {
  const handleClickOutside = useCallback(
    (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        if (cb) {
          cb()
        } else {
          useStateCB(false);
        }
      }
    },
    [cb, ref]
  );
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);
};
