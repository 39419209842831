import types from '../actionTypes';

export const casinoTournamentsRequest = (selecetedTab) => ({
  type: types.GET_CASINO_TOURNAMENTS_REQUEST,
  selecetedTab,
});

export const casinoTournamentsRequestWihoutDelay = ({ checkResumePopup, openRegisterPopup, setFirstTournamentAsSelected }) => ({
  type: types.GET_CASINO_TOURNAMENTS_REQUEST_WITHOUT_DELAY,
  checkResumePopup,
  openRegisterPopup,
  setFirstTournamentAsSelected,
});

export const casinoTournamentsSuccess = (payload) => ({
  type: types.GET_CASINO_TOURNAMENTS_SUCCESS,
  payload,
});

export const casinoTournamentsFailure = (e) => ({
  type: types.GET_CASINO_TOURNAMENTS_FAILURE,
  exception: e,
});

export const selectCasinoTournament = (payload) => ({
  type: types.SELECT_CASINO_TOURNAMENT,
  payload,
});

export const resetCasinoTournament = () => ({
  type: types.RESET_CASINO_TOURNAMENT
});

export const updateCasinoTournaments = (payload) => ({
  type: types.UPDATE_CASINO_TOURNAMENTS,
  payload,
});

export const casinoTournamentsReset = () => ({
  type: types.GET_CASINO_TOURNAMENTS_RESET,
});

export const casinoTournamentsUpdateWihoutDelay = () => ({
  type: types.CASINO_TOURNAMENTS_UPDATE_WITHOUT_DELAY
})
