import { useCallback, useMemo } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { openDialog, closeDialog } from '../../../store/actionCreators/dialogManager';
import { requestTournamentGameUrl } from '../../../store/actionCreators/tournamentGameUrl';
import { getCasinoTournament } from '../../../store/selectors';
import Loader from '../../common/Loader';
import { useTranslation } from 'react-i18next';
import './SuccessTournamentRebuy.scss';
import PopUpDialogTemplate from '../../common/PopUpDialogTemplate';

const SuccessTournamentRebuy = ({ isMobile, isPortrait, isLandscape }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const tournament = useSelector(getCasinoTournament);

  const onClose = useCallback(() => {
    dispatch(requestTournamentGameUrl(tournament, true));
    dispatch(closeDialog('successTournamentRebuy'));
  }, [dispatch, tournament]);

  const onSubmit = useCallback(() => {
    dispatch(requestTournamentGameUrl(tournament, true));
    dispatch(closeDialog('successTournamentRebuy'));
  }, [dispatch, onClose, tournament]);

  const canPlay = useMemo(() => tournament && !moment().isBefore(tournament.startDate), [tournament]);
  
  if (!tournament) return (<div><Loader size='small' /></div>)

  return (
    <PopUpDialogTemplate
      className="successfull-rebuy-pop-up"
      dialogName="successfullRebuyPopUp"
      withoutDescription
      onClose={onClose}
      dynamicTitle={`${tournament?.tournamentName} (#${tournament?.tournamentId})`}
      onConfirm={canPlay && onSubmit}
      confirmText={t("resume")}
      setHTML
    >
      <div className="successfull-rebuy">
        <div className="successfull-rebuy__success-icon">
          <span className="successfull-rebuy__success-icon-wrapper">
            <i className="icon-check success-icon" />
          </span>
        </div>
        <div className="successfull-rebuy__description">
          <span className="successfull-rebuy__description--text">{t('successfullyRebuyForTheTournament')}</span>
        </div>
      </div>
    </PopUpDialogTemplate>
  );
};

export default SuccessTournamentRebuy;
