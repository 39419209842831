import { useDispatch } from 'react-redux';
// import SkinLogo from '../../../../SkinLogo';
import './DesktopJackpotGameHeader.scss';
import { jacpotsRequest } from '../../../../../store/actionCreators/jackpot';
import { getSpinAndGoConfigsRequest } from '../../../../../store/actionCreators/spinAndGoConfigs';

const DesktopJackpotGameHeader = ({ onClose, tournament }) => {
  const dispatch = useDispatch();
  const onCloseGame = () => {
    onClose();
    dispatch(jacpotsRequest());
    dispatch(getSpinAndGoConfigsRequest());
  };
  const { tournamentId = '', tournamentName = '' } = tournament;

  return (
    <div className="jackpot-game-header">
      <span className="jackpot-game-header__name-id">
        {tournamentName} | {tournamentId}
      </span>
      <div onClick={onCloseGame} className="jackpot-game-header__icon-wrapper">
        <i className="icon-home" />
      </div>
    </div>
  );
};

export default DesktopJackpotGameHeader;
