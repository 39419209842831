import { isMobile } from "react-device-detect";
import MobileLeaderboardTable from "./MobileLeaderboardTable";
import DesktopLeaderboardTable from "./DesktopLeaderboardTable";

const LeaderboardTable = (props) => {
  const Component = isMobile ? MobileLeaderboardTable : DesktopLeaderboardTable;
  return <Component {...props} />;
};

export default LeaderboardTable;
