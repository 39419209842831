import TournamentFinished_v1 from "./TournamentFinished_v1";
import TournamentFinished_v2 from "./TournamentFinished_v2";
import config from "../../../configs/config";

const TournamentFinished = (props) => {
  // const Component = config?.popUpVersion_2
  //   ? TournamentFinished_v2
  //   : TournamentFinished_v1;
  return <TournamentFinished_v2 {...props} />;
};

export default TournamentFinished;
