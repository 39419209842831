import types from '../actionTypes';

export const getSpinAndGoWinnersRequest = () => ({
  type: types.GET_SPIN_AND_GO_WINNERS_REQUEST,
});

  
export const getSpinAndGoWinnersFailure = (e) => ({
  type: types.GET_SPIN_AND_GO_WINNERS_FAILURE,
	exception: e,
});

  
export const getSpinAndGoWinnersSucsess = (payload) => ({
  type: types.GET_SPIN_AND_GO_WINNERS_SUCSESS,
  payload,
});

  
export const getSpinAndGoWinnersReset = () => ({
  type: types.GET_SPIN_AND_GO_WINNERS_RESET,
});
