import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { useTranslation } from "react-i18next";

import { getToasts } from '../../store/selectors';

import './index.scss';

export const ToastManager = () => {
  const { t } = useTranslation();
  const { addToast } = useToasts();

  const toasts = useSelector(getToasts);

  const [lastIdx, setLastIdx] = useState(0);

  useEffect(() => {
    if (lastIdx < toasts.length) {
      setLastIdx(toasts.length);
      const newToasts = toasts.slice(lastIdx, toasts.length);
      newToasts.forEach(nt => {
        const { message, msgTranslation, ...options } = nt;
        addToast(<div dangerouslySetInnerHTML={{ __html: (msgTranslation || t(message)) }} />, options);
      });
    }
  }, [toasts, addToast]);

  return false;
};

export default ToastManager;
