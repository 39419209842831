import { useDispatch, useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { getCasinoTournamentPlayers, getTournamentPlayers } from '../../../../store/selectors';
import MobileJackpotGameLeaderBoard from './MobileJackpotGameLeaderBoard';
import DesktopJackpotGameLeaderBoard from './DesktopJackpotGameLeaderBoard';
import { useEffect } from 'react';
import { casinoTournamentPlayersRequest, casinoTournamentPlayersReset } from '../../../../store/actionCreators/casinoTournamentPlayers';

const JackpotGameLeaderBoard = (props) => {
  const dispatch = useDispatch();
  const playersFromSocket = useSelector(getTournamentPlayers)?.(
    props?.tournament?.tournamentId
  );
  const playersFromAPI = useSelector(getCasinoTournamentPlayers)?.(
    props?.tournament?.tournamentId
  );
  const players = playersFromSocket || playersFromAPI;
  const self = players?.find(player => player?.self);
  
  useEffect(() => {
    if (props?.tournament?.tournamentId) {
      dispatch(casinoTournamentPlayersRequest({ id: props.tournament.tournamentId, offset: 0, limit: 5 }));
    }
    return () => {
      dispatch(casinoTournamentPlayersReset());
    };
  }, [dispatch, props?.tournament?.tournamentId]);
  
  const getLeader = players?.length  && [...players?.filter(player => !player?.self), self]?.sort((a, b) => b?.score - a?.score)?.[0];

  const Component = isMobile
    ? MobileJackpotGameLeaderBoard
    : DesktopJackpotGameLeaderBoard;
  return <Component leader={getLeader} players={players} self={self} {...props} />;
};

export default JackpotGameLeaderBoard;
