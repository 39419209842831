import { useDispatch, useSelector } from 'react-redux';
import { casinoTournamentPlayersRequest } from '../../../../store/actionCreators/casinoTournamentPlayers';
import { casinoTournamentRegisterReset } from '../../../../store/actionCreators/casinoTournamentRegister';
import { openDialog } from '../../../../store/actionCreators/dialogManager';
import { resetTournamentPlayers } from '../../../../store/actionCreators/tournamentCasinoPlayers';
import { resetTournamentGameUrl } from '../../../../store/actionCreators/tournamentGameUrl';
import { getCasinoTournament } from '../../../../store/selectors';
import Loader from '../../../common/Loader';
import './TournamentGameHeader.scss';
import { resetToggleJackpotGameFromHost } from '../../../../store/actionCreators/toggleJackpotGameFromHost';

const TournamentGameHeader = () => {
  const dispatch = useDispatch();
  const tournament = useSelector(getCasinoTournament);

  const onGameClose = () => {
    dispatch(resetTournamentGameUrl());
    dispatch(resetToggleJackpotGameFromHost(false));
    dispatch(casinoTournamentRegisterReset());
    dispatch(resetTournamentPlayers());
    dispatch(casinoTournamentPlayersRequest({ id: tournament.tournamentId, offset: 0, limit: 20 }));
  };

  const openTournamentInfo = () => {
    dispatch(openDialog({ dialogType: "tournamentInformation" }));
  }

  if (!tournament) return <div><Loader size='small'/></div>;
  const { tournamentName,  tournamentId } = tournament;
  return (
    <div className="tournament-game-header">
      <div className="tournament-game-header__info">
        <span dangerouslySetInnerHTML={{__html: `${tournamentName} | ${tournamentId}`}} />
      </div>
      <div className="tournament-game-header__right-side">
        <div className="tournament-game-header__right-side--tournament-info" onClick={openTournamentInfo}>
          <span className='tournament-game-header__right-side--tournament-info-button'>?</span>
        </div>
        <div className="tournament-game-header__right-side--close-icon">
          <i className='icon-cross' onClick={onGameClose} />
        </div>
      </div>
    </div>
  );
};

export default TournamentGameHeader;