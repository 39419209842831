import { useTranslation } from "react-i18next";
import { getCasinoTournament } from "../../../store/selectors";
import ToasterTemplate from "../common/ToasterTemplate";
import './TournamentRebuyFailure.scss';

const TournamentRegisterFailure = ({ onClose }) => {
  const { t } = useTranslation();

  return (
    <ToasterTemplate onClose={onClose}>
      <ToasterTemplate.Right toasterName="tournamentRegisterFailure">
        <div className="toaster-template__info-text">{t('tournamentRegisterFailureText')}</div>
      </ToasterTemplate.Right>
    </ToasterTemplate>
  );
};

export default TournamentRegisterFailure;
