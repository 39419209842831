import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useFormatCurrency } from "../../../../hooks/useFormatCurrency";
import { closeDialog } from "../../../../store/actionCreators/dialogManager";
import { getCasinoTournament } from "../../../../store/selectors";
import PopUpDialogTemplate from "../../../common/PopUpDialogTemplate";
import "./TournamentInformation_v2.scss";

const TournamentInformation_v2 = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const tournament = useSelector(getCasinoTournament);
  const formatCurrency = useFormatCurrency();


  const onClose = useCallback(
    () => dispatch(closeDialog("tournamentInformation")),
    [dispatch]
  );
  if (!tournament) return null;

  return (
    <PopUpDialogTemplate
      className="tournament-information-pop-up"
      dialogName="tournamentInformationDialog"
      onClose={onClose}
      howToPlay
      onCloseBtn={onClose}
      closeText={t('tournamentInformationDialogConfirmButton')}
      textParams={{
        tournamentName: tournament.tournamentName,
        prize: tournament?.prizePool?.type === 'tourTicket' ? t('ticket') : formatCurrency(tournament.prizePool.value, tournament.prizePool.currency)
      }}
    >
      <div className="tournament-information-v2">
        <span className="tournament-information-v2__text">{`${t('tournamentRules')}:`}</span>
        <div className="tournament-information-v2__info-wrapper">
          <div className="tournament-information-v2__info-item">
            <span className="tournament-information-v2__info-item-label">{`${t('freeSpins')}:`}</span>
            <span className="tournament-information-v2__info-item-value">{`${tournament.actualTotalSpins} ${t('spins')}`}</span>
          </div>
          <div className="tournament-information-v2__info-item">
            <span className="tournament-information-v2__info-item-label">{`${t('allowedReBuys')}:`}</span>
            <span className="tournament-information-v2__info-item-value">{tournament.rebuyAllowed ? t('yes') : t('no')}</span>
          </div>
          <div className="tournament-information-v2__info-item">
            <span className="tournament-information-v2__info-item-label">{`${t('players')}:`}</span>
            <span className="tournament-information-v2__info-item-value">{tournament.enrolled}</span>
          </div>
        </div>
      </div>
    </PopUpDialogTemplate>
  );
};

export default TournamentInformation_v2;
