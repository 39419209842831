import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from 'react-redux';
import App from './components/App';
import store from './store';
import "./i18n";
import 'swiper/css';
import 'swiper/css/pagination';
import './styles/index.scss';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
      <Router>
        <App />
      </Router>
    </Provider>
);
