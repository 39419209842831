import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { getCasinoTournament, getTournamentPlayers } from "../../../store/selectors";
import PopUpDialogTemplate from "../../common/PopUpDialogTemplate";
import { useFormatCurrency } from "../../../hooks/useFormatCurrency";
import JackpotGameLeaderboardTable from "../../Lobby/Jackpot/JackpotGameLeaderboardTable";
import { closeDialog } from "../../../store/actionCreators/dialogManager";
import { resetTournamentGameUrl } from "../../../store/actionCreators/tournamentGameUrl";
import { resetToggleJackpotGameFromHost } from "../../../store/actionCreators/toggleJackpotGameFromHost";
import "./JackpotTournamentFinished.scss";
import { jacpotsRequest } from "../../../store/actionCreators/jackpot";
import { getSpinAndGoConfigsRequest } from "../../../store/actionCreators/spinAndGoConfigs";

const JackpotTournamentFinished = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const tournament = useSelector(getCasinoTournament);
  const players = useSelector(getTournamentPlayers)(tournament?.tournamentId);
  const self = players?.find((item) => item.self);
  const formatCurrency = useFormatCurrency();

  const onClose = useCallback(() => {
    dispatch(resetTournamentGameUrl());
    dispatch(resetToggleJackpotGameFromHost(false));
    dispatch(jacpotsRequest());
    dispatch(getSpinAndGoConfigsRequest());
    dispatch(closeDialog("jackpotTournamentFinished"));
  }, [tournament]);

  return (
    <PopUpDialogTemplate
      selector=".tournament-game__iframe-wrapper"
      className="jackpot-tournament-finished-pop-up"
      id="jackpot-tournament-finished"
      dialogName="jackpotTournamentFinishedPopUp"
      onClose={onClose}
      onCloseBtn
      closeText={t('goToLobby')}
    >
      <div className="jackpot-tournament-finished">
        <div className="jackpot-tournament-finished__box-wrapper">
          <span className="jackpot-tournament-finished__box-label">
            {t("prize")}
          </span>
          <span className="jackpot-tournament-finished__box-value">
            {formatCurrency(
              tournament?.prizePool?.value,
              tournament?.prizePool?.currency
            )}
          </span>
        </div>
        <div className="jackpot-tournament-finished__jackpot-game-leader-board-table-wrapper">
          <JackpotGameLeaderboardTable players={players} self={self} tournament={tournament} isGame />
        </div>
      </div>
    </PopUpDialogTemplate>
  );
};

export default JackpotTournamentFinished;
