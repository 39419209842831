import { useState } from "react";
import { useDispatch } from "react-redux";
import { closeDialog, openDialog } from "../store/actionCreators/dialogManager";
import config from "../configs/config";

export const useMobileMenu = () => {
  const dispatch = useDispatch();
  const [isClosed, setisClosed] = useState(false);

  function closeBurgerMenu() {
    setisClosed(true);
  }

  const openHowToPlayDialog = () => {
    closeBurgerMenu();
    config?.externalLinkHowToPlay
    ? window.open(config?.externalLinkHowToPlay, '_blank')
    : dispatch(openDialog({ dialogType: "howToPlay" }));
  };

  const openHistoryDialog = () => {
    closeBurgerMenu();
    dispatch(openDialog({ dialogType: "tournamentsHistory" }));
  };

  const openCurrencyExchangeDialog = () => {
    closeBurgerMenu();
    dispatch(openDialog({ dialogType: "currencyExchange" }));
  };

  const openChangeAvatarDialog = () => {
    closeBurgerMenu();
    dispatch(openDialog({ dialogType: "userSettings" }));
  };

  const openTermsAndConditions = () => {
    config?.externalLinkTermsAndConditions
      ? window.open(config?.externalLinkTermsAndConditions, '_blank')
      : dispatch(openDialog({ dialogType: "termsAndConditions" }));
    closeBurgerMenu();
  };

  const handleAnimationEnd = () => {
    if (isClosed) {
      dispatch(closeDialog("burgerMenu"));
    }
  };

  const menu = [
    { icon_text: "?", text: "howToPlay", action: openHowToPlayDialog, permission: true },
    {
      icon: "history-2",
      text: "history",
      isCapitalized: true,
      action: openHistoryDialog,
      permission: true
    },
    {
      icon: "terms",
      text: "terms&Conditions",
      isCapitalized: true,
      action: openTermsAndConditions,
      permission: true
    },
    {
      icon: "changes",
      text: "currency",
      isCapitalized: true,
      action: openCurrencyExchangeDialog,
      permission: config?.mulicurrency?.enableCurrencySwitcherMenu
    },
    { icon: "settings", text: "changeAvatar", action: openChangeAvatarDialog, permission: true },
  ];

  return { menu, isClosed, handleAnimationEnd, closeBurgerMenu };
};
