import { useMemo } from "react";
import LeaderboardTopLeadCard from "../LeaderboardTopLeadCard";
import "./LeaderboardTopLeads.scss";
import Skeleton from "../../../Skeleton";
import { isMobile } from "react-device-detect";

const LeaderboardTopLeads = ({
  computeAwardArray,
  players,
  avatars,
  isPlayersPending,
}) => {
  const leads = useMemo(
    () => players?.slice(0, Math.min(computeAwardArray()?.length, 3)),
    [players, computeAwardArray()]
  );

  return (
    <div className="leaderboard-top-leads">
      {isPlayersPending ? <div className="leaderboard-top-leads__skeleton-list">
            <div className="leaderboard-top-leads__skeleton-wrapper">
              <Skeleton
                width={isMobile ? "100%" : "246px"}
                height={isMobile ? 123 : 150}
                radius={8}
              />
            </div>
            <div className="leaderboard-top-leads__skeleton-wrapper">
            <Skeleton
                width={isMobile ? "100%" : "246px"}
                height={isMobile ? 123 : 150}
                radius={8}
              />
            </div>
            <div className="leaderboard-top-leads__skeleton-wrapper">
            <Skeleton
                width={isMobile ? "100%" : "246px"}
                height={isMobile ? 123 : 150}
                radius={8}
              />
            </div>
          </div> : <div className="leaderboard-top-leads__list">
          {leads?.map((player, index) => (
            <div
              key={player?.accountId}
              className="leaderboard-top-leads__leaderboard-top-lead-card-wrapper"
            >
              <LeaderboardTopLeadCard
                item={{
                  ...player,
                  ...computeAwardArray()?.[index],
                  image: avatars[player.accountId]?.imagePath,
                  position: index + 1,
                }}
              />
            </div>
          ))}
      </div>}
      
    </div>
  );
};

export default LeaderboardTopLeads;
