import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './Input.scss';

const Input = ({ placeholder, value, callbackFn, className, type, style, showPasswordToggle }) => {
  const { t } = useTranslation();
  const [showPass, setShowPass] = useState(false);

  const onChangeValue = (e) => {
    callbackFn && callbackFn(e)
  };

  return (
    <form className='input__form' onSubmit={e => e.preventDefault()} >
      <div style={style ?? {}} className='input__wrapper'>
        <input
          type={showPasswordToggle && type === 'password' ? showPass ? 'text' : 'password' : type ?? 'text'}
          className={`input ${className ?? ''}`}
          placeholder={t(placeholder)}
          value={value || ''}
          onChange={onChangeValue}
          autoComplete="false"
        />
        {showPasswordToggle && (
          <i
            className='icon-eye input__eye'
            onClick={() => setShowPass(prev => !prev)}
          />
        )}
      </div>
    </form>
  );
};

export default Input;
