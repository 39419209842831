import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { classNames } from "../../helpers/pureFunctions";
import { closeDialog } from "../../store/actionCreators/dialogManager";
import { getCasinoTournamentsHistoryTotalCount, getCasinoTournamentsHistoryTournaments } from "../../store/selectors";
import TournamentsHistoryTableItem from "./TournamentsHistoryTableItem";
import { Pagination } from 'antd';
import { casinoTournamentsHistoryRequest, casinoTournamentsHistoryReset } from "../../store/actionCreators/casinoTournamentHistory";
import config from "../../configs/config";
import "./TournamentsHistoryPage.scss";
import EmptyList from "../EmptyList";
import { useResetState } from "../../hooks/useResetState";
import Tabs from "../Tabs";

const HISTORY_TABLE_CAPTIONS = ["tournament", "prizePool", "players", "date"];
const COUNT_IN_EACH_PAGE = 7;

const TournamentsHistoryPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  useResetState(backToTournaments);
  const [openItemID, setOpenItemID] = useState(null);
  const [page, setPage] = useState(1);
  const [tab, setTab] = useState('regular');

  const casinoTournamentsHistoryTournaments = useSelector(getCasinoTournamentsHistoryTournaments)(tab);
  const casinoTournamentsHistoryTotalCount = useSelector(getCasinoTournamentsHistoryTotalCount)(tab);


  const changePageHandler = (currentPage) => {
    setPage(currentPage);
  };
  
  const pageContainerClasses = classNames("tournaments-history-page", {
    mobile: isMobile,
  });

  function backToTournaments() {
    dispatch(closeDialog("tournamentsHistory"));
  }

  const accordionToggleHandler = (id) => {
    id === openItemID ? setOpenItemID(null) : setOpenItemID(id)
  };

  useEffect(() => {
    dispatch(casinoTournamentsHistoryRequest(1, config.tournamentsHistoryPageItemsLimit));
    return () => dispatch(casinoTournamentsHistoryReset());
  }, []);

  return (
    <div className={pageContainerClasses}>
      <div className="tournaments-history-page__close-icon" onClick={backToTournaments}>
        <i className="icon icon-cross" />
      </div>
      <div className="tournaments-history-page__title">
        <span>{t("allTournaments")}</span>
      </div>
      <div className="tournaments-history-page__tabs-wrapper">
        <Tabs cb={() => setPage(1)} externalTabSetter={setTab} externalTab={tab} />
      </div>
      {casinoTournamentsHistoryTournaments?.length ? <><div className="tournaments-history-page__history-table">
        {!isMobile && (
          <div className="tournaments-history-page__history-table--header">
            {HISTORY_TABLE_CAPTIONS.map((caption) => (
              <span key={caption} className={caption}>{t(caption)}</span>
            ))}
            <span className="toggler"></span>
          </div>
        )}
        <div className="tournaments-history-page__history-table--body">
          {casinoTournamentsHistoryTournaments.slice(COUNT_IN_EACH_PAGE * (page - 1), COUNT_IN_EACH_PAGE * page)?.map((item) => (
            <div key={item?.tournamentId} className="tournaments-history-page__table-item">
              <TournamentsHistoryTableItem
                data={item}
                opened={openItemID === item?.tournamentId}
                cb={accordionToggleHandler}
              />
            </div>
          ))}
        </div>
      </div>
      <div className="tournaments-history-page__pagination-section">
        <Pagination
          defaultCurrent={1}
          pageSize={COUNT_IN_EACH_PAGE}
          total={casinoTournamentsHistoryTotalCount}
          showSizeChanger={false}
          current={page}
          onChange={changePageHandler}
          className="history-pagination"
        />
      </div></> : <div className="tournaments-history-page__empty-list-wrapper">
        <EmptyList textKey="noHistoryToShow" />
      </div>}
    </div>
  );
};

export default TournamentsHistoryPage;
