import { useMemo, memo } from "react";
import { useTranslation } from "react-i18next";
import { useFormatCurrency } from "../../../../../hooks/useFormatCurrency";
import Skeleton from "../../../../Skeleton";
import GOSPoint from "../../../../GOSPoint";
import "./MobileLeaderboardTable.scss";

const MobileLeaderboardTable = ({
  computeAwardArray,
  players,
  avatars,
  isPlayersPending,
  isAvatarPending,
}) => {
  const { t } = useTranslation();
  const formatCurrency = useFormatCurrency();
  const items = useMemo(
    () => players?.slice(Math.min(computeAwardArray()?.length, 3)),
    [players, computeAwardArray()]
  );

  if (!items?.length && !isPlayersPending) return null;


  return (
    <div className="mobile-leaderboard-table">
      {isPlayersPending ? (
        <div className="mobile-leaderboard-table__skeleton-list">
          <div className="mobile-leaderboard-table__skeleton-wrapper">
            <Skeleton count={1} width="100%" height={63} radius={8} />
          </div>
          <div className="mobile-leaderboard-table__skeleton-wrapper">
            <Skeleton count={1} width="100%" height={63} radius={8} />
          </div>
          <div className="mobile-leaderboard-table__skeleton-wrapper">
            <Skeleton count={1} width="100%" height={63} radius={8} />
          </div>
        </div>
      ) : (
        items?.map((item, index) => (
          <div key={item?.accountId} className="mobile-leaderboard-table__row">
            <div className="mobile-leaderboard-table__avatar-pos-username-wrapper">
              {isAvatarPending ? (
                <div className="mobile-leaderboard-table__avatar-skeleton-wrapper">
                  <Skeleton width={34} height={34} rounded />
                </div>
              ) : avatars?.[item.accountId]?.imagePath ? (
                <img
                  className="mobile-leaderboard-table__avatar"
                  src={avatars?.[item.accountId]?.imagePath}
                  alt="avatar"
                />
              ) : (
                <div className="mobile-leaderboard-table__default-avatar">
                  <i className="icon-avatar" />
                </div>
              )}
              <div className="mobile-leaderboard-table__pos-username-wrapper">
                <span className="mobile-leaderboard-table__pos">
                  #{Math.min(computeAwardArray()?.length, 3) + index + 1}
                </span>
                <span className="mobile-leaderboard-table__username">
                  {item?.unMaskedName}
                </span>
              </div>
            </div>
            <div className="mobile-leaderboard-table__label-value-wrapper">
              <div className="mobile-leaderboard-table__label-value-wrapper-inner">
                <span className="mobile-leaderboard-table__label">
                  {t("points")}
                </span>
                <span className="mobile-leaderboard-table__value">
                  {item?.points}
                </span>
              </div>
            </div>
            <div className="mobile-leaderboard-table__label-value-wrapper">
              <div className="mobile-leaderboard-table__label-value-wrapper-inner">
                <span className="mobile-leaderboard-table__label">
                  {t("prize")}
                </span>
                <span className="mobile-leaderboard-table__value">
                  {computeAwardArray()?.[
                    Math.min(computeAwardArray()?.length, 3) + index
                  ] &&
                  computeAwardArray()?.[
                    Math.min(computeAwardArray()?.length, 3) + index
                  ]?.currency === "GOS" ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                      }}
                      className="GOSPoint"
                    >
                      <GOSPoint width={12} />
                      <span style={{ marginLeft: 4 }}>
                        {
                          computeAwardArray()?.[
                            Math.min(computeAwardArray()?.length, 3) + index
                          ]?.value
                        }
                      </span>
                    </div>
                  ) : (
                    computeAwardArray()?.[Math.min(computeAwardArray()?.length, 3) + index]?.type === "externalPrize" ? computeAwardArray()?.[Math.min(computeAwardArray()?.length, 3) + index]?.url ? <a target="_blank" href={computeAwardArray()?.[Math.min(computeAwardArray()?.length, 3) + index]?.url} >{computeAwardArray()?.[Math.min(computeAwardArray()?.length, 3) + index]?.name}</a> : computeAwardArray()?.[Math.min(computeAwardArray()?.length, 3) + index]?.name : formatCurrency(
                      computeAwardArray()?.[
                        Math.min(computeAwardArray()?.length, 3) + index
                      ]?.value,
                      computeAwardArray()?.[
                        Math.min(computeAwardArray()?.length, 3) + index
                      ]?.currency,
                      2,
                      computeAwardArray()?.[
                        Math.min(computeAwardArray()?.length, 3) + index
                      ]?.type
                    )
                  )}
                </span>
              </div>
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default memo(MobileLeaderboardTable);
