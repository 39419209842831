import { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentAvatar, getUserId } from "../../store/selectors";
import { getUsersAvatarRequest } from "../../store/actionCreators/avatars";
import "./Avatar.scss";

const Avatar = ({ size }) => {
  const dispatch = useDispatch();
  const userId = useSelector(getUserId);
  const avatar = useSelector(getCurrentAvatar)(+userId);

  useEffect(() => {
    dispatch(getUsersAvatarRequest({ userIds: [+userId] }));
  }, []);

  return (
    <div
      className={`avatar ${avatar?.imagePath ? '' : 'avatar--default-avatar'}`}
      style={{ width: size || 30, height: size || 30, fontSize: size ? size / 2.5 : 12 }}
    >
      {!avatar?.imagePath && <i className="icon-avatar" />}
      {avatar?.imagePath && <img src={avatar.imagePath} alt="Avatar" />}
    </div>
  );
};

export default memo(Avatar);
