import { memo } from "react";
import GOSPointImage from "./gos-point.svg";
import "./GOSPoint.scss";

const GOSPoint = ({ height }) => {
  return (
    <img
      className="gos-point"
      style={{ height: height || 14 }}
      src={GOSPointImage}
      alt="gos-point"
    />
  );
};

export default memo(GOSPoint);
