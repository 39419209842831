import { createSelector as cs } from "reselect";
import moment from "moment";
import { isStartsIn, prop } from "../helpers/pureFunctions";


const casinoTournament = prop("casinoTournament");
const jackpots = prop("jackpots");
const queryParams = prop("queryParams");
const dialogManager = prop("dialogManager");
const casinoTournamentRegister = prop("casinoTournamentRegister");
const casinoTournamentUnregister = prop("casinoTournamentUnregister");
const selectedCasinoTournament = prop("selectedCasinoTournament");
const tournamentGameUrl = prop("tournamentGameUrl");
const cashGameUrl = prop("cashGameUrl");
const tournamentPlayers = prop("tournamentPlayers");
const casinoTournamentPlayersAPI = prop("casinoTournamentPlayers");
const getAccountBalance = prop("getAccountBalance");
const openedToasters = prop('openedToasters');
const gameOpenCloseState = prop('gameOpenCloseState');
const spinAndGoGameOpenCloseState = prop('spinAndGoGameOpenCloseState');
const actualLanguage = prop('actualLanguage');
const casinoTournamentRebuy = prop("casinoTournamentRebuy");
const casinoTournamentsHistory = prop("casinoTournamentsHistory");
const currency = prop('currency');
const localExchangeRates = prop('localExchangeRates');
const messages = prop('messages');
const avatarMenu = prop('avatarMenu');
const usersAvatar = prop('usersAvatar');
const myTournaments = prop('myTournaments');
const tab = prop('tab');
const category = prop('category');
const toggleJackpotGameFromHost = prop('toggleJackpotGameFromHost');
const jackpotJoinedPlayers = prop('jackpotJoinedPlayers');
const selectedTournament = prop('selectedTournament');
const gosPlayerLastWin = prop('gosPlayerLastWin');
const jackpotPrizeGenerationBool = prop('jackpotPrizeGenerationBool');
const onGoingTournamentsModal = prop('onGoingTournamentsModal');
const gosPopupsFromCMS = prop('gosPopupsFromCMS');
const playerStateInTournaments = prop('playerStateInTournaments');
const CMSResponse = prop('CMSResponse');
const playerResult = prop('playerResult');
const spinAndGoConfigs = prop('spinAndGoConfigs');
const spinAndGoRegister = prop('spinAndGoRegister');
const spinAndGoUnreg = prop('spinAndGoUnreg');
const spinAndGoInstances = prop('spinAndGoInstances');
const spinAndGoWinners = prop('spinAndGoWinners');

export const getSpinAndGoWinners = cs(spinAndGoWinners, prop('data'));
export const isSpinAndGoWinnersPending = cs(spinAndGoWinners, prop('pending'));
export const isSpinAndGoWinnersFailed = cs(spinAndGoWinners, prop('failed'));

export const getSpinAndGoInstances = cs(spinAndGoInstances, prop('data'));
export const isSpinAndGoInstancesPending = cs(spinAndGoInstances, prop('pending'));
export const isSpinAndGoInstancesFailed = cs(spinAndGoInstances, prop('failed'));

export const isSpinAndGoUnregPending = cs(spinAndGoUnreg, prop('pending'));
export const isSpinAndGoUnregFailed = cs(spinAndGoUnreg, prop('failed'));

export const isSpinAndGoRegisterPending = cs(spinAndGoRegister, prop('pending'));
export const isSpinAndGoRegisterFailed = cs(spinAndGoRegister, prop('failed'));

export const isSpinAndGoConfigsPending = cs(spinAndGoConfigs, prop('pending'));
export const isSpinAndGoConfigsFailed = cs(spinAndGoConfigs, prop('failed'));
export const getSpinAndGoConfigs = cs(spinAndGoConfigs, prop('data'));

export const getGosPopupsFromCMS = cs(gosPopupsFromCMS, prop('data'));
export const getDesktopGosPopupsFromCMS = cs(getGosPopupsFromCMS, prop('desktop'));
export const getMobileGosPopupsFromCMS = cs(getGosPopupsFromCMS, prop('mobile'));

export const getIsOngoingTournamentsModalOpen = cs(onGoingTournamentsModal, prop('isOngoingTournamentsModalOpen'));

export const isGosPlayerLastWinPending = cs(gosPlayerLastWin, prop('pending'));
export const getGosPlayerLastWinData = cs(gosPlayerLastWin, prop('data'));

export const getSelectedTab = cs(tab, (state) => state);
export const getSelectedCategory = cs(category, (state) => state);
export const getToggleJackpotGameFromHost = cs(toggleJackpotGameFromHost, prop('toggle'));
export const getToggleJackpotGameIdFromHost = cs(toggleJackpotGameFromHost, prop('id'));
export const isSelectedJackpotTab = cs(tab, (state) => state === 'jackpot');
export const getJackpotJoinedPlayers = cs(jackpotJoinedPlayers, (state) => state);
export const getSelectedTournament = cs(selectedTournament, (state) => state);

export const getPlayerResult = cs(playerResult, prop('data'));

export const isPlayerStateInTournamentsPending = cs(playerStateInTournaments, prop('pending'));
export const getPlayerStateInTournaments = cs(playerStateInTournaments, prop('data'));

export const getCurrentPlayerStateInTournaments = cs(
  getPlayerStateInTournaments,
  (a) => (id) => a?.find(item => item?.tournamentId === id),
);

export const getUsersAvatars = cs(usersAvatar, prop('usersAvatar'));
export const isUsersAvatarsPending = cs(usersAvatar, prop('pending'));

export const getCurrentAvatar = cs(
  getUsersAvatars,
  (a) => (id) => a?.[id]
);
export const getMyTournamentsToggleStatus = cs(
  myTournaments,
  tab,
  (a, t) => a?.[t]?.toggle,
);

export const getAvatarMenu = cs(avatarMenu, prop('avatarMenu'));
export const isAvatarMenuPending = cs(avatarMenu, prop('pending'));

export const getMessages =  cs(messages, prop('messages'));

export const getSplashMessages = cs(
  getMessages,
  (mss) => mss.filter(m => m.type === 'splash' && m.status === 'unread'),
);

export const isLocalExchangeRatesPending = cs(localExchangeRates, prop('pending'));
export const isLocalExchangeRatesFailed = cs(localExchangeRates, prop('failed'));
export const getLocalExchangeRates = cs(localExchangeRates, prop('data'));



export const getIsAutoConvertToPlayerCurrencyEnabled = cs(currency, prop('isAutoConvertToPlayerCurrencyEnabled'));
export const getSelectedCurrency = cs(currency, prop('selectedCurrency'));

export const getToasts = prop("toasts");

export const getCasinoTournaments = cs(
  casinoTournament,
  prop("casinoTournaments")
);

export const getResumeTournaments = cs(
  getPlayerStateInTournaments,
  getCasinoTournaments,
  (a, b) => {
    const canResumeTournamentIds = a?.filter(item => item?.canResume)?.map(item => item?.tournamentId) || [];
    return b?.filter(item => canResumeTournamentIds?.includes(item?.tournamentId));
  }
);

export const getRebuyTournaments = cs(
  getPlayerStateInTournaments,
  getCasinoTournaments,
  (a, b) => {
    const canRebuyTournamentIds = a?.filter(item => item?.canRebuy)?.map(item => item?.tournamentId) || [];
    return b?.filter(item => canRebuyTournamentIds?.includes(item?.tournamentId));
  }
);
export const getRegisteredUpcomingTournaments = cs(
  getCasinoTournaments,
  (a) => {
    return a?.filter(item => isStartsIn(item?.startDate) && item?.playerStatus === 'registered') || [];
  }
);

export const getJackpots = cs(
  jackpots,
  prop("jackpots")
);

export const getSatellites = cs(
  getCasinoTournaments,
  (t) => (id) => t?.filter(item => item?.targetTournament?.instanceId === id),
);

export const getTagetTournament = cs(
  getCasinoTournaments,
  (t) => (id) => {
    const tournament = t?.find(item => item?.targetTournament && item?.tournamentId === id);
    if (tournament) return t.find(item => tournament?.targetTournament?.instanceId === item.tournamentId)
  },
);

export const getUpcomigTournaments = cs(
  getCasinoTournaments,
  t => t?.filter(({ endDate }) => moment(endDate).isAfter(moment()))
);
export const isCasinoTournamentsPending = cs(casinoTournament, prop("pending"));
export const isJackpotsPending = cs(jackpots, prop("pending"));

export const getSkinId = cs(queryParams, prop("skinId"));
export const getUserId = cs(queryParams, prop("userId"));
export const getUsername = cs(queryParams, prop("username"));
export const getToken = cs(queryParams, prop("token"));
export const getLanguage = cs(queryParams, prop("lang"));
export const getWebsiteName = cs(queryParams, prop("websiteName"));
export const getAllSharedToursSeparately = cs(queryParams, prop("getAllSharedToursSeparately"));
export const getShowHeaderStatistics = cs(queryParams, prop("showHeaderStatistics"));
export const getShowFlags = cs(queryParams, prop("showFlags"));
export const getShowFormattedAmountWithDot = cs(queryParams, prop("showFormattedAmountWithDot"));
export const getGameDaddyLibrary = cs(queryParams, prop("gameDaddyLibrary"));
export const getOpenedToasters = cs(openedToasters, (state) => state);
export const getGameOpenCloseState = cs(gameOpenCloseState, (state) => state);
export const getSpinAndGoGameOpenCloseState = cs(spinAndGoGameOpenCloseState, (state) => state);
export const getActualLanguage = cs(actualLanguage, (state) => state);
export const getCasinoTournamentsHistory = cs(casinoTournamentsHistory, (state) => state.casinoTournamentsHistory)
export const isJackpotPrizeGenerated = cs(jackpotPrizeGenerationBool, (state) => state);

export const getDialogs = cs(dialogManager, prop("dialogs"));

export const isCMSResponsePending = cs(CMSResponse, prop('pending'));
export const getCMSResponse = cs(CMSResponse, prop('data'));

export const getDesktopBanners = cs(getCMSResponse, (b) => b?.banners?.desktopBannerURL);
export const getMobileBanners = cs(getCMSResponse, (b) => b?.banners?.mobileBannerURL);

export const getDesktopHowToPlayContent = cs(getCMSResponse, (h) => (language) => h?.[language]?.howToPlay?.desktopHowToPlay);
export const getMobileHowToPlayContent = cs(getCMSResponse, (h) => (language) => h?.[language]?.howToPlay?.mobileHowToPlay);

export const getDesktopHowToPlayContentJackpot = cs(getCMSResponse,  (h) => (language) => h?.[language]?.howToPlay?.desktopHowToPlayJackpot);
export const getMobileHowToPlayContentJackpot = cs(getCMSResponse,  (h) => (language) => h?.[language]?.howToPlay?.mobileHowToPlayJackpot);

export const getTermsAndConditions = cs(getCMSResponse, (b) => (language) => b?.[language]?.termsAndConditions);

export const getCasinoTournament = cs(
  selectedCasinoTournament,
  prop("selectedTournament")
);
export const getRegisterErrorMessage = cs(
  casinoTournamentRegister,
  prop("failMessage")
);
export const getRegisterErrorData = cs(
  casinoTournamentRegister,
  prop("failData")
);

export const getSpinAndGORegisterErrorMessage = cs(
  spinAndGoRegister,
  prop("failMessage")
);
export const getSpinAndGORegisterErrorData = cs(
  spinAndGoRegister,
  prop("failData")
);

export const isRegisterPending = cs(
  casinoTournamentRegister,
  prop("pending")
);
export const isUnRegisterPending = cs(
  casinoTournamentUnregister,
  prop("pending")
);

export const getRegisteredCasinoTournament = cs(
  casinoTournamentRegister,
  prop("casinoTournament")
);

export const getTournamentGameUrl = cs(tournamentGameUrl, prop("url"));
export const getOpenTournamentGameId = cs(tournamentGameUrl, prop("tournamentId"));

export const getCashGameUrl = cs(cashGameUrl, prop("url"));
export const getOpenCashGameId = cs(cashGameUrl, prop("cashGameId"));

export const getTournamentPlayers = cs(
  tournamentPlayers,
  (p) => (id) => p[id]?.playersList
);
export const getPlayer = cs(
  tournamentPlayers,
  (p) => (id) => p[id]?.playersList.find((s) => s.self)
);

export const getCasinoTournamentPlayers = cs(
  casinoTournamentPlayersAPI,
  (p) => (id) => p?.[id]?.players
);

export const getPlayerPositionFromAPI = cs(
  casinoTournamentPlayersAPI,
  getUserId,
  (playersState, userId) => (id) => {
    const players = playersState[id]?.players || [];
    return players.find((s) => +userId === +s.accountId);
  }
);

export const isCasinoTournamentPlayersPending = cs(
  casinoTournamentPlayersAPI,
  prop("pending")
);

export const getWallets = cs(getAccountBalance, prop("wallets"));
export const isGetWalletsPending = cs(getAccountBalance, prop("pending"));
export const getGOSWallet = cs(getWallets, (wallets) =>
  wallets.find((w) => w?.currency === "GOS")
);
export const getWallet = cs(
  getWallets,
  (wallets) => (currency) => wallets.find((w) => w?.currency === currency)
);

export const getCurrentExchangeRate = cs(
  getLocalExchangeRates,
  (l) => (currency) => l?.find(item => item?.currencyCode === currency), 
);

export const getCurrentLanguage = cs(
  getActualLanguage,
  getLanguage,
  (actual, paramsLanguage) => actual ? actual : paramsLanguage

)

export const getRebuyErrorMessage = cs(
  casinoTournamentRebuy,
  prop("failMessage")
);

export const getRebuyErrorData = cs(
  casinoTournamentRebuy,
  prop("failData")
);

export const isRebuyPending = cs(
  casinoTournamentRebuy,
  prop("pending")
);

export const getCasinoTournamentsHistoryTournaments = cs(
  getCasinoTournamentsHistory,
  (data) => (type) => data?.casinoTournamentHistory?.[type] || null
);

export const getCasinoTournamentsHistoryTotalCount = cs(
  getCasinoTournamentsHistory,
  (data) => (type) => data?.totalCount?.[type] || null
);