import { all, call, cancel, fork, put, select, take } from "redux-saga/effects";
import { apiGetMedia, apiPostMedia } from "./callApi";
import apiRoutes from "../../constants/apiRoutes";
import { getAvatarMenuFailure, getAvatarMenuSucsess, getUsersAvatarFailure, getUsersAvatarSucsess, setAvatarFailure, setAvatarSucsess } from "../actionCreators/avatars";
import types from '../actionTypes';
import { getToken, getUserId } from "../selectors";

// get avatar menu

function* processGetAvatarMenuRequest() {
  try {
    const token = yield select(getToken);
    const response = yield call(apiGetMedia, apiRoutes.GET_AVATAR_MENU, { token });
    if (response.data?.result?.avatarMenu?.length) {
      yield put(getAvatarMenuSucsess(response.data.result.avatarMenu));
    }
  } catch (e) {
    yield put(getAvatarMenuFailure(e));
  }
}

function* watchGetAvatarMenuRequest() {
  let forked = null;
  while (true) {
    yield take(types.GET_AVATAR_MENU_REQUEST);
    if (forked) yield cancel(forked);
    forked = yield fork(processGetAvatarMenuRequest);
  }
}

 // set avatar


 function* processPostSetAvatarRequest({ imagePath }) {
  try {
    const token = yield select(getToken);
    const userId = yield select(getUserId);

    const params = { name: '', imagePath, token };

    yield call(apiPostMedia, apiRoutes.SET_AVATAR, params);

    yield put(setAvatarSucsess([+userId]));
  } catch (e) {
    yield put(setAvatarFailure(e));
  }
}

function* watchSetAvatarRequest() {
  let forked = null;
  while (true) {
    const action = yield take(types.SET_AVATAR_REQUEST);
    if (forked) yield cancel(forked);
    forked = yield fork(processPostSetAvatarRequest, action);
  }
}

// get users avatars

function* processGetUsersAvatarRequest({ userIds }) {
  try {
    if (userIds?.length) {
      const response = yield call(apiPostMedia, apiRoutes.GET_AVATARS, { accounts: userIds });
      if (response.data?.result?.avatars && Object.keys(response.data.result.avatars).length) {
        yield put(getUsersAvatarSucsess(response.data.result.avatars));
      }
    }
  } catch (e) {
    yield put(getUsersAvatarFailure(e));
  }
}

function* watchGetUsersAvatarRequest() {
  let forked = null;
  while (true) {
    const action = yield take([
      types.GET_USERS_AVATAR_REQUEST,
      types.GET_CASINO_TOURNAMENT_PLAYERS_SUCCESS,
      types.SET_AVATAR_SUCSESS,
      types.TOURNAMENT_PLAYER_UPDATE,
    ]);
    if (action.type === types.TOURNAMENT_PLAYER_UPDATE && forked) {
      // If a forked instance already exists, don't fork again
      continue;
    }
    if (forked) yield cancel(forked);
    forked = yield fork(processGetUsersAvatarRequest, action);
  }
}

export default function* watchAvatarRequsets() {
  yield all([
    call(watchGetAvatarMenuRequest),
    call(watchGetUsersAvatarRequest),
    call(watchSetAvatarRequest),
  ]);
};
