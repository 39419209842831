import {
  createContext,
  useContext,
  useReducer,
  useMemo,
  useCallback,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getRebuyTournaments,
  getRegisteredUpcomingTournaments,
  getResumeTournaments,
} from "../../../store/selectors";
import { closeDialog } from "../../../store/actionCreators/dialogManager";
import { useEffect } from "react";

const OnGoningEvents = createContext();

const TABS = ["play", "rebuy", "upcoming"];

const initial = {
  tabs: TABS,
  activeTab: TABS[0],
  isOpenedLayout: true,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_ACTIVE_TAB": {
      return {
        ...state,
        activeTab: action.payload,
      };
    }
    case "CLOSE_LAYOUT": {
      return {
        ...state,
        isOpenedLayout: false,
      };
    }
    default: {
      return state;
    }
  }
};

const OnGoningEventsProvider = ({ children }) => {
  const [{ tabs, activeTab, isOpenedLayout }, dispatch] = useReducer(reducer, initial);
  const resumeTournaments = useSelector(getResumeTournaments);
  const rebuyTournaments = useSelector(getRebuyTournaments);
  const registeredUpcomingTournaments = useSelector(getRegisteredUpcomingTournaments);
  const reduxDispatch = useDispatch();

  const tournaments = useMemo(() => {
    return {
      play: resumeTournaments || [],
      rebuy: rebuyTournaments || [],
      upcoming: registeredUpcomingTournaments || [],
    };
  }, [resumeTournaments, rebuyTournaments]);

  
  useEffect(() => {
    if (tournaments?.play.length) {
      handleSetActiveTab('play');
      return;
    }
    if (!tournaments?.play.length && tournaments?.rebuy.length) {
      handleSetActiveTab('rebuy');
      return;
    }
    if (!tournaments?.play.length && !tournaments?.rebuy.length && tournaments?.upcoming.length) {
      handleSetActiveTab('upcoming');
      return;
    }
  }, [tournaments?.play, tournaments?.rebuy, tournaments?.upcoming]);

  const handleSetActiveTab = useCallback((payload) => {
    if (!tournaments[payload].length) return;
    dispatch({ type: "SET_ACTIVE_TAB", payload });
  }, [tournaments]);

  const closeLayout = useCallback(() => dispatch({ type: 'CLOSE_LAYOUT' }));

  const closeOnGoingEventsDialog = () => reduxDispatch(closeDialog('onGoingEvents'));

  const handleAnimationEnd = useCallback(() => {
    if (!isOpenedLayout) {
      closeOnGoingEventsDialog();
    }
  }, [isOpenedLayout])

  const values = useMemo(() => {
    return {
      isOpenedLayout,
      tournaments,
      currentTournamentList: tournaments[activeTab],
      tabs,
      activeTab,
      closeOnGoingEventsDialog,
      handleAnimationEnd,
      handleSetActiveTab,
      closeLayout,
    };
  }, [isOpenedLayout, tournaments, tabs, activeTab, handleAnimationEnd, handleSetActiveTab, closeLayout]);

  return (
    <OnGoningEvents.Provider value={values}>{children}</OnGoningEvents.Provider>
  );
};

const useOnGoningEvents = () => useContext(OnGoningEvents);

export { OnGoningEventsProvider, useOnGoningEvents };
