import { take, fork, put, call, select, cancel } from "redux-saga/effects";
import { apiGetLobby } from "./callApi";
import types from "../actionTypes";
import { getSkinId, getUserId, getToken, getLanguage } from "../selectors";
import apiRoutes from "../../constants/apiRoutes";
import settings from "../../configs/settings";
import {
  failCashGameUrl,
  successCashGameUrl,
} from "../actionCreators/cashGameUrl";
import { isMobile } from "react-device-detect";

function* getCashGameUrlSagas({ game }) {
  try {
    const token = yield select(getToken);
    const skinId = yield select(getSkinId);
    const userId = yield select(getUserId);
    const lang = yield select(getLanguage);
    if (game) {
      const tournamentGame = game.tournamentGames[0];
      const params = {
        token,
        skinId,
        lang: lang || "en",
        userId: userId || null,
        device: isMobile ? "mobile" : "desktop",
        id: tournamentGame["id"],
      };

      if (tournamentGame.integrator === "silver") {
        params.lobbyUrl = settings.SILVER_HOME_URL;
      } else if (
        ["Endorphina"].includes(tournamentGame.providerName) ||
        (tournamentGame.integrator === "xpress" &&
          tournamentGame.provider === "EVOLUTION")
      ) {
        params.lobbyUrl = window.location.origin.toString();
      }

      const response = yield call(apiGetLobby, apiRoutes.GET_GAME_URL, params);

      if (response.data?.success && response.data?.result?.data?.url) {
        yield put(
          successCashGameUrl(
            {
              url: response.data?.result?.data?.url,
              cashGameId: game?.tournamentId,
            },
            response.data.result?.width
          )
        );
      } else {
        yield put(failCashGameUrl("Something went wrong"));
      }
    }
  } catch (e) {
    yield put(failCashGameUrl(e));
  }
}

export default function* watchRequestCashGamesUrl() {
  let forked = null;
  while (true) {
    const action = yield take(types.CASH_GAMES_URL_REQUEST);
    if (forked) yield cancel(forked);
    yield fork(getCashGameUrlSagas, action);
  }
}
