import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { openDialog } from '../../../../../store/actionCreators/dialogManager';
import './HowToPlayPageLink.scss';
import config from '../../../../../configs/config';

const HowToPlayPageLink = () => {

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const openHowToPlayDialog = () => {
        config?.externalLinkHowToPlay
        ? window.open(config?.externalLinkHowToPlay, '_blank')
        : dispatch(openDialog({ dialogType: "howToPlay" }));
    }

    return (
        <div className='link-widget-wrapper' onClick={openHowToPlayDialog}>
            <span className='link-widget-wrapper__text'>
                {t('howToPlay')}
                <span className='link-widget-wrapper__icon'><i className='icon-arrow-top-right-box' /></span>
            </span>
        </div>
    )
}

export default HowToPlayPageLink