import moment from "moment";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getTournamentBuyinInfo } from "../../../helpers/getTournamentBuyinInfo";
import { classNames } from "../../../helpers/pureFunctions";
import { useFormatCurrency } from "../../../hooks/useFormatCurrency";
import { tournamentRebuyRequest } from "../../../store/actionCreators/casinoTournamentRebuy";
import { closeDialog } from "../../../store/actionCreators/dialogManager";
import { getCurrentExchangeRate, getSelectedCurrency, getWallet, getWallets, isRebuyPending } from "../../../store/selectors";
import PopUpDialogTemplate from "../../common/PopUpDialogTemplate";
import TournamentItemDetailInfo from "../../common/TournamentItemDetailInfo";
import "./TournamentRebuy.scss";

const TournamentRebuy = ({ tournament, isMobile ,isPortrait ,isLandscape }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const formatCurrency = useFormatCurrency();
  
  const {
    rebuy,
  } = tournament;
  
  const [details, setDetails] = useState(false);
  const [radioOption, setRadioOption] = useState("userBalance");
  const GOSWallet = useSelector(getWallet)("GOS");
  const mainWallet = useSelector(getWallet)(rebuy?.currency);
  const wallets = useSelector(getWallets);
  const pending = useSelector(isRebuyPending);
  const {
    allowRebuyMain,
    allowRebuyGOSPoint,
    formatForMainRebuy,
    GOSPointRebuyinWithoutIcon,
    rebuyValue,
    formatForGOSPointBuyinWithoutIcon,
  } = getTournamentBuyinInfo(tournament, formatCurrency);
    const selectedCurrency = useSelector(getSelectedCurrency);
    const currentExchangeRate = useSelector(getCurrentExchangeRate)(rebuy?.currency);

    const currentStructure = useMemo(() => {
      const { enrolled, tournamentAward } = tournament || {};
      const { structure } = tournamentAward || {};
      const defaultStructure = structure?.[0];
      return enrolled === 0 || structure?.length === 1
        ? defaultStructure
        : structure.find(({ playersCount }) =>
            isBetweenOrEqual(enrolled, playersCount.min, playersCount.max)
          ) || structure[structure.length - 1];
    }, [tournament]);

  const onClose = useCallback(
    () => dispatch(closeDialog("tournamentRebuy")),
    [dispatch]
  );

  const onSubmit = useCallback(() => {
    dispatch(
      tournamentRebuyRequest({
        ...tournament,
        paymentType: allowRebuyGOSPoint
        ? allowRebuyMain
          ? radioOption === "vipPoints" ? 'GOS' : 'realMoney'
          : 'GOS'
        : 'realMoney',
      })
    );
    onClose();
  }, [dispatch, onClose, tournament, radioOption, allowRebuyGOSPoint, allowRebuyMain]);

  const onChangeHandler = (e) => {
    setRadioOption(e.target.value);
  };

  const tournamentRebuyClassNames = classNames('tournament-rebuy', {
    'tournament-rebuy--portrait': isMobile && isPortrait,
    'tournament-rebuy--landscape': isMobile && isLandscape,
  });

  if(!mainWallet && !GOSWallet) return null;

  return (
    <PopUpDialogTemplate
      className="tournament-rebuy-pop-up"
      dialogName="tournamentRebuyDialog"
      onClose={onClose}
      dynamicTitle={tournament.tournamentName}
      setHTML
    >
      <div className={tournamentRebuyClassNames}>
        <span className="tournament-rebuy__text">{`${t('currentResults')}:`}</span>
        <div className="tournament-rebuy__info-wrapper">
          <div className="tournament-rebuy__info-item">
            <span className="tournament-rebuy__info-item-label">{`${t('position')}:`}</span>
            <span className="tournament-rebuy__info-item-value">{tournament?.playerPosition}</span>
          </div>
          <div className="tournament-rebuy__info-item">
            <span className="tournament-rebuy__info-item-label">{`${t('score')}:`}</span>
            <span className="tournament-rebuy__info-item-value">{tournament?.playerPoints}</span>
          </div>
          <div className="tournament-rebuy__info-item">
            <span className="tournament-rebuy__info-item-label">{`${t('awardedPositions')}:`}</span>
            <span className="tournament-rebuy__info-item-value">{currentStructure?.awards?.at(-1)?.placesTo}</span>
          </div>
        </div>
        <div style={{ marginTop: details ? 20 : 0 }} className="tournament-rebuy__rebuy-info-wrapper">
          {details && <>
            <p className="tournament-rebuy__rebuy-info-wrapper--text">{t('rebuyMentionedText')}</p>
            <p className="tournament-rebuy__rebuy-info-wrapper--sub-text">{t('rebuyDetails')}</p>
            <div className="tournament-rebuy__rebuy-info-wrapper--tournament-details">
              <TournamentItemDetailInfo title={t('price')} info={rebuyValue} first last={!!isMobile && !!isPortrait} />
              <TournamentItemDetailInfo title={t('spins')} info={rebuy?.spins} first={!!isMobile && !!isPortrait} last={!!isMobile && !!isPortrait} />
              <TournamentItemDetailInfo title={t('endDate')} info={`${moment(tournament?.endDate).format('HH:mm | DD/MM/YY')}`} first={!!isMobile && !!isPortrait} last={!!isMobile && !!isPortrait} />
              <TournamentItemDetailInfo title={`# ${t('availableRebuysCount')}`} info={tournament?.rebuysLeft} last first={!!isMobile && !!isPortrait} />
            </div>
          </>}
        </div>
        <div onClick={() => setDetails(prev => !prev)} className="tournament-rebuy__details-wrapper">
          <span>{t(details ? 'hideDetails' : 'seeDetails')}</span>
          <i
            className="icon-arrow-right"
            style={{
              transform: `rotate(${details ? '-': ''}90deg) translateX(${details ? '-': ''}1px)`
            }}
          />
        </div>
        {+wallets?.[0]?.balance >= 0 && allowRebuyMain && !allowRebuyGOSPoint && (
          <div
          className={`tournament-register-v2__wallets-section--item tournament-register-v2__wallets-section--item--rate ${
            radioOption === "userBalance" ? "selected" : ""
          }`}
        >
          <div className="tournament-register-v2__wallets-section--item-label-value-wrapper">
            <div className="tournament-register-v2__wallets-section--item-label">
              <label htmlFor="userBalance">
                <span className="wallets-section--item-label__title">{`${t(
                  "mainBalance"
                )}:`}</span>
                <span className="wallets-section--item-label__balance">
                  {formatCurrency(wallets?.[0]?.balance, wallets?.[0]?.currency)}
                </span>
              </label>
            
            </div>
            <div className="tournament-register-v2__wallets-section--item-value">
              {formatForMainRebuy}
            </div>
          </div>
          {(selectedCurrency?.label === '!convert' && currentExchangeRate?.baseCurrencyCode !== currentExchangeRate?.currencyCode) && (
            <span className="tournament-register-v2__wallets-section--item-rate-info">
              {formatCurrency((rebuy?.cost + rebuy?.fee), rebuy?.currency)} = {formatCurrency((rebuy?.cost + rebuy?.fee) / currentExchangeRate?.exchangeRate, currentExchangeRate?.baseCurrencyCode)}
            </span>
          )}
        </div>
        )}
        {(+mainWallet?.balance >= 0 || +GOSWallet?.balance >= 0) && allowRebuyMain && allowRebuyGOSPoint && (
            <>
              {+wallets?.[0]?.balance >= 0 && (
                <div
                  className={`tournament-register-v2__wallets-section--item tournament-register-v2__wallets-section--item--rate ${
                    radioOption === "userBalance" ? "selected" : ""
                  }`}
                >
                  <div className="tournament-register-v2__wallets-section--item-label-value-wrapper">
                    <div className="tournament-register-v2__wallets-section--item-label">
                      <input
                        onChange={onChangeHandler}
                        checked={radioOption === "userBalance"}
                        value="userBalance"
                        type="radio"
                        name="balanceOption"
                        id="userBalance"
                      />
                      <label htmlFor="userBalance">
                        <span className="wallets-section--item-label__title">{`${t(
                          "mainBalance"
                        )}:`}</span>
                        <span className="wallets-section--item-label__balance">
                          {formatCurrency(wallets?.[0]?.balance, wallets?.[0]?.currency)}
                        </span>
                      </label>
                    
                    </div>
                    <div className="tournament-register-v2__wallets-section--item-value">
                      {formatForMainRebuy}
                    </div>
                  </div>
                  {(selectedCurrency?.label === '!convert' && currentExchangeRate?.baseCurrencyCode !== currentExchangeRate?.currencyCode) && (
                    <span className="tournament-register-v2__wallets-section--item-rate-info">
                      {formatCurrency((rebuy?.cost + rebuy?.fee), rebuy.currency)} = {formatCurrency((rebuy?.cost + rebuy?.fee) / currentExchangeRate?.exchangeRate, currentExchangeRate?.baseCurrencyCode)}
                    </span>
                  )}
                </div>
               )}
              {+GOSWallet?.balance >= 0 && (
                <div
                  className={`tournament-register-v2__wallets-section--item ${
                    radioOption === "vipPoints" ? "selected" : ""
                  }`}
                >
                  <div className="tournament-register-v2__wallets-section--item-label">
                    <input
                      onChange={onChangeHandler}
                      checked={radioOption === "vipPoints"}
                      value="vipPoints"
                      type="radio"
                      name="balanceOption"
                      id="vipPoints"
                    />
                    <label htmlFor="vipPoints">
                      <div className="wallets-section--item-label-value">
                        <div className="wallets-section--item-label__title">{`${t(
                          "vipPoints"
                        )}:`}</div>
                        <div className="wallets-section--item-label__balance">
                          {formatForGOSPointBuyinWithoutIcon(GOSWallet.balance)}
                        </div>
                      </div>
                    </label>
                  </div>
                  <div className="tournament-register-v2__wallets-section--item-value">
                    {GOSPointRebuyinWithoutIcon}
                  </div>
                </div>
              )}
            </>
          )}
                  <div className="tournament-rebuy__rebuy-info-wrapper--rebuy-button">
            <button disabled={pending} className="rebuy-confirm-button" onClick={onSubmit}>
              {t('rebuy')} {!(allowRebuyMain && allowRebuyGOSPoint) && rebuyValue}
            </button>
          </div>
        <p className="tournament-rebuy__description">{t('rebuyPointsWillBeAddedtoCurrentResult')}</p>
        <span className="tournament-rebuy__tournament-id">#{tournament?.tournamentId}</span>
      </div>
    </PopUpDialogTemplate>
  );
};

export default TournamentRebuy;
