import { call } from "redux-saga/effects";
import axios from "axios";
import settings from "../../configs/settings";

const GET = "GET";
const POST = "POST";
const PATCH = "PATCH";

export default function* callApi(url, path, method, options, headers) {
  let token = null;

  const to = `${url}${path}`;
  const axiosConfig = { method, url: to };
  const params = { ...options };

  const hs = headers || {};

  if (params.token || token) {
    hs["x-access-token"] = params.token || token;
    delete params.token;
  }

  axiosConfig.headers = hs;

  if (options) axiosConfig[method === "GET" ? "params" : "data"] = params;

  const result = yield call(axios, axiosConfig);

  return result;
}

export function* apiGet(to, params, headers, flags) {
  return yield call(callApi, settings.apiUrl, to, GET, params, headers, flags);
}
export function* apiGetLobbyURL(to, params, headers, flags) {
  return yield call(callApi, settings.GOS_LOBBY_URL, to, GET, params, headers, flags);
}
export function* apiGetReport(to, params, headers, flags) {
  return yield call(callApi, settings.reportUrl, to, GET, params, headers, flags);
}

export function* apiGetMedia(to, params, headers, flags) {
  return yield call(callApi, settings.MEDIA_URL, to, GET, params, headers, flags);
}

export function* apiPostMedia(to, params, headers, flags) {
  return yield call(callApi, settings.MEDIA_URL, to, POST, params, headers, flags);
}

export function* apiGetLobby(to, params, headers, flags) {
  return yield call(callApi, settings?.LOBBY || settings?.apiUrl, to, GET, params, headers, flags);
}

export function* accountingApiGet(to, params, headers, flags) {
  return yield call(
    callApi,
    settings.ACCOUNTING_API,
    to,
    GET,
    params,
    headers,
    flags
  );
}

export function* apiPost(to, params, headers, flags) {
  return yield call(callApi, settings.apiUrl, to, POST, params, headers, flags);
}

export function* callMsgApi(to, params, headers, flags) {
  return yield call(callApi, settings.MESSAGES_URL, to, PATCH, params, headers, flags);
}
export function* callMsgApiGet(to, params, headers, flags) {
  return yield call(callApi, settings.MESSAGES_URL, to, GET, params, headers, flags);
}
export function* apiGetFileStorage(to, params, headers, flags) {
  return yield call(callApi, settings.FILE_STORAGE_ADDRESS, to, GET, params, headers, flags);
}
