import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeDialog } from "../../../store/actionCreators/dialogManager";
import PopUpDialogTemplate from "../../common/PopUpDialogTemplate";
import { getLanguage, getTermsAndConditions } from "../../../store/selectors";
import "./TermsAndConditions.scss";

const TermsAndConditions = () => {
  const dispatch = useDispatch();
  const language = useSelector(getLanguage);
  const termsAndConditions = useSelector(getTermsAndConditions)(language);

  const handleClose = useCallback(() => dispatch(closeDialog('termsAndConditions')));

  return (
    <PopUpDialogTemplate
      className="terms-and-conditions-pop-up"
      dialogName="termsAndConditionsPopUp"
      withoutDescription
      onClose={handleClose}
    >
      <div className="terms-and-conditions">
        <div className="terms-and-conditions__list" dangerouslySetInnerHTML={{ __html: termsAndConditions }} />
      </div>
    </PopUpDialogTemplate>
  );
};

export default TermsAndConditions;
