import types from '../actionTypes';

export const updatePlayerResult = (payload) => ({
  type: types.UPDATE_PLAYER_RESULT,
  payload,
});

export const resetPlayerResult = (payload) => ({
  type: types.RESET_PLAYER_RESULT,
  payload,
});
