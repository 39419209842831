import types from '../actionTypes';

export const createSpinAndGoRegisterRequest = (tournamentData) => ({
  type: types.CREATE_SPIN_AND_GO_REGISTER_REQUEST,
  tournamentData,
});

  
export const createSpinAndGoRegisterFailure = (e, tournamentData) => ({
  type: types.CREATE_SPIN_AND_GO_REGISTER_FAILURE,
	exception: e,
  failMessage: e.response?.data?.message?.key,
  failData: tournamentData,
});

  
export const createSpinAndGoRegisterSucsess = () => ({
  type: types.CREATE_SPIN_AND_GO_REGISTER_SUCSESS,
});

export const createSpinAndGoRegisterErrorMessageReset = () => ({
  type: types.CREATE_SPIN_AND_GO_REGISTER_ERROR_MESSAGE_RESET,
});

export const createSpinAndGoRegisterReset = () => ({
  type: types.CREATE_SPIN_AND_GO_REGISTER_RESET,
});
