// TODO: Давайте разделим содержимое этого файла по их функциональному предназначению.

import moment from "moment";

export const pipe =
  (...fns) =>
  (x) =>
    fns.reduce((v, f) => f(v), x);

export const prop = (key) => (obj) => obj?.[key];

export const classNames = (...args) => {
  const classes = [];

  for (let i = 0; i < args.length; i++) {
    const arg = args[i];

    if (arg) {
      if (typeof arg === "object") {
        for (const key in arg) {
          if (arg[key]) {
            classes.push(key);
          }
        }
      } else {
        classes.push(arg);
      }
    }
  }

  return classes.join(" ").trim();
};

export const formatNumber = (number) => {
  if (!number) return 0;
  return Intl.NumberFormat("de-DE").format(number);
};


export const generateId = (length) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for ( let i = 0; i < (length ?? 5); i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const maskString = (str) => {
  if(!str && typeof str !== 'string') return;
  let firstTwoLetters;
  let lastTwoLetters;
  if (str.length < 7) {
    firstTwoLetters = str.slice(0, 2);
    lastTwoLetters = str.slice(-1);
  } else {
    firstTwoLetters = str.slice(0, 3);
    lastTwoLetters = str.slice(-2);
  }
  return `${firstTwoLetters}***${lastTwoLetters}`;
}

export const isBetweenOrEqual = (num, lower, upper) => num >= lower && num <= upper;

export const filterObjectById = (obj, id) => {
  const filteredObj = Object.fromEntries(
    Object.entries(obj).filter(([key]) => key === id)
  );
  return filteredObj;
}

export const isNumber = (value) => !Number.isNaN(Number(value));

export const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

export const isStartsIn = (startDate) => moment(startDate).isAfter(moment());

export const updateImageSrc = (htmlString, domain) => {
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = htmlString;
  const imgTags = tempDiv.querySelectorAll('img');

  imgTags.forEach(imgTag => {
    const relativeSrc = imgTag.getAttribute('src');
    if (relativeSrc) {
      const absoluteSrc = `${domain}${relativeSrc}`;
      imgTag.setAttribute('src', absoluteSrc);
    }

    const srcset = imgTag.getAttribute('srcset');
    if (srcset) {
      const updatedSrcset = srcset
        .split(',')
        .map(srcsetItem => {
          const parts = srcsetItem.trim().split(' ');
          const relativeSrcset = parts[0];
          const absoluteSrcset = `${domain}${relativeSrcset}`;
          return `${absoluteSrcset} ${parts.slice(1).join(' ')}`;
        })
        .join(', ');
      imgTag.setAttribute('srcset', updatedSrcset);
    }
  });

  return tempDiv.innerHTML;
}


export const debounce = (func, delay) => {
  let timeoutId;
  return function (...args) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => func.apply(this, args), delay);
  };
};


export const getRandomElement = (arr) => {
  if (arr.length === 0) return undefined;
  const randomIndex = Math.floor(Math.random() * arr.length);
  return arr[randomIndex];
}