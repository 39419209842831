import { useMemo, memo } from "react";
import { useTranslation } from "react-i18next";
import { useFormatCurrency } from "../../../../../hooks/useFormatCurrency";
import Skeleton from "../../../../Skeleton";
import "./DesktopLeaderboardTable.scss";
import GOSPoint from "../../../../GOSPoint";

const DesktopLeaderboardTable = ({
  computeAwardArray,
  players,
  avatars,
  isPlayersPending,
  isAvatarPending,
}) => {
  const { t } = useTranslation();
  const formatCurrency = useFormatCurrency();
  const items = useMemo(
    () => players?.slice(Math.min(computeAwardArray()?.length, 3)),
    [players, computeAwardArray()]
  );

  if (!items?.length && !isPlayersPending) return null;

  return (
    <div className="leaderboard-table">
      {isPlayersPending ? (
        <div className="leaderboard-table__skeleton-list">
          <div className="leaderboard-table__skeleton-wrapper">
            <Skeleton radius={8} count={1} height={62} width="100%" />
          </div>
          <div className="leaderboard-table__skeleton-wrapper">
            <Skeleton radius={8} count={1} height={62} width="100%" />
          </div>
          <div className="leaderboard-table__skeleton-wrapper">
            <Skeleton radius={8} count={1} height={62} width="100%" />
          </div>
        </div>
      ) : (
        <>
          <div className="leaderboard-table__header">
            <span className="leaderboard-table__header-col leaderboard-table__header-col--pos">
              {t("positionShort")}
            </span>
            <span className="leaderboard-table__header-col leaderboard-table__header-col--player">
              {t("player")}
            </span>
            <span className="leaderboard-table__header-col leaderboard-table__header-col--points">
              {t("points")}
            </span>
            <span className="leaderboard-table__header-col leaderboard-table__header-col--prize">
              {t("prize")}
            </span>
          </div>
          <div className="leaderboard-table__body">
            {items?.map((item, index) => (
              <div
                key={item?.accountId}
                className="leaderboard-table__body-row"
              >
                <div className="leaderboard-table__body-col leaderboard-table__body-col--pos">
                  <span>
                    #{Math.min(computeAwardArray()?.length, 3) + index + 1}
                  </span>
                  {isAvatarPending ? (
                    <div className="leaderboard-table__body-col-avatar-skeleton-wrapper">
                      <Skeleton width={40} height={40} rounded />
                    </div>
                  ) : avatars?.[item.accountId]?.imagePath ? (
                    <img
                      src={avatars?.[item.accountId]?.imagePath}
                      alt="avatar"
                    />
                  ) : (
                    <div className="leaderboard-table__body-col-avatar">
                      <i className="icon-avatar" />
                    </div>
                  )}
                </div>
                <span className="leaderboard-table__body-col leaderboard-table__body-col--player">
                  {item?.unMaskedName}
                </span>
                <span className="leaderboard-table__body-col leaderboard-table__body-col--points">
                  {item?.points}
                </span>
                <span className="leaderboard-table__body-col leaderboard-table__body-col--prize">
                  {computeAwardArray()?.[
                    Math.min(computeAwardArray()?.length, 3) + index
                  ] &&
                  computeAwardArray()?.[
                    Math.min(computeAwardArray()?.length, 3) + index
                  ]?.currency === "GOS" ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                      }}
                      className="GOSPoint"
                    >
                      <GOSPoint width={12} />
                      <span style={{ marginLeft: 4 }}>
                        {
                          computeAwardArray()?.[
                            Math.min(computeAwardArray()?.length, 3) + index
                          ]?.value
                        }
                      </span>
                    </div>
                  ) : (
                    computeAwardArray()?.[Math.min(computeAwardArray()?.length, 3) + index]?.type === "externalPrize" ? computeAwardArray()?.[Math.min(computeAwardArray()?.length, 3) + index]?.url ? <a target="_blank" href={computeAwardArray()?.[Math.min(computeAwardArray()?.length, 3) + index]?.url} >{computeAwardArray()?.[Math.min(computeAwardArray()?.length, 3) + index]?.name}</a> : computeAwardArray()?.[Math.min(computeAwardArray()?.length, 3) + index]?.name : formatCurrency(
                      computeAwardArray()?.[
                        Math.min(computeAwardArray()?.length, 3) + index
                      ]?.value,
                      computeAwardArray()?.[
                        Math.min(computeAwardArray()?.length, 3) + index
                      ]?.currency,
                      2,
                      computeAwardArray()?.[
                        Math.min(computeAwardArray()?.length, 3) + index
                      ]?.type
                    )
                  )}
                </span>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default memo(DesktopLeaderboardTable);
