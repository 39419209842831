import moment from "moment";
import { useMemo, useState } from "react";
import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getTournamentBuyinInfo } from "../../../../helpers/getTournamentBuyinInfo";
import { useFormatCurrency } from "../../../../hooks/useFormatCurrency";
import { tournamentRebuyRequest } from "../../../../store/actionCreators/casinoTournamentRebuy";
import { casinoTournamentRegisterReset } from "../../../../store/actionCreators/casinoTournamentRegister";
import { closeDialog } from "../../../../store/actionCreators/dialogManager";
import { resetGameOpenCloseState } from "../../../../store/actionCreators/gameOpenCloseState";
import { closeToaster } from "../../../../store/actionCreators/toaster";
import { resetTournamentGameUrl } from "../../../../store/actionCreators/tournamentGameUrl";
import { getCasinoTournament, getCurrentExchangeRate, getGameOpenCloseState, getSelectedCurrency, getWallet, getWallets, isRebuyPending, isSelectedJackpotTab } from "../../../../store/selectors";
import PopUpDialogTemplate from "../../../common/PopUpDialogTemplate";
import TournamentItemDetailInfo from "../../../common/TournamentItemDetailInfo";
import "./TournamentFinished_v2.scss";
import { resetToggleJackpotGameFromHost } from "../../../../store/actionCreators/toggleJackpotGameFromHost";
import { jacpotsRequest } from "../../../../store/actionCreators/jackpot";
import { getSpinAndGoConfigsRequest } from "../../../../store/actionCreators/spinAndGoConfigs";

const TournamentFinished_v2 = ({ info, isMobile, isPortrait }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const keepGameOpen = useSelector(getGameOpenCloseState);
  const tournament = useSelector(getCasinoTournament);
  const { rebuy } = tournament;
  const [details, setDetails] = useState(false);
  const formatCurrency = useFormatCurrency();
  const [radioOption, setRadioOption] = useState("userBalance");
  const GOSWallet = useSelector(getWallet)("GOS");
  const wallets = useSelector(getWallets);
  const pending = useSelector(isRebuyPending);
  const selectedCurrency = useSelector(getSelectedCurrency);
  const currentExchangeRate = useSelector(getCurrentExchangeRate)(rebuy?.currency);
  const isJackpotTab = useSelector(isSelectedJackpotTab);

  const currentStructure = useMemo(() => {
    const { enrolled, tournamentAward } = tournament || {};
    const { structure } = tournamentAward || {};
    const defaultStructure = structure?.[0];
    return enrolled === 0 || structure?.length === 1
      ? defaultStructure
      : structure.find(({ playersCount }) =>
          isBetweenOrEqual(enrolled, playersCount.min, playersCount.max)
        ) || structure[structure.length - 1];
  }, [tournament]);

  const {
    allowRebuyMain,
    allowRebuyGOSPoint,
    formatForMainRebuy,
    GOSPointRebuyinWithoutIcon,
    rebuyValue,
    formatForGOSPointBuyinWithoutIcon,
  } = getTournamentBuyinInfo(tournament, formatCurrency);

  const onRebuy = () => {
    dispatch(
      tournamentRebuyRequest({
        ...tournament,
        paymentType: allowRebuyGOSPoint
          ? allowRebuyMain
            ? radioOption === "vipPoints" ? 'GOS' : 'realMoney'
            : 'GOS'
          : 'realMoney',
      })
    );
    dispatch(closeDialog("tournamentFinished"));
    dispatch(closeToaster({ view: 'tournamentRebuyFailure' }));
    dispatch(closeToaster({ view: 'tournamentLastBet' }));
  };

  const onClose = useCallback(() => {
    if (!keepGameOpen) {
      dispatch(resetTournamentGameUrl());
      dispatch(resetToggleJackpotGameFromHost(false));
      dispatch(casinoTournamentRegisterReset());
      if (isJackpotTab) {
        dispatch(jacpotsRequest());
        dispatch(getSpinAndGoConfigsRequest());
      }
    }
    dispatch(closeDialog("tournamentFinished"));
    dispatch(closeToaster({ view: 'tournamentLastBet' }));
  }, [dispatch, keepGameOpen, isJackpotTab]);

  const onChangeHandler = (e) => {
    setRadioOption(e.target.value);
  };

  useEffect(() => {
    return () => dispatch(resetGameOpenCloseState());
  }, []);

  const hasRebuy = !!tournament?.rebuyAllowed && !!tournament?.rebuy && tournament?.rebuysLeft;
  
  return (
    <PopUpDialogTemplate
      className="tournament-finished-pop-up"
      dialogName={!!hasRebuy ? "tournamentRebuyDialog" : "tournamentFinishedDialog"}
      onClose={onClose}
      dynamicTitle={info.tournamentId === tournament.tournamentId ? `${tournament.tournamentName} 🏆` : null}
      setHTML
      customTextReplacingHowToPlay={!hasRebuy}
    >
      <div className={`tournament-finished-v2 ${!!hasRebuy ? 'tournament-finished-v2--rebuy' : ''}`}>
        <span className="tournament-finished-v2__text">{!!hasRebuy ? t('currentResults') : t('summary')}</span>
        <div className="tournament-finished-v2__info-wrapper">
          <div className="tournament-finished-v2__info-item">
            <span className="tournament-finished-v2__info-item-label">{`${t('position')}:`}</span>
            <span className="tournament-finished-v2__info-item-value">{info?.data?.playerPosition}</span>
          </div>
          <div className="tournament-finished-v2__info-item">
            <span className="tournament-finished-v2__info-item-label">{`${t('score')}:`}</span>
            <span className="tournament-finished-v2__info-item-value">{info?.data?.score}</span>
          </div>
          <div className="tournament-finished-v2__info-item">
            <span className="tournament-finished-v2__info-item-label">{`${t('awardedPositions')}:`}</span>
            <span className="tournament-finished-v2__info-item-value">{currentStructure?.awards?.at(-1)?.placesTo}</span>
          </div>
        </div>
        {!!hasRebuy && (
          <div style={{ marginTop: details ? 20 : 0 }} className="tournament-finished-v2__rebuy-info-wrapper">
            {details && <><p className="tournament-finished-v2__rebuy-info-wrapper--text">{t('rebuyMentionedText')}</p>
            <p className="tournament-finished-v2__rebuy-info-wrapper--sub-text">{t('rebuyDetails')}</p>
            <div className="tournament-finished-v2__rebuy-info-wrapper--tournament-details">
            <TournamentItemDetailInfo title={t('price')} info={rebuyValue} first last={!!isMobile && !!isPortrait} />
              <TournamentItemDetailInfo title={t('spins')} info={rebuy?.spins} first={!!isMobile && !!isPortrait} last={!!isMobile && !!isPortrait} />
              <TournamentItemDetailInfo title={t('endDate')} info={`${moment(tournament?.endDate).format('HH:mm | DD/MM/YY')}`} last={!!isMobile && !!isPortrait} first={!!isMobile && !!isPortrait} />
              <TournamentItemDetailInfo title={`# ${t('availableRebuysCount')}`} info={tournament?.rebuysLeft} last first={!!isMobile && !!isPortrait} />
            </div></>}
            <div onClick={() => setDetails(prev => !prev)} className="tournament-finished-v2__details-wrapper">
              <span>{t(details ? 'hideDetails' : 'seeDetails')}</span>
              <i
                className="icon-arrow-right"
                style={{
                  transform: `rotate(${details ? '-': ''}90deg) translateX(${details ? '-': ''}1px)`
                }}
              />
            </div>
            {+wallets?.[0]?.balance >= 0 && allowRebuyMain && !allowRebuyGOSPoint && (
              <div
              className={`tournament-register-v2__wallets-section--item tournament-register-v2__wallets-section--item--rate ${
                radioOption === "userBalance" ? "selected" : ""
              }`}
            >
              <div className="tournament-register-v2__wallets-section--item-label-value-wrapper">
                <div className="tournament-register-v2__wallets-section--item-label">
                  <label htmlFor="userBalance">
                    <span className="wallets-section--item-label__title">{`${t(
                      "mainBalance"
                    )}:`}</span>
                    <span className="wallets-section--item-label__balance">
                      {formatCurrency(wallets?.[0]?.balance, wallets?.[0]?.currency)}
                    </span>
                  </label>
                
                </div>
                <div className="tournament-register-v2__wallets-section--item-value">
                  {formatForMainRebuy}
                </div>
              </div>
              {(selectedCurrency?.label === '!convert' && currentExchangeRate?.baseCurrencyCode !== currentExchangeRate?.currencyCode) && (
                <span className="tournament-register-v2__wallets-section--item-rate-info">
                  {formatCurrency((rebuy?.cost + rebuy?.fee), rebuy?.currency)} = {formatCurrency((rebuy?.cost + rebuy?.fee) / currentExchangeRate?.exchangeRate, currentExchangeRate?.baseCurrencyCode)}
                </span>
              )}
            </div>
            )}
            {(+wallets?.[0]?.balance >= 0 || +GOSWallet?.balance >= 0) && allowRebuyMain && allowRebuyGOSPoint && (
            <>
              {+wallets?.[0]?.balance >= 0 && (
                <div
                className={`tournament-register-v2__wallets-section--item tournament-register-v2__wallets-section--item--rate ${
                  radioOption === "userBalance" ? "selected" : ""
                }`}
              >
                <div className="tournament-register-v2__wallets-section--item-label-value-wrapper">
                  <div className="tournament-register-v2__wallets-section--item-label">
                    <input
                      onChange={onChangeHandler}
                      checked={radioOption === "userBalance"}
                      value="userBalance"
                      type="radio"
                      name="balanceOption"
                      id="userBalance"
                    />
                    <label htmlFor="userBalance">
                      <span className="wallets-section--item-label__title">{`${t(
                        "mainBalance"
                      )}:`}</span>
                      <span className="wallets-section--item-label__balance">
                        {formatCurrency(wallets?.[0]?.balance, wallets?.[0]?.currency)}
                      </span>
                    </label>
                  
                  </div>
                  <div className="tournament-register-v2__wallets-section--item-value">
                    {formatForMainRebuy}
                  </div>
                </div>
                {(selectedCurrency?.label === '!convert' && currentExchangeRate?.baseCurrencyCode !== currentExchangeRate?.currencyCode) && (
                  <span className="tournament-register-v2__wallets-section--item-rate-info">
                    {formatCurrency((rebuy?.cost + rebuy?.fee), rebuy?.currency)} = {formatCurrency((rebuy?.cost + rebuy?.fee) / currentExchangeRate?.exchangeRate, currentExchangeRate?.baseCurrencyCode)}
                  </span>
                )}
              </div>
               )}
              {+GOSWallet?.balance >= 0 && (
                <div
                  className={`tournament-register-v2__wallets-section--item ${
                    radioOption === "vipPoints" ? "selected" : ""
                  }`}
                >
                  <div className="tournament-register-v2__wallets-section--item-label">
                    <input
                      onChange={onChangeHandler}
                      checked={radioOption === "vipPoints"}
                      value="vipPoints"
                      type="radio"
                      name="balanceOption"
                      id="vipPoints"
                    />
                    <label htmlFor="vipPoints">
                      <div className="wallets-section--item-label-value">
                        <div className="wallets-section--item-label__title">{`${t(
                          "vipPoints"
                        )}:`}</div>
                        <div className="wallets-section--item-label__balance">
                          {formatForGOSPointBuyinWithoutIcon(GOSWallet.balance)}
                        </div>
                      </div>
                    </label>
                  </div>
                  <div className="tournament-register-v2__wallets-section--item-value">
                    {GOSPointRebuyinWithoutIcon}
                  </div>
                </div>
              )}
            </>
          )}
            <div className="tournament-finished-v2__rebuy-info-wrapper--rebuy-button">
              <button disabled={pending} className="rebuy-confirm-button" onClick={onRebuy}>
                {t('rebuy')} {!(allowRebuyMain && allowRebuyGOSPoint) && rebuyValue}
              </button>
            </div>
          </div>
        )}
        <p className="tournament-finished-v2__description">{!!hasRebuy ? t('rebuyPointsWillBeAddedtoCurrentResult') : t('tournamentFinishedDescriptionDialog')} {!hasRebuy && (<span onClick={onClose}>{t('tournamentPage')}</span>)}</p>
        <span className="tournament-finished-v2__tournament-id">#{info?.tournamentId}</span>
      </div>
    </PopUpDialogTemplate>
  );
};

export default TournamentFinished_v2;
