import { takeEvery, put, select } from 'redux-saga/effects';
import { updateTournamentPlayers } from '../actionCreators/tournamentCasinoPlayers';
import { updatePlayerResult } from '../actionCreators/playerResult';
import { isSelectedJackpotTab } from '../selectors';
import types from '../actionTypes';

function* handleUpdatePlayer({ player }) {
    try {
      const isJackpot = yield select(isSelectedJackpotTab);
      if (isJackpot) {
        yield put(updateTournamentPlayers(player?.tournamentId, undefined, player));
      } else {
        yield put(updatePlayerResult(player));
      }
    } catch (error) {
        console.log(error)
    }
}

export function* watchUpdatePlayer() {
  yield takeEvery(types.UPDATE_PLAYER, handleUpdatePlayer);
}