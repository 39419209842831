import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getTournamentBuyinInfo } from "../../../helpers/getTournamentBuyinInfo";
import { useFormatCurrency } from "../../../hooks/useFormatCurrency";
import { casinoTournamentRebuyReset } from "../../../store/actionCreators/casinoTournamentRebuy";
import { getRebuyErrorData, getWallet } from "../../../store/selectors";
import PopUpDialogTemplate from "../../common/PopUpDialogTemplate";
import PopUpItemInfo from "../../common/PopUpItemInfo";
import { resetTournamentGameUrl } from "../../../store/actionCreators/tournamentGameUrl";
import "./RebuyErrorLowBalance.scss";


const RebuyErrorLowBalance = () => {
  const formatCurrency = useFormatCurrency();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const failData = useSelector(getRebuyErrorData);
  const { formatForGOSPointBuyinWithoutIcon } = getTournamentBuyinInfo(failData, formatCurrency);
  const { paymentType, buyIn: { currency } } = failData;
  const GOSWallet = useSelector(getWallet)("GOS");
  const mainWallet = useSelector(getWallet)(currency);

  const onClose = useCallback(() => {
    dispatch(casinoTournamentRebuyReset());
    dispatch(resetTournamentGameUrl());
  }, [dispatch]);

  return (
    <PopUpDialogTemplate
      className="tournament-rebuy-error-low-balance-pop-up"
      dialogName="tournamentRebuyErrorLowBalancePopUp"
      onClose={onClose}
      onConfirm={onClose}
      confirmText={t("ok")}
    >
      <div className="tournament-rebuy-error-low-balance">
        {<PopUpItemInfo
          title={'price'}
          value={formatCurrency(failData?.rebuy.cost + (failData?.rebuy.fee || 0), failData?.rebuy.currency)}
        />}
        {<PopUpItemInfo
          title={'yourBalance'}
          value={paymentType === 'GOS' ? formatForGOSPointBuyinWithoutIcon(GOSWallet.balance) : formatCurrency(mainWallet.balance, mainWallet.currency)}
          variant={'danger'}
          descriptionText={'replenishYourBalance'}
        />}
      </div>
    </PopUpDialogTemplate>
  );
};

export default RebuyErrorLowBalance;
