import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import CountdownTimer from "../../../CountdownTimer";
import ProgressBar from "../../../ProgressBar";
import JackpotGameLeaderBoard from "../JackpotGameLeaderBoard";
import { useFormatCurrency } from "../../../../hooks/useFormatCurrency";
import Skeleton from "../../../Skeleton";
import { getDialogs, isJackpotPrizeGenerated } from "../../../../store/selectors";
import "./JackpotGameSidebar.scss";

const JackpotGameSideBar = ({ tournament }) => {
  const { t } = useTranslation();
  const dialogs = useSelector(getDialogs);
  const pending = useSelector(isJackpotPrizeGenerated);
  const formatCurrency = useFormatCurrency();
  return (
    <div className="jackpot-game-sidebar">
      <div className="jackpot-game-sidebar__countdown-wrapper">
        <span className="jackpot-game-sidebar__countdown-label">
          {t("endsIn")}
        </span>
        <div className="jackpot-game-sidebar__countdown-value">
          <i className="icon-time" />
          <div className="jackpot-game-sidebar__countdown-timer-wrapper">
            <CountdownTimer
              size="sm"
              variant="thirdType"
              countdownDurationMs={tournament?.endsAfterMS}
              simple
              skeleton
              skeletonHeight={20}
            />
          </div>
        </div>
      </div>
      <div className="jackpot-game-sidebar__details">
        <div className="jackpot-game-sidebar__details-header">
          <span>{t('details')}</span>
        </div>
        <div className="jackpot-game-sidebar__details-body">
          {!dialogs?.prizePoolGenerator || pending
          ?  <span className="jackpot-game-sidebar__details-prize">{formatCurrency(tournament?.prizePool.value, tournament?.prizePool.currency, 2)}</span>
          : <Skeleton height={24} width="100%" radius={4} />
          }
          <span className="jackpot-game-sidebar__details-name">{tournament?.tournamentName}</span>
          <div className="jackpot-game-sidebar__details-progress-bar-countdown-wrapper">
            <span className="jackpot-game-sidebar__details-progress-bar-countdown-text">{t('endsIn')}</span>
            <div className="jackpot-game-sidebar__details-countdown-wrapper">
              <CountdownTimer
                countdownDurationMs={tournament?.endsAfterMS}
                size="sm"
                variant="thirdType"
                simple
                skeleton
                skeletonHeight={20}
              />
            </div>
            {tournament?.duration && <div className="jackpot-game-sidebar__details-progress-bar-wrapper">
              <ProgressBar endsAfterMS={tournament?.endsAfterMS} duration={tournament?.duration} />
            </div>}
          </div>
        </div>
      </div>
      <div className="jackpot-game-sidebar__jackpot-game-leaderboard-wrapper">
        <JackpotGameLeaderBoard tournament={tournament} />
      </div>
    </div>
  );
};

export default JackpotGameSideBar;
