import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import VeloxPopupTemplate from "../../common/VeloxPopupTemplate";
import { closeDialog } from "../../../store/actionCreators/dialogManager";
import { createSpinAndGoUnregRequest } from "../../../store/actionCreators/spinAndGoUnreg";

const VeloxUnregisterConfirmation = ({ configId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onClose = useCallback(
    () => dispatch(closeDialog("veloxUnregisterConfirmation")),
    []
  );

  const onConfirm = useCallback(() => {
    dispatch(createSpinAndGoUnregRequest(configId));
    onClose();
  }, []);

  return (
    <VeloxPopupTemplate
      className="velox-unregister-confirmation-dialog"
      title={t("veloxUnregisterConfirmationDialogTitle")}
      onClose={onClose}
      onCancel={onConfirm}
      onConfirm={onClose}
      confirmText={t("no")}
      cancelText={t("yes")}
    />
  );
};

export default VeloxUnregisterConfirmation;
