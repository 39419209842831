import React from 'react';
import { useMobileOrientation } from 'react-device-detect';
import { useSelector } from 'react-redux';
import { classNames } from '../../../../../helpers/pureFunctions';
import { getCasinoTournament } from '../../../../../store/selectors';
import PlayerInfo from '../../PlayerInfo';
import HowToPlayPageLink from '../HowToPlayPageLink';
import TournamentGameInfoWidget from '../TournamentGameInfoWidget';
import TournamentGameTimerWidget from '../TournamentGameTimerWidget';
import './MobileTournamentGameWidgetDetails.scss';

const MobileTournamentGameWidgetDetails = () => {

  const tournament = useSelector(getCasinoTournament);
  const { isLandscape } = useMobileOrientation();
  
  if (!tournament) return null;
  const { endDate } = tournament

  const tournamentGameWidgetDetailsClasses = classNames('tournament-game__widget-details', {
    'landscape': isLandscape
  })

  return (
    <div className={tournamentGameWidgetDetailsClasses}>
      <div className="tournament-game__widget-details__info-link-wrapper">
        <HowToPlayPageLink />
      </div>
      <div className="tournament-game__widget-details__countdown-timer-wrapper">
        <TournamentGameTimerWidget countdownTimestamp={endDate} />
      </div>
      <div className="tournament-game__widget-details__player-info-wrapper">
        <PlayerInfo />
      </div>
      <div className="tournament-game__widget-details__players-list-wrapper">
        <TournamentGameInfoWidget />
      </div>
    </div>
  )
}

export default MobileTournamentGameWidgetDetails;