import types from '../actionTypes';

export const casinoTournamentUnregisterRequest = (tournamentData) => ({
  type: types.CASINO_TOURNAMENT_UNREGISTER_REQUEST,
  tournamentData,
});

export const casinoTournamentUnregisterSuccess = (id) => ({
  type: types.CASINO_TOURNAMENT_UNREGISTER_SUCCESS,
  id,
});

export const casinoTournamentUnregisterFailure = (e) => ({
  type: types.CASINO_TOURNAMENT_UNREGISTER_FAILURE,
  exception: e,
});

export const casinoTournamentUnregisterReset = () => ({
  type: types.CASINO_TOURNAMENT_UNREGISTER_RESET,
});
