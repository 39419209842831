export const THEME = {
  dark: {
    '--text-1': '#efefef',
    '--text-2': '#BBBBBD',
    '--surface-main-bg': '#14161C',
    '--surface-border': '#313341',
    '--surface-second': '#1F222D',
    '--surface-inputs': '#1B1D24',
    '--active-active-shades': '#36342F',
    '--active-active-main': '#FFBF17',
    '--active-active-tournament': '#156D96',
    '--active-tournament-hover': '#373C4F',
    '--active-active-tournament-bg': '#293247',
    '--blur': 'linear-gradient(231deg, rgba(49, 51, 65, 0.60) 0%, rgba(33, 33, 33, 0.60) 100%)',
    '--active-go-s-color': '#C5D93C',
    '--active-attention': '#FCF251',
    '--active-attention-shade': 'rgba(252, 242, 81, 0.10)',
    '--active-blue': '#7CC4F8',
    '--active-jackpot-bg': '#202936',
    '--active-active-jackpot-bg': '#177180',
    '--active-active-jackpot': '#177180',
    '--active-active-shade-jackpot': 'rgba(123, 207, 192, 0.30)',
    '--active-jackpot-hover': '#1F3843',
    '--active-cta': '#0F5903',
    '--active-cta-hover': '#427C2C',
    '--active-cta-pressed': '#0B4102',
  },
  lightGreen: {
    '--success': '#0F5903',
    '--text-1': '#212121',
    '--text-2': '#313341',
    '--text': '#F0F0E6',
    '--surface-main-bg': '#FFFFFF',
    '--surface-border': '#D4D6D9',
    '--surface-second': '#E8EAED',
    '--surface-inputs': '#F9F9F9',
    '--active-active-shades': '#FEF0E6',
    '--active-active-main': '#54af3a',
    '--active-active-tournament': '#54af3a',
    '--active-tournament-hover': '#FFFFFF',
    '--active-active-tournament-bg': '#ECF6E9',
    '--background-active-bg': '#313341',
    '--background-third-bg': '#1F222D',
    '--blur': 'linear-gradient(231deg, rgba(239, 239, 239, 0.60) 0%, rgba(187, 187, 189, 0.60) 100%)',
    '--active-go-s-color': '#567500',
    '--active-attention': '#C38100',
    '--active-attention-shade': 'rgba(195, 129, 0, 0.10)',
    '--active-blue': '#0988E3',
    '--active-jackpot-bg': '#E8EAED',
    '--active-active-jackpot-bg': 'rgba(123, 207, 192, 0.50)',
    '--active-active-jackpot': '#7BCFC0',
    '--active-active-shade-jackpot': 'rgba(123, 207, 192, 0.30)',
    '--active-jackpot-hover': '#F9F9F9',
    '--active-cta': '#0F5903',
    '--active-cta-hover': '#427C2C',
    '--active-cta-pressed': '#0B4102',
  },
  lightOrange: {
    '--success': '#0F5903',
    '--text-1': '#212121',
    '--text-2': '#313341',
    '--text': '#F0F0E6',
    '--surface-main-bg': '#FFFFFF',
    '--surface-border': '#D4D6D9',
    '--surface-second': '#E8EAED',
    '--surface-inputs': '#F9F9F9',
    '--active-active-shades': '#FEF0E6',
    '--active-active-main': '#F66504',
    '--active-active-tournament': '#F66504',
    '--active-tournament-hover': '#FFFFFF',
    '--active-active-tournament-bg': '#FEF0E6',
    '--background-active-bg': '#313341',
    '--background-third-bg': '#1F222D',
    '--blur': 'linear-gradient(231deg, rgba(239, 239, 239, 0.60) 0%, rgba(187, 187, 189, 0.60) 100%)',
    '--active-go-s-color': '#567500',
    '--active-attention': '#C38100',
    '--active-attention-shade': 'rgba(195, 129, 0, 0.10)',
    '--active-blue': '#0988E3',
    '--active-jackpot-bg': '#E8EAED',
    '--active-active-jackpot-bg': 'rgba(123, 207, 192, 0.50)',
    '--active-active-jackpot': '#7BCFC0',
    '--active-active-shade-jackpot': 'rgba(123, 207, 192, 0.30)',
    '--active-jackpot-hover': '#F9F9F9',
    '--active-cta': '#0F5903',
    '--active-cta-hover': '#427C2C',
    '--active-cta-pressed': '#0B4102',
  }
}

export const THEME_OPTIONS = [
  { label: "Dark them", value: "dark" },
  { label: "Green theme", value: "lightGreen" },
  { label: "Orange theme", value: "lightOrange" },
];