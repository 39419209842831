import types from '../actionTypes';

export const casinoTournamentRegisterRequest = (tournamentData) => ({
  type: types.CASINO_TOURNAMENT_REGISTER_REQUEST,
  tournamentData,
});

export const casinoTournamentRegisterSuccess = (tournament, id) => ({
  type: types.CASINO_TOURNAMENT_REGISTER_SUCCESS,
  payload: tournament,
  id,
});

export const casinoTournamentRegisterFailure = (e, tournamentData) => ({
  type: types.CASINO_TOURNAMENT_REGISTER_FAILURE,
  exception: e,
  failMessage: e.response?.data?.message?.key,
  failData: tournamentData,
});

export const casinoTournamentRegisterReset = () => ({
  type: types.CASINO_TOURNAMENT_REGISTER_RESET,
});

export const casinoTournamentRegisterErrorMessageReset = () => ({
  type: types.CASINO_TOURNAMENT_REGISTER_ERROR_MESSAGE_RESET,
});
