import types from '../actionTypes';

export const openDialog = (dialog) => ({
  type: types.OPEN_DIALOG,
  payload: dialog,
});

export const closeDialog = (dialogType) => ({
  type: types.CLOSE_DIALOG,
  payload: dialogType,
});
