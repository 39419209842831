import types from "../actionTypes";

export const getGosPlayerLastWinRequest = () => ({
  type: types.GET_GOS_PLAYER_LAST_WIN_REQUEST,
});

export const getGosPlayerLastWinFailure = (e) => ({
  type: types.GET_GOS_PLAYER_LAST_WIN_FAILURE,
  exception: e,
});

export const getGosPlayerLastWinSucsess = (payload) => ({
  type: types.GET_GOS_PLAYER_LAST_WIN_SUCSESS,
  payload,
});

export const getGosPlayerLastWinReset = () => ({
  type: types.GET_GOS_PLAYER_LAST_WIN_RESET,
});
