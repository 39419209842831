import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useOutsideClick } from "../../hooks/useOutsideClick";
import { classNames } from "../../helpers/pureFunctions";
import { THEME, THEME_OPTIONS } from "../../constants/theme";
import config from "../../configs/config";
import { changeTheme } from "../../helpers/theme";
import "./ThemeSwitcher.scss";

const ThemeSwitcher = ({ openToTopOfList }) => {
  const { t } = useTranslation();
  const dropdownRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  useOutsideClick(dropdownRef, setIsOpen);

  useEffect(() => {
    if (!selectedOption) {
      setSelectedOption(
        THEME_OPTIONS?.find((option) => option?.value === config?.skinStyle)
          ?.value || THEME_OPTIONS[0].value
      );
    }
  }, [selectedOption]);

  const handleOptionClick = useCallback((option) => {
    setSelectedOption(option.value);
    changeTheme(THEME[option.value]);
  }, []);

  const bodyItems = useMemo(
    () =>
      THEME_OPTIONS.map((option, index) => (
        <div
          key={index}
          className={classNames("theme-switcher__body-item", {
            "theme-switcher__body-item--disabled": !!option.disabled,
          })}
          onClick={() => handleOptionClick(option)}
        >
          {selectedOption && (
            <i
              className={classNames("theme-switcher__cheked-icon icon-check", {
                "theme-switcher__cheked-icon--visible":
                  option.value === selectedOption,
              })}
            />
          )}
          <span className="theme-switcher__body-item-text">{option.label}</span>
          <div
            className={`theme-switcher__body-item-theme-image theme-switcher__body-item-theme-image--${option.value}`}
          />
        </div>
      )),
    [THEME_OPTIONS, handleOptionClick, selectedOption]
  );

  return (
    <>
      <div
        className={classNames("theme-switcher", {
          "theme-switcher--opened": isOpen,
          "theme-switcher--open-to-top-of-list": !!openToTopOfList,
        })}
        ref={dropdownRef}
      >
        <div
          className="theme-switcher__header"
          onClick={() => setIsOpen((prev) => !prev)}
        >
          <div className="theme-switcher__header-icon-wrapper">
            <i className="icon-theme" />
          </div>
          <span className="theme-switcher__header-text">{t("GOSTheme")}</span>
          <i className="theme-switcher__header-icon icon-arrow-right" />
        </div>
        {isOpen && (
          <div className="theme-switcher__body">
            <div className="theme-switcher__body-inner">{bodyItems}</div>
          </div>
        )}
      </div>
    </>
  );
};

export default ThemeSwitcher;
