import types from '../actionTypes';

export const casinoTournamentPlayersRequest = ({ id, dialogType, payload, offset = 0, limit = 20 }) => ({
  type: types.GET_CASINO_TOURNAMENT_PLAYERS_REQUEST,
  id,
  dialogType,
  payload,
  offset,
  limit,
});

export const casinoTournamentPlayersSuccess = ({ casinoTournamentPlayers, id, userIds, offset }) => ({
  type: types.GET_CASINO_TOURNAMENT_PLAYERS_SUCCESS,
  casinoTournamentPlayers,
  id,
  userIds,
  offset,
});

export const casinoTournamentPlayersFailure = (e) => ({
  type: types.GET_CASINO_TOURNAMENT_PLAYERS_FAILURE,
  exception: e,
});

export const casinoTournamentPlayersReset = () => ({
  type: types.GET_CASINO_TOURNAMENT_PLAYERS_RESET,
});
