import types from '../actionTypes';

export const casinoTournamentsHistoryRequest = (page, limit) => ({
  type: types.GET_CASINO_TOURNAMENTS_HISTORY_REQUEST,
  page,
  limit
});

export const casinoTournamentsHistorySuccess = (payload) => ({
  type: types.GET_CASINO_TOURNAMENTS_HISTORY_SUCCESS,
  payload,
});

export const casinoTournamentsHistoryFailure = (e) => ({
  type: types.GET_CASINO_TOURNAMENTS_HISTORY_FAILURE,
  exception: e,
});

export const casinoTournamentsHistoryReset = () => ({
  type: types.GET_CASINO_TOURNAMENTS_HISTORY_RESET,
});