import { call, cancel, fork, put, select, take } from "@redux-saga/core/effects";
import { getToken, getWallets } from "../selectors";
import types from "../actionTypes";
import { accountingApiGet } from "./callApi";
import apiRoutes from "../../constants/apiRoutes";
import { getLocalExchangeRatesFailure, getLocalExchangeRatesSucsess } from "../actionCreators/localExchangeRates";

function* processGetLocalExchangeRatesRequest() {
  const wallets = yield select(getWallets);
  const token = yield select(getToken);

  try {
    const params = {
      baseCurrencyCode: wallets[0].currency,
      token,
    };

    const response = yield call(accountingApiGet, apiRoutes.GET_LOCAL_EXCHANGE_RATES, params);
    if (response.data?.success && response.data?.currencyRates.length) {
      yield put(getLocalExchangeRatesSucsess(response.data.currencyRates));
    }
  } catch (e) {
    yield put(getLocalExchangeRatesFailure(e));
  }
}

export default  function* watchGetLocalExchangeRatesRequest() {
  let forked = null;
  while (true) {
    yield take(types.GET_LOCAL_EXCHANGE_RATES_REQUEST);
    if (forked) yield cancel(forked);
    forked = yield fork(processGetLocalExchangeRatesRequest);
  }
}