import types from '../actionTypes';

export const requestCashGameUrl = (game) => ({
  type: types.CASH_GAMES_URL_REQUEST,
  game,
});

export const successCashGameUrl = ({ url, cashGameId }, size) => ({
  type: types.CASH_GAMES_URL_SUCCESS,
  value: { url, cashGameId, size },
});

export const failCashGameUrl = (e) => ({
  type: types.CASH_GAMES_URL_FAILURE,
  exception: e,
});

export const resetCashGameUrl = () => ({
  type: types.CASH_GAMES_URL_RESET,
});
