import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import PopUpDialogTemplate from "../../../common/PopUpDialogTemplate";
import { useFormatCurrency } from "../../../../hooks/useFormatCurrency";
import { useDispatch, useSelector } from "react-redux";
import { getCasinoTournamentPlayers, getJackpots, getOpenTournamentGameId, getTournamentGameUrl, isCasinoTournamentPlayersPending } from "../../../../store/selectors";
import { closeDialog } from "../../../../store/actionCreators/dialogManager";
import { requestReadMessage } from "../../../../store/actionCreators/messages";
import { useEffect } from "react";
import { resetTournamentGameUrl } from "../../../../store/actionCreators/tournamentGameUrl";
import { resetToggleJackpotGameFromHost } from "../../../../store/actionCreators/toggleJackpotGameFromHost";
import JackpotGameLeaderboardTable from "../../../Lobby/Jackpot/JackpotGameLeaderboardTable";
import { jacpotsRequest } from "../../../../store/actionCreators/jackpot";
import { getSpinAndGoConfigsRequest } from "../../../../store/actionCreators/spinAndGoConfigs";
import './Jackpot.scss';

const TournamentEndJackpot = ({ payload, dialogKey }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const formatCurrency = useFormatCurrency();
  const jackpots = useSelector(getJackpots);
  const players = useSelector(getCasinoTournamentPlayers)(payload?.tournamentId);

  const tournament = jackpots?.find(item => item?.tournamentId === payload?.tournamentId);
  const tournamentGameUrl = useSelector(getTournamentGameUrl);
  const openTournamentGameId = useSelector(getOpenTournamentGameId);
  const self = players?.find((item) => item.self);

  const onClose = useCallback(
    () => {
      if (payload?.messageId) {
        dispatch(requestReadMessage(payload.messageId));
      }
      dispatch(closeDialog(dialogKey));
      if (!(tournamentGameUrl && String(payload?.tournamentId) === String(openTournamentGameId))) {
        dispatch(jacpotsRequest());
        dispatch(getSpinAndGoConfigsRequest());
      }
    },
    [dispatch, tournamentGameUrl, payload, openTournamentGameId]
  );

  useEffect(() => {
    if (tournamentGameUrl && String(payload?.tournamentId) === String(openTournamentGameId)) {
      dispatch(resetTournamentGameUrl());
      dispatch(resetToggleJackpotGameFromHost(false));
      dispatch(closeDialog("jackpotTournamentFinished"));
    }
  }, [payload, openTournamentGameId, tournamentGameUrl]);

  return (
    <PopUpDialogTemplate
      className="tournament-end-jackpot-pop-up"
      dialogName="tournamentEndJackpotDialog"
      confirmText={t("close")}
      onConfirm={onClose}
      withoutDescription={tournamentGameUrl && String(payload?.tournamentId) === String(openTournamentGameId)}
      boldSubtitle
      titleParams={{
        tournamentName: payload?.tournamentName,
        tournamentId: payload?.tournamentId,
      }}
    >
      <div className="tournament-end-jackpot">
        <span
          className="tournament-end-jackpot__win-loss"
          dangerouslySetInnerHTML={{
            __html: payload?.winning?.amount > 0
              ? t('youWon', { amount: formatCurrency(payload?.winning?.amount, payload?.winning?.currency) })
              : t('youLose')
          }}
        />
        <div className="tournament-end-jackpot__jackpot-game-leader-board-table-wrapper">
          <JackpotGameLeaderboardTable players={players} self={self} tournament={tournament} />
        </div>
      </div>
    </PopUpDialogTemplate>
  );
};

export default TournamentEndJackpot;
