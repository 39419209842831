import TournamentInformation_v1 from "./TournamentInformation_v1";
import TournamentInformation_v2 from "./TournamentInformation_v2";
import config from "../../../configs/config";

const TournamentInformation = (props) => {
  // const Component = config?.popUpVersion_2
  //   ? TournamentInformation_v2
  //   : TournamentInformation_v1;
  return <TournamentInformation_v2 {...props} />;
};

export default TournamentInformation;
