import moment from "moment";
import 'moment/min/locales';
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { getCurrentLanguage } from "../store/selectors";

const getDatesInfo = (now, startDate, endDate) => {
  const startsAt = moment(new Date(startDate));
  const endsAt = moment(new Date(endDate));
  const duration = moment.duration(startsAt.diff(now));
  return [startsAt, endsAt, duration];
}

export const useTournamentButtonState = (tournament) => {
  const language = useSelector(getCurrentLanguage);

  return  useMemo(() => {
    moment.locale(language);
    const {
      startDate,
      endDate,
      registrationStartDate,
      registrationEndDate,
      tournamentType,
      tournamentState,
      playerStatus,
      buyIn,
      rebuyAllowed,
      rebuysLeft
    } = tournament;

    const now = moment();
    const [tournamentStartsAt, tournamentEndsAt, tournamentDuration] = getDatesInfo(now, startDate, endDate);
    const [registrationStartsAt, registrationEndsAt, registrationDuration] = getDatesInfo(now, registrationStartDate, registrationEndDate);

    return {
      started: (!!startDate && !now.isBefore(tournamentStartsAt)) || (tournamentType === 'sitngo' && tournamentState === 'inProgress'),
      isActive: moment(new Date(endDate)).isAfter(now),
      finished: playerStatus === 'finished' || tournamentState === 'refund' || tournamentEndsAt.isBefore(now),
      canUnregister: playerStatus === 'registered' && !moment().isAfter(moment(new Date(startDate))),
      startsToday: now.isSame(tournamentStartsAt, 'date'),
      startsIn:  tournamentDuration.humanize(),
      startsOn: tournamentStartsAt.format('MMM DD | HH:mm'),
      regStarted: (!!startDate && !now.isBefore(registrationStartsAt)) || (tournamentType === 'sitngo' && tournamentState === 'registration'),
      regFinished: ['finished', 'refund', 'ready', 'inProgress'].includes(tournamentState) || registrationEndsAt.isBefore(now),
      regStartsToday: now.isSame(registrationStartsAt, 'date'),
      regStartsIn: registrationDuration.humanize(),
      regStartsOn: registrationStartsAt.format('MMM DD | HH:mm'),
      onlyByTicket: buyIn?.methodsAllowed?.tourTicket && (!buyIn?.methodsAllowed?.realMoney && !buyIn?.methodsAllowed?.GOS),
      rebuy: ((!!startDate && !now.isBefore(tournamentStartsAt)) || (tournamentType === 'sitngo' && tournamentState === 'inProgress')) && !!rebuyAllowed  && !!rebuysLeft
    };
  }, [tournament, language]);
};
