import types from '../actionTypes';

export const getGosPopupsFromCMSRequest = () => ({
  type: types.GET_GOS_POPUPS_FROM_CMS_REQUEST,
});

  
export const getGosPopupsFromCMSFailure = (e) => ({
  type: types.GET_GOS_POPUPS_FROM_CMS_FAILURE,
	exception: e,
});

  
export const getGosPopupsFromCMSSucsess = (payload) => ({
  type: types.GET_GOS_POPUPS_FROM_CMS_SUCSESS,
  payload,
});

  
export const getGosPopupsFromCMSReset = () => ({
  type: types.GET_GOS_POPUPS_FROM_CMS_RESET,
});
