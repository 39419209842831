import { useContext } from "react";
import { useSelector } from "react-redux";
import IsMobileContext from "../../../../contexts/IsMobileContext";
import { getCasinoTournament } from "../../../../store/selectors";
import Loader from "../../../common/Loader";
import DesktopTournamentGameWidget from "./Desktop";
import MobileTournamentGameWidgetDetails from "./MobileTournamentGameWidgetDetails";
import './TournamentGameWidget.scss';

const TournamentGameWidget = (props) => {
  const tournament = useSelector(getCasinoTournament);

  if (!tournament) return <div><Loader /></div>;

  const { isMobile } = useContext(IsMobileContext);
  const Component =  isMobile ? MobileTournamentGameWidgetDetails :  DesktopTournamentGameWidget;
  return <Component {...props} />;
};

export default TournamentGameWidget;
