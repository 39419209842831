import React from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { classNames } from "../../../helpers/pureFunctions";
import "./InfoTab.scss";

const InfoTab = ({text, background='blue', view='top', size='auto', color='light', icon }) => {
  const { t } = useTranslation();
  const infoTabClassNames = classNames("info-tab-wrapper", {
    [background]: background,
    [view]: view,
    [size]: size,
    mobile: isMobile
  });

  const infoTabTextClassNames = classNames("info-tab-text", {
    [size]: size,
    [color]: color
  });

  return (
    <div className={infoTabClassNames}>
      <span className={infoTabTextClassNames}>{!!icon && <i className={`icon tab-icon icon-${icon}`} /> } {t(text)}</span>
    </div>
  );
};

export default InfoTab;
