import { useCallback, useEffect, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isMobile, useMobileOrientation } from "react-device-detect";
import { ToastProvider } from "react-toast-notifications";
import { useTranslation } from "react-i18next";

import ToastManager from "../ToastManager";
import MainToaster from "../Toaster";
import Router from "../Router/Router";
import Dialogs from "../Dialogs";

import {
  casinoTournamentsRequestWihoutDelay,
  casinoTournamentsUpdateWihoutDelay,
  selectCasinoTournament,
} from "../../store/actionCreators/casinoTournament";
import {
  closeDialog,
  openDialog,
} from "../../store/actionCreators/dialogManager";
import {
  loginSetQueryParams,
  logoutSetQueryParams,
  setQueryParams,
} from "../../store/actionCreators/queryParams";
import {
  getCasinoTournament,
  getDesktopGosPopupsFromCMS,
  getIsAutoConvertToPlayerCurrencyEnabled,
  getIsOngoingTournamentsModalOpen,
  getJackpots,
  getLanguage,
  getMobileGosPopupsFromCMS,
  getRebuyTournaments,
  getRegisteredUpcomingTournaments,
  getResumeTournaments,
  getSkinId,
  getToggleJackpotGameFromHost,
  getToggleJackpotGameIdFromHost,
  getToken,
  getTournamentGameUrl,
  getUserId,
  getWallets,
  isSelectedJackpotTab,
} from "../../store/selectors";
import {
  requestTournamentGameUrl,
  resetTournamentGameUrl,
} from "../../store/actionCreators/tournamentGameUrl";
import { casinoTournamentRegisterReset } from "../../store/actionCreators/casinoTournamentRegister";
import { resetTournamentPlayers } from "../../store/actionCreators/tournamentCasinoPlayers";
import { openToaster } from "../../store/actionCreators/toaster";
import { setActualLanguage } from "../../store/actionCreators/language";
import {
  getAccountBalanceRequest,
  getAccountBalanceRequestFromHost,
} from "../../store/actionCreators/getAccountBalance";

import IsMobileContext from "../../contexts/IsMobileContext";
import SocketContext from "../../contexts/SocketContext";

import { useLanguageChange } from "../../hooks/useLanguageChange";
import { useQuery } from "../../hooks/useQuery";
import useTournamentSocket from "../../hooks/useTournamentSocket";

import { classNames, generateId } from "../../helpers/pureFunctions";

import config from "../../configs/config";

import {
  enalbleAutoConvertToPlayerCurrency,
  selectCurrency,
} from "../../store/actionCreators/currency";
import { getLocalExchangeRatesRequest } from "../../store/actionCreators/localExchangeRates";
import {
  requestPublicMessages,
  requestReadMessage,
} from "../../store/actionCreators/messages";
import { getPlayerStateInTournamentsRequest } from "../../store/actionCreators/playerStateInTournaments";
import { useGoogleAnalytics } from "../../hooks/useGoogleAnalytics";
import { setTab } from "../../store/actionCreators/tab";
import {
  resetToggleJackpotGameFromHost,
  setToggleJackpotGameFromHost,
} from "../../store/actionCreators/toggleJackpotGameFromHost";
import { getCMSResponseRequest } from "../../store/actionCreators/CMSResponse";
import { setOngoingTournamentsModal } from "../../store/actionCreators/onGoingTournamentsModal";
import { getGosPopupsFromCMSRequest } from "../../store/actionCreators/gosPopupsFromCMS";
import { changeTheme } from "../../helpers/theme";
import { THEME, THEME_OPTIONS } from "../../constants/theme";
import Footer from "../Footer";
import "./App.scss";

const App = () => {
  useGoogleAnalytics();
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const queries = useQuery();
  const skinId = useSelector(getSkinId);
  const userId = useSelector(getUserId);
  const token = useSelector(getToken);
  const language = useSelector(getLanguage);
  const jackpots = useSelector(getJackpots);
  const tournament = useSelector(getCasinoTournament);
  const tournamentGameUrl = useSelector(getTournamentGameUrl);
  const toggleJackpotGameFromHost = useSelector(getToggleJackpotGameFromHost);
  const toggleJackpotGameIdFromHost = useSelector(
    getToggleJackpotGameIdFromHost
  );
  const IsAutoConvertToPlayerCurrencyEnabled = useSelector(
    getIsAutoConvertToPlayerCurrencyEnabled
  );
  const wallets = useSelector(getWallets);
  const isJackpot = useSelector(isSelectedJackpotTab);

  const resumeTournaments = useSelector(getResumeTournaments);
  const rebuyTournaments = useSelector(getRebuyTournaments);
  const registeredUpcomingTournaments = useSelector(
    getRegisteredUpcomingTournaments
  );
  const isOngoingTournamentsModalOpen = useSelector(
    getIsOngoingTournamentsModalOpen
  );
  const DesktopGosPopupsFromCMS = useSelector(getDesktopGosPopupsFromCMS);
  const MobileGosPopupsFromCMS = useSelector(getMobileGosPopupsFromCMS);
  const gosPopupsFromCMS = isMobile
    ? MobileGosPopupsFromCMS
    : DesktopGosPopupsFromCMS;

  const { isLandscape } = useMobileOrientation();
  // useMessagesSocket();
  // useMessagesHandler();

  useEffect(() => {
    if (
      token &&
      localStorage.getItem("isAlreadyShowedOngoingTournaments") !== "true" &&
      !isOngoingTournamentsModalOpen &&
      (resumeTournaments?.length ||
        rebuyTournaments?.length ||
        registeredUpcomingTournaments?.length)
    ) {
      dispatch(openDialog({ dialogType: "onGoingEvents" }));
      dispatch(setOngoingTournamentsModal(true));
      localStorage.setItem("isAlreadyShowedOngoingTournaments", "true");
    }
  }, [
    token,
    resumeTournaments,
    rebuyTournaments,
    registeredUpcomingTournaments,
    isOngoingTournamentsModalOpen,
  ]);

  const handleFrameMessages = useCallback((e) => {
    switch (e.data.type) {
      case "logout":
        localStorage.removeItem("selectedCurrency");
        break;
      case "readMessage":
        if (e.data.messageId) {
          dispatch(requestReadMessage(e.data.messageId));
        }
        break;
      case "openDialogByHostInitiative":
        if (e.data.dialogType) {
          if (e.data.dialogType === "tournamentRegister") {
            if (e.data.game) {
              dispatch(selectCasinoTournament(e.data.game));
              return dispatch(
                openDialog({
                  tournament: e.data.game,
                  dialogType: "tournamentRegister",
                })
              );
            }
            if (e.data.tournamentId && !e.data.game) {
              dispatch(
                casinoTournamentsRequestWihoutDelay({
                  openRegisterPopup: { tournamentId: e.data.tournamentId },
                })
              );
              return;
            }
          }
          dispatch(openDialog({ dialogType: e.data.dialogType }));
        }
        break;
      case "closeDialogByHostInitiative":
        if (e.data.dialogType) {
          dispatch(closeDialog(e.data.dialogType));
        }
        break;
      case "openGame":
        if (e.data.game) {
          dispatch(requestTournamentGameUrl(e.data.game));
          dispatch(selectCasinoTournament(e.data.game));
        }
        break;
      case "openSipinAndGo":
        if (e.data?.tournamentId) {
          dispatch(
            setToggleJackpotGameFromHost({
              toggle: true,
              id: e.data.tournamentId,
            })
          );
          dispatch(setTab("jackpot"));
        }
        break;
      case "closeGame":
        dispatch(resetTournamentGameUrl());
        dispatch(casinoTournamentRegisterReset());
        dispatch(resetTournamentPlayers());
        dispatch(resetToggleJackpotGameFromHost(false));
        break;
      case "languageChange":
        if (e.data.language) {
          dispatch(setActualLanguage(e.data.language));
          i18n.changeLanguage(e.data.language);
        }
        break;
      case "CHANGE_LANGUAGE":
        let { language } = e.data?.payload || {};
        if (language) {
          dispatch(setActualLanguage(language));
          i18n.changeLanguage(language);
        }
      case "triggerBalance":
        dispatch(getAccountBalanceRequestFromHost());
        break;
      case "updateTournaments":
        dispatch(casinoTournamentsUpdateWihoutDelay());
        break;
      case "GD_LOGIN_ACTION":
        dispatch(loginSetQueryParams(e.data.payload));
        break;
      case "GD_LOGOUT_ACTION":
        dispatch(logoutSetQueryParams());
        break;
      default:
        break;
    }
  }, []);

  useLanguageChange(language || "en");

  const onShowBadBet = useCallback(() => {
    dispatch(openDialog({ dialogType: "badBet" }));
  }, [dispatch]);

  const onServerMessage = useCallback(
    (data) => {
      if (data.message === "tournament_finished") {
        dispatch(
          openDialog({
            dialogType: isJackpot
              ? "jackpotTournamentFinished"
              : "tournamentFinished",
            info: data,
          })
        );
        if (data?.tournamentId) {
          dispatch(
            getPlayerStateInTournamentsRequest([data?.tournamentId], true)
          );
        }
        return;
      } else if (data.message === "last_bet" && config.showLastBetToaster) {
        dispatch(
          openToaster({
            view: "tournamentLastBet",
            id: generateId(),
          })
        );
      } else if (data.message === "reloadGame" && data?.tournamentId) {
        dispatch(requestTournamentGameUrl(null, true, data.tournamentId, true));
      }
    },
    [isJackpot]
  );

  useEffect(() => {
    dispatch(
      casinoTournamentsRequestWihoutDelay({ checkResumePopup: !!queries?.token })
    );
  }, []);

  useEffect(() => {
    if (token) {
      dispatch(requestPublicMessages());
    } else {
      localStorage.removeItem("isAlreadyShowedOngoingTournaments");
    }
  }, [token]);

  useEffect(() => {
    if (
      !tournamentGameUrl &&
      toggleJackpotGameFromHost &&
      toggleJackpotGameIdFromHost &&
      jackpots?.length
    ) {
      const tournament = jackpots?.find(
        (tour) =>
          String(tour?.tournamentId) === String(toggleJackpotGameIdFromHost)
      );
      if (tournament) {
        dispatch(requestTournamentGameUrl(tournament));
        dispatch(selectCasinoTournament(tournament));
      }
    }
  }, [
    toggleJackpotGameFromHost,
    toggleJackpotGameIdFromHost,
    jackpots,
    tournamentGameUrl,
  ]);

  useEffect(() => {
    dispatch(setTab("regular", { init: true }));
    dispatch(getCMSResponseRequest());
    dispatch(getGosPopupsFromCMSRequest());
    changeTheme(THEME[config?.skinStyle || THEME_OPTIONS[0].value]);
    window.addEventListener("message", (e) => handleFrameMessages(e));
    return () => {
      window.removeEventListener("message", (e) => handleFrameMessages(e));
      localStorage.removeItem("isAlreadyShowedOngoingTournaments");
    };
  }, []);

  useEffect(() => {
    if (gosPopupsFromCMS?.length) {
      gosPopupsFromCMS.forEach((item) => {
        if (
          item?.imageURL &&
          item?.id &&
          localStorage.getItem(`dontShowAgain--gosPopupFromCMS--${item.id}`) !==
            "true"
        ) {
          dispatch(
            openDialog({
              dialogType: `gosPopupFromCMS--${item.id}`,
              imageURL: item.imageURL,
            })
          );
        }
      });
    }
  }, [gosPopupsFromCMS]);

  useEffect(() => {
    if (wallets?.length) {
      dispatch(getLocalExchangeRatesRequest());
    }
  }, [dispatch, wallets]);

  const emitToSocket = useTournamentSocket(
    tournament ? tournament?.tournamentId : null,
    onShowBadBet,
    onServerMessage
  );

  useLayoutEffect(() => {
    dispatch(setQueryParams(queries));
  }, [dispatch, queries]);

  useEffect(() => {
    if (userId && token) {
      dispatch(getAccountBalanceRequest(true));
    }
  }, [dispatch, userId, token]);

  useEffect(() => {
    if (tournamentGameUrl) {
      if (tournament) {
        emitToSocket("joinToGame", { tourId: tournament.tournamentId });
      }
    }
  }, [tournamentGameUrl, tournament]);

  const dontConvert = () => {
    dispatch(selectCurrency({ label: "!convert" }));
    dispatch(enalbleAutoConvertToPlayerCurrency(false));
  };

  useEffect(() => {
    if (wallets?.length) {
      const selectedCurrencyStorage = JSON.parse(
        localStorage.getItem("selectedCurrency")
      );
      if (selectedCurrencyStorage) {
        dispatch(selectCurrency(selectedCurrencyStorage));
        dispatch(
          enalbleAutoConvertToPlayerCurrency(
            selectedCurrencyStorage.label !== "!convert"
          )
        );
      } else {
        if (IsAutoConvertToPlayerCurrencyEnabled) {
          dispatch(selectCurrency({ label: wallets[0].currency }));
          localStorage.setItem(
            "selectedCurrency",
            JSON.stringify({ label: wallets[0].currency })
          );
        } else {
          dontConvert();
          localStorage.setItem(
            "selectedCurrency",
            JSON.stringify({ label: "!convert" })
          );
        }
      }
    }
  }, [IsAutoConvertToPlayerCurrencyEnabled, wallets]);

  const appClassNames = classNames("app", {
    landscape: isLandscape,
    mobile: isMobile,
    desktop: !isMobile,
  });

  return (
    <>
      <ToastProvider>
        <ToastManager />
        <div className={appClassNames}>
          <div className="container">
            <div className="app__inner">
              {skinId && (
                <SocketContext.Provider value={{ emitToSocket }}>
                  <IsMobileContext.Provider value={{ isMobile }}>
                    <Dialogs />
                    <MainToaster />
                    <Router />
                  </IsMobileContext.Provider>
                </SocketContext.Provider>
              )}
            </div>
          </div>
          <div className="app__footer-wrapper">
            <Footer />
          </div>
        </div>
      </ToastProvider>
    </>
  );
};

export default App;
