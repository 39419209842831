export const locales = {
  BRL: "pt-BR",
  CNY: "zh-CN",
  USD: "en-EN",
  EUR: "en-FR",
  HKD: "zh-HK",
  IDR: "id-ID",
  IRR: "fa-IR",
  ILS: "he-IL",
  KZT: "kk-KZ",
  MYR: "ms-MY",
  PEN: "es-PE",
  GBP: "en-GB",
  RUB: "ru-RU",
  SGD: "en-SG",
  THB: "th-TH",
  TND: "fr-TN",
  TRY: "tr-TR",
  VND: "vi-VN",
};
