import { take, fork, cancel, call, put, select, all, delay } from 'redux-saga/effects';
import { apiGetLobbyURL } from './callApi';
import apiRoutes from '../../constants/apiRoutes';
import types from '../actionTypes';
import { getCasinoTournament, getDialogs, getJackpots, getSelectedTournament, getSkinId, getToken, getTournamentGameUrl, isSelectedJackpotTab } from '../selectors';
import { jacpotsFailure, jacpotsSuccess, updateCasinoTournamentsJackpotFromSocketSuccess } from '../actionCreators/jackpot';
import { selectCasinoTournament } from '../actionCreators/casinoTournament';
import { requestTournamentGameUrl } from '../actionCreators/tournamentGameUrl';
import { setTournament } from '../actionCreators/setTournament';
import tournamentConfig from '../../configs/tournamentConfig';

function* processJackpotsRequest({ id, isLastPlayer, setFirstTournamentAsSelected }) {
  try {
    const token = yield select(getToken);
    const skinId = yield select(getSkinId);
    const selectedTournament = yield select(getCasinoTournament);
    const selectedListTournament = yield select(getSelectedTournament);
    const params = { skinId: skinId, tournamentType: "spinAndGo" };
    const dialogs = yield select(getDialogs);
    const tournamentGameUrl = yield select(getTournamentGameUrl);
    const jackpots = yield select(getJackpots);
    const isJackpot = yield select (isSelectedJackpotTab);
    if (token) params.token = token;

    const response = yield call(apiGetLobbyURL, apiRoutes.GET_CASINO_TOURNAMENTS, params);
    if (response.data) {
      const tournaments = response.data.result;
      const newSelectedTournament  = response.data.result?.find(item => item?.tournamentId === selectedTournament?.tournamentId);
      const newListSelectedTournament  = response.data.result?.find(item => item?.tournamentId === selectedListTournament?.tournamentId);
      if (newSelectedTournament) {
        yield put(selectCasinoTournament(newSelectedTournament));
      }
      if (newListSelectedTournament) {
        yield put(setTournament(newListSelectedTournament));
      }
      yield put(jacpotsSuccess(tournaments));
      if (!newListSelectedTournament && isJackpot && (!jackpots?.length || setFirstTournamentAsSelected)) {
        yield put(setTournament(tournaments[0]));
      }
      if (id) {
        if (!dialogs?.waitingRoom && !isLastPlayer) {
          if (window && window.parent) {
            yield window.parent.postMessage({ type: "spinAndGoStarted", tournamentId: id }, "*");
          }
        } else {
          if (!tournamentGameUrl) {
            yield put(requestTournamentGameUrl(undefined, undefined, id));
          }
        } 
      }
    } else {
      yield put(jacpotsFailure());
    }
  } catch (e) {
    yield put(jacpotsFailure(e));
  }
}

function* watchJackpotsRequest() {
  let forked = null
  while (true) {
    const action = yield take(types.GET_CASINO_TOURNAMENTS_JACKPOTS_REQUEST);
    forked = yield fork(processJackpotsRequest, action);
  }
};

function* watchJackpotsWithDelayRequest() {
  let forked = null;

  while (true) {
    const action = yield take([types.GET_CASINO_TOURNAMENTS_JACKPOTS_WITH_DELAY_REQUEST, types.SET_SELECTED_TAB]);
    const isJackpot = yield select(isSelectedJackpotTab);

    if (!isJackpot) {
      if (forked) {
        yield cancel(forked);
        forked = null;
      }
      continue; // Skip the rest of the loop and wait for the next action
    }

    forked = yield fork(processJackpotsRequest, action);

    while (true) {
      yield delay(tournamentConfig.updateInterval);

      if (forked) {
        yield cancel(forked);
      }

      forked = yield fork(processJackpotsRequest, action);

      const newIsJackpot = yield select(isSelectedJackpotTab);
      if (!newIsJackpot) {
        yield cancel(forked);
        break;
      }
    }
  }
}

function* processUpdateTournamentsFromSocket({ payload }) {
  try {
    yield put(updateCasinoTournamentsJackpotFromSocketSuccess(payload));
  } catch (e) {
    yield put(getGosPlayerLastWinFailure(e));
  }
}

function* watchUpdateTournamentsFromSocket() {
  let forked = null;
  while (true) {
    const action = yield take(types.UPDATE_CASINO_TOURNAMENTS_JACKPOTS_FROM_SOCKET_REQUEST);
    if (forked) yield cancel(forked);
    forked = yield fork(processUpdateTournamentsFromSocket, action);
  }
}

export default function* jackpotsRequests() {
  yield all([
    call(watchJackpotsRequest),
    call(watchJackpotsWithDelayRequest),
    call(watchUpdateTournamentsFromSocket),
  ]);
};
