import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import VeloxPopupTemplate from "../../common/VeloxPopupTemplate";
import { closeDialog } from "../../../store/actionCreators/dialogManager";
import { exitFullScreenMode } from "../../../helpers/fullScreen";
import { resetTournamentGameUrl } from "../../../store/actionCreators/tournamentGameUrl";
import { resetToggleJackpotGameFromHost } from "../../../store/actionCreators/toggleJackpotGameFromHost";
import { casinoTournamentPlayersRequest } from "../../../store/actionCreators/casinoTournamentPlayers";

const VeloxForceCloseGame = ({ tournamentId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onClose = useCallback(
    () => dispatch(closeDialog("veloxForceCloseGame")),
    [dispatch]
  );

  const onConfirm = useCallback(
    () => {
      exitFullScreenMode();
      dispatch(resetTournamentGameUrl());
      dispatch(resetToggleJackpotGameFromHost(false));
      dispatch(casinoTournamentPlayersRequest({ id: tournamentId, offset: 0, limit: 20 }));
      dispatch(closeDialog("jackpotTournamentFinished"));
      onClose();
    },
    [dispatch]
  );

  return (
    <VeloxPopupTemplate
      className="velox-force-close-game-dialog"
      title={t("veloxForceCloseGameDialogTitle")}
      onClose={onClose}
      onConfirm={onConfirm}
      confirmText={t("yes")}
    />
  );
};

export default VeloxForceCloseGame;
