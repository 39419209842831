import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getTournamentBuyinInfo } from "../../../../helpers/getTournamentBuyinInfo";
import { classNames } from "../../../../helpers/pureFunctions";
import { validatePassword } from "../../../../helpers/validators";
import {
  casinoTournamentRegisterErrorMessageReset,
  casinoTournamentRegisterRequest,
} from "../../../../store/actionCreators/casinoTournamentRegister";
import { closeDialog } from "../../../../store/actionCreators/dialogManager";
import {
  getCurrentExchangeRate,
  getRegisteredCasinoTournament,
  getRegisterErrorMessage,
  getSelectedCurrency,
  getWallet,
  getWallets,
  isRegisterPending,
} from "../../../../store/selectors";
import PopUpDialogTemplate from "../../../common/PopUpDialogTemplate";
import "./TournamentRegister_v2.scss";
import moment from "moment";
import Input from "../../../common/Input";
import TournamentItemDetailInfo from "../../../common/TournamentItemDetailInfo";
import PopUpItemInfo from "../../../common/PopUpItemInfo";
import { useFormatCurrency } from "../../../../hooks/useFormatCurrency";
import { setOngoingTournamentsModal } from "../../../../store/actionCreators/onGoingTournamentsModal";

const TournamentRegister_v2 = ({
  tournament,
  isMobile,
  isPortrait,
  isLandscape,
}) => {
  const formatCurrency = useFormatCurrency();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    tournamentName,
    tournamentId,
    totalSpins,
    startDate,
    buyIn,
  } = tournament;
  const [tournamentGame] = tournament.tournamentGames;
  
  const [radioOption, setRadioOption] = useState("userBalance");
  const [password, setPassword] = useState({
    value: "",
    errorMessage: "",
  });
  const GOSWallet = useSelector(getWallet)("GOS");
  const wallets = useSelector(getWallets);
  const registerErrorMessage = useSelector(getRegisterErrorMessage);
  const registeredCasinoTournament = useSelector(getRegisteredCasinoTournament);
  const pending = useSelector(isRegisterPending);
  const selectedCurrency = useSelector(getSelectedCurrency);
  const currentExchangeRate = useSelector(getCurrentExchangeRate)(buyIn?.currency);

  const {
    allowPaymentMain,
    allowPaymentGOSPoint,
    formatForMainBuyin,
    GOSPointBuyinWithoutIcon,
    formatForGOSPointBuyinWithoutIcon,
    buyinValueWithoutIcon,
    allowByTicket,
  } = getTournamentBuyinInfo(tournament, formatCurrency);


  useEffect(() => {
    if (registerErrorMessage && !password.errorMessage) {
      setPassword((prev) => ({ ...prev, errorMessage: registerErrorMessage }));
    }
    return () => dispatch(casinoTournamentRegisterErrorMessageReset());
  }, [registerErrorMessage, password.errorMessage]);

  useEffect(() => {
    if (
      tournament?.isPasswordProtected &&
      registeredCasinoTournament?.tournamentId === tournament?.tournamentId
    ) {
      onClose();
    }
  }, [registeredCasinoTournament, tournament]);

  const onClose = useCallback(
    () => dispatch(closeDialog("tournamentRegister")),
    [dispatch]
  );

  const onTournamentRegisterRequest = useCallback(
    (protectedPassword) => {
      const params = {
        ...tournament,
        paymentType: allowPaymentGOSPoint
          ? allowPaymentMain
            ? radioOption === "vipPoints" ? 'GOS' : 'realMoney'
            : 'GOS'
          : 'realMoney',
      };
      if (protectedPassword) params.password = protectedPassword;
      dispatch(setOngoingTournamentsModal(true));
      localStorage.setItem('isAlreadyShowedOngoingTournaments', 'true');
      dispatch(casinoTournamentRegisterRequest(params));
    },
    [dispatch, radioOption]
  );

  const onHandlePasswordProtectedSubmit = useCallback(() => {
    const errorMessage = validatePassword(password.value);
    if (!errorMessage) {
      setPassword((prev) => ({ ...prev, errorMessage: "" }));
      onTournamentRegisterRequest(password.value);
    } else {
      setPassword((prev) => ({ ...prev, errorMessage }));
    }
  }, [password]);

  const onSubmit = useCallback(() => {
    if (tournament.isPasswordProtected) {
      onHandlePasswordProtectedSubmit();
    } else {
      onTournamentRegisterRequest();
      onClose();
    }
  }, [
    tournament,
    onHandlePasswordProtectedSubmit,
    onTournamentRegisterRequest,
  ]);

  const onChangeHandler = (e) => {
    setRadioOption(e.target.value);
  };

  const tournamentRegisterV2Classes = classNames("tournament-register-v2", {
    mobile: isMobile,
    portrait: isMobile && isPortrait,
    landscape: isMobile && isLandscape,
  });

  return (
    <PopUpDialogTemplate
      className="tournament-register-pop-up"
      dialogName="tournamentRegisterDialog"
      onClose={onClose}
      onConfirm={onSubmit}
      confirmText={allowByTicket && allowPaymentMain ? t('buyTicketFor', { amount: formatForMainBuyin }): t('registratonPopupButtonText')}
      withoutDescription
      pending={pending}
    >
      <div className={tournamentRegisterV2Classes}>
        <div className="tournament-register-v2__tournament-info-section">
          <div className="tournament-register-v2__tournament-info">
            <div className="tournament-register-v2__tournament-info-header">
              <div className="tournament-register-v2__tournament-info-header--img">
                <img src={tournamentGame.thumbnail} alt="" />
              </div>
              {isMobile && isPortrait && (
                <div className="tournament-register-v2__tournament-info-header--title">
                  <span dangerouslySetInnerHTML={{__html: tournamentName}} />
                  <span>{`(#${tournamentId})`}</span>
                </div>
              )}
            </div>
            <div className="tournament-register-v2__tournament-info--details">
              {!isPortrait && (
                <div className="tournament-register-v2__tournament-info--details-title">
                  <span dangerouslySetInnerHTML={{__html: `${tournamentName} (#${tournamentId})`}} />
                </div>
              )}
              <div className="tournament-register-v2__tournament-info--details-description">
                <TournamentItemDetailInfo title={'spins'} info={totalSpins} first />
                <TournamentItemDetailInfo title={'price'} info={buyinValueWithoutIcon} />
                <TournamentItemDetailInfo title={'startDate'} info={moment(startDate).format('HH:mm | DD/MM/YY')} last />
              </div>
            </div>
          </div>
        </div>
        <div className="tournament-register-v2__wallets-section">
          {+wallets?.[0]?.balance >= 0 && allowPaymentMain &&
            !allowPaymentGOSPoint && (
              <div
                  className={`tournament-register-v2__wallets-section--item tournament-register-v2__wallets-section--item--rate ${
                    radioOption === "userBalance" ? "selected" : ""
                  }`}
                >
                  <div className="tournament-register-v2__wallets-section--item-label-value-wrapper">
                    <div className="tournament-register-v2__wallets-section--item-label">
                      <label htmlFor="userBalance">
                        <span className="wallets-section--item-label__title">{`${t(
                          "mainBalance"
                        )}:`}</span>
                        <span className="wallets-section--item-label__balance">
                          {formatCurrency(wallets?.[0]?.balance, wallets?.[0]?.currency)}
                        </span>
                      </label>
                    
                    </div>
                    <div className="tournament-register-v2__wallets-section--item-value">
                      {buyinValueWithoutIcon}
                    </div>
                  </div>
                  {(selectedCurrency?.label === '!convert' && currentExchangeRate?.baseCurrencyCode !== currentExchangeRate?.currencyCode) && (
                    <span className="tournament-register-v2__wallets-section--item-rate-info">
                      {formatCurrency((buyIn?.buyin + buyIn?.fee) , buyIn.currency)} = {formatCurrency((buyIn?.buyin + buyIn?.fee) / currentExchangeRate?.exchangeRate, currentExchangeRate?.baseCurrencyCode)}
                    </span>
                  )}
                </div>
            )
          }
          {+GOSWallet?.balance >= 0 && allowPaymentGOSPoint &&
            !allowPaymentMain && (
              <PopUpItemInfo title={'bonusCoins'} titleInfo={formatForGOSPointBuyinWithoutIcon(GOSWallet.balance)} value={GOSPointBuyinWithoutIcon} variant={'succsess'} />
            )
          }
          {(+wallets?.[0]?.balance >= 0 || +GOSWallet?.balance >= 0) && allowPaymentMain && allowPaymentGOSPoint && (
            <>
              {+wallets?.[0]?.balance >= 0 && (
                <div
                  className={`tournament-register-v2__wallets-section--item tournament-register-v2__wallets-section--item--rate ${
                    radioOption === "userBalance" ? "selected" : ""
                  }`}
                >
                  <div className="tournament-register-v2__wallets-section--item-label-value-wrapper">
                    <div className="tournament-register-v2__wallets-section--item-label">
                      <input
                        onChange={onChangeHandler}
                        checked={radioOption === "userBalance"}
                        value="userBalance"
                        type="radio"
                        name="balanceOption"
                        id="userBalance"
                      />
                      <label htmlFor="userBalance">
                        <span className="wallets-section--item-label__title">{`${t(
                          "mainBalance"
                        )}:`}</span>
                        <span className="wallets-section--item-label__balance">
                          {formatCurrency(wallets?.[0]?.balance, wallets?.[0]?.currency)}
                        </span>
                      </label>
                    
                    </div>
                    <div className="tournament-register-v2__wallets-section--item-value">
                      {formatForMainBuyin}
                    </div>
                  </div>
                  {(selectedCurrency?.label === '!convert' && currentExchangeRate?.baseCurrencyCode !== currentExchangeRate?.currencyCode) && (
                    <span className="tournament-register-v2__wallets-section--item-rate-info">
                      {formatCurrency((buyIn?.buyin + buyIn?.fee), buyIn.currency)} = {formatCurrency((buyIn?.buyin + buyIn?.fee) / currentExchangeRate?.exchangeRate, currentExchangeRate?.baseCurrencyCode)}
                    </span>
                  )}
                </div>
               )}
              {+GOSWallet?.balance >= 0 && (
                <div
                  className={`tournament-register-v2__wallets-section--item ${
                    radioOption === "vipPoints" ? "selected" : ""
                  }`}
                >
                  <div className="tournament-register-v2__wallets-section--item-label">
                    <input
                      onChange={onChangeHandler}
                      checked={radioOption === "vipPoints"}
                      value="vipPoints"
                      type="radio"
                      name="balanceOption"
                      id="vipPoints"
                    />
                    <label htmlFor="vipPoints">
                      <div className="wallets-section--item-label-value">
                        <div className="wallets-section--item-label__title">
                          {`${t("vipPoints")}:`}
                        </div>
                        <div className="wallets-section--item-label__balance">
                          {formatForGOSPointBuyinWithoutIcon(GOSWallet.balance)}
                        </div>
                      </div>
                    </label>
                  </div>
                  <div className="tournament-register-v2__wallets-section--item-value">
                    {GOSPointBuyinWithoutIcon}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
        {tournament?.isPasswordProtected && (
          <div className="tournament-register-v2__password-protected">
            <div className="tournament-register-v2__password-protected--label">
              {t("enterPrivatePassword")}
            </div>
            <Input
              type="password"
              className={`tournament-register-v2__password-protected--input ${
                password.errorMessage
                  ? "tournament-register-v2__password-protected--input-error"
                  : ""
              }`}
              value={password.value}
              callbackFn={(e) =>
                setPassword((prev) => ({
                  ...prev,
                  value: e.target.value,
                  errorMessage: "",
                }))
              }
              showPasswordToggle
            />
            {password.errorMessage && (
              <span className="tournament-register-v2__password-protected--error">
                {t(password.errorMessage)}
              </span>
            )}
          </div>
        )}
      </div>
    </PopUpDialogTemplate>
  );
};

export default TournamentRegister_v2;
