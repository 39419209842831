import { select, call, put, take, cancel, fork } from "redux-saga/effects";
import { getToken } from "../selectors";
import { apiPost } from "./callApi";
import apiRoutes from "../../constants/apiRoutes";
import types from '../actionTypes';
import { createSpinAndGoRegisterFailure, createSpinAndGoRegisterSucsess } from "../actionCreators/spinAndGoRegister";
import { getSpinAndGoConfigsRequest } from "../actionCreators/spinAndGoConfigs";
import { openDialog } from "../actionCreators/dialogManager";

function* processCreateSpinAndGoRegisterRequest({ tournamentData }) {
  try {
    const { configId, name } = tournamentData;
    const token = yield select(getToken);
    const response = yield call(apiPost, apiRoutes.SPIN_AND_GO_REGISTER, { configId, token });
    if (response?.data?.success) {
      yield put(createSpinAndGoRegisterSucsess());
      yield put(getSpinAndGoConfigsRequest());
      yield put(openDialog({ dialogType: 'veloxSuccessfulRegistration', payload: { name } }));
    }
  } catch (e) {
    yield put(createSpinAndGoRegisterFailure(e, tournamentData));
  }
}

export function* watchCreateSpinAndGoRegisterRequest() {
  let forked = null;
  while (true) {
    const action = yield take(types.CREATE_SPIN_AND_GO_REGISTER_REQUEST);
    if (forked) yield cancel(forked);
    forked = yield fork(processCreateSpinAndGoRegisterRequest, action);
  }
}
