import React from "react";
import { useTranslation } from "react-i18next";
import { useFormatCurrency } from "../../../../hooks/useFormatCurrency";
import "./TournamentsHistoryTableItemResultsDesktop.scss";

const RESULTS_TABLE_CAPTIONS = ["position", "player", "points", "win"];
const TOURNAMENT_WINNERS_RESULTS = [
  {key: "position1", award: "gold", position: 1},
  {key: "position2", award: "silver", position: 2},
  {key: "position3", award: "bronze", position: 3},
];

const TournamentsHistoryTableItemResultsDesktop = ({ data }) => {
  const { t } = useTranslation();
  const formatCurrency = useFormatCurrency();

  const { requesterPosition } = data;

  return (
    <div className="history-table-item-results">
      <div className="history-table-item-results__player-results">
        <div className="player-results-table-caption">
          {RESULTS_TABLE_CAPTIONS.map((title) => (
            <span key={title}>{t(title)}</span>
          ))}
        </div>
        <div className="player-results-table-body">
          <span>#{requesterPosition?.position}</span>
          <span>{requesterPosition?.username}</span>
          <span>{requesterPosition?.points}</span>
          <span>{requesterPosition?.win?.type === 'externalPrize' ? requesterPosition?.win?.prizeName : requesterPosition?.win?.amount >= 0 ? formatCurrency(requesterPosition.win.amount, requesterPosition.win.currency) : 0}</span>
        </div>
      </div>
      <div className="history-table-item-results__tournament-results">
        <div className="tournament-results-table-caption">
          {RESULTS_TABLE_CAPTIONS.map((title) => (
            <div className="tournament-results-table-caption__item" key={title}>{t(title)}</div>
          ))}
        </div>
        <div className="tournament-results-table-body">
          {TOURNAMENT_WINNERS_RESULTS.map((el, index) => (
            <div key={el.key} className="tournament-results-table-body__item">
              <div className="tournament-results-table-body__item-col">{`#${el.position}`} <div className={`award-position ${el.award}`}></div></div>
              <div className="tournament-results-table-body__item-col">{data[el.key]?.username}</div>
              <div className="tournament-results-table-body__item-col">{data[el.key]?.points}</div>
              <div className="tournament-results-table-body__item-col">{data[el.key]?.win?.type === 'externalPrize' ? data[el.key]?.win?.prizeName : data[el.key]?.win?.amount >= 0 ? formatCurrency(data[el.key].win.amount, data[el.key].win.currency) : 0}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TournamentsHistoryTableItemResultsDesktop;
