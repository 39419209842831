import { take, fork, cancel, call, put, select } from "redux-saga/effects";
import apiRoutes from "../../constants/apiRoutes";
import { getToken } from "../selectors";
import types from "../actionTypes";
import { apiGetReport } from "./callApi";
import {
  getGosPlayerLastWinFailure,
  getGosPlayerLastWinSucsess,
} from "../actionCreators/gosPlayerLastWin";

function* processGetGosPlayerLastWinRequest() {
  try {
    const token = yield select(getToken);
    const params = { token };

    const response = yield call(
      apiGetReport,
      apiRoutes.GET_GOS_PLAYER_LAST_WIN,
      params
    );
    yield put(getGosPlayerLastWinSucsess(response.data?.result));
  } catch (e) {
    yield put(getGosPlayerLastWinFailure(e));
  }
}

export default function* watchGetGosPlayerLastWinRequest() {
  let forked = null;
  while (true) {
    const action = yield take(types.GET_GOS_PLAYER_LAST_WIN_REQUEST);
    if (forked) yield cancel(forked);
    forked = yield fork(processGetGosPlayerLastWinRequest, action);
  }
}
