import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { requestReadMessage } from '../../../../store/actionCreators/messages';
import PopUpDialogTemplate from '../../../common/PopUpDialogTemplate';
import { useFormatCurrency } from '../../../../hooks/useFormatCurrency';
import { closeDialog } from '../../../../store/actionCreators/dialogManager';
import { resetTournamentGameUrl } from '../../../../store/actionCreators/tournamentGameUrl';
import { getOpenTournamentGameId, getTournamentGameUrl } from '../../../../store/selectors';
import './Regular.scss';

const TournamentEndRegular = ({ payload, dialogKey }) => {
  const dispatch = useDispatch();
  const tournamentGameUrl = useSelector(getTournamentGameUrl);
  const openTournamentGameId = useSelector(getOpenTournamentGameId);
  const formatCurrency = useFormatCurrency();
  const { tournamentName } = payload;

  const { t } = useTranslation();

  const isTypeTourTicket = payload?.winning?.type === 'tourTicket';

  const tournamentResults = [
    { label: `${t('score')}:`, value: payload?.score },
    { label: `${t('yourPosition')}:`, value: payload?.playerPosition },
    { label: `${t('tournamentId')}:`, value: payload?.tournamentId },
  ];

  const onClose = useCallback(
    () => {
      if (payload?.messageId) {
        dispatch(requestReadMessage(payload.messageId));
      }
      dispatch(closeDialog(dialogKey));
    },
    [dispatch]
  );

  useEffect(() => {
    if (tournamentGameUrl && String(payload?.tournamentId) === String(openTournamentGameId)) {
      dispatch(resetTournamentGameUrl());
    }
  }, [payload, openTournamentGameId, tournamentGameUrl]);

  return (
    <PopUpDialogTemplate
      className="tournament-end-regular-pop-up"
      dialogName="tournamentEndRegularDialog"
      textParams={{ tournamentName }}
      customTextReplacingHowToPlay={isTypeTourTicket}
      onClose={onClose}
    >
      <div className="tournament-end-regular">
        <span className="tournament-end-regular__text">{`${t('tournamentResults')}:`}</span>
        <div className="tournament-end-regular__info-wrapper">
          {tournamentResults.map((result) => {
            return (
              <div className="tournament-end-regular__info-item">
                <span className="tournament-end-regular__info-item-label">{result.label}</span>
                <span className="tournament-end-regular__info-item-value">{result?.value}</span>
              </div>
            );
          })}
        </div>
        {(payload?.winning?.amount > 0 || isTypeTourTicket || payload?.winning?.prizeName) && (
          <div className="tournament-end-regular__winning-wrapper">
            <div className="tournament-end-regular__winning">
              <div className="tournament-end-regular__winning-icon-wrapper">
                <div className={`tournament-end-regular__${isTypeTourTicket ? 'ticket' : 'winning'}-icon`} />
              </div>
              <div className="tournament-end-regular__winning-info-wrapper">
                {!isTypeTourTicket && (
                  <span className="tournament-end-regular__winning-info-title">
                    {payload?.winning?.prizeName
                      ? payload?.winning?.prizeName
                      : formatCurrency(payload.winning.amount, payload.winning.currency)}
                  </span>
                )}
                <span
                  className="tournament-end-regular__winning-info-subtitle"
                  dangerouslySetInnerHTML={{
                    __html: isTypeTourTicket
                      ? t('winningTicket', { tournamentName: payload?.winning?.targetName })
                      : payload?.winning?.prizeName
                      ? t('winningExternalPrizeText')
                      : t('addedToYourBalance'),
                  }}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </PopUpDialogTemplate>
  );
};

export default TournamentEndRegular;
