import moment from "moment";
import { useTranslation } from "react-i18next";
import CountdownTimer from "../../../CountdownTimer";
import { useFormatCurrency } from "../../../../hooks/useFormatCurrency";
import TournamentButtonState from "../../../TournamentButtonState";
import { useOnGoningEvents } from "../OnGoningEventsProvider";
import Button from "../../../common/Button";

const MobileOnGoingTournamentItem = ({ tournament }) => {
  const { t } = useTranslation();
  const formatCurrency = useFormatCurrency();
  const { activeTab, closeLayout } = useOnGoningEvents();
  const { tournamentGames, endDate, prizePool, tournamentName, startDate } =
    tournament;
  const isStartsIn = moment(startDate).isAfter(moment());
  return (
    <div className="mobile-on-going-tournament-item">
      <div className="item">
        <div className="item__countdown-label-wrapper">
          <span className="item__countdown-label">
            {t(isStartsIn ? "startsIn" : "endsIn")}
          </span>
          <div className="item__countdown-wrapper">
            <CountdownTimer
              variant="thirdType"
              countdownTimestampMs={isStartsIn ? startDate : endDate}
              simple
              skeleton
              onEndText={isStartsIn ? t('starting') : t('finished')}
            />
          </div>
        </div>
        <div className="item__card-wrapper">
          {activeTab !== 'upcoming' && <div className="item__card-header">
            {activeTab === 'play' && <span className="item__card-header-left-text">{t('spinsLeft')}: <span>{tournament?.spins}</span></span>}
            {activeTab === 'rebuy' && <span className="item__card-header-left-text"># {t('rebuy')} <span>
                    {tournament?.rebuysLeft ?? tournament?.rebuy.count}/
                  </span>
                  <b>{tournament?.rebuy.count}</b>{" "}</span>}
            <span className="item__card-header-right-text">{t('pos')} <span>{tournament?.playerPosition}</span></span>
          </div>}
          <div className="item__card-top">
            <div className="item__card-image-id-wrapper">
              <span className="item__card-id">ID: {tournament?.tournamentId}</span>
              <img src={tournamentGames[0].thumbnail} alt="thumbnail" />
            </div>
            <div className="item__card-prize-name-wrapper">
              <span className="item__card-prize">
                {formatCurrency(
                  prizePool.value,
                  prizePool.currency,
                  2,
                  prizePool?.type
                )}
              </span>
              <span
                className="item__card-name"
                dangerouslySetInnerHTML={{ __html: tournamentName }}
              />
            </div>
          </div>
          <div className="item__card-bottom">
            <div className="item__tournament-button-state-wrapper">
              {activeTab === 'upcoming' ? <Button disabled >{t('play')}</Button> : <TournamentButtonState rubutButtonText="REBUY" cb={closeLayout} showBuyin tournament={tournament} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileOnGoingTournamentItem;
