import React from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { classNames } from "../../../../../helpers/pureFunctions";
import "./TournamentGameInfoBoard.scss";

const TournamentGameInfoBoard = ({
  title,
  data,
  totalCountInfo,
  icon,
  view,
  threeColumn,
  children,
}) => {
  if (!data) return null;
  const { firstColumnTitle, secondColumnTitle, dataInfo } = data;

  const { t } = useTranslation();

  const tournamentGameInfoBoardClasses = classNames(
    "tournament-game-info-board-wrapper",
    {
      mobile: isMobile,
      [view]: view,
      "three-column": threeColumn,
    }
  );

  return (
    <div className={tournamentGameInfoBoardClasses}>
      <div className="tournament-game-info-board-wrapper__header">
        <div className="tournament-game-info-board-wrapper__header--title">
          {t(title)}
        </div>
        {(totalCountInfo || icon) && (
          <div className="tournament-game-info-board-wrapper__header--info">
            {icon && <i className={`icon-${icon} header-info-icon`} />}
            <span
              className={`tournament-game-info-board-wrapper__header--info-total ${
                !icon ? "primary-info-text" : ""
              }`}
            >
              {totalCountInfo}
            </span>
          </div>
        )}
      </div>
      <div className="tournament-game-info-board-wrapper__body">
        <div className="tournament-game-info-board-wrapper__body-labels">
          <span className="tournament-game-info-board-wrapper__body-label first">
            {t(firstColumnTitle)}
          </span>
          <span className="tournament-game-info-board-wrapper__body-label second">
            {t(secondColumnTitle)}
          </span>
          {!!threeColumn && (
            <span className="tournament-game-info-board-wrapper__body-label third">
              {t(data?.thirdColumnTitle)}
            </span>
          )}
        </div>
        {!!dataInfo?.length && (
          <div className="tournament-game-info-board-wrapper__body-items">
            {dataInfo?.map((item) => (
              <div
                key={item?.id}
                className={`tournament-game-info-board-wrapper__body-item ${
                  !!item?.self ? "current" : ""
                }`}
              >
                <div className="board-item--property">
                  <span className="board-item--property--order">
                    {item?.place}.
                  </span>
                  <span className="board-item--property--name">
                    {t(item?.rowName)}
                  </span>
                </div>
                <div className={`board-item--value ${Boolean(item.secondColumnIcon) && 'with-icon'}`}>
                  <span className="board-item--value-name">
                    {item?.rowValue === "fixedPrize" ? "" : Boolean(item.secondColumnIcon) ? item?.rowValue : item?.rowValue?.url ? <a target="_blank" href={item?.rowValue?.url}>{item?.rowValue?.name}</a> : item?.rowValue}
                  </span>
                </div>
                {threeColumn && (
                  <div className="board-item--value second-value">
                    <span className="board-item--value-name">
                      {item?.thirdColumnValue?.url ? <a target="_blank" href={item?.thirdColumnValue?.url}>{item?.thirdColumnValue?.name}</a> : item?.thirdColumnValue}
                    </span>
                  </div>
                )}
              </div>
            ))}
          </div>
        )}

        {children}
      </div>
    </div>
  );
};

export default TournamentGameInfoBoard;
