import { all, call } from "redux-saga/effects";
import casinoTournamentsRequests from "./casinoTournament";
import casinoTournamentRegistrationRequests from "./casinoTournamentRegister";
import {watchRequestTournamentGameUrl, watchRequestTournamentGameUrlReset} from "./tournamentGameUrl";
import watchTournamentCasinoUnregister from "./casinoTournamentUnregister";
import casinoTournamentPlayersRequests from "./casinoTournamentPlayers";
import getAccountBalanceRequests from "./getAccountBalance";
import getAccountBalanceRequestsFromHost from "./getAccountBalanceFromHost";
import watchTournamentLastBetRequest from "./tournamentLastBetRequest";
import watchTournamentCasinoRebuy from "./casinoTournamentRebuy";
import watchCasinoTournamentsHistoryRequest from "./casinoTournamentsHistory";
import watchGetLocalExchangeRatesRequest from "./localExchangeRates";
import { watchPublicMessages, watchReadMessage } from "./messages";
import watchAvatarRequsets from "./avatars";
import jackpotsRequests from "./jackpot";
import { watchGetPlayerStateInTournamentsRequest } from "./playerStateInTournaments";
import { watchGetCMSResponseRequest } from "./CMSResponse";
import watchGetGosPlayerLastWinRequest from "./gosPlayerLastWin";
import { watchGetGosPopupsFromCMSRequest } from "./gosPopupsFromCMS";
import { watchGetSpinAndGoConfigsRequest } from "./spinAndGoConfigs";
import { watchCreateSpinAndGoRegisterRequest } from "./spinAndGoRegister";
import { watchCreateSpinAndGoUnregRequest } from "./spinAndGoUnreg";
import { watchSpinAndGoOpenCloseStateRequest } from "./spinAndGoGameOpenCloseState";
import { watchUpdatePlayer } from "./updatePlayer";
import { watchUpdateTournament } from "./updateTournament";
import { watchGetSpinAndGoInstancesRequest } from "./spinAndGoInstances";
import { watchGetSpinAndGoWinnersRequest } from "./spinAndGoWinners";
import watchRequestCashGamesUrl from "./cashTournamentURL";

function* rootSaga() {
  yield all([
    call(casinoTournamentsRequests),
    call(casinoTournamentRegistrationRequests),
    call(watchTournamentCasinoRebuy),
    call(watchRequestTournamentGameUrl),
    call(watchTournamentCasinoUnregister),
    call(casinoTournamentPlayersRequests),
    call(getAccountBalanceRequests),
    call(getAccountBalanceRequestsFromHost),
    call(watchTournamentLastBetRequest),
    call(watchCasinoTournamentsHistoryRequest),
    call(watchGetLocalExchangeRatesRequest),
    call(watchReadMessage),
    call(watchAvatarRequsets),
    call(jackpotsRequests),
    call(watchGetPlayerStateInTournamentsRequest),
    call(watchPublicMessages),
    call(watchGetCMSResponseRequest),
    call(watchGetGosPlayerLastWinRequest),
    call(watchGetGosPopupsFromCMSRequest),
    call(watchGetSpinAndGoConfigsRequest),
    call(watchCreateSpinAndGoRegisterRequest),
    call(watchCreateSpinAndGoUnregRequest),
    call(watchSpinAndGoOpenCloseStateRequest),
    call(watchUpdatePlayer),
    call(watchUpdateTournament),
    call(watchGetSpinAndGoInstancesRequest),
    call(watchGetSpinAndGoWinnersRequest),
    call(watchRequestCashGamesUrl),
    call(watchRequestTournamentGameUrlReset),
  ]);
}

export default rootSaga;
