import { useTranslation } from 'react-i18next';

import './TournamentGameDetailsInfoWidget.scss';

const TournamentGameDetailsInfoWidget = ({
  title,
  info,
  hasProgressBar = false,
  progressTarget = 0,
  progressStatus = 0,
  variant = 'widget'
}) => {
  const { t } = useTranslation();

  return (
    <div className={`tournament-game-details-info-container ${variant} ${title}`}>
      <div className='tournament-game-details-info-container__info'>
        <span className={`info-text ${(typeof info === 'number') ? '' : 'info-text--skeleton'}`}>{info}</span>
      </div>
      <div className='tournament-game-details-info-container__title'>
        <span className='title-text'>{t(title)}</span>
      </div>
      {hasProgressBar && (
        <div className='tournament-game-details-info-container__progress-bar'>
          <span
            style={{ width: `${progressStatus / progressTarget * 100}%` }}
            className='tournament-game-details-info-container__progress-bar--progress'
          ></span>
        </div>
      )}
    </div>
  )
}

export default TournamentGameDetailsInfoWidget;