import types from '../actionTypes';

// get avatars menu

export const getAvatarMenuRequest = () => ({
  type: types.GET_AVATAR_MENU_REQUEST,
});

  
export const getAvatarMenuFailure = (e) => ({
  type: types.GET_AVATAR_MENU_FAILURE,
	exception: e,
});

  
export const getAvatarMenuSucsess = (payload) => ({
  type: types.GET_AVATAR_MENU_SUCSESS,
  payload,
});

// Set Avatar

export const setAvatarRequest = (imagePath) => ({
  type: types.SET_AVATAR_REQUEST,
  imagePath,
});

  
export const setAvatarFailure = (e) => ({
  type: types.SET_AVATAR_FAILURE,
	exception: e,
});

  
export const setAvatarSucsess = (userIds) => ({
  type: types.SET_AVATAR_SUCSESS,
  setAvatar: true,
  userIds,
});


// get users avatar

export const getUsersAvatarRequest = ({ userIds, currentUserId }) => ({
  type: types.GET_USERS_AVATAR_REQUEST,
  userIds,
  currentUserId
});

  
export const getUsersAvatarFailure = (e) => ({
  type: types.GET_USERS_AVATAR_FAILURE,
	exception: e,
});

  
export const getUsersAvatarSucsess = (payload) => ({
  type: types.GET_USERS_AVATAR_SUCSESS,
  payload,
});
