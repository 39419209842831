import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import { casinoTournamentUnregisterRequest } from '../../../store/actionCreators/casinoTournamentUnregister';
import { classNames } from "../../../helpers/pureFunctions";
import { closeDialog } from '../../../store/actionCreators/dialogManager';
import CountdownTimer from '../../CountdownTimer';
import PopUpDialogTemplate from '../../common/PopUpDialogTemplate'
import { isUnRegisterPending } from '../../../store/selectors';
import './TournamentUnregister.scss';


const TournamentUnregister = ({ tournament, isMobile, isPortrait, isLandscape }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const pending = useSelector(isUnRegisterPending);

  const onClose = useCallback(() => dispatch(closeDialog('tournamentUnregister')), [dispatch]);

  const onSubmit = useCallback(() => {
    dispatch(casinoTournamentUnregisterRequest(tournament));
    onClose();
  }, [dispatch, onClose, tournament]);

  const tournamentUnregisterClassNames = classNames('tournament-unregister', {
    'tournament-unregister--portrait': isMobile && isPortrait,
    'tournament-unregister--landscape': isMobile && isLandscape,
  });

  return (
    <PopUpDialogTemplate
      className="tournament-unregister-pop-up"
      dialogName="badBetDialog"
      titleIcon="alert-octagon"
      withoutDescription
      onClose={onClose}
      onCloseBtn
      closeText={t('noCancel')}
      onConfirm={onSubmit}
      confirmText={t('yesIMSure')}
      confirmBtnDanger
      pending={pending}
    >
      <div className={tournamentUnregisterClassNames}>
        <div className="tournament-unregister__date-section">
          <CountdownTimer countdownTimestampMs={tournament.startDate} variant='succsess' size='sm-rectangle' />
        </div>
        <div className="tournament-unregister__description-section">
          <span>{t("tournamentUnregisterDescriptionText")}</span>
        </div>
      </div>
    </PopUpDialogTemplate>
  );
};

export default TournamentUnregister;
