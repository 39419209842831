const settings = {
  apiUrl: "https://casinotoursapi.draftplaza.com",
  GOS_LOBBY_URL: "https://casinotoursapi.draftplaza.com",
  reportUrl: "https://reports.draftplaza.com",
  SILVER_HOME_URL: "https://apps2.pokerplaza.com/coinbar",
  SOCKET_URL: ["https://casinotoursapi.draftplaza.com"],
  ACCOUNTING_API: "https://webapi.draftplaza.com",
  MEDIA_URL: "https://webapi.draftplaza.com/api",
  FILE_STORAGE_ADDRESS: "https://strapi4betpassion.draftplaza.com"
};

export default settings;
