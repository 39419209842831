import { classNames } from '../../../helpers/pureFunctions';
import Modal from '../../common/Modal';
import TournamentsHistoryPage from '../../TournamentsHistoryPage';
import './TournamentsHistory.scss'

const TournamentsHistory = ({ isMobile, isPortrait, isLandscape }) => {

    const tournamentsHistoryModalContainerClasses = classNames('tournaments-history-modal-container', {
        'tournaments-history-modal-container--portrait': isMobile && isPortrait,
        'tournaments-history-modal-container--landscape': isMobile && isLandscape,
    });

    return (
        <Modal fullScreen={true}>
            <Modal.Header />
            <Modal.Body>
                <div className={tournamentsHistoryModalContainerClasses}>
                    <TournamentsHistoryPage />
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default TournamentsHistory;
