import { select, call, put, take, cancel, fork } from "redux-saga/effects";
import { getToken } from "../selectors";
import { apiPost } from "./callApi";
import apiRoutes from "../../constants/apiRoutes";
import types from '../actionTypes';
import { createSpinAndGoUnregFailure, createSpinAndGoUnregSucsess } from "../actionCreators/spinAndGoUnreg";
import { getSpinAndGoConfigsRequest } from "../actionCreators/spinAndGoConfigs";
import { openDialog } from "../actionCreators/dialogManager";

function* processCreateSpinAndGoUnregRequest({ configId }) {
  try {
    const token = yield select(getToken);

    const response = yield call(apiPost, apiRoutes.SPIN_AND_GO_UNREG, { configId, token });
    if (response?.data?.success) {
      yield put(createSpinAndGoUnregSucsess());
      yield put(getSpinAndGoConfigsRequest());
      yield put(openDialog({ dialogType: 'veloxUnregister'}))
    }
  } catch (e) {
    yield put(createSpinAndGoUnregFailure(e));
  }
}

export function* watchCreateSpinAndGoUnregRequest() {
  let forked = null;
  while (true) {
    const action = yield take(types.CREATE_SPIN_AND_GO_UNREG_REQUEST);
    if (forked) yield cancel(forked);
    forked = yield fork(processCreateSpinAndGoUnregRequest, action);
  }
}