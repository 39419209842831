import moment from 'moment';
import React from 'react';
import { useFormatCurrency } from '../../../../hooks/useFormatCurrency';
import InfoTab from '../../../common/InfoTab';
import TournamentsHistoryTableItemResults from '../../TournamentsHistoryTableItemResults';
import './TournamentsHistoryTableItemDesktop.scss';

const TournamentsHistoryTableItemDesktop = ({ data, opened, cb }) => {
  const formatCurrency = useFormatCurrency();

  const {
    tournamentId,
    tournamentName,
    prizePool,
    totalPlayerCount,
    tournamentEndDate
  } = data

  return (
    <div className='history-table-item' onClick={() => cb(tournamentId)}>
      <div className='history-table-item-container'>
        <div className={`history-table-item-container__header ${opened ? 'show' : 'hide'}`}>
          <div className={'history-table-item-container__header--tournament'}>
            <div className={'history-table-item-container__header--tournament-image'}>
              <img src={data?.tournamentGames[0]?.thumbnail} alt="" />
            </div>
            <div className={'history-table-item-container__header--tournament-name'}>
              <span className='tournament-name' dangerouslySetInnerHTML={{__html: `${tournamentName} (#${tournamentId})`}} />
            </div>
          </div>
          <div className={'history-table-item-container__header--prizePool'}>{formatCurrency(prizePool?.value, prizePool?.currency, 2, prizePool?.type)}</div>
          <div className={'history-table-item-container__header--players'}>{totalPlayerCount}</div>
          <div className={'history-table-item-container__header--date'}>{moment(tournamentEndDate).format("DD/MM/YYYY hh:mm")}</div>
          <div className={'history-table-item-container__header--toggler'}>
            <span><i className='icon icon-casino-tournament'/></span>
          </div>
          <div className="history-table-item-container__header--info-tab">
            <InfoTab text={'finished'} background="violet" size="sm" />
          </div>
        </div>
        <div className={`history-table-item-container__body ${opened ? 'show' : 'hide'}`}>
          <div className='history-table-item-container__body--item'>
            <TournamentsHistoryTableItemResults data={data} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default TournamentsHistoryTableItemDesktop