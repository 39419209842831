import { memo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import AvatarWidget from "../AvatarWidget";
import BalanceWidget from "../BalanceWidget";
import Logo from "../Logo";
import { getToken } from "../../store/selectors";
import { openDialog } from "../../store/actionCreators/dialogManager";
import config from "../../configs/config";
import "./Header.scss";

const Header = () => {
  const dispatch = useDispatch();
  const token = useSelector(getToken);

  const openHistory = useCallback(() => {
    dispatch(openDialog({ dialogType: "tournamentsHistory" }));
  }, []);

  const openHowToPlayDialog = useCallback(() => {
    config?.externalLinkHowToPlay
      ? window.open(config?.externalLinkHowToPlay, "_blank")
      : dispatch(openDialog({ dialogType: "howToPlay" }));
  }, []);

  const openSettings = useCallback(() => {
    dispatch(openDialog({ dialogType: "userSettings" }));
  }, []);

  return (
    <div className="header">
      <div className="header__inner">
        <Logo />
        <div className="header__right">
          {token && (
            <>
              <div onClick={openHistory} className="header__link-to-wrapper">
                <i className="header__link-to-icon icon-history" />
              </div>
              <div
                onClick={openSettings}
                className="header__link-to-wrapper header__ml8"
              >
                <i className="header__link-to-icon icon-settings" />
              </div>
              <div className="header__balance-widget-wrapper header__ml8">
                <BalanceWidget tooltipPosition="bottom" />
              </div>
              <div className="header__avatar-widget-wrapper header__ml8">
                <AvatarWidget />
              </div>
            </>
          )}
          <div
            onClick={openHowToPlayDialog}
            className="header__link-to-wrapper header__ml8"
          >
            <i className="header__link-to-icon icon-how-to-play" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(Header);
