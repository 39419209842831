import { Suspense, lazy, memo } from "react";
import { useSelector } from "react-redux";
import Loader from "../../common/Loader";
import { getSelectedTab } from "../../../store/selectors";
import Navigation from "../../Navigation";

const DesktopLobby = () => {
  const selectedTab = useSelector(getSelectedTab);

  const LazyView = lazy(() => {
    return import(
      `../${selectedTab?.charAt(0).toUpperCase() + selectedTab?.slice(1)}`
    );
  });

  return (
    <div className="lobby">
      <div className="lobby__left-right-wrapper">
        <div className="lobby__left">
          <div className="lobby__navigation-wrapper">
            <Navigation />
          </div>
          <div className="lobby__tab-view-wrapper">
            <Suspense fallback={<Loader />}>
              <LazyView />
            </Suspense>
          </div>
        </div>
        <div className="lobby__right" />
      </div>
    </div>
  );
};

export default memo(DesktopLobby);
