import types from '../actionTypes';

export const requestTournamentGameUrl = (game, rebuy, tournamentId, isRegular) => ({
  type: types.TOURNAMENT_GAME_URL_REQUEST,
  game,
  rebuy,
  tournamentId,
  isRegular,
});

export const successTournamentGameUrl = ({ url, tournamentId }, size, needToReload) => ({
  type: types.TOURNAMENT_GAME_URL_SUCCESS,
  value: { url, tournamentId, size, needToReload },
});

export const failTournamentGameUrl = (e) => ({
  type: types.TOURNAMENT_GAME_URL_FAILURE,
  exception: e,
});

export const resetTournamentGameUrl = () => ({
  type: types.TOURNAMENT_GAME_URL_RESET,
});
