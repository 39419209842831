import { isMobile } from "react-device-detect";
import { useSelector } from "react-redux";
import { getDesktopHowToPlayContentJackpot, getLanguage, getMobileHowToPlayContentJackpot } from "../../../store/selectors";
import settings from "../../../configs/settings";
import { updateImageSrc } from "../../../helpers/pureFunctions";

const HowToPlayJackpot = () => {
  const language = useSelector(getLanguage);
  const desktopHowToPlay = useSelector(getDesktopHowToPlayContentJackpot)(language);
  const mobileHowToPlay = useSelector(getMobileHowToPlayContentJackpot)(language);

  return (
    <div className="how-to-play-section how-to-play-section--jackpot">
      <div className="how-to-play-section__content" dangerouslySetInnerHTML={{ __html: isMobile ? updateImageSrc(mobileHowToPlay, settings?.FILE_STORAGE_ADDRESS) : updateImageSrc(desktopHowToPlay, settings?.FILE_STORAGE_ADDRESS) }} />
    </div>
  );
};

export default HowToPlayJackpot;
