import types from '../actionTypes';

export const jacpotsRequestWithDelay = () => ({
  type: types.GET_CASINO_TOURNAMENTS_JACKPOTS_WITH_DELAY_REQUEST,
});
export const jacpotsRequest = (id, isLastPlayer, setFirstTournamentAsSelected) => ({
  type: types.GET_CASINO_TOURNAMENTS_JACKPOTS_REQUEST,
  id,
  isLastPlayer,
  setFirstTournamentAsSelected,
});

export const jacpotsSuccess = (payload) => ({
  type: types.GET_CASINO_TOURNAMENTS_JACKPOTS_SUCCESS,
  payload,
});

export const jacpotsFailure = (e) => ({
  type: types.GET_CASINO_TOURNAMENTS_JACKPOTS_FAILURE,
  exception: e,
});

export const updateCasinoTournamentsJackpots = (payload) => ({
  type: types.UPDATE_CASINO_TOURNAMENTS_JACKPOTS,
  payload,
});

export const updateCasinoTournamentsJackpotFromSocketRequest = (payload) => ({
  type: types.UPDATE_CASINO_TOURNAMENTS_JACKPOTS_FROM_SOCKET_REQUEST,
  payload,
});

export const updateCasinoTournamentsJackpotFromSocketSuccess = (payload) => ({
  type: types.UPDATE_CASINO_TOURNAMENTS_JACKPOTS_FROM_SOCKET_SUCCESS,
  payload,
});

export const setJackpotPrizeGenerationBool = (payload) => ({
  type: types.JACKPOT_PRIZE_GENERATED_TOGGLE,
  payload,
});
