import { isMobile } from "react-device-detect";
import MobileHeaderStatistics from "./MobileHeaderStatistics";
import DesktopHeaderStatistics from "./DesktopHeaderStatistics";
import { memo } from "react";
import "./HeaderStatistics.scss";

const HEADER_STATISTICS = [
  { label: "prizesDistributed", icon: "prizesDistributed", value: "$3,000,000" },
  { label: "totalPlayers", icon: "totalPlayers", value: "22,544"},
  { label: "awardedPlayers", icon: "awardedPlayers", value: "6,318"},
  { label: "tournamentsAvailable", icon: "tournamentsAvailable", value: "84" },
  { label: "totalPrizePool", icon: "totalPrizePool", value: "$3,000,000" },
  { label: "dailyEntry", icon: "dailyEntry", value: "2,453" },
  { label: "onlinePlayers", icon: "onlinePlayers", value: "1401"},
  { label: "prizesDistributed", icon: "prizesDistributed", value: "$3,000,000" },
  { label: "totalPlayers", icon: "totalPlayers", value: "22,544"},
  { label: "awardedPlayers", icon: "awardedPlayers", value: "6,318"},
  { label: "tournamentsAvailable", icon: "tournamentsAvailable", value: "84" },
  { label: "totalPrizePool", icon: "totalPrizePool", value: "$3,000,000" },
  { label: "dailyEntry", icon: "dailyEntry", value: "2,453" },
  { label: "onlinePlayers", icon: "onlinePlayers", value: "1401"},
];

const HeaderStatistics = (props) => {
  const Component = isMobile ? MobileHeaderStatistics : DesktopHeaderStatistics;
  return <Component data={HEADER_STATISTICS} {...props} />;
};

export default memo(HeaderStatistics);
